import CircularProgress from "@material-ui/core/CircularProgress";


const Log = () => {
    return <>
            <div id="vm_log_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_log_tab-label">
                    <div className="row"><div className="large-12 columns vm_log_container monospace" style={{overflow: 'auto', height: 500}}><div className="text-center" style={{height: 100}}><span style={{fontSize: 80}}>
                        <CircularProgress className='loading-w40'/>
                    </span></div></div></div>
                </div>
            </>
}


export default Log;