import axiosService from "./axiosService";
import { baseUrl, serverUrl } from "../redux/contants/urlApi";

// 밴치마크 MQT list 가져오기
export const getBenchmarkScriptsList = async (type) => {
  // tpye = {'All' , 'Scalable' , 'Non-Scalable' }

  let api = baseUrl + "v1/benchmarks/circuits/?type=" + type;
  return await axiosService.get(api);
};

// 벤치마크 상세 정보 가져오기 - 사용하지 않음
export const getBenchmarkScriptsDetail = async (uuid) => {
  let api = baseUrl + "v1/benchmarks/circuits/" + uuid + "/detail";
  return await axiosService.get(api);
};

// 벤치마크 상세 정보 (이미지 , QASM) 가져오기
export const getBenchmarkScriptsDetailPreview = async (data) => {
  let api =
    baseUrl + "v1/benchmarks/circuits/" + data.benchmark_uuid + "/preview";
  return await axiosService.post(api, data);
};

// my 밴치마크 리스트 가져오기
export const getMyBenchmarkList = async () => {
  let api = baseUrl + "v1/benchmarks/my/";
  return await axiosService.get(api);
};

// my 벤치마크 생성
export const createMyBenchmark = async (data) => {
  let api = baseUrl + "v1/benchmarks/my/";
  return await axiosService.post(api, data);
};

// my 벤치마크 실행
export const executeMyBenchmark = async (data) => {
  let api =
    baseUrl + "v1/benchmarks/results/" + data.benchmark_uuid + "/execute";
  return await axiosService.post(api, data);
};

// my 벤치마크 결과 list 가져오기
export const getMyBenchmarkResults = async (uuid) => {
  let api = baseUrl + "v1/benchmarks/results/" + uuid + "/all";
  return await axiosService.get(api);
};

// my 벤치마크 결과 상세 정보 가져오기
export const getMyBenchmarkResultsDetail = async (uuid) => {
  let api = baseUrl + "v1/benchmarks/results/" + uuid + "/detail";
  return await axiosService.get(api);
};
