// styled components
import * as Styled from "./connectPort.styled.js";
import { useState, useEffect, useRef } from "react";
import ListTable from "../../../../../components/Table/ListTable.jsx";
// qubit list Component
import { useParams } from "react-router";
import axiosService from "../../../../../api/axiosService.js";
import { baseUrl } from "../../../../../redux/contants/urlApi.js";
import { tError, tSuccess } from "../../../../../common/CommonToast.jsx";
import { ConfirmDialog } from "../../../../../components/Dialog/index.jsx";

const ConnectPort = ({ setPageStep }) => {
  // 선택한 port 정보
  const [selectedPort, setSelectedPort] = useState(null);

  // connected qubits count 임시 변수
  const [tempConnectedQubitsCnt, setTempConnectedQubitsCnt] = useState(0);

  const [data, setData] = useState({ portList: [] });
  const [savePop, setSavePop] = useState(false);

  // instrument ID
  const { id } = useParams();

  useEffect(() => {
    handleFetch()
    console.log("selectedPort :", selectedPort)
  }, [])

  const handleDisconnect = (idx, rowData) => {
    let tempQubitList = data?.qubitList
    if (tempQubitList?.length > 0) {
      // 연결 제거
      let aftQubit = tempQubitList[idx]?.find(item => item == rowData)
      aftQubit.asignedChannel = '';

      // Disconnect 되면, Port List 데이터 수정.
      let tempPortList = data?.portList
      tempPortList?.map(item => {
        let channelName = item?.channelName
        let connectedQubitCnt = tempQubitList?.filter(qubitTable =>
          qubitTable?.find(qubitRow => qubitRow.asignedChannel == channelName)
        )?.length
        item.qubitsCnt = connectedQubitCnt
      })

      let tempSelectedPort = selectedPort
      tempSelectedPort.qubitsCnt = tempQubitList?.filter(item => (
        item?.find(row => row?.asignedChannel == selectedPort?.channelName)
      ))?.length

      setData((prev) => ({ ...prev, qubitList: tempQubitList, portList: tempPortList }))
      setSelectedPort(tempSelectedPort)
    }
  }

  const handleConnect = (idx, rowData) => {
    let tempQubitList = data?.qubitList
    if (tempQubitList?.length > 0) {
      // Qubit List당 1개씩만 연결되도록 처리
      let bfrQubit = tempQubitList[idx]?.find(item => item?.asignedChannel == selectedPort?.channelName);
      if (bfrQubit) {
        bfrQubit.asignedChannel = ''
      }
      let aftQubit = tempQubitList[idx]?.find(item => item == rowData)
      aftQubit.asignedChannel = selectedPort?.channelName;


      // Connect 되면, Port List 데이터 수정.
      let tempPortList = data?.portList
      tempPortList?.map(item => {
        let channelName = item?.channelName
        let connectedQubitCnt = tempQubitList?.filter(qubitTable =>
          qubitTable?.find(qubitRow => qubitRow.asignedChannel == channelName)
        )?.length
        item.qubitsCnt = connectedQubitCnt
      })

      let tempSelectedPort = selectedPort
      tempSelectedPort.qubitsCnt = tempQubitList?.filter(item => (
        item?.find(row => row?.asignedChannel == selectedPort?.channelName)
      ))?.length

      setData((prev) => ({ ...prev, qubitList: tempQubitList, portList: tempPortList }))
      setSelectedPort(tempSelectedPort)
    }
  }

  const renderQubitList = (item, index) => {
    return (
      <div className="row">
        <div className="sunstone-list large-12">
          <Styled.SQubitDivTitle>Qutit {index} List</Styled.SQubitDivTitle>
          <Styled.SQubitContainerDiv>
            <Styled.SQubitRowTitleDiv>
              <div>Qubit No</div>
              <div>Type</div>
              <div>Asigned Channel</div>
              <div>Actions</div>
            </Styled.SQubitRowTitleDiv>
            {item?.map((row) => {

              return (
                <Styled.SQubitRowDiv active={(selectedPort && (row?.asignedChannel == selectedPort?.channelName)) ? true : false}>
                  <div>{row?.qubitNo}</div>
                  <div>{row?.type}</div>
                  <div>{row?.asignedChannel}</div>
                  <div>
                    <Styled.SQubitButtonDiv>
                      <Styled.SQubitButton className="button" disabled={!selectedPort} onClick={() => { handleConnect(index, row) }}>Connect</Styled.SQubitButton>
                      <Styled.SQubitButton className="button alert" disabled={!selectedPort} onClick={() => { handleDisconnect(index, row) }}>Disconnect</Styled.SQubitButton>
                    </Styled.SQubitButtonDiv>
                  </div>
                </Styled.SQubitRowDiv>
              )
            })}
          </Styled.SQubitContainerDiv>
        </div>
      </div>
    )
  }

  const handleFetch = async () => {
    try {
      // Instrument Info 가져오기
      let instrumentRes = await axiosService.get(baseUrl + "v1/instruments/" + id+'/details');
      if (instrumentRes?.status == 200) {
        let instrumentData = instrumentRes?.data;
        let instrumentDriverUuid = instrumentData?.instrument_driver?.uuid;

        // Instrument Driver Channel Conf 가져오기
        let instrumentDriverChannelConfRes = await axiosService.get(baseUrl + "v1/instrument-drivers/" + instrumentDriverUuid + "/channels")
        if (instrumentDriverChannelConfRes?.status == 200) {
          let instrumentDriverChannelConfData = instrumentDriverChannelConfRes?.data
          instrumentDriverChannelConfData = instrumentDriverChannelConfData?.sort((a, b) => (parseInt(a.port_no) > parseInt(b.port_no) ? 1 : -1));
          instrumentDriverChannelConfData = instrumentDriverChannelConfData?.map((item, index) => ({
            id: index + 1
            , portName: item?.port_name
            , channelName: item?.channel_name
            , qubitsCnt: 0
          }))

          // Port List Setting
          setData((prev) => ({ ...prev, portList: instrumentDriverChannelConfData }))

          if (instrumentData?.circuit_id) {
            // Circuit 정보 조회
            let circuitListRes = await axiosService.get(baseUrl + "v1/circuits/")

            // Relation 정보 조회
            let relRes = await axiosService.get(baseUrl + "v1/instruments/" + id + "/channel-relations")

            // Circuit의 Qubit cnt만큼 qubitTable 데이터를 생성하고, 기존 Relation 데이터를 매핑한다.
            if (circuitListRes?.status == 200 && relRes?.status == 200) {
              let circuitListData = circuitListRes?.data;
              let instrumentCircuitData = circuitListData?.find(item => item.id == instrumentData?.circuit_id)
              let qubitList = [];

              // 기존 Relation 데이터
              let relData = relRes?.data;

              for (let i = 0; i < instrumentCircuitData?.qubit_count; i++) {
                let tempArray = [0, 1, 2, 3];
                let tempQubit = tempArray?.map((item, idx) => {
                  let connectedData = relData?.find(item => item.conn_qubit_no == i)
                  return {
                    id: item
                    , qubitNo: 'q' + i
                    , type: item == 0 ? 'readout' : item == 1 ? 'feedback' : item == 2 ? 'drive' : 'flux'
                    , asignedChannel: item == 0 ? connectedData?.conn_qubit_readout : item == 1 ? connectedData?.conn_qubit_feedback : item == 2 ? connectedData?.conn_qubit_drive : connectedData?.conn_qubit_flux
                  }
                }
                )

                qubitList.push(tempQubit);
              }

              // 기존 Relation 되어있는 데이터를 PortList에 매핑한다.
              instrumentDriverChannelConfData?.map(portItem => {
                let channelName = portItem?.channelName
                portItem.qubitsCnt = qubitList?.filter(qubitTable =>
                  qubitTable?.find(qubitRow => qubitRow?.asignedChannel == channelName)
                )?.length
              })

              // baseRelationData 만들기
              let baseRelationData = qubitList?.map((_, qubitIdx) => {
                let tempRelData = relData?.find(item => item.conn_qubit_no == qubitIdx)


                return ({
                  uuid: tempRelData?.uuid
                  , conn_qubit_no: qubitIdx
                  , conn_qubit_readout: tempRelData?.conn_qubit_readout
                  , conn_qubit_feedback: tempRelData?.conn_qubit_feedback
                  , conn_qubit_drive: tempRelData?.conn_qubit_drive
                  , conn_qubit_flux: tempRelData?.conn_qubit_flux
                  , type: tempRelData?.uuid ? 'R' : 'C'
                })
              })

              setData((prev) => ({ ...prev, qubitList: qubitList, baseRelationData: baseRelationData }))
            }
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const portColumns = [
    { id: "id", label: "ID", minWidth: 10, hidden: true },
    { id: "portName", label: "Port Name", minWidth: 20 },
    { id: "channelName", label: "Channel Name", minWidth: 20 },
    {
      id: "qubitsCnt",
      label: "Connected Qubits Cnt.",
      minWidth: 20,
    },
  ];

  // port row 선택 시
  const setPortRowEvt = (rowData) => {
    setSelectedPort(rowData);
  };

  // port row 선택 해제
  const unsetRowEvt = () => {
    setSelectedPort(null);
  };

  const handlePopupResult = async (rslt) => {
    if (rslt?.result) {
      // 변경전 데이터 (DB 데이터)
      // console.log("baseRelationData : ",data?.baseRelationData)

      // 변경후 데이터
      let tempQubitList = data?.qubitList
      let newQubitRelation = tempQubitList.map((qubitTable, idx) => ({
        uuid: null
        , conn_qubit_no: idx
        , conn_qubit_readout: qubitTable?.find(item => item.type == 'readout')?.asignedChannel
        , conn_qubit_feedback: qubitTable?.find(item => item.type == 'feedback')?.asignedChannel
        , conn_qubit_drive: qubitTable?.find(item => item.type == 'drive')?.asignedChannel
        , conn_qubit_flux: qubitTable?.find(item => item.type == 'flux')?.asignedChannel
        , type: null
      }))

      // 비교
      newQubitRelation.map(item => {
        let orgData = data?.baseRelationData?.find(orgItem => orgItem.conn_qubit_no == item.conn_qubit_no)

        item.uuid = orgData?.uuid;

        if (orgData?.type == 'C') {
          item.type = 'C'
        } else {
          item.type = orgData?.type;
          if (item.conn_qubit_readout != orgData?.conn_qubit_readout) { item.type = 'M' }
          if (item.conn_qubit_feedback != orgData?.conn_qubit_feedback) { item.type = 'M' }
          if (item.conn_qubit_drive != orgData?.conn_qubit_drive) { item.type = 'M' }
          if (item.conn_qubit_flux != orgData?.conn_qubit_flux) { item.type = 'M' }
        }
      })

      console.log("newQubitRelation :", newQubitRelation)
      // CreateList
      let createTargetList = newQubitRelation?.filter(item => item.type == 'C');
      let updateTargetList = newQubitRelation?.filter(item => item.type == 'M');
      let createRelationRes = null
      let updateRelationResList = []

      if (createTargetList?.length > 0) {
        createRelationRes = await axiosService.post(baseUrl + "v1/instruments/" + id + "/channel-relations", createTargetList);
      }

      if (updateTargetList?.length > 0) {
        // updateRelationResList.push(await axiosService)
        for (let i = 0; i < updateTargetList.length; i++) {
          let tempRes = await axiosService.put(baseUrl + "v1/instruments/" + id + "/channel-relations/" + updateTargetList[i]?.uuid, updateTargetList[i]);
          updateRelationResList.push(tempRes);
        }
      }

      if (createTargetList?.length == 0 && updateTargetList?.length == 0) {
        tSuccess("Success")
        setSelectedPort(null)
        setSavePop(false)
        return
      }

      if (createTargetList?.length > 0 && updateTargetList?.length > 0) {
        if (createRelationRes?.status == 201 && updateRelationResList?.filter(item => item?.status == 201)?.length == updateTargetList?.length) {
          tSuccess("Success")
          setSelectedPort(null)
          handleFetch()
        }
      } else if (createTargetList?.length > 0 && updateTargetList?.length <= 0) {
        if (createRelationRes?.status == 201) {
          tSuccess("Success")
          setSelectedPort(null)
          handleFetch()
        }
      } else if (updateTargetList?.length > 0 && createTargetList?.length <= 0) {
        if (updateRelationResList?.filter(item => item?.status == 201)?.length == updateTargetList?.length) {
          tSuccess("Success")
          setSelectedPort(null)
          handleFetch()
        }
      } else {
        tError("Faild")
      }
    }

    setSavePop(false);
  }

  const renderConfirmPop = () => {

    const runPopContents = {
      title: "Confirm",
      contents: (
        <div>
          <div style={{ bottom: '0.5rem' }}>
            저장하시겠습니까?
          </div>
        </div>
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <ConfirmDialog
        callback={handlePopupResult}
        contents={runPopContents} />
    )

  }

  return (
    <>
      <Styled.SLayoutDiv>
        <div className="row">
          {/* back button */}
          <div className="sunstone-list large-1 columns">
            <button
              className="button"
              onClick={() => {
                setPageStep(1);
              }}
            >
              Back
            </button>
          </div>
        </div>

        {/* Port 컴포넌트 */}
        <div className="row">
          <div className="sunstone-list large-8">
            <ListTable
              cols={portColumns}
              list={data?.portList}
              selectCallback={setPortRowEvt}
              deselectCallback={unsetRowEvt}
              title={"Port List"}
              noPagination={true}
              height={250}
              maxHeight={300}
              selectRow={data?.portList?.findIndex(item => item == selectedPort)}
            />
          </div>
        </div>

        <>
          {
            data?.qubitList?.map((item, index) => (
              renderQubitList(item, index)
            ))
          }
          <div className="row ">
            <div className="sunstone-list large-12 columns">
              <div className="text-right" style={{marginTop:'1rem'}}>
                <button className="button" onClick={()=>{setSavePop(true)}}>Save</button>
              </div>
            </div>
          </div>
        </>
      </Styled.SLayoutDiv>
      {savePop && renderConfirmPop()}
    </>
  );
};

// port 클릭시 qubit list 활성화

export default ConnectPort;
