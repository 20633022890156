



const ScheduleAction = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                &nbsp; {icon3}&nbsp;{name} </a>
            <div id="advanced_section_5" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="large-12 columns actionContext0">
                        <fieldset>
                            <style dangerouslySetInnerHTML={{ __html: "\n                                    .title_label {\n                                        font-weight: bold;\n                                        color: #555;\n                                    }\n                                " }} />
                            <table id="scheduling_inst_actions_table" className="info_table dataTable">
                                <thead>
                                    <tr>
                                        <th> Action </th>
                                        <th> Time </th>
                                        <th> Rep </th>
                                        <th> Ends </th>
                                        <th colSpan />
                                        <th>
                                            <button id="add_scheduling_inst_action" className="button small success right radius"> Add action </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody id="sched_inst_actions_body" />
                            </table>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ScheduleAction;