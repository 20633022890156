

const Index = ({handleClickOsTabone1}) => {
    return <ul className="tabs vertical" id="osTabs" data-tabs="afchsc-tabs">
                <li className="tabs-title is-active osTabs-item" role="presentation">
                    <a onClick={e => handleClickOsTabone1(e)} href="javascript:void(0)" role="tab" aria-controls="bootTabone4" aria-selected="true" id="bootTabone4-label">Boot</a>
                </li>
                <li className="tabs-title osTabs-item" role="presentation">
                    <a onClick={e => handleClickOsTabone1(e)} href="javascript:void(0)" role="tab" aria-controls="featuresTabone4" aria-selected="false" id="featuresTabone4-label">Features</a>
                </li>
                <li className="tabs-title osTabs-item" role="presentation">
                    <a onClick={e => handleClickOsTabone1(e)} href="javascript:void(0)" role="tab" aria-controls="cpuTabone4" aria-selected="false" id="cpuTabone4-label">CPU Model</a>
                </li>
            </ul>
}

export default Index;