import * as React from 'react';
import "./index.css"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

const FieldGroup = ({ label, foldable, defaultFold, children, classNames }) => {
    const [fold , setFold] = React.useState(false)
    const themeColor = process.env?.REACT_APP_THEME;

    const handleClick = () => {
        if(foldable){
            setFold(!fold)
        }
    }

    React.useEffect(()=>{
        if(defaultFold){
            setFold(defaultFold)
        }
    },[])

    return (
        <div className={`${themeColor==='dark'?"fieldgroup-dark-theme":""} ${classNames}`}>
            { label &&
            <div className={`fieldgroup-title-container ${foldable?'foldable':''}`} onClick={handleClick}>
                <div className={`fieldgroup-label ${themeColor==='dark'?"fieldgroup-label-dark-theme":""}`}>{label}</div>
                {foldable&&<>
                    <ArrowBackIosIcon className={`fieldgroup-title-fold-icn ${fold ? 'fold' : ''}`}/>
                </>}
            </div>
            }
            {!fold&&<main>{children}</main>}
        </div>
    )
}


export default FieldGroup