import { createContext, useContext, useState } from "react";

const INITIAL_STATE = {
  isShowSideBar: false,
  currentActiveSideBar: "",
  currentActiveSideBarChild: "",
};

const ContentContext = createContext(INITIAL_STATE);

export function ContentProvider({ children }) {
  const [isShowSideBar, setIsShowSideBar] = useState(true);
  const [currentActiveSideBar, setCurrentActiveSideBar] = useState("");
  const [currentActiveSideBarChild, setCurrentActiveSideBarChild] =
    useState("");
  // 1 depth 메뉴 active
  const [currentActiveSideBarActive, setCurrentActiveSideBarActive] =
    useState("");

  const value = {
    isShowSideBar,
    setIsShowSideBar,
    currentActiveSideBar,
    setCurrentActiveSideBar,
    currentActiveSideBarChild,
    setCurrentActiveSideBarChild,
    currentActiveSideBarActive,
    setCurrentActiveSideBarActive,
  };

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
}

export const useContentContext = () => useContext(ContentContext);
