import DefaultLayout from "../../../layouts/DefaultLayout";
import TabItem from "../../../components/Tab";
import CustomIcon from "../../../components/Icon";
import Info from "./Path/Info/Information";
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import { useEffect, useState, React } from "react";
import SkeletonPage from "../../../components/SkeletonPage";
import AlertDialog from "../../../components/Dialog";
import { tSuccess } from "../../../common/CommonToast";
// import Benchmark from "./Path/Benchmark/Benchmark";

import { ResultTab } from "./Path/Result";

// import Excute Benchmark
import ExcuteBenchmark from "../../Benchmark/excuteBenchmark/Index";
import { useParams } from "react-router";
import axiosService from "../../../api/axiosService";
import { baseUrl } from "../../../redux/contants/urlApi";
import { convertDateFormat } from "../../../common/CommonUtil";

import Conf from "./Path/Conf";

const Index = () => {
  const history = useHistory();
  const [data, setData] = useState({
    stationInfo: {},
    controllerInfo: {},
    propsData: [],
    setting: {},
  });
  const { id } = useParams();
  const tabData = [
    {
      tabId: "info",
      tabLabel: "Info",
      icon: <CustomIcon type="faInfoCircle" />,
      contents: <Info></Info>,
    },
    {
      tabId: "conf",
      tabLabel: "config",
      icon: <CustomIcon type="faLaptop" />,
      contents: <Conf data={data.setting} setData={setData} id={id}></Conf>,
    },
    {
      tabId: "result",
      tabLabel: "Result",
      icon: <CustomIcon type="faLaptop" />,
      contents: <ResultTab propsData={data?.propsData} />,
    },
    {
      tabId: "benchmark",
      tabLabel: "Benchmark",
      icon: <CustomIcon type="faLaptop" />,
      contents: <ExcuteBenchmark ware={data?.stationInfo?.name} />,
    },
  ];

  useEffect(() => {
    // Station Data 조회
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    let res = await axiosService.get(
      baseUrl + "v1/stations/" + id + "/details"
    );
    if (res?.status == 200) {
      setData((prev) => ({
        ...prev,
        stationInfo: res?.data,
        setting: res?.data?.settings,
      }));
    }
  };

  const handlePopupResult = ({ id, result }) => {
    console.log("id : ", id, " , bool : ", result);
    if (id == "run" && result) {
      let tempData = [
        {
          instruments: "SQK Instrument",
          circuit: "QSVM",
          status: "RUNNING",
          createdAt: convertDateFormat(new Date()),
          finishedAt: "",
          id: 1,
        },
        {
          instruments: "SQK Instruments",
          circuit: "QSVM",
          status: "FINISHED",
          createdAt: "2024.01.13 17:52:14",
          finishedAt: "2024.01.13 18:02:04",
          id: 2,
        },
        {
          instruments: "Dummy Instruments",
          circuit: "ces2024-QSVM",
          status: "FINISHED",
          createdAt: "2024.1.13 17:19:37",
          finishedAt: "2024.1.13 17:32:04",
          id: 3,
        },
      ];
      setTimeout(() => {
        tSuccess("The request was success");
        setData((prev) => ({ ...prev, propsData: tempData }));
      }, 500);
    }
  };

  const runPopContents = {
    title: (
      <div style={{ fontSize: "13pt", fontWeight: "bold" }}>Run Station</div>
    ),
    contents: (
      <div>
        <div style={{ fontSize: "11pt", marginBottom: "1rem" }}>
          Current Information
        </div>
        <div style={{ fontSize: "11pt" }}>Station : SQK-Station</div>
        <div style={{ fontSize: "11pt" }}>
          Controller : SQK-Instruments (0.6.3)
        </div>
        <div style={{ fontSize: "11pt" }}>Circuit : Circuit Model 1.9.2</div>
        <div style={{ fontWeight: "bold", fontSize: "11pt" }}>
          <br />
          Execute the Station with the information?
        </div>
      </div>
    ),
    agreeBtnLabel: "OK",
    disagreeBtnLabel: "Cancel",
  };

  return (
    <>
      <DefaultLayout title={"Station detail"}>
        <div className="sunstone-list">
          <CustomButton
            type="goback"
            onClick={() => {
              history.push("/platform/list");
            }}
          />
          <CustomButton
            type="sync"
            onClick={() => {
              fetchDataList();
            }}
          />
          <AlertDialog
            type="run"
            id="run"
            disabled={false}
            callback={handlePopupResult}
            contents={runPopContents}
          />
          <TabItem tabData={tabData} />
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
