// import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import "./index.css";

function index({
  height,
  readOnly,
  value,
  onChange,
  handleKeyUp,
  error = false,
  handleKeyDown,
}) {
  const className = `${error ? "error-textarea" : "dark-textarea"} scrollable-textarea`;

  return (
    <TextareaAutosize
      className={className}
      readOnly={readOnly ? readOnly : false}
      value={value}
      onChange={onChange}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      style={{
        height: height ? height : "100%",
      }}
    />
  );
}

export default index;
