import DefaultLayout from "../../layouts/DefaultLayout";

import "./Main.css";
import * as Styled from "./main.styled";
import { useEffect, useState } from "react";

import SkeletonPage from "../../components/SkeletonPage";

import CustomIcon from "../../components/Icon";
import TabItem from "../../components/Tab";

// Tab Contents
import Scalable from "./content/scalable/Index";
import NonScalable from "./content/non-scalable/Index";
import MyBenchmarks from "./content/myBenchmark/Index";

const Main = () => {
  const [loading, setLoading] = useState(true);

  // Tab Layout
  const tabData = [
    {
      tabId: "Scalable",
      tabLabel: "Scalable",
      icon: <CustomIcon type="faClone" />,
      contents: <Scalable tabId={"Scalable"} />,
    },
    {
      tabId: "Non-Scalable",
      tabLabel: "Non-Scalable",
      icon: <CustomIcon type="faExpand" />,
      contents: <NonScalable tabId={"Non-Scalable"} />,
    },
    {
      tabId: "myBenchmarks",
      tabLabel: "My Benchmarks",
      icon: <CustomIcon type="faFolderOpen" />,
      contents: <MyBenchmarks tabId={"myBenchmarks"} />,
    },
  ];

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <div className="row">
        <div className="small-12 columns sunstone-content large-10">
          {loading ? (
            <SkeletonPage title={"Please Wait..."} />
          ) : (
            <>
              <TabItem tabData={tabData} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Main;
