


const Index = () => {
    return <>
        <div className="wizard_internal_tab is-active tabs-panel bootTab" id="bootTabone4" role="tabpanel" aria-hidden="false" aria-labelledby="bootTabone4-label">
                            <div className="row">
                                <div className="medium-4 columns">
                                <label> CPU Architecture <select wizard_field="ARCH">
                                    <option value />
                                    <option value="i686">i686</option>
                                    <option value="x86_64">x86_64</option>
                                    </select>
                                </label>
                                </div>
                                <div className="medium-8 columns hypervisor only_kvm">
                                <label id="kvm-info"> machine type <select id="machine-type" wizard_field="MACHINE">
                                    <option value />
                                    </select>
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="medium-4 columns">
                                <label> Root device <input type="text" wizard_field="ROOT" placeholder="sda1" />
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="medium-8 columns">
                                <label> Boot order <span data-tooltip="q45kl1-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="7msrwj-tooltip" data-yeti-box="7msrwj-tooltip" data-toggle="7msrwj-tooltip" data-resize="7msrwj-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                </label>
                                <table className="boot-order dataTable" value>
                                    <tbody>
                                    <tr>
                                        <td>Disks and NICs will appear here</td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="large-12 columns">
                                <label> Kernel boot parameters <input type="text" wizard_field="KERNEL_CMD" placeholder="ro console=tty1" />
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                <label> Path to the bootloader executable <input type="text" wizard_field="BOOTLOADER" />
                                </label>
                                </div>
                            </div>
                            </div>
                            <div id="kernelTabone4" className="wizard_internal_tab tabs-panel kernelTab" role="tabpanel" aria-hidden="true" aria-labelledby="kernelTabone4-label">
                            <div className="row">
                                <div className="large-12 columns text-center">
                                <input id="radioKernelDsone4" type="radio" name="kernel_type" defaultValue="kernel_ds" defaultChecked />
                                <label htmlFor="radioKernelDsone4">Registered Image</label>
                                <input id="radioKernelPathone4" type="radio" name="kernel_type" defaultValue="kernel_path" />
                                <label htmlFor="radioKernelPathone4">Remote path</label>
                                </div>
                            </div>
                            <br />
                            <div className="kernel_ds">
                                <div id="osTabone1one2Container">
                                <div className="row select-resources">
                                    <div className="small-12 medium-6 columns" id="selected_ids_row_osTabone1one2">
                                    <label htmlFor="selected_resource_id_osTabone1one2" id="select_resource_osTabone1one2">Please select a file from the list</label>
                                    <span id="selected_resource_osTabone1one2" hidden style={{display: 'none'}}>You selected the following file:</span>
                                    <span id="select_resource_multiple_osTabone1one2" hidden style={{display: 'none'}}>Please select one or more files from the list</span>
                                    <span id="selected_resource_multiple_osTabone1one2" hidden style={{display: 'none'}}>You selected the following files:</span>
                                    <span id="selected_resource_name_osTabone1one2" className="radius label" style={{display: 'none'}} />
                                    <input id="selected_resource_id_osTabone1one2" type="text" style={{visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline'}} />
                                    </div>
                                    <div className="small-10 medium-4 columns right">
                                    <input id="osTabone1one2_search" className="search" type="search" placeholder="Search" />
                                    </div>
                                    <div className="small-2 columns right">
                                    <a id="refresh_button_osTabone1one2" href="#" className="refresh-table right">
                                        <i className="fas fa-lg fa-fw fa-sync-alt" />
                                    </a>
                                    </div>
                                </div>
                                <div id="osTabone1one2_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                    <table id="osTabone1one2" className="hover dataTable no-footer" role="grid" aria-describedby="osTabone1one2_info">
                                    <thead>
                                        <tr role="row">
                                        <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="osTabone1one2" aria-sort="descending">ID</th>
                                        <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Name</th>
                                        <th aria-label="Owner: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Owner</th>
                                        <th aria-label="Group: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Group</th>
                                        <th aria-label="Datastore: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Datastore</th>
                                        <th aria-label="Type: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Type</th>
                                        <th aria-label="Status: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one2">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                        <td valign="top" colSpan={7} className="dataTables_empty">
                                            <span className="text-center" style={{fontSize: 18, color: '#999'}}>
                                            <br />
                                            <span className="fa-stack fa-3x" style={{color: '#dfdfdf'}}>
                                                <i className="fas fa-cloud fa-stack-2x" />
                                                <i className="fas fa-info-circle fa-stack-1x fa-inverse" />
                                            </span>
                                            <br />
                                            <span style={{color: '#999'}}>There is no data available</span>
                                            </span>
                                            <br />
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div className="row">
                                    <div className="small-6 columns">
                                        <div className="dataTables_length" id="osTabone1one2_length">
                                        <label>
                                            <select name="osTabone1one2_length" aria-controls="osTabone1one2" className>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            </select>
                                        </label>
                                        </div>
                                        <div className="dataTables_info" id="osTabone1one2_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                    </div>
                                    <div className="small-6 columns">
                                        <div className="dataTables_paginate paging_simple_numbers" id="osTabone1one2_paginate">
                                        <ul className="pagination">
                                            <li className="paginate_button previous unavailable disabled" aria-controls="osTabone1one2" tabIndex={0} id="osTabone1one2_previous">Previous</li>
                                            <li className="paginate_button next unavailable disabled" aria-controls="osTabone1one2" tabIndex={0} id="osTabone1one2_next">Next</li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div id="kernel_ds_inputs" className="row">
                                <div className="large-12 columns">
                                    <label> KERNEL_DS <input type="text" wizard_field="KERNEL_DS" id="KERNEL_DS" name="KERNEL_DS" />
                                    </label>
                                </div>
                                </div>
                            </div>
                            <div id="kernel_path_inputs" className="kernel_path row" hidden>
                                <div className="large-12 columns">
                                <label> Path to the OS kernel to boot the image <input type="text" wizard_field="KERNEL" />
                                </label>
                                </div>
                            </div>
                            </div>
                            <div id="ramdiskTabone4" className="wizard_internal_tab tabs-panel ramdiskTab" role="tabpanel" aria-hidden="true" aria-labelledby="ramdiskTabone4-label">
                            <div className="row">
                                <div className="large-12 columns text-center">
                                <input id="radioInintrdDsone4" type="radio" name="initrd_type" defaultValue="initrd_ds" defaultChecked />
                                <label htmlFor="radioInintrdDsone4">Registered Image </label>
                                <input id="radioInitrdPathone4" type="radio" name="initrd_type" defaultValue="initrd_path" />
                                <label htmlFor="radioInitrdPathone4">Remote path</label>
                                </div>
                            </div>
                            <br />
                            <div className="initrd_ds">
                                <div id="osTabone1one3Container">
                                <div className="row select-resources">
                                    <div className="small-12 medium-6 columns" id="selected_ids_row_osTabone1one3">
                                    <label htmlFor="selected_resource_id_osTabone1one3" id="select_resource_osTabone1one3">Please select a file from the list</label>
                                    <span id="selected_resource_osTabone1one3" hidden style={{display: 'none'}}>You selected the following file:</span>
                                    <span id="select_resource_multiple_osTabone1one3" hidden style={{display: 'none'}}>Please select one or more files from the list</span>
                                    <span id="selected_resource_multiple_osTabone1one3" hidden style={{display: 'none'}}>You selected the following files:</span>
                                    <span id="selected_resource_name_osTabone1one3" className="radius label" style={{display: 'none'}} />
                                    <input id="selected_resource_id_osTabone1one3" type="text" style={{visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline'}} />
                                    </div>
                                    <div className="small-10 medium-4 columns right">
                                    <input id="osTabone1one3_search" className="search" type="search" placeholder="Search" />
                                    </div>
                                    <div className="small-2 columns right">
                                    <a id="refresh_button_osTabone1one3" href="#" className="refresh-table right">
                                        <i className="fas fa-lg fa-fw fa-sync-alt" />
                                    </a>
                                    </div>
                                </div>
                                <div id="osTabone1one3_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                    <table id="osTabone1one3" className="hover dataTable no-footer" role="grid" aria-describedby="osTabone1one3_info">
                                    <thead>
                                        <tr role="row">
                                        <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="osTabone1one3" aria-sort="descending">ID</th>
                                        <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Name</th>
                                        <th aria-label="Owner: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Owner</th>
                                        <th aria-label="Group: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Group</th>
                                        <th aria-label="Datastore: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Datastore</th>
                                        <th aria-label="Type: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Type</th>
                                        <th aria-label="Status: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="osTabone1one3">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="odd">
                                        <td valign="top" colSpan={7} className="dataTables_empty">
                                            <span className="text-center" style={{fontSize: 18, color: '#999'}}>
                                            <br />
                                            <span className="fa-stack fa-3x" style={{color: '#dfdfdf'}}>
                                                <i className="fas fa-cloud fa-stack-2x" />
                                                <i className="fas fa-info-circle fa-stack-1x fa-inverse" />
                                            </span>
                                            <br />
                                            <span style={{color: '#999'}}>There is no data available</span>
                                            </span>
                                            <br />
                                        </td>
                                        </tr>
                                    </tbody>
                                    </table>
                                    <div className="row">
                                    <div className="small-6 columns">
                                        <div className="dataTables_length" id="osTabone1one3_length">
                                        <label>
                                            <select name="osTabone1one3_length" aria-controls="osTabone1one3" className>
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            </select>
                                        </label>
                                        </div>
                                        <div className="dataTables_info" id="osTabone1one3_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                    </div>
                                    <div className="small-6 columns">
                                        <div className="dataTables_paginate paging_simple_numbers" id="osTabone1one3_paginate">
                                        <ul className="pagination">
                                            <li className="paginate_button previous unavailable disabled" aria-controls="osTabone1one3" tabIndex={0} id="osTabone1one3_previous">Previous</li>
                                            <li className="paginate_button next unavailable disabled" aria-controls="osTabone1one3" tabIndex={0} id="osTabone1one3_next">Next</li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                </div>
                                <div className="row">
                                <div className="large-12 columns">
                                    <label> INITRD_DS <input type="text" wizard_field="INITRD_DS" id="INITRD_DS" name="initrd_id" />
                                    </label>
                                </div>
                                </div>
                            </div>
                            <div id="initrd_path_inputs" className="initrd_path" hidden>
                                <div className="row">
                                <div className="large-12 columns">
                                    <label> Path to the initrd image <input type="text" wizard_field="INITRD" />
                                    </label>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="wizard_internal_tab tabs-panel featuresTab" id="featuresTabone4" role="tabpanel" aria-hidden="true" aria-labelledby="featuresTabone4-label">
                            <div className="row">
                                <div className="medium-6 columns">
                                <label> ACPI <span data-tooltip="eqfrds-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="y6tyu9-tooltip" data-yeti-box="y6tyu9-tooltip" data-toggle="y6tyu9-tooltip" data-resize="y6tyu9-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="ACPI">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                                <div className="medium-6 columns">
                                <label> PAE <span data-tooltip="cq441v-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="4so3xw-tooltip" data-yeti-box="4so3xw-tooltip" data-toggle="4so3xw-tooltip" data-resize="4so3xw-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="PAE">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="medium-6 columns">
                                <label> APIC <span data-tooltip="3g7k3d-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="x5vmfw-tooltip" data-yeti-box="x5vmfw-tooltip" data-toggle="x5vmfw-tooltip" data-resize="x5vmfw-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="APIC">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                                <div className="medium-6 columns">
                                <label> HYPERV <span data-tooltip="6yqjwp-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="twbmbm-tooltip" data-yeti-box="twbmbm-tooltip" data-toggle="twbmbm-tooltip" data-resize="twbmbm-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="HYPERV">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="medium-6 columns">
                                <label> Localtime <span data-tooltip="xdguwd-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="pw3dqo-tooltip" data-yeti-box="pw3dqo-tooltip" data-toggle="pw3dqo-tooltip" data-resize="pw3dqo-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="LOCALTIME">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                                <div className="medium-6 columns">
                                <label> QEMU Guest Agent <span data-tooltip="kfm5zv-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="v0ooss-tooltip" data-yeti-box="v0ooss-tooltip" data-toggle="v0ooss-tooltip" data-resize="v0ooss-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="GUEST_AGENT">
                                    <option value />
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                    </select>
                                </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="medium-6 columns">
                                <label> virtio-scsi Queues <span data-tooltip="88ry9u-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="zur7oa-tooltip" data-yeti-box="zur7oa-tooltip" data-toggle="zur7oa-tooltip" data-resize="zur7oa-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <select wizard_field="VIRTIO_SCSI_QUEUES">
                                    <option value />
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={4}>4</option>
                                    <option value={5}>5</option>
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                    <option value={13}>13</option>
                                    <option value={14}>14</option>
                                    <option value={15}>15</option>
                                    <option value={16}>16</option>
                                    </select>
                                </label>
                                </div>
                            </div>
                            </div>
                            <div className="wizard_internal_tab tabs-panel cpuTab" id="cpuTabone4" role="tabpanel" aria-hidden="true" aria-labelledby="cpuTabone4-label">
                            <div className="row">
                                <div className="medium-4 columns">
                                <label id="cpu-model"> CPU Model <select id="model-cpu" wizard_field="MODEL">
                                    <option value />
                                    <option value="host-passthrough">host-passthrough</option>
                                    </select>
                                </label>
                                </div>
                            </div>
                            </div>
    </>
}


export default Index;