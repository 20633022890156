import React, { useEffect, useLayoutEffect, useState } from "react";
import TableArea from "../../../Create/TableArea";
import InputField from "../InputField";



function index({
  isReset,
  setIsReset,
  modelElement,
  setModelElement,
  setSelectedOption,
  isvalidation,
  setIsvalidation,
  selectedOption,
  setCurrentUuid,
  deleteChannel,
  isModalOpen,
  setIsModalOpen,
}) {


  return (
    <>
      <InputField
        modelElement={modelElement}
        setModelElement={setModelElement}
        setSelectedOption={setSelectedOption}
        setIsReset={setIsReset}
        isReset={isReset}
        isvalidation={isvalidation}
        setIsvalidation={setIsvalidation}
        selectedOption={selectedOption}
        setCurrentUuid={setCurrentUuid}
        deleteChannel={deleteChannel}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}

export default index;
