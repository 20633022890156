


const Placement = () => {
    return <>
    <div id="vm_placement_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_placement_tab-label">
    <div className="row">
        <div className="large-12 columns">
               <table id="vm_history_table" className="dataTable">
                                   <thead>
                                                         
                                <tr>                          
                                <th>#</th>                          
                                <th>Host</th>                          
                                <th>Datastore</th>                          
                                <th>Action</th>                          
                                <th>UID</th>                          
                                <th>GID</th>                          
                                <th>ReqID</th>                          
                                <th>Change time</th>                          
                                <th>Total time</th>                          
                                <th colSpan={2}>Prolog time</th>                    
                                </tr>                  
                                </thead>                    
                                <tbody>     
                                    <tr>                
                                        <td colSpan={8} style={{width: '5%'}}>No data available in table</td>             
                                    </tr>
                                </tbody>               
                </table>         
    </div>       
</div>
<div className="row">         
<div className="large-12 columns">           
<table id="vm_ds_placement_table" className="dataTable">                    
<thead>                      
    <tr>                          
        <th align="center">Sched Message</th>                    
        </tr>                  
</thead>                    
<tbody>                       
    <tr>                        
        <td>Sun Dec 31 12:10:19 2023 : Cannot dispatch VM to any Host. Possible reasons: Not enough capacity in Host or System DS, or dispatch limit reached</td>                    
        </tr>                  
        </tbody>         
</table>         
</div>       
</div>
<div className="row">       
<div className="large-9 columns">           
<table id="vm_placement_table" className="dataTable">                    
<thead>                      
    <tr>                          
        <th colSpan={2} align="center">Placement - Host</th>                    
        </tr>                  
</thead>                    
<tbody>                       
    <tr>                        
        <td>Requirements</td>                        
        <td>-</td>                    
    </tr>                       
    <tr>                        
        <td>Rank</td>                        
        <td>-</td>                    
    </tr>                  
</tbody>         
</table>           
<table id="vm_ds_placement_table" className="dataTable">                    
<thead>                      
    <tr>                          
        <th colSpan={2} align="center">Placement - Datastore</th>                    
        </tr>                  
</thead>                    
<tbody>                       
    <tr>                        
        <td>DS Requirements</td>                        
        <td>-</td>                    
    </tr>                       
    <tr>                        
        <td>DS Rank</td>                        
        <td>-</td>                    
    </tr>                  
</tbody>         
</table>         
</div>       
</div>
  </div>
    </>
}

export default Placement;