import "./Sidebar.css";
import Item from "./Item/Index";
import MenuIcon from '@mui/icons-material/Menu';

import { useContentContext } from "../../context/ContentContext";
import { sidebarLink } from "../../constants";

const Index = () => {
  const themeColor = process.env?.REACT_APP_THEME;
  const { isShowSideBar, setIsShowSideBar } = useContentContext();

  return (
    <div id="menu-wrapper" className={`${isShowSideBar ? "menu-layout-2" : "menu-layout-2-fold"} ${isShowSideBar ? "" : "close"} ${themeColor==='dark'?"dark-theme-bgc dark-theme-rb":""}`} style={{ position: "relative", width:"100%"}}>
      <div className={`menu_toggle ${!isShowSideBar && "close"}`} onClick={() => setIsShowSideBar(!isShowSideBar)}>
        {/* <img src="/assets/icons/menu.svg" alt="Menu" /> */}
        <MenuIcon className={`${themeColor==='dark'?'custom-side-menu-toggle-dark':'custom-side-menu-toggle'}`} />
      </div>
      <div id="menu">
        {sidebarLink && (
          <ul className="vertical menu-2" id="navigation">
            {sidebarLink.map((element, index) => (
                <Item
                  key={element?.title + index}
                  icon={element?.icon}
                  title={element?.title}
                  sub={element?.sub}
                  link={element?.link}
                  isPop={element?.isPop}
                />
            ))}
          </ul>
        )}
      </div>
      {isShowSideBar && (
        <div id="footer" className="text-center large-12 columns">
          <p className="footer-content">Super Quantum Station 1.0</p>
          <p className="footer-content">SQK Inc</p>
        </div>
      )}
    </div>
  );
};

export default Index;