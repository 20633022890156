import React from "react";
import TextArea from "../../components/TextArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Editor({
  fromText,
  toText,
  handleTextChange,
  handleKeyUp,
  error,
}) {



  const handleKeyDown = (e) => {
    const key = e.key; 
  
    const allowedKeys = [
      ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i)),
      ...Array.from({ length: 26 }, (_, i) => String.fromCharCode(97 + i)),
      ...Array.from({ length: 10 }, (_, i) => String.fromCharCode(48 + i)),
      " ",
      ";",
      " ", 
      "[", 
      "]", 
      ":", 
      "{",
      "}",
      "'",
      ",",
      ".",
      "<",
      ">",
      "?",
      "/",
      "Backspace", 
      "Delete", 
      "Tab", 
      "Shift", 
      "Ctrl", 
      "Alt", 
      "Escape", 
      "ArrowUp", 
      "ArrowDown", 
      "ArrowLeft", 
      "ArrowRight",
      "Enter", 
      "F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12",
    ];
  
    if (!allowedKeys.includes(key)) {
      e.preventDefault();
      toast.warning("Only English alphabets and digits are allowed.");
    }
  };
  
  return (
    <div className="editorLayout">
      <TextArea
        value={fromText|| ""}
        onChange={handleTextChange}
        handleKeyUp={handleKeyUp}
        handleKeyDown={handleKeyDown}
        height = "48rem"
      />
      <TextArea readOnly={true} value={toText} error={error ? true : false}  height = "48rem" />
    </div>
  );
}

export default Editor;
