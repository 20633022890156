import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  uploadFileRuncardAction,
  listFileRuncard,
  activeFileRunCard,
} from "../../../../../../../../redux/actions/platform";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { useHistory } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function createData(filename, status, kbs, timeupload) {
  return { filename, status, kbs, timeupload };
}

const rows = [createData("Frozen yoghurt", "RUNNING", "6.0", "24")];

const Index = () => {
  const history = useHistory();
  const dispath = useDispatch();
  const { id } = useParams();
  const platformReducerState = useSelector((state) => state?.platformReducer);
  const uploadRuncardStatus = useSelector(
    (state) => state?.platformReducer?.uploadFileRuncardSuccess
  );
  const uploadFileRuncardError = useSelector(
    (state) => state?.platformReducer?.uploadFileRuncardError
  );
  const listRuncard = useSelector(
    (state) => state?.platformReducer?.listRuncard
  );
  const error_active = useSelector(
    (state) => state?.platformReducer?.error_active?.message
  );
  const active_success = useSelector(
    (state) => state?.platformReducer?.active_success
  );
  console.log("uploadRuncardStatus", uploadRuncardStatus);
  console.log("error_active", error_active);
  const uploadFileConf = (e) => {
    console.log(e.target.files[0]);
    let file = e.target.files[0];

    console.log("log", file.name);
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("id", id);
    // let data = {
    //     id: id,
    //     data: file
    // }
    dispath(uploadFileRuncardAction(formData, id));
  };

  const handleUploadFileConf = (e) => {
    e.preventDefault();
    $("#select_runcard_file").click();
  };
  useEffect(() => {
    if (error_active !== undefined) {
      toast.error(error_active, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [error_active]);

  useEffect(() => {
    if (active_success !== undefined) {
      toast.success("Active file success !", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [active_success]);

  useEffect(
    (state) => {
      if (uploadRuncardStatus !== undefined) {
        toast.success("Upload file success !", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      dispath(listFileRuncard(id));
    },
    [uploadRuncardStatus]
  );

  useEffect(() => {
    if (uploadFileRuncardError !== undefined) {
      toast.error(uploadFileRuncardError, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [uploadFileRuncardError]);

  const handleActiveFileRuncard = (e) => {
    console.log("runcardId", e.target.value);
    let data = {
      platform_uuid: id,
      runcard_uuid: e.target.value,
    };
    dispath(activeFileRunCard(data));
  };

  return (
    <>
      <ToastContainer />
      <div
        className="wizard_internal_tab is-active tabs-panel netsshTab wizard_internal_tab_ContextTabone7"
        id="netsshTabone9"
        role="tabpanel"
        aria-hidden="false"
        aria-labelledby="netsshTabone9-label"
      >
        {/* <div className="row">
                                <div className="columns medium-6">
                                <div className="row">
                                    <div className="columns large-12">
                                    <input type="checkbox" className="ssh_context" id="ssh_contextone9" />
                                    <label htmlFor="ssh_contextone9"> Add SSH contextualization <span data-tooltip="51nhu6-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="kvu4g0-tooltip" data-yeti-box="kvu4g0-tooltip" data-toggle="kvu4g0-tooltip" data-resize="kvu4g0-tooltip">
                                        <i className="fas fa-question-circle" />
                                        </span>
                                    </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="large-12 columns">
                                    <label> SSH public key <textarea rows={2} type="text" id="ssh_public_key" name="ssh_public_key" defaultValue={""} />
                                    </label>
                                    </div>
                                </div>
                                </div>
                                <div className="columns medium-6">
                                <div className="row">
                                    <div className="columns large-12">
                                    <input type="checkbox" className="network_context" id="network_contextone9" />
                                    <label htmlFor="network_contextone9"> Add Network contextualization <span data-tooltip="cw62xz-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="8rr5gd-tooltip" data-yeti-box="8rr5gd-tooltip" data-toggle="8rr5gd-tooltip" data-resize="8rr5gd-tooltip">
                                        <i className="fas fa-question-circle" />
                                        </span>
                                    </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="columns large-12">
                                    <input type="checkbox" className="token_context" id="token_contextone9" />
                                    <label htmlFor="token_contextone9"> Add OneGate token <span data-tooltip="qv1lf9-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="p1rtbd-tooltip" data-yeti-box="p1rtbd-tooltip" data-toggle="p1rtbd-tooltip" data-resize="p1rtbd-tooltip">
                                        <i className="fas fa-question-circle" />
                                        </span>
                                    </label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="columns large-12">
                                    <input type="checkbox" className="report_ready_context" id="report_ready_contextone9" />
                                    <label htmlFor="report_ready_contextone9"> Report Ready to OneGate <span data-tooltip="tn46nm-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="glgumr-tooltip" data-yeti-box="glgumr-tooltip" data-toggle="glgumr-tooltip" data-resize="glgumr-tooltip">
                                        <i className="fas fa-question-circle" />
                                        </span>
                                    </label>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                <label> Start script <span data-tooltip="ymy0up-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="xdgggm-tooltip" data-yeti-box="xdgggm-tooltip" data-toggle="xdgggm-tooltip" data-resize="xdgggm-tooltip">
                                    <i className="fas fa-question-circle" />
                                    </span>
                                    <textarea rows={4} type="text" className="START_SCRIPT monospace" placeholder="yum upgrade" defaultValue={""} />
                                </label>
                                <input type="checkbox" className="ENCODE_START_SCRIPT" id="ENCODE_START_SCRIPTone9" defaultChecked />
                                <label htmlFor="ENCODE_START_SCRIPTone9">Encode script in Base64</label>
                                </div>
                            </div>
                            <br /> */}
        <div className="row">
          <label>Choose file</label>
          <select onChange={(e) => handleActiveFileRuncard(e)}>
            <option>Select file run</option>
            {listRuncard?.length
              ? listRuncard.map(function (value, index) {
                  return (
                    <option key={index + value?.uuid} value={value?.uuid}>
                      {value?.name}
                    </option>
                  );
                })
              : ""}
          </select>
        </div>
      </div>
      <div
        className="wizard_internal_tab tabs-panel filesTab wizard_internal_tab_ContextTabone7"
        id="filesTabone9"
        role="tabpanel"
        aria-hidden="true"
        aria-labelledby="filesTabone9-label"
      >
        <div className="row">
          <div className="large-12 columns">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Filename</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Runcard Platform ID</TableCell>
                    <TableCell align="right">Time upload</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {listRuncard?.length ? (
                    listRuncard.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">{row.status}</TableCell>
                        <TableCell align="right">
                          {row.runcard_platform_id}
                        </TableCell>
                        <TableCell align="right">
                          {moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <tr className="odd">
                        <td
                          valign="top"
                          colSpan={4}
                          className="dataTables_empty"
                          style={{ textAlign: "center" }}
                        >
                          <span
                            className="text-center"
                            style={{ fontSize: 18, color: "#999" }}
                          >
                            <br />
                            <span
                              className="fa-stack fa-3x"
                              style={{ color: "#dfdfdf" }}
                            >
                              <i className="fas fa-cloud fa-stack-2x" />
                              <i className="fas fa-info-circle fa-stack-1x fa-inverse" />
                            </span>
                            <br />
                            <span style={{ color: "#999" }}>
                              There is no data available
                            </span>
                          </span>
                          <br />
                        </td>
                      </tr>
                    </>
                  )}
                </TableBody>
              </Table>
              <div
                id="osTabone1one2_wrapper"
                className="dataTables_wrapper dt-foundation no-footer"
                style={{ width: "95%" }}
              >
                <div className="row">
                  <div className="small-6 columns">
                    <div
                      className="dataTables_length"
                      id="osTabone1one2_length"
                    >
                      <label>
                        <select
                          name="osTabone1one2_length"
                          aria-controls="osTabone1one2"
                          className
                        >
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </label>
                    </div>
                    <div
                      className="dataTables_info"
                      id="osTabone1one2_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing 0 to 0 of 0 entries
                    </div>
                  </div>
                  <div className="small-6 columns">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="osTabone1one2_paginate"
                    >
                      <ul className="pagination">
                        <li
                          className="paginate_button previous unavailable disabled"
                          aria-controls="osTabone1one2"
                          tabIndex={0}
                          id="osTabone1one2_previous"
                        >
                          Previous
                        </li>
                        <li
                          className="paginate_button next unavailable disabled"
                          aria-controls="osTabone1one2"
                          tabIndex={0}
                          id="osTabone1one2_next"
                        >
                          Next
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </TableContainer>
          </div>
        </div>
        <br />
        <div
          className="row"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <div className="large-12 columns">
            <input
              id="select_runcard_file"
              type="file"
              onChange={(e) => uploadFileConf(e)}
              style={{
                display: "none",
              }}
            />
            <button
              onClick={(e) => handleUploadFileConf(e)}
              className="button success"
            >
              Upload
            </button>
          </div>
        </div>
      </div>
      <div
        className="wizard_internal_tab tabs-panel customTab wizard_internal_tab_ContextTabone7"
        id="customTabone9"
        role="tabpanel"
        aria-hidden="true"
        aria-labelledby="customTabone9-label"
      >
        <div className="row">
          <div className="large-12 columns">
            <table className="dataTable policies_table">
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Value</th>
                  <th />
                </tr>
              </thead>
              <tbody className="custom_tags ui-sortable" style={{}}>
                <tr>
                  <td style={{ display: "flex", justifyContent: "flex-start" }}>
                    <input
                      className="custom_tag_key"
                      type="text"
                      name="key"
                      defaultValue="DISK_ID"
                    />
                  </td>
                  <td>
                    <textarea
                      style={{ marginLeft: 7 }}
                      className="custom_tag_value"
                      name="value"
                      defaultValue={"0"}
                    />
                    <table>
                      <tbody className="custom_body" />
                      <tfoot>
                        <tr>
                          <td colSpan={3}>
                            <a
                              type="button"
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#2E9CB9",
                              }}
                              className="change_to_vector_attribute button small small-12 secondary radius"
                            >
                              <i className="fas fa-plus" />
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                  <td
                    style={{
                      width: "150%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a href="#">
                      <i className="fas fa-times-circle remove-tab" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex", justifyContent: "flex-start" }}>
                    <input
                      className="custom_tag_key"
                      type="text"
                      name="key"
                      defaultValue="TARGET"
                    />
                  </td>
                  <td>
                    <textarea
                      style={{ marginLeft: 7 }}
                      className="custom_tag_value"
                      name="value"
                      defaultValue={"hda"}
                    />
                    <table>
                      <tbody className="custom_body" />
                      <tfoot>
                        <tr>
                          <td colSpan={3}>
                            <a
                              type="button"
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#2E9CB9",
                              }}
                              className="change_to_vector_attribute button small small-12 secondary radius"
                            >
                              <i className="fas fa-plus" />
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                  <td
                    style={{
                      width: "150%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a href="#">
                      <i className="fas fa-times-circle remove-tab" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex", justifyContent: "flex-start" }}>
                    <input
                      className="custom_tag_key"
                      type="text"
                      name="key"
                      defaultValue="IMAGE"
                    />
                  </td>
                  <td>
                    <textarea
                      style={{ marginLeft: 7 }}
                      className="custom_tag_value"
                      name="value"
                      defaultValue={"Context"}
                    />
                    <table>
                      <tbody className="custom_body" />
                      <tfoot>
                        <tr>
                          <td colSpan={3}>
                            <a
                              type="button"
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#2E9CB9",
                              }}
                              className="change_to_vector_attribute button small small-12 secondary radius"
                            >
                              <i className="fas fa-plus" />
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                  <td
                    style={{
                      width: "150%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a href="#">
                      <i className="fas fa-times-circle remove-tab" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td style={{ display: "flex", justifyContent: "flex-start" }}>
                    <input
                      className="custom_tag_key"
                      type="text"
                      name="key"
                      defaultValue="CONTEXT"
                    />
                  </td>
                  <td>
                    <textarea
                      style={{ marginLeft: 7 }}
                      className="custom_tag_value"
                      name="value"
                      defaultValue={"true"}
                    />
                    <table>
                      <tbody className="custom_body" />
                      <tfoot>
                        <tr>
                          <td colSpan={3}>
                            <a
                              type="button"
                              style={{
                                borderRadius: 20,
                                backgroundColor: "#2E9CB9",
                              }}
                              className="change_to_vector_attribute button small small-12 secondary radius"
                            >
                              <i className="fas fa-plus" />
                            </a>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </td>
                  <td
                    style={{
                      width: "150%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <a href="#">
                      <i className="fas fa-times-circle remove-tab" />
                    </a>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3}>
                    <a
                      type="button"
                      className="add_custom_tag button small small-12 secondary radius"
                    >
                      <i className="fas fa-lg fa-plus-circle" />
                    </a>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
