import QuantumCircuit from "quantum-circuit";


const useCircuit = (fromText, setToText) => {
  let circuit = new QuantumCircuit();

  function executeCircuitMethod(LanguageName, circuitText) {
    return new Promise((resolve, reject) => {
      circuit[LanguageName](circuitText, (errors) => {
        if (errors.length) {
          reject(errors);
        } else {
          resolve("success");
        }
      });
    });
  }

  const convertCircuit = async (selectedLanguageOption, decompose) => {
    try {
      const newDecompose = decompose ? decompose : false;
      const parsedLaguageOption = {};
      Object.keys(selectedLanguageOption).forEach((key) => {
        const value = selectedLanguageOption[key];
        key === "import"
          ? Object.assign(parsedLaguageOption, { import: `import${value}` })
          : Object.assign(parsedLaguageOption, { export: `export${value}` });
      });

      await executeCircuitMethod(parsedLaguageOption?.import, fromText);
      const newExportLanguage = await circuit[parsedLaguageOption?.export](
        fromText,
        newDecompose
      );

      setToText(newExportLanguage);
    } catch (err) {
      console.log("err", err);
      setToText(`Error: ${err[0]?.msg}`);
    }
  };

  return [convertCircuit];
};

export default useCircuit;
