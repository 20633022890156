import * as Styled from "../connectPort.styled.js";
import { useState, useEffect } from "react";

import ListTable from "../../../../../../components/Table/ListTable.jsx";

const PortList = (props) => {
  // portList State
  const [portListState, setPortListState] = useState([]);

  // port Table
  const portColumns = [
    { id: "id", label: "ID", minWidth: 10, hidden: true },
    { id: "portName", label: "Port Name", minWidth: 20 },
    { id: "channelName", label: "Channel Name", minWidth: 20 },
    {
      id: "qubitsCnt",
      label: "Connected Qubits Cnt.",
      minWidth: 20,
    },
  ];

  // Table Data
  const portList = [
    {
      id: 1,
      portName: "o1",
      channelName: "ch1out",
      qubitsCnt: 0,
    },
    {
      id: 2,
      portName: "i1",
      channelName: "ch1in",
      qubitsCnt: 0,
    },
    {
      id: 3,
      portName: "o2",
      channelName: "ch2",
      qubitsCnt: 0,
    },
    {
      id: 4,
      portName: "o3",
      channelName: "ch3",
      qubitsCnt: 0,
    },
    {
      id: 5,
      portName: "o4",
      channelName: "chf1",
      qubitsCnt: 0,
    },
    {
      id: 6,
      portName: "o5",
      channelName: "chf2",
      qubitsCnt: 0,
    },
  ];

  // port row 선택 시
  const setPortRowEvt = (rowData) => {
    props.setSelectedPortYn(true);
    props.setSelectedPort(rowData);
    props.setTempConnectedQubitsCnt(0);
  };

  // port row 선택 해제
  const unsetRowEvt = () => {
    // qubit list 비활성화
    props.setSelectedPortYn(false);
    props.setSelectedPort(null);
    props.setTempConnectedQubitsCnt(0);
  };

  useEffect(() => {
    // port list state set
    setPortListState(portList);
  }, []);

  useEffect(() => {
    // 초기화
    if (props.tempConnectedQubitsCnt === 0) {
      setPortListState(portList);

      return;
    }

    if (props.selectedPort != null) {
      // port list update
      const tempPortList = portList.map((item) => {
        if (item.id === props.selectedPort.id) {
          return props.selectedPort;
        } else {
          return item;
        }
      });
      setPortListState(tempPortList);
    }
  }, [props.tempConnectedQubitsCnt]);

  return (
    <>
      <div className="row">
        <div className="sunstone-list large-8">
          <ListTable
            cols={portColumns}
            list={portListState}
            selectCallback={setPortRowEvt}
            deselectCallback={unsetRowEvt}
            title={"Port List"}
            noPagination={true}
            height={300}
            maxHeight={300}
          />
        </div>
      </div>
    </>
  );
};

export default PortList;
