import React from "react";
import getImagePath from "./util/getImagePath";

function Card({ handleRedirectUrl, driver,mapper }) {
  const themeColor = process.env?.REACT_APP_THEME;
  const ventureImage = driver && getImagePath(driver,mapper);

  return (
    <div
      className={`layout  ${
        themeColor === "dark"
          ? "list-card-item-black-layout"
          : "list-card-item-layout"
      }`}
      onClick={() => handleRedirectUrl(driver.uuid)}
    >
      <div className="list-card-image-div">
        {ventureImage && (
          <>
            <img
              style={{ width: ventureImage.size, marginLeft: ventureImage.left }}
              src={ventureImage.path}
              alt="Instrument"
            />
            {ventureImage?.text && (
              <span style={{marginLeft: ventureImage?.textLeft?ventureImage?.textLeft:'8px', fontSize: '11px', fontWeight: 'bold'}}>
                {ventureImage?.text}
              </span>
            )}
            <span>
              {ventureImage.path.includes("qick-logo2") && driver.name}
            </span>
          </>
        )}

        <span class="deploying-color right">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="square"
            class="svg-inline--fa fa-square "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill="currentColor"
              d="M0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96z"
            ></path>
          </svg>
        </span>
      </div>
      <div className="list-card-type-div">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="laptop"
          class="svg-inline--fa fa-laptop "
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 640 512"
          style={{width:'15px'}}
        >
          <path
            fill={themeColor === "dark" ? "#f4f4f4" : "currentColor"}
            d="M128 32C92.7 32 64 60.7 64 96V352h64V96H512V352h64V96c0-35.3-28.7-64-64-64H128zM19.2 384C8.6 384 0 392.6 0 403.2C0 445.6 34.4 480 76.8 480H563.2c42.4 0 76.8-34.4 76.8-76.8c0-10.6-8.6-19.2-19.2-19.2H19.2z"
          ></path>
        </svg>
        <span style={{ color: themeColor === "dark" ? "#f4f4f4" : "black", marginLeft:'-4px' }}>
          {ventureImage?.typeName?ventureImage?.typeName:driver?.type}
          {/* {instrumentType[venture]
            ? instrumentType[venture]
            : "qblox-instruments 0.9.0"} */}
        </span>
      </div>
      <div className="list-bullet-item">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="globe"
          class="svg-inline--fa fa-globe "
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path
            fill={themeColor === "dark" ? "#f4f4f4" : "currentColor"}
            d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
          ></path>
        </svg>
        <span style={{ color: themeColor === "dark" ? "#f4f4f4" : "#28869f" }}>
          --
        </span>
      </div>
      <div className="list-user-div">
        <div className="list-card-icon">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="user"
            class="svg-inline--fa fa-user "
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              fill={themeColor === "dark" ? "#f4f4f4" : "currentColor"}
              d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
            ></path>
          </svg>
          <span
            style={{ color: themeColor === "dark" ? "#f4f4f4" : "#28869f" }}
          >
            {driver?.user}
          </span>
        </div>
        <span
          className={`list-card-createtime  ${
            themeColor === "dark"
              ? "list-card-black-createtime"
              : "list-card-createtime"
          }`}
        >
          {driver?.createTime}
          {/* {createTime[venture] ? `${createTime[venture]} ago` : "2h ago"}{" "} */}
        </span>
      </div>
    </div>
  );
}

export default Card;
