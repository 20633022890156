import { Skeleton } from "@mui/material";

const themeColor = process.env.REACT_APP_THEME;

export const renderInfoSkeleton = () => {
  return (
    <>
      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
      </div>

      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
      </div>

      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
      </div>

      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '20%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
      </div>

      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '90%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
      </div>
      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '65%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem' }} />
      </div>
    </>
  )
}

export const renderPermissionSkeleton = () => {
  return (
    <>
      <div className={`driver-info-page-right-grid-container header ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '40%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
      </div>
    </>
  )
}

export const renderOwnershipSkeleton = () => {
  return (
    <>
      <div className={`driver-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '72%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '60%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
      </div>
      <div className={`driver-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '52%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '25%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '50%' }} />
      </div>
      <div className={`driver-info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '82%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '44%' }} />
        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '70%' }} />
      </div>
    </>
  )
}
