import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
    fontSize: 14,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function TableTemplate({ setNameTpl }) {
  const renderRowLink = (name) => {
    return (
      <a onClick={() => actClickRow(name)} href="">
        {name}
      </a>
    );
  };

  const actClickRow = (name) => {
    console.log("setNameTpl", setNameTpl);
    setNameTpl(name);
  };

  return (
    <ThemeProvider theme={theme}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                  fontSize: "14px",
                }}
              >
                Dessert (100g serving)
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                  fontSize: "14px",
                }}
              >
                Calories
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                  fontSize: "14px",
                }}
              >
                Fat&nbsp;(g)
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                  fontSize: "14px",
                }}
              >
                Carbs&nbsp;(g)
              </TableCell>
              <TableCell
                align="right"
                sx={{
                  fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                  fontSize: "14px",
                }}
              >
                Protein&nbsp;(g)
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  sx={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "14px",
                  }}
                >
                  {renderRowLink(row.name)}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "14px",
                  }}
                >
                  <a
                    onClick={() => actClickRow(row.name)}
                    href=""
                  >
                    {row.calories}
                  </a>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "14px",
                  }}
                >
                  <a
                    onClick={() => actClickRow(row.name)}
                    href=""
                  >
                    {row.fat}
                  </a>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "14px",
                  }}
                >
                  <a
                    onClick={() => actClickRow(row.name)}
                    href=""
                  >
                    {row.carbs}
                  </a>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "14px",
                  }}
                >
                  <a
                    onClick={() => actClickRow(row.name)}
                    href=""
                  >
                    {row.protein}
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
}
