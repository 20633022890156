import React, { useEffect, useLayoutEffect, useState } from "react";
import TableArea from "../../../Create/TableArea";
import InputField from "../../../Create/InputField";
import { useParams } from "react-router";

const columns = [
  { id: "id", label: "ID", minWidth: 100, hidden: true },
  { id: "name", label: "Model Name", minWidth: 70 },
  { id: "description", label: "Description", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const list = [
  {
    id: 1,
    name: "Zurich Instruments",
    description: "Zurich driver main class.",
    status: "Active",
  },
  {
    id: 2,
    name: "QUANTUM MACHINES",
    description:
      "Instrument object for controlling Quantum Machines (QM) OPX controllers.",
    status: "Active",
  },
  {
    id: 3,
    name: "QBLOX",
    description:
      "A class to extend the functionality of qblox_instruments Cluster.",
    status: "Active",
  },
  {
    id: 4,
    name: "Xilinx RFSoCs",
    description: "Instrument object for controlling RFSoC FPGAs.",
    status: "Active",
  },
  {
    id: 5,
    name: "Dummy Instrument",
    description:
      "Dummy instrument that returns random voltage values. Useful for testing code without requiring access to hardware.",
    status: "Active",
  },
];

function index({
  isReset,
  setIsReset,
  modelElement,
  setModelElement,
  setSelectedOption,
  isvalidation,
  setIsvalidation,
  selectedOption,

}) {
  // 수정 api 만들어야됨
  const [selectedModel, setSelectedModel] = useState({});
  const [defaultValue, setDefaultValue] = useState(null);

  const { id } = useParams();
 console.log("id",id)
  useEffect(() => {
    const defaultTableValue = list.filter((bander) => bander.name === id);
    setDefaultValue(...defaultTableValue);
  }, [defaultValue]);

  const setRowEvt = (rowData) => {
    setSelectedModel(rowData);
    console.log("setRowEvt111 :", rowData.name);
  };

  return (
    <>
      <TableArea
        columns={columns}
        list={list}
        isReset={isReset}
        setRowEvt={setRowEvt}
        setIsReset={setIsReset}
        defaultTableValue={defaultValue}
      />
      <InputField
        modelElement={modelElement}
        setModelElement={setModelElement}
        setSelectedOption={setSelectedOption}
        setIsReset={setIsReset}
        isReset={isReset}
        isvalidation={isvalidation}
        setIsvalidation={setIsvalidation}
        selectedOption={selectedOption}
      />
    </>
  );
}

export default index;
