import React from 'react'
import Header from '../../../layouts/Header/Index'
import Sidebar from '../../../layouts/Sidebar/Index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faArrowLeft,
    faCaretDown, 
    faList, 
    faPlus, 
    faRefresh, 
    faSync, 
    faTags, 
    faTrash, 
    faUser 
} from '@fortawesome/free-solid-svg-icons'
import { listUser } from '../../../redux/actions/auth';
import { 
    useDispatch, 
    useSelector
 } from 'react-redux';
import { useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableTemplate from './TableTemplate'
import ButtonAction from '../../../components/Button/ButtonAction'
import './Create.css'
import TemplateInfo from './TemplateInfo'




const Index = () => {
    const [selectTemp, setSelecteTemp] = React.useState('');
    const setNameTpl = (name) => {
        setSelecteTemp(name)
    }


    return <>
         <Header />
        <div className="row">
            <Sidebar />
            <div className='small-12 columns sunstone-content large-10'>
                <div className="sunstone-header"></div>
                <div id='dashboard-tab' className='tab'>
                    <div className="row header-row">
                        <div className="large-12 columns">
                            <h5 className="header-title only-sunstone-list">
                                <span className="header-resource">Instrument create</span>
                            </h5>
                            <h5 className="header-title only-sunstone-info" hidden="" style={{ display: 'none' }}>
                                <span className="header-resource">
                                
                                <small className="resource-id"></small>
                                <small className="resource-info-header"></small>
                                <small className="resource-info-header-small"></small>
                                </span>
                            </h5>
                            <h5 className="header-title only-sunstone-form" hidden="" style={{ display: 'none' }}>
                                <span className="sunstone-form-title"></span>
                                <small className="sunstone-form-id"></small>
                                <small className="sunstone-form-info-header"></small>
                                <small className="sunstone-form-info-header-small"></small>
                            </h5>
                        </div>
                    </div>





                     <div className="actions_row">
                        <div className="medium-12 columns action_blocks large-12">
                            <span>
                                <span id="users-tabback_button" className=" only-sunstone-info only-sunstone-form" style={{display: 'inline'}}>
                                <a className="button radius" href="/instrument/list">
                                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }}/>
                                    <FontAwesomeIcon icon={faList}/>
                                </a>
                                </span>
                                <span id="users-tabform_buttons" className="only-sunstone-form" hidden style={{display: 'inline'}}>
                                <span id="users-tabreset_button">
                                    <button className="button secondary radius reset_button" href="submit">Reset</button>
                                </span>
                                <span id="users-tabsubmit_button">
                                    <button 
                                        onClick={e=>onSubmitForm(e)} 
                                        className="button success radius submit_button" 
                                        href="submit">Create</button>
                                </span>
                                </span>
                                <span id="users-tabcreate_buttons" className=" only-sunstone-list" style={{display: 'none'}}>
                                    <button className="create_dialog_button action_button top_button success button" href="User.create_dialog">
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                </span>
                                <span id="users-tabrefresh_buttons" className=" only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                                    <button className="action_button top_button alwaysActive refresh button secondary" href="User.refresh">
                                        <i className="fas fa-sync-alt" />
                                        <FontAwesomeIcon icon={faSync}/>
                                    </button>
                                </span>
                            </span>
                            <span>
                                <span id="users-tabmain_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}} />
                                <span id="users-tabvmsplay_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}} />
                                <ul id="users-tablock_buttons" className="lock-sunstone-info only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="eqioe5-dropdown" aria-hidden="true" data-yeti-box="users-tablock_buttons" data-resize="users-tablock_buttons" aria-labelledby="bm649k-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <ul id="users-tabvmspause_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="pf40hu-dropdown" aria-hidden="true" data-yeti-box="users-tabvmspause_buttons" data-resize="users-tabvmspause_buttons" aria-labelledby="m1jooi-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <ul id="users-tabvmsstop_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="v9ludl-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsstop_buttons" data-resize="users-tabvmsstop_buttons" aria-labelledby="b99wao-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <ul id="users-tabvmsrepeat_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="rlx7ez-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsrepeat_buttons" data-resize="users-tabvmsrepeat_buttons" aria-labelledby="r6o0eo-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <ul id="users-tabvmsplanification_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="4p817x-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsplanification_buttons" data-resize="users-tabvmsplanification_buttons" aria-labelledby="o5injb-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            </span>
                            <span>
                                <ul id="users-tabmore_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="zs2dqs-dropdown" aria-hidden="true" data-yeti-box="users-tabmore_buttons" data-resize="users-tabmore_buttons" aria-labelledby="f2oznb-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <button type="button" data-toggle="users-tabuser_buttons" className="only-sunstone-info only-sunstone-list top_button  secondary button dropdown" aria-controls="users-tabuser_buttons" data-is-focus="false" data-yeti-box="users-tabuser_buttons" aria-haspopup="true" aria-expanded="false" disabled="disabled" style={{display: 'none'}}>
                                <i className="fas fa-user" />
                                </button>
                                <ul id="users-tabuser_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="lb164g-dropdown" aria-hidden="true" data-yeti-box="users-tabuser_buttons" data-resize="users-tabuser_buttons" aria-labelledby="t5vypz-dd-anchor" data-events="resize" style={{display: 'none'}}>
                                <li>
                                    <a className="confirm_with_select_button top_button" href="User.chgrp" disabled="disabled">Change primary group</a>
                                </li>
                                </ul>
                                <span id="users-tablabels_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                                <button type="button" data-toggle="users-tabLabelsDropdown" className="undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown" aria-controls="users-tabLabelsDropdown" data-is-focus="false" data-yeti-box="users-tabLabelsDropdown" aria-haspopup="true" aria-expanded="false" disabled="disabled" style={{display: 'none'}}>
                                    <i className="fas fa-tags" />
                                </button>
                                <div id="users-tabLabelsDropdown" className="only-sunstone-info only-sunstone-list labels-dropdown dropdown-pane large menu vertical" data-dropdown="ix27dw-dropdown" data-close-on-click="true" aria-hidden="true" data-yeti-box="users-tabLabelsDropdown" data-resize="users-tabLabelsDropdown" aria-labelledby="l1lse9-dd-anchor" data-events="resize" style={{display: 'none'}}>
                                    <div>
                                    <h6>Edit Labels</h6>
                                    <div className="labeltree-container">
                                        <ul className="labels-tree" />
                                    </div>
                                    <div className="input-container">
                                        <input type="text" className="newLabelInput" placeholder="Add Label" />
                                    </div>
                                    </div>
                                </div>
                                </span>
                                <ul id="users-tabvmsdelete_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="chnqv4-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsdelete_buttons" data-resize="users-tabvmsdelete_buttons" aria-labelledby="52gtey-dd-anchor" data-events="resize" style={{display: 'none'}} />
                                <span id="users-tabdelete_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                                <button className="confirm_button top_button alert button" href="User.delete" disabled="disabled">
                                    <i className=" fas fa-trash-alt" />
                                </button>
                                </span>
                                <span className="only-sunstone-form" style={{display: 'inline'}}>
                                <ul id="users-tabFormTabs" className="tabs right wizard_tabs " hidden data-tabs="oiub66-tabs" style={{display: 'none'}}>
                                    <li id="wizard_mode" className="tabs-title is-active" role="presentation">
                                    <a href="#users-tab-wizardForms" role="tab" aria-controls="users-tab-wizardForms" aria-selected="true" id="users-tab-wizardForms-label">Wizard</a>
                                    </li>
                                    <li id="advanced_mode" className="tabs-title" role="presentation">
                                    <a href="#users-tab-advancedForms" role="tab" aria-controls="users-tab-advancedForms" aria-selected="false" id="users-tab-advancedForms-label">Advanced</a>
                                    </li>
                                </ul>
                                </span>
                            </span>
                        </div>
                    </div>
                            
                    <div className="sunstone-list">
                      {/* Content */}
                      <div className="row select-resources">
                            <div className="small-12 medium-6 columns" id="selected_ids_row_vm_create">
                                {
                                    selectTemp.length ?
                                    <span id="selected_resource_vm_create">You selected the following Template: <span className='radius label'>{selectTemp}</span></span> :
                                    <span id="select_resource_multiple_vm_create" style={{fontSize:"14px"}}>Please select one or more Templates from the list</span>
                                }
                                {/* <label htmlFor="selected_resource_id_vm_create" id="select_resource_vm_create" style={{display: 'block'}}>Please select a Template from the list</label>
                                <span id="selected_resource_vm_create" hidden style={{display: 'none'}}>You selected the following Template:</span>
                                <span id="select_resource_multiple_vm_create" hidden style={{display: 'none'}}>Please select one or more Templates from the list</span>
                                <span id="selected_resource_multiple_vm_create" hidden style={{display: 'none'}}>You selected the following Templates:</span>
                                <span id="selected_resource_name_vm_create" className="radius label" style={{display: 'none'}} /> */}
                                <input id="selected_resource_id_vm_create" type="text" style={{visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline'}} />
                            </div>
                            <div className='box-search'>
                                <div className="small-10 medium-4 columns right">
                                    <input id="vm_create_search" className="search" type="search" placeholder="Search" />
                                </div>
                                <ButtonAction icon={<FontAwesomeIcon icon={faRefresh}/>} cls={``} />
                            </div>
                            
                        </div>

                        {/* Table */}
                        <TableTemplate setNameTpl={setNameTpl} />
                        {/* Template Info */}
                        {selectTemp.length ? <TemplateInfo cls='mgt-20' selectTemp={selectTemp} /> : ''}

                    </div>
                </div>
            </div>
        </div>
    </>
}


export default Index;