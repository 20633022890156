import Header from './Header/Index.js'
import Sidebar from './Sidebar/Index.js'
import { ToastContainer, toast } from 'react-toastify';
import "./DefaultLayout.css";

const DefaultLayout = ({ children, title }) => {
    const themeColor = process.env?.REACT_APP_THEME;
    return (
        <div>
            <Header />
            <div className="row" id='create_controller'>
                <Sidebar />

                <div className={`small-12 columns sunstone-content large-10 ${themeColor==='dark'?"custom-default-layout-dark":"custom-default-layout"}`}>
                    <div id='dashboard-tab' className='tab'>
                        {title && <div className="row header-row">
                            <div className="large-12 columns">
                                <h5 className="header-title only-sunstone-list">
                                    <span className={`header-resource ${themeColor==='dark'?"dark-theme-tc":""}`}>{title}</span>
                                </h5>
                            </div>
                        </div>}
                        
                        <main className={themeColor==='dark'?"dark-theme-bgc-contents":""}>{children}</main>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultLayout