import Tabs from './Tabs/Index'
import Content from './Content/Index'

const Index = () => {
    const handleClickOsTabone1 = (e) => {
        let id = e.target.getAttribute('aria-controls')
        let element = document.getElementsByClassName("osTabs-item");
        let tabs = document.getElementsByClassName('wizard_internal_tab')
        for (var i = 0; i < element.length; i++) {
            element[i].classList.remove("is-active")
            tabs[i].classList.remove("is-active")
            if(id === element[i].childNodes[0].getAttribute('aria-controls')){
                element[i].classList.add("is-active")
                tabs[i].classList.add("is-active")
            }
        }
    }
    return <div id="osTabone1" className="wizard_tab tabs-panel is-active" role="tabpanel" aria-hidden="false" aria-labelledby="osTabone1-label">
                <div id="tabs-bootos" className="row collapse">
                    <div className="medium-2 columns">
                        <Tabs handleClickOsTabone1={handleClickOsTabone1} />
                    </div>
                    <div className="medium-10 columns">
                        <div className="tabs-content vertical" data-tabs-content="osTabs">
                            <Content />
                        </div>
                    </div>
                </div>
            </div>
}

export default Index;