export function getTimeAgo(timestamp) {
    const currentTime = new Date();
    const targetTime = new Date(timestamp);
  
    const timeDifference = currentTime - targetTime;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
  
    if (daysDifference > 1) {
      return `${daysDifference} days ago`;
    } else if (hoursDifference > 1) {
      return `${hoursDifference} hours ago`;
    } else {
      return `${minutesDifference} minutes ago`;
    }
  }