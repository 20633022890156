import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./Create.css";
import InputField from "./InputField";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableArea from "./TableArea";
import DefaultLayout from "../../../layouts/DefaultLayout";
import ButtonField from "./ButtonField";
import axiosService from "../../../api/axiosService";
import { toast } from "react-toastify";
import { baseUrl } from "../../../redux/contants/urlApi";
import CustomButton from "../../../components/Button/CustomButton";
import { isNullorEmpty } from "../../../common/CommonUtil";
import { tError, tSuccess, tWarnning } from "../../../common/CommonToast";

const columns = [
  { id: "id", label: "ID", minWidth: 100, hidden: true },
  { id: "name", label: "BanderName", minWidth: 70 },
  { id: "description", label: "Description", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const list = [
  {
    id: 1,
    name: "Zurich Instruments",
    description: "Zurich driver main class.",
    status: "Active",
  },
  {
    id: 2,
    name: "Quantum Machines",
    description:
      "Instrument object for controlling Quantum Machines (QM) OPX controllers.",
    status: "Active",
  },
  {
    id: 3,
    name: "Qblox",
    description:
      "A class to extend the functionality of qblox_instruments Cluster.",
    status: "Active",
  },
  {
    id: 4,
    name: "Xilinx RFSoCs",
    description: "Instrument object for controlling RFSoC FPGAs.",
    status: "Active",
  },
  {
    id: 5,
    name: "Dummy Instrument",
    description:
      "Dummy instrument that returns random voltage values. Useful for testing code without requiring access to hardware.",
    status: "Active",
  },
];

const Index = () => {
  const [selectedModel, setSelectedModel] = useState({});
  const [isReset, setIsReset] = useState(false);
  const [isvalidation, setIsvalidation] = useState({
    bander: false,
    Name: false,
    Description: false,
    type: false,
    selectedOption: false,
    instrument: [],
  });
  const [modelElement, setModelElement] = useState({
    title: [
      {
        label: "Name",
        value: "",
      },
      {
        label: "Description",
        value: "",
      },
    ],
    type: [
      { key: "Dummy", label: "Dummy Instrument" },
      { key: "Zurich", label: "Zurich Instrument" },
      { key: "QMOPX", label: "QMOPX Instrument" },
      { key: "Qblox", label: "Qblox Instrument" },
      { key: "RFSoC", label: "RFSoC" },
      { key: "ERA", label: "ERA Instrument" },
      { key: "SGS100A", label: "SGS100A Instrument" },
      { key: "SPI", label: "SPI Instrument" },
    ],
    instrument: [],
  });
  const setRowEvt = (rowData) => {
    setSelectedModel(rowData);

    console.log(rowData);
  };
  const [selectedOption, setSelectedOption] = useState({});

  const postInstrumentModel = async () => {
    // modelElement
    let name = modelElement?.title?.find(item => item.label == 'Name')?.value
    let desc = modelElement?.title?.find(item => item.label == 'Description')?.value
    let type = selectedOption?.select
    let ports = modelElement?.instrument?.filter(item => item.hasOwnProperty('portNum'))

    let createDriverFlag = false
    let instrumentUUID = null;

    if(isNullorEmpty(name)){
      tWarnning("name is required");
      return
    }

    if(isNullorEmpty(desc)){
      tWarnning("Description is required");
      return
    }

    if(isNullorEmpty(type)){
      tWarnning("Type is required");
      return
    }
    
    let driverParam = {
      name: name,
      description: desc,
      type: type
    };

    

    // Instrument Model 생성
    try {
      let res = await axiosService.post(baseUrl + "v1/instrument-drivers/", driverParam);
      console.log("res:", res);
      if(res?.status == 201){
        createDriverFlag = true;
        instrumentUUID = res?.data?.uuid
      } else {
        tError("Create Faild");
      }
    } catch (e) {
      console.log("Faild:", e)
      tError("Faild");
    }


    // Instrument Channel 생성
    if(ports?.length && createDriverFlag){
      try{
        ports?.map(item=>{item['port_name']=item.portName; item['port_no']= item.portNum; item['channel_name']=item.ChannelName})
        
        let res = await axiosService.post(baseUrl + "v1/instrument-drivers/"+instrumentUUID+"/channels", ports);
        if(res?.status == 201){
          tSuccess("Create Success")
          history.back()
        } else {
          tError("Create Faild");
        }
      } catch (e) {
        console.log("Faild:", e)
      }
    } else if (createDriverFlag && ports?.length == 0){
      tSuccess("Create Success")
      history.back()
    }
  };

  return (
    <DefaultLayout title={"Create Instruments Model"}>
      {/* <ToastContainer /> */}
      <div className="sunstone-list">
        <CustomButton type={"goback"} onClick={() => { history.back() }} />
        <CustomButton type={"sync"} onClick={() => { }} />
        <CustomButton type={"custom"} label={"save"} onClick={postInstrumentModel} />
        <InputField
          modelElement={modelElement}
          setModelElement={setModelElement}
          setSelectedOption={setSelectedOption}
          setIsReset={setIsReset}
          isReset={isReset}
          isvalidation={isvalidation}
          setIsvalidation={setIsvalidation}
          selectedOption={selectedOption}
        />
      </div>
    </DefaultLayout>
  );
};

export default Index;
