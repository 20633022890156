import { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import CheckableTable from "../../../components/Table/SelectTable";
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import { sim1JupyterUrl } from "../../../redux/contants/urlApi";
import TerminalIcon from "@mui/icons-material/Terminal";
import "./Index.css";

const Index = () => {
  const [data, setData] = useState({});
  const history = useHistory();

  useEffect(() => {
    // 1. List 목록 받아오기.
    fetchDataList();
  }, []);

  const linkIcon = (link) => {
    return (
      <div className="simulator-list-page-ide-icn">
        <TerminalIcon
          onClick={($event) => {
            $event.stopPropagation();
            window.open(link);
          }}
        />
      </div>
    );
  };

  const fetchDataList = async () => {
    let tempData = [
      {
        id: 1,
        name: "SQK-compiler",
        compiler: "SQS: latest",
        address: "104.42.6.128",
        type: "XACC",
        ide: "vscode",
        action: linkIcon("http://104.42.6.128:3000/#/home/dev"),
      },
      {
        id: 2,
        name: "IBM-compiler",
        compiler: "Qiskit-acr: latest",
        address: "104.42.6.128",
        type: "Qiskit-runtime",
        ide: "jupyter",
        action: linkIcon("http://104.42.6.128:8889/tree"),
      },
      {
        id: 3,
        name: "Q#-compiler",
        compiler: "Q#: latest",
        address: "104.42.6.128",
        type: "Q#-runtime",
        ide: "vscode",
        action: linkIcon("http://104.42.6.128:4000/#/home/dev"),
      },
      {
        id: 4,
        name: "Cirq-compiler",
        compiler: "Cirq: latest",
        address: "104.42.6.128",
        type: "Cirq-QSim",
        ide: "jupyter",
        action: linkIcon("http://104.42.6.128:8888/tree"),
      },
      {
        id: 5,
        name: "QuEST-compiler",
        compiler: "QuEST: latest",
        address: "104.42.6.128",
        type: "QuEST",
        ide: "jupyter",
        action: linkIcon("http://104.42.6.128:8887/tree"),
      },
      
    ];
    setData((prev) => ({ ...prev, list: tempData }));
  };

  const createListColumns = () => {
    return [
      // { id: 'id', label: 'ID', minWidth: 100, hidden: true },
      // { id: 'name', label: 'Name', minWidth: 70 },
      // { id: 'description', label: 'Description', minWidth: 200 },
      // { id: 'address', label: 'Address', minWidth: 100 },
      // { id: 'type', label: 'Type', minWidth: 100 },
      // {
      //     id: 'osVersion',
      //     label: 'OS Version',
      //     minWidth: 170,
      //     align: 'left',
      //     format: (value) => value.toLocaleString('en-US'),
      // },
      // { id: 'Python Version', label: 'Python Version', minWidth: 100 },
      // { id: 'actions', label: 'Actions', minWidth: 100 },

      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "name", label: "Name", minWidth: 70 },
      { id: "compiler", label: "Compiler", minWidth: 200 },
      { id: "address", label: "Address", minWidth: 100 },
      { id: "type", label: "Type", minWidth: 100 },
      { id: "ide", label: "IDE", minWidth: 100 },
      { id: "actions", label: "Actions", minWidth: 100 },
    ];
  };

  // Select Event
  const selectRows = (selectedRowData) => {
    setData((prev) => ({ ...prev, selectedRowData: selectedRowData }));
  };

  // Delete Event
  const deleteData = () => {
    const deleteTargetList = data?.selectedRowData;
    console.log("deleteTargetList : ", deleteTargetList);
  };

  return (
    <>
      <DefaultLayout title="Simulator">
        <div className="sunstone-list">
          <CustomButton
            type="iconPlus"
            onClick={() => {
              history.push("/simulation/simulator/create");
            }}
          />
          <CustomButton
            type="sync"
            onClick={() => {
              fetchDataList();
            }}
          />
          <CustomButton
            type="delete"
            onClick={() => {
              deleteData();
            }}
          />
          <CheckableTable
            cols={createListColumns()}
            callback={selectRows}
            list={data?.list}
            height={400}
            maxHeight={400}
            // type={"link"}
          />
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
