import DetailHeader from "./Header";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import Tab from "./Tab";
import Edit from "./Edit";
import CustomIcon from "../../../../components/Icon";
import Info from "./Conf/Information"
import { useState,useEffect } from "react";
import { useParams } from "react-router";

function index() {
  const [isDelete, setIsDelete] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [modelElement, setModelElement] = useState({
    title: [
      {
        label: "Name",
        value: "",
      },
      {
        label: "Description",
        value: "",
      },
    ],
    type: [
      { key: "Dummy Measurement", label: "Dummy Measurement" },
    ],
    instrument: [],
  });
  const [isvalidation, setIsvalidation] = useState({
    bander: false,
    Name: false,
    Description: false,
    type: false,
    selectedOption: false,
    instrument: [],
  });
  const {id} = useParams()
  const mapper = [
    {
      id: "SQK",
      path: "/assets/images/sqk-kokkos-white-code01-instruments2.svg",
      size: "80px",
      text: 'Instruments',
      textLeft: '0px',
      typeName: "SQK Inst"
    },
    {
      id:"IQM",
      path: "/assets/images/IQM-logo.svg",
      size: "50px",
      left: '5px',
      text: 'Instruments',
      textLeft: '8px',
      typeName: "IQM Inst"
    },
    {
      id:"IONQ",
      path: "/assets/images/ionq-logo-light.svg",
      size: "70px",
      left: '2px',
      text: 'Instruments',
      textLeft: '8px',
      typeName: "IONQ Inst"
    },
    {
      id:"Bluefors",
      path: "/assets/images/bluefors-logo-white.png.webp",
      size: "90px",
      left: '2px',
      text: 'Instruments',
      textLeft: '8px',
      typeName: "Bluefors Inst"
    },
    {
      id: 'SQK Measurement'
      ,imageUrl: "/assets/quantums/sqk-instruments.webp"
      ,imageSize: ""
      ,version:"0.6.3"
    },
    {
      id: 'Zurich Measurement'
      ,imageUrl: "/assets/quantums/1.zurichi-instrument.webp"
      ,imageSize: "50rem"
      ,version:"1.0.1"
    },
    {
      id: 'QUANTUM MACHINES'
      ,imageUrl: "/assets/quantums/3.QM-instrument.webp"
      ,imageSize: "50rem"
      ,version:"0.9"
    },
    {
      id: 'QBLOX'
      ,imageUrl: "/assets/quantums/2.qblox-instrument.webp"
      ,imageSize: "40rem"
      ,version:"-"
    },
    {
      id: 'KeySight'
      ,imageUrl: "/assets/quantums/4.keysight-instrument.webp"
      ,imageSize: "50rem"
      ,version:"1.0.4"
    },
    {
      id: 'Dummy Measurement'
      ,imageUrl: "/assets/quantums/quantum-1.jpg"
      ,imageSize: ""
      ,version:"1.9"
    },
  ]
  const detailMapper = {
    SQK: {
      id: "SQK Measurement Driver",
      imageUrl: "/assets/quantums/sqk-instruments.webp",
      imageSize: "",
      version:"0.6.3"
    },
    "Zurich Instruments": {
      imageUrl: "/assets/quantums/1.zurichi-instrument.webp",
      imageSize: "50rem",
    },
    "QUANTUM MACHINES": {
      imageUrl: "/assets/quantums/3.QM-instrument.webp",
      imageSize: "50rem",
      version:"0.6.3"
    },
    "Bluefors": {
      id: "Bluefors Measurement Driver",
      imageUrl: "/assets/quantums/bluefors-mc.png",
      imageSize: "50rem",
      version:"0.6.3"
    },
    "IQM": {
      id: "IQM Measurement Driver",
      imageUrl: "/assets/quantums/IQM-instrument-2.png",
      imageSize: "200%",
      imageHeight: "30rem",
      maxWidth: "200%",
      version:"0.6.3"
    },
    "IONQ": {
      id: "IONQ Measurement Driver",
      imageUrl: "/assets/quantums/ionqbox.webp",
      imageSize: "40rem",
    },
    QBLOX: {
      imageUrl: "/assets/quantums/2.qblox-instrument.webp",
      imageSize: "40rem",
    },
    KeySight: {
      imageUrl: "/assets/quantums/4.keysight-instrument.webp",
      imageSize: "50rem",
    },
    default: {
      imageUrl: "/assets/quantums/quantum-1.jpg",
      imageSize: "",
    },
  };
  const [selectedOption, setSelectedOption] = useState({});
  const [isFirstReset,setIsFirstReset] = useState(false);
  const [data, setData] = useState({})

  
  useEffect(()=>{
    setData((prev)=>({...prev,...(detailMapper[id])}))
  },[])
  
  console.log("isReset0",isReset)

  const tabData = [
    {
      tabId: "info",
      tabLabel: "Info",
      icon: <CustomIcon type="faInfoCircle" />,
      contents: <Info data={data}/>,
    },
    {
      tabId: "Edit",
      tabLabel: "Edit",
      icon: <CustomIcon type="MdOutlineModeEdit" />,
      contents: (
        <Edit
          isReset={isReset}
          setIsReset={setIsReset}
          isvalidation={isvalidation}
          setIsvalidation={setIsvalidation}
          modelElement={modelElement}
          setModelElement={setModelElement}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setIsFirstReset={setIsFirstReset}
        />
      ),
    },
  ];

  const deleteInstrumentModel = () => {
    // 삭제 api 함수 세팅 자리

  };

  const changeInstrumentModel = () => {
    // 수정 api 함수 자리
    console.log("테스트")
    validationCheck();
  };

  const validationCheck = () => {
    const { title, instrument } = modelElement;

    if (title[0].value.length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        Name: true,
      }));
    }
    if (title[1].value.length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        Description: true,
      }));
    }
    if (Object.keys(selectedOption).length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        selectedOption: true,
      }));
    }
    if (instrument.length > 0) {
      const validationResults = instrument.map((item) => {
        const portNameValid = item.portName.length > 0;
        const channelNameValid = item.ChannelName.length > 0;
        return { portName: !portNameValid, ChannelName: !channelNameValid };
      });

      setIsvalidation((prev) => ({
        ...prev,
        instrument: validationResults,
      }));
    }
  };

  return (
    <>
      <DefaultLayout>
        <DetailHeader
          setIsReset={setIsReset}
          deleteInstrumentModel={deleteInstrumentModel}
          changeInstrumentModel={changeInstrumentModel}
        />
        <Tab tabData={tabData} />
      </DefaultLayout>
    </>
  );
}

export default index;
