import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import ListTable from "../../../../../components/Table/ListTable.jsx";
import { useState, useRef, useEffect } from "react";

// styled components
import * as Styled from "./circuitSelect.styled.js";

const CircuitSelect = ({ circuitInfo, setCircuitInfo, setPageStep }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  // Yamleditor
  const editorRef = useRef(null);

  // circuit select - circuit info 활성화 시키기 위한 state
  const [circuitSelect, setCircuitSelect] = useState(false);

  // table
  const columns = [
    { id: "id", label: "ID", minWidth: 100, hidden: true },
    { id: "name", label: "Circuit Name", minWidth: 70 },
    { id: "description", label: "Description", minWidth: 200 },
    {
      id: "qubits",
      label: "Qubits",
      minWidth: 70,
    },
    {
      id: "date",
      label: "Created at",
      minWidth: 100,
    },
    {
      id: "runCard",
      label: "Run Card",
      hidden: true,
    },
  ];
  const list = [
    {
      id: 1,
      name: "QASM Model 1",
      description: "QASM2",
      qubits: "2",
      date: "01/19/2024",
      runCard: `nqubits: 2

      qubits: [0, 1]
      
      settings:
          nshots: 1024
          sampling_rate: 1000000000
          relaxation_time: 50_000
      
      topology: [[0, 1]]
      
      native_gates:
          single_qubit:
              0: # qubit number
                  RX:
                      duration: 40
                      amplitude: 0.0484
                      frequency: 4_855_663_000
                      shape: Drag(5, -0.02)
                      type: qd # qubit drive
                      start: 0
                      phase: 0
                  MZ:
                      duration: 620
                      amplitude: 0.003575
                      frequency: 7_453_265_000
                      shape: Rectangular()
                      type: ro # readout
                      start: 0
                      phase: 0
              1: # qubit number
                  RX:
                      duration: 40
                      amplitude: 0.05682
                      frequency: 5_800_563_000
                      shape: Drag(5, -0.04)
                      type: qd # qubit drive
                      start: 0
                      phase: 0
                  MZ:
                      duration: 960
                      amplitude: 0.00325
                      frequency: 7_655_107_000
                      shape: Rectangular()
                      type: ro # readout
                      start: 0
                      phase: 0
      
          two_qubit:
              0-1:
                  CZ:
                  - duration: 30
                    amplitude: 0.055
                    shape: Rectangular()
                    qubit: 1
                    relative_start: 0
                    type: qf
                  - type: virtual_z
                    phase: -1.5707963267948966
                    qubit: 0
                  - type: virtual_z
                    phase: -1.5707963267948966
                    qubit: 1
      
      characterization:
          single_qubit:
              0:
                  readout_frequency: 7_453_265_000
                  drive_frequency: 4_855_663_000
                  T1: 0.0
                  T2: 0.0
                  sweetspot: -0.047
                  # parameters for single shot classification
                  threshold: 0.00028502261712637096
                  iq_angle: 1.283105298787488
              1:
                  readout_frequency: 7_655_107_000
                  drive_frequency: 5_800_563_000
                  T1: 0.0
                  T2: 0.0
                  sweetspot: -0.045
                  # parameters for single shot classification
                  threshold: 0.0002694329123116206
                  iq_angle: 4.912447775569025`,
    },
    {
      id: 2,
      name: "VQE Model 1",
      description: "QASM2",
      qubits: "2",
      date: "01/29/2024",
      runCard: `qubits: [0, 1]
      couplers: [0]
      
      # Mapping couplers to qubit pairs
      topology: {0: [0, 1]}
      
      native_gates:
          two_qubit:
              0-1:
                  CZ:
                  - duration: 30
                    amplitude: 0.6025
                    shape: Rectangular()
                    qubit: 1
                    relative_start: 0
                    type: qf
      
                  - type: virtual_z
                    phase: -1
                    qubit: 0
                  - type: virtual_z
                    phase: -3
                    qubit: 1
      
                  - type: coupler
                    duration: 40
                    amplitude: 0.1
                    shape: Rectangular()
                    coupler: 0
                    relative_start: 0
      
      characterization:
          coupler:
              0:
                  sweetspot: 0.0`,
    },
    {
      id: 3,
      name: "VQE Model 2",
      description: "QASM2",
      qubits: "4",
      date: "01/09/2024",
      runCard: `nqubits: 2

qubits: [0, 1]

settings:
    nshots: 1024
    sampling_rate: 1000000000
    relaxation_time: 50_000

topology: [[0, 1]]

instruments:
    twpa_pump:
        frequency: 4_600_000_000
        power: 5

native_gates:
    single_qubit:
        0: # qubit number
            RX:
                duration: 40
                amplitude: 0.0484
                frequency: 4_855_663_000
                shape: Drag(5, -0.02)
                type: qd # qubit drive
                start: 0
                phase: 0
            MZ:
                duration: 620
                amplitude: 0.003575
                frequency: 7_453_265_000
                shape: Rectangular()
                type: ro # readout
                start: 0
                phase: 0
        1: # qubit number
            RX:
                duration: 40
                amplitude: 0.05682
                frequency: 5_800_563_000
                shape: Drag(5, -0.04)
                type: qd # qubit drive
                start: 0
                phase: 0
            MZ:
                duration: 960
                amplitude: 0.00325
                frequency: 7_655_107_000
                shape: Rectangular()
                type: ro # readout
                start: 0
                phase: 0

    two_qubit:
        0-1:
            CZ:
            - duration: 30
              amplitude: 0.055
              shape: Rectangular()
              qubit: 1
              relative_start: 0
              type: qf
            - type: virtual_z
              phase: -1.5707963267948966
              qubit: 0
            - type: virtual_z
              phase: -1.5707963267948966
              qubit: 1

characterization:
    single_qubit:
        0:
            readout_frequency: 7_453_265_000
            drive_frequency: 4_855_663_000
            T1: 0.0
            T2: 0.0
            sweetspot: -0.047
            # parameters for single shot classification
            threshold: 0.00028502261712637096
            iq_angle: 1.283105298787488
        1:
            readout_frequency: 7_655_107_000
            drive_frequency: 5_800_563_000
            T1: 0.0
            T2: 0.0
            sweetspot: -0.045
            # parameters for single shot classification
            threshold: 0.0002694329123116206
            iq_angle: 4.912447775569025`,
    },
  ];

  const setRowEvt = (rowData) => {
    console.log(rowData);
    // circuit info 활성화
    setCircuitSelect(true);

    if (editorRef.current && rowData !== undefined && rowData !== null) {
      setCircuitInfo(rowData);
    }
  };

  const unsetRowEvt = (rowData) => {
    // circuit info 비활성화
    setCircuitSelect(false);
  };

  const handleChange = ({ json, text }) => {
    console.log("json", json);
  };

  // yaml editor에 circuit info 넣기
  useEffect(() => {
    if (
      circuitInfo !== null &&
      circuitInfo !== undefined &&
      editorRef.current
    ) {
      editorRef.current.replaceValue({ json: circuitInfo.runCard });
    }
  }, [circuitInfo]);

  // select 버튼
  const handleSelect = () => {
    console.log("circuitInfo", circuitInfo);

    setPageStep(2);
  };

  return (
    <>
      <Styled.SLayoutDiv>
        <div className="row">
          <div className="sunstone-list large-12">
            <ListTable
              cols={columns}
              list={list}
              selectCallback={setRowEvt}
              deselectCallback={unsetRowEvt}
              title={"Circuit List"}
            />
          </div>
        </div>

        <div className={`sunstone-list ${circuitSelect ? " " : " hidden"}`}>
          <div className="row">
            <Styled.STitleDiv
              className={`${
                themeColor === "dark" ? " dark-theme-tc" : " "
              } large-12 columns`}
            >
              <span>Circuit Info</span>
            </Styled.STitleDiv>
          </div>

          <div className="row">
            <div
              className="large-12"
              style={{
                border: "1px solid #eee",
              }}
            >
              <YamlEditor
                theme={oneDark}
                ref={editorRef}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{
            marginTop: "1rem",
          }}
        >
          <div className="sunstone-list large-12 columns ">
            {/* 우측 정렬 */}
            <div className="text-right">
              <button
                className="button"
                disabled={!circuitSelect}
                onClick={handleSelect}
              >
                Select
              </button>
            </div>
          </div>
        </div>
      </Styled.SLayoutDiv>
    </>
  );
};

export default CircuitSelect;
