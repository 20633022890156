import { ActionTypes } from './../contants/action-types';

const initialState = {
     platfrom : {}
}

export const platformReducer = (state = initialState, {type, payload})=>{
     switch (type) {
          case ActionTypes.CREATE_PLATFORM:
               return {...state, payload};
          case ActionTypes.CREATE_PLATFORM_SUCCESS:
               const platform = payload;
               return {...state, platform }
          case ActionTypes.CREATE_PLATFORM_ERROR:
               const platformErr = payload;
               return {...state, platformErr }
          case ActionTypes.LIST_PLATFORM:
               const list = payload;
               return {...state, list }
          case ActionTypes.LIST_PLATFORM_SUCCESS:
               const listPlatformSuccess = payload;
               return {...state, listPlatformSuccess }
          case ActionTypes.LIST_PLATFORM_ERROR:
               const listPlatformError = payload;
               return {...state, listPlatformError }
            case ActionTypes.DELETE_PLATFORM:
            const deletePlatform = payload;
               return {...state, deletePlatform }
            case ActionTypes.DELETE_PLATFORM_SUCCESS:
               const deletePlatformSuccess = payload;
               return {...state, deletePlatformSuccess }
            case ActionTypes.DELETE_PLATFORM_ERROR:
               const deletePlatformError = payload;
               return {...state, deletePlatformError }
          case ActionTypes.DETAIL_PLATFORM:
               const detailPlatform = payload;
               return {...state, detailPlatform }
          case ActionTypes.DETAIL_PLATFORM_SUCCESS:
               const itemPlatform = payload;
               return {...state, itemPlatform }
          case ActionTypes.UPLOAD_FILE_RUNCARD:
               const uploadFileRuncard = payload;
               return {...state, uploadFileRuncard }
          case ActionTypes.UPLOAD_FILE_RUNCARD_SUCCESS:
               const uploadFileRuncardSuccess = payload;
               return {...state, uploadFileRuncardSuccess }
          case ActionTypes.UPLOAD_FILE_RUNCARD_ERROR:
               const uploadFileRuncardError = payload;
               return {...state, uploadFileRuncardError }
          case ActionTypes.LIST_FILE_RUNCARD:
               return {...state, payload }
          case ActionTypes.LIST_FILE_RUNCARD_SUCCESS:
               let listRuncard = payload
               return {...state, listRuncard }
          case ActionTypes.LIST_FILE_RUNCARD_ERROR:
               return {...state, payload }
          case ActionTypes.ACTIVE_FILE_RUNCARD:
               return {...state, payload }
          case ActionTypes.ACTIVE_FILE_RUNCARD_SUCCESS:
               console.log('data result in reducer', payload);
               let active_success = payload
               return {...state, active_success }
          case ActionTypes.ACTIVE_FILE_RUNCARD_ERROR:
               let error_active = payload
               return {...state, error_active }
            default:
               return state;
     }
}