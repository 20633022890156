import { useState } from "react";
import axiosService from "../../../api/axiosService";
import { baseUrl } from "../../../redux/contants/urlApi";
import { convertDateFormat } from "../../../common/CommonUtil";
import { toast } from "react-toastify";

// benchmark API
import {
  getBenchmarkScriptsList,
  getMyBenchmarkList,
  getBenchmarkScriptsDetailPreview,
  getMyBenchmarkResults,
  getMyBenchmarkResultsDetail,
} from "../../../api/benchmarks";

const useBenchmark = () => {
  // MQT benchmark list
  const [mqtBenchmarkList, setMqtBenchmarkList] = useState([]);
  // selected MQT benchmark data
  const [selectMqtBenchmarkData, setSelectMqtBenchmarkData] = useState(null);
  // selected MQT benchmark Preview data
  const [selectMqtBenchmarkPreviewData, setSelectMqtBenchmarkPreviewData] =
    useState(null);

  // Benchmark List
  const [benchmarkList, setBenchmarkList] = useState([]);
  const [selectedBenchmark, setSelectedBenchmark] = useState(null);
  // Benchmark Results
  const [benchmarkResults, setBenchmarkResults] = useState([]);
  const [selectedBenchmarkResult, setSelectedBenchmarkResult] = useState(null);
  const [selectedBenchmarkResultDetail, setSelectedBenchmarkResultDetail] =
    useState(null);

  // QASM Pop
  const [qasmPop, setQasmPop] = useState({ open: false });

  // QASM set View Code
  const [viewCode, setViewCode] = useState("");

  // 벤치마크 리스트 가져오기
  const getMqtBenchmarkList = (type) => {
    try {
      getBenchmarkScriptsList(type).then((res) => {
        if (res?.data && res?.data?.length > 0) {
          // 라디오 그룹에 맞게, 매핑
          const tempData = res?.data?.map((item) => {
            return {
              id: item?.uuid,
              name: item?.title,
              description: item?.description,
              benchmark_type: item?.benchmark_type,
              benchmark_algorithm: item?.benchmark_algorithm,
            };
          });
          setMqtBenchmarkList(tempData);
        } else {
          setMqtBenchmarkList([]);
        }
      });

      // fetch data
    } catch (err) {
      console.log("errors", errors);
    }
  };

  // 벤치마크 상세 정보 가져오기
  const getBenchmarkDetail = (data) => {
    try {
      getBenchmarkScriptsDetailPreview(data).then((res) => {
        if (res?.data != undefined && res?.data != null) {
          setSelectMqtBenchmarkPreviewData(res.data);
        } else {
          toast.error(
            "Failed to get benchmark detail. Please try again later."
          );

          setSelectMqtBenchmarkPreviewData(null);
        }
      });
    } catch (err) {
      console.log("errors", errors);
      toast.error("Failed to get benchmark detail. Please try again later.");
      setSelectMqtBenchmarkPreviewData(null);
    }
  };

  // Benchmark API
  // Instrument List
  const getInstrumentList = () => {
    const rtnData = [];

    try {
      // fetch data
      const tempData = [
        {
          id: 1,
          name: "Local",
        },
        {
          id: 2,
          name: "Remote",
        },
        {
          id: 3,
          name: "AWS Simulator 1",
        },
        {
          id: 4,
          name: "GPU Sim 1",
        },
      ];

      rtnData.push(...tempData);
    } catch (err) {
      console.log("errors", errors);
    }

    return rtnData;
  };

  // my Benchmark List
  const getBenchmarkList = () => {
    try {
      // fetch data
      getMyBenchmarkList().then((res) => {
        if (res?.data && res?.data?.length > 0) {
          // 라디오 그룹에 맞게, 매핑
          const tempData = res?.data?.map((item) => {
            return {
              id: item?.uuid,
              tempCircuit: item?.benchmark_script,
              benchmark: item?.title,
              name: item?.benchmark_name,
              qubits: item?.num_qubits,
              compiler: item?.compiler,
              createdAt: convertDateFormat(item?.created_at),
              code: (
                <div
                  className="simulator-benchmark-code"
                  onClick={($event) => {
                    $event.stopPropagation();
                    setViewCode(item?.benchmark_script);
                    setQasmPop({ open: true });
                  }}
                >
                  View Code
                </div>
              ),
              instrument: "Simulator 1",
            };
          });
          setBenchmarkList(tempData.reverse());
        } else {
          setBenchmarkList([]);
        }
      });
    } catch (err) {
      console.log("errors", errors);
    }
  };

  // Benchmark Results
  // circuit 임시 추가 -- Benchmark 돌릴때 프로그램 종류가 backend에서는 QuantumTeleportation, QuantumTeleportationWithSubCircuits, Unknown 3개밖에 지원되지 않음.
  const getBenchmarkResults = async (instrument, id, circuit) => {
    try {
      // fetch data
      if (id != null) {
        getMyBenchmarkResults(id).then((res) => {
          if (res?.data && res?.data?.length > 0) {
            let tempBenchmarkResultList = res.data;

            let benchmarkResultList = [];
            tempBenchmarkResultList.map((item, index) => {
              benchmarkResultList.push({
                id: item?.uuid,
                name: item.title,
                qubits: item.num_qubits,

                simulator: item?.simulator,
                status: item?.status,

                createdAt: convertDateFormat(item?.created_at),
                finishedAt: convertDateFormat(item?.updated_at),

                features_chart: item?.features_chart,
                results_chart: item?.results_chart,
              });
            });

            setBenchmarkResults(benchmarkResultList);
          } else {
            setBenchmarkResults([]);
          }
        });
      }

      // 임시
      // if (benchmark == "GHZ") {
      //   setBenchmarkResults([]);
      // } else if (benchmark == "AE") {
      //   setBenchmarkResults([
      //     {
      //       id: 1,
      //       name: instrument + " - " + "AE" + " - " + "1",
      //       qubits: 3,
      //       compiler: "Qiskit",
      //       status: "FINISHED",
      //       createdAt: "2024.1.13 17:52:14",
      //       finishedAt: "2024.1.13 17:52:14",
      //     },
      //     {
      //       id: 2,
      //       name: instrument + " - " + "AE" + " - " + "2",
      //       qubits: 20,
      //       compiler: "Qiskit",
      //       status: "FINISHED",
      //       createdAt: "2024.1.13 17:52:14",
      //       finishedAt: "2024.1.13 17:52:14",
      //     },
      //   ]);
      // } else if (benchmark == "QAOA") {
      //   setBenchmarkResults([
      //     {
      //       id: 1,
      //       name: instrument + " - " + "QAOA" + " - " + "1",
      //       qubits: 3,
      //       compiler: "Qiskit",
      //       status: "RUNNING",
      //       createdAt: "2024.1.13 17:52:14",
      //       finishedAt: "2024.1.13 17:52:14",
      //     },
      //   ]);
      // } else {
      //   setBenchmarkResults([]);
      // }
    } catch (err) {
      console.log("errors", err);
      setBenchmarkResults([]);
    }
  };

  // getBenchmarkResults Detail
  const getBenchmarkResultsDetail = async (id) => {
    try {
      // fetch data
      if (id != null) {
        getMyBenchmarkResultsDetail(id).then((res) => {
          if (res?.data != undefined && res?.data != null) {
            setSelectedBenchmarkResultDetail(res.data);
          } else {
            setSelectedBenchmarkResultDetail(null);
          }
        });
      }
    } catch (err) {
      console.log("errors", err);
      setSelectedBenchmarkResultDetail(null);
    }
  };

  return {
    mqtBenchmarkList,
    setMqtBenchmarkList,
    selectMqtBenchmarkData,
    setSelectMqtBenchmarkData,
    selectMqtBenchmarkPreviewData,

    getMqtBenchmarkList,

    getBenchmarkDetail,
    // Benchmark API
    // Instrument List
    getInstrumentList,
    // Benchmark List
    benchmarkList,
    getBenchmarkList,
    selectedBenchmark,
    setSelectedBenchmark,
    qasmPop,
    setQasmPop,
    viewCode,

    // Benchmark Results
    benchmarkResults,
    getBenchmarkResults,
    selectedBenchmarkResult,
    setSelectedBenchmarkResult,

    getBenchmarkResultsDetail,
    selectedBenchmarkResultDetail,
  };
};

export default useBenchmark;
