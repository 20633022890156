import React from "react";
import FieldGroup from "../../components/FieldGroup";
import ListTable from "../../components/Table/ListTable";

function TableField({ data, selectRows, defaultValue }) {
  const createListColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "Circuit Name", label: "Circuit Name", minWidth: 70 },
      {
        id: "Circuit Description",
        label: "Circuit Description",
        minWidth: 200,
      },
      { id: "Create Time", label: "Create Time", minWidth: 100 },
      { id: "Update Time", label: "Update Time", minWidth: 100 },
      { id: "code", hidden: true },
    ];
  };

  return (
    <FieldGroup label={"Circuit List"} foldable={true} defaultFold={true}>
      <ListTable
        cols={createListColumns()}
        selectCallback={selectRows}
        list={data?.list}
        height={200}
        defaultValue={defaultValue}
      />
    </FieldGroup>
  );
}

export default TableField;
