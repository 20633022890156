import React from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import BackendHeader from "./Header";
import "./index.css";
import TableArea from "./TableArea";

function index() {
  return (
    <DefaultLayout>
      <BackendHeader />
      <TableArea />
    </DefaultLayout>
  );
}

export default index;
