import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../layouts/DefaultLayout";
import useCircuit from "./utils/useCircuit";
import "./index.css";
import EditorHeader from "./EditorHeader";
import Editor from "./Editor";
import TableField from "./TableField";
import { useParams } from "react-router-dom";
import { downloadTextAsFile } from "./utils/handleFileDownload";

const exportLanguageOption = [
  { key: "QASM", value: "QASM", label: "QASM" },
  { key: "JavaScript", value: "JavaScript", label: "JavaScript" },
  { key: "Qiskit", value: "Qiskit", label: "Qiskit" },
  { key: "Pyquil", value: "Pyquil", label: "Pyquil" },
  { key: "Quil", value: "Quil", label: "Quil" },
  { key: "Cirq", value: "Cirq", label: "Cirq" },
  { key: "QuEST", value: "QuEST", label: "QuEST" },
  { key: "QSharp", value: "QSharp", label: "QSharp" },
  { key: "PyAQASM", value: "PyAQASM", label: "PyAQASM" },
  { key: "AQASM", value: "AQASM", label: "AQASM" },
  { key: "SVG", value: "SVG", label: "SVG" },
];

const importLanguageOption = [
  { key: "QASM", value: "QASM", label: "QASM" },
  { key: "Quil", value: "Quil", label: "Quil" },
  { key: "Qobj", value: "Qobj", label: "Qobj" },
  { key: "Ionq", value: "Ionq", label: "Ionq" },
];

const initText = () => {
  return 'OPENQASM 2.0;\ninclude  "qelib1.inc";\nqreg q[2];\nh q[0];\ncx q[0],q[1];\n';
};

function Transpiler() {
  const { myParam } = useParams();
  // 추후 받아오는 쿼리스트링의 값의 이름에 따라 변수명 변경예정
  const [data, setData] = useState({});
  const [selectedOption, setSelectedOption] = useState({
    import: "QASM",
    export: "QASM",
  });
  const [fromText, setfromText] = useState(initText());
  const [toText, setToText] = useState(null);
  const [convertCircuit] = useCircuit(fromText, setToText);

  const error = toText?.includes("Error") ? true : false;

  const DefaultTableValue = data.hasOwnProperty("selectedRowData")
    ? data.selectedRowData
    : "";

  const fileInputRef = useRef(null);

  // let ionqCircuit = {
  //   qubits: 2,
  //   circuit: [
  //     {
  //       gate: "h",
  //       target: 0,
  //     },
  //     {
  //       gate: "cnot",
  //       target: 1,
  //       control: 0,
  //     },
  //   ],
  // };

  // let circuit = new QuantumCircuit();

  // function executeCircuitMethod(LanguageName, circuitText) {
  //   return new Promise((resolve, reject) => {
  //     circuit[LanguageName](circuitText, (errors) => {
  //       if (errors.length) {
  //         console.log("errors", errors);
  //         reject(errors);
  //       } else {
  //         console.log("success");
  //         resolve("success");
  //       }
  //     });
  //   });
  // }

  // executeCircuitMethod("importIonq", ionqCircuit);

  //  let qasm = circuit.exportToPyquil(ionqCircuit, false);

  //  console.log("qasm",qasm);

  useEffect(() => {
    fetchDataList();
    setData((prev) => ({
      ...prev,
      importLanguage: importLanguageOption,
      exportLanguage: exportLanguageOption,
    }));

    if (myParam) {
      decodeQuery = decodeURI(myParam);
      setImportCircuitText(decodeQuery);
    }
  }, []);

  useEffect(() => {
    if (data.hasOwnProperty("selectedRowData")) {
      const { selectedRowData } = data;
      setfromText(selectedRowData?.code);
    }
  }, [data]);

  useEffect(() => {
    if (selectedOption) {
      converter();
    }
  }, [selectedOption, fromText]);

  const handleTableSelectOption = (v) => {
    setSelectedOption((prev) => ({ ...prev, [v.key]: v.value }));
  };

  const selectRows = (selectedRowData) => {
    setData((prev) => ({ ...prev, selectedRowData: selectedRowData }));
  };
  const handleTextChange = (e) => {
    const newText = e.target.value;
    setfromText(newText);
  };

  const converter = () => {
    convertCircuit(selectedOption);
  };

  const fetchDataList = async () => {
    let tempData = [
      {
        id: 1,
        name: "QASM",
        description: "for Demo",
        address: "2024-05-12 14:19:13",
        updateTime: "2024-05-12 14:19:13",
        code: `OPENQASM 2.0;
        include "qelib1.inc";
        qreg q[2];
        creg c[2];
        h q[0];
        cx q[0], q[1];
        measure q[0] -> c[0];
        measure q[1] -> c[1];`,
      },
      {
        id: 2,
        name: "Test",
        description: "description",
        address: "2024-05-10 14:19:13",
        updateTime: "2024-05-10 14:19:13",
        code: `{
          "qubits": 2,
          "circuit": [
            {
              "gate": "h",
              "target": 0
            },
            {
              "gate": "cnot",
              "target": 1,
              "control": 0
            }
          ]
        }
        `,
      },
      {
        id: 3,
        name: "QSVM",
        description: "description",
        address: "2024-04-04 14:19:13",
        updateTime: "2024-04-04 14:19:13",
        code: `DECLARE ro BIT[2]
        H 0
        CNOT 0 1
        MEASURE 0 ro[0]
        MEASURE 1 ro[1]`,
      },
    ];
    setData((prev) => ({ ...prev, list: tempData }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setfromText(e.target.result);
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const downLoadFile = () => {
    return downloadTextAsFile(toText);
  };

  return (
    <DefaultLayout title={"Transpiler"}>
      <div className="sunstone-list">
        <TableField
          selectRows={selectRows}
          data={data}
          defaultValue={DefaultTableValue}
        />
        <EditorHeader
          importLanguage={data?.importLanguage}
          exportLanguage={data?.exportLanguage}
          handleChangeEvt={handleTableSelectOption}
          handleButtonClick={handleButtonClick}
          handleFileChange={handleFileChange}
          fileInputRef={fileInputRef}
          downLoadFile={downLoadFile}
        />
        <Editor
          fromText={fromText}
          toText={toText}
          handleTextChange={handleTextChange}
          handleKeyUp={converter}
          error={error}
        />
      </div>
    </DefaultLayout>
  );
}

export default Transpiler;
