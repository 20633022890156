import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const SpecificHost = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                &nbsp; {icon3}&nbsp;{name}
            </a>
            <div id="advanced_section_3" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="small-12 columns hostContext0">
                        <fieldset>
                            <div className="provision_host_selector0" data-tab-content>
                                <div id="HostsTableone1Container">
                                    <div className="row select-resources">
                                        <div className="small-12 medium-6 columns" id="selected_ids_row_HostsTableone1">
                                            <label htmlFor="selected_resource_id_HostsTableone1" id="select_resource_HostsTableone1" style={{ display: 'none' }}>Please select a Host from the list</label>
                                            <span id="selected_resource_HostsTableone1" hidden style={{ display: 'none' }}>You selected the following Host:</span>
                                            <span id="select_resource_multiple_HostsTableone1" hidden style={{ display: 'inline' }}>Please select one or more hosts from the list</span>
                                            <span id="selected_resource_multiple_HostsTableone1" hidden style={{ display: 'none' }}>You selected the following hosts:</span>
                                            <span id="selected_resource_name_HostsTableone1" className="radius label" style={{ display: 'none' }} />
                                            <input id="selected_resource_id_HostsTableone1" type="text" style={{ visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline' }} />
                                        </div>
                                        <div className="small-10 medium-4 columns right">
                                            <input id="HostsTableone1_search" className="search" type="search" placeholder="Search" />
                                        </div>
                                        <div className="small-2 columns right">
                                            <a id="refresh_button_HostsTableone1" href="#" className="refresh-table right">
                                                <i className="fas fa-lg fa-fw fa-sync-alt" />
                                            </a>
                                        </div>
                                    </div>
                                    <div id="HostsTableone1_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                        <table id="HostsTableone1" className="hover dataTable no-footer" role="grid" aria-describedby="HostsTableone1_info">
                                            <thead>
                                                <tr role="row">
                                                    <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="HostsTableone1" aria-sort="descending">ID</th>
                                                    <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="HostsTableone1">Name</th>
                                                    <th aria-label="Cluster: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="HostsTableone1">Cluster</th>
                                                    <th aria-label="RVMs: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="HostsTableone1">RVMs</th>
                                                    <th aria-label="Allocated CPU" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 155 }}>Allocated CPU</th>
                                                    <th aria-label="Allocated MEM" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 155 }}>Allocated MEM</th>
                                                    <th aria-label="Status: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="HostsTableone1">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="odd">
                                                    <td valign="top" colSpan={7} className="dataTables_empty">
                                                        <span className="text-center" style={{ fontSize: 18, color: '#999' }}>
                                                            <br />
                                                            <span className="fa-stack fa-3x" style={{ color: '#dfdfdf' }}>
                                                                <i className="fas fa-cloud fa-stack-2x" />
                                                                <i className="fas fa-info-circle fa-stack-1x fa-inverse" />
                                                            </span>
                                                            <br />
                                                            <span style={{ color: '#999' }}>There is no data available</span>
                                                        </span>
                                                        <br />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="small-6 columns">
                                                <div className="dataTables_length" id="HostsTableone1_length">
                                                    <label>
                                                        <select name="HostsTableone1_length" aria-controls="HostsTableone1" className>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="dataTables_info" id="HostsTableone1_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                            </div>
                                            <div className="small-6 columns">
                                                <div className="dataTables_paginate paging_simple_numbers" id="HostsTableone1_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button previous unavailable disabled" aria-controls="HostsTableone1" tabIndex={0} id="HostsTableone1_previous">Previous</li>
                                                        <li className="paginate_button next unavailable disabled" aria-controls="HostsTableone1" tabIndex={0} id="HostsTableone1_next">Next</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                    <label htmlFor="SCHED_REQUIREMENTS"> Expression <span data-tooltip aria-haspopup="true" className="has-tip" data-disable-hover="false" title="Boolean expression that rules out entries from the pool of datastores suitable to run this VM.">
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </span>
                                    </label>
                                    <input type="text" wizard_field="SCHED_REQUIREMENTS" id="SCHED_REQUIREMENTS0" name="requirements" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default SpecificHost;