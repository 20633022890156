


const Actions = () => {
    return <>
    <div id="vm_actions_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_actions_tab-label">
        <div className="row">      
        <div className="large-12 columns">        
        <table id="scheduling_vms_actions_table" className="info_table dataTable">         
            <thead>           
                <tr>              
                    <th>ID</th>              
                    <th>Action</th>              
                    <th>Time</th>              
                    <th>Rep</th>              
                    <th>End</th>                
                    <th>Done</th>              
                    <th>Message</th>              
                    <th colSpan> </th>              
                    <th><button id="add_scheduling_vms_action" className="button small success right radius">Add action</button></th>         
                </tr>        
            </thead>          
            <tbody id="sched_vms_actions_body">      
                <tr id="no_actions_tr">          
                <td colSpan={6}>No actions to show</td>    
                </tr>
            </tbody>       
        </table>      
    </div>    
    </div>
    </div>
    </>
}

export default Actions;