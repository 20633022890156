import React from "react";
import CustomButton from "../../../../components/Button/CustomButton";
import { useParams } from "react-router";
import { useHistory } from "react-router";



const mapper = {
  "SQK": {
    path: "/assets/images/sqk-kokkos-white-code01-instruments2.svg",
    size: "80px",
    text: 'Measurements',
    textLeft: '0px',
    typeName: "SQK Inst"
  },
  "IQM": {
    path: "/assets/images/IQM-logo.svg",
    size: "50px",
    left: '5px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "IQM Inst"
  },
  "IONQ": {
    path: "/assets/images/ionq-logo-light.svg",
    size: "70px",
    left: '2px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "IONQ Inst"
  },
  "Bluefors": {
    path: "/assets/images/bluefors-logo-white.png.webp",
    size: "90px",
    left: '2px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "Bluefors Inst"
  }
};
const imageSize = {
  "SQK Instruments": "150px",
  "Zurich Instruments": "130px",
  "QUANTUM MACHINES": "160px",
  "KeySight":"170px",
  QBLOX: "150px",
};
const deafultImg = "/assets/quantums/4-qick-logo2.svg";

function Header({changeInstrumentModel,setIsReset,deleteInstrumentModel}) {
  const { id } = useParams();
  const history = useHistory();

  return (
    <div className="list-detail-header-layout">
      <div className="list-detail-title">
        {mapper[id] ? (
          <>
          <img style={{ width: `${mapper[id].size}` }} src={mapper[id].path} />
          {mapper[id]?.text && (
            <span style={{marginLeft: mapper[id]?.textLeft?mapper[id]?.textLeft:'8px', fontSize: '15px', fontWeight: 'bold'}}>
              {mapper[id]?.text}
            </span>
          )}
          </>
        ) : (
          <span className="image-title" style={{color:'#EEEEEE'}}>
            <img className="list-card-image" src={deafultImg} />
            {id}
          </span>
        )}
      </div>
      <div className="custom-btn-layout">
        <CustomButton
          type="goback"
          onClick={() => {
            console.log("goback");
            history.push("/measurement-driver/list");
          }}
        />
        <CustomButton
          type="sync"
          onClick={() => {
            console.log("reset Btn");
            setIsReset(true)
          }}
        />
        <CustomButton
          type="custom" label={"SAVE"}
          onClick={() => {
            console.log("save Btn");
            changeInstrumentModel()
          }}
        />
        <CustomButton
          disabled="disabled"
          type="delete"
          onClick={() => {
            console.log("create Btn");
            deleteInstrumentModel();
          }}
        />
      </div>
    </div>
  );
}

export default Header;