import styled, { css } from "styled-components";

export const SLayout = styled.section`
  margin-top: 2rem;
  width: 100%;
`;

export const SContentsLayout = styled.div`
  color: #fff;
  height: 100%;
  min-height: 31.25rem;
  margin: 1.5rem 2rem 1.5rem 2rem;
`;

export const STitleDiv = styled.div`
  font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin: 1rem 1rem 1rem 1rem;
`;

// description
export const STitleDescriptionDiv = styled.div`
  margin: 1rem 0 1rem 0;
  font-size: 1.2rem;
  font-style: italic;
  color: #858d98;
  height: 10%;
`;

export const SListDiv = styled.div`
  height: auto;
  display: flex;
`;

export const SControlsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0 1rem 0;
  height: 20%;
`;

export const SControlItemDiv = styled.div`
  padding: 1rem 1rem 1rem 1rem;
  margin-right: 3rem;
`;

export const SButtonDiv = styled.div`
  display: flex;
  justify-content: ${({ isOneBtn }) => (isOneBtn ? 'flex-end' : 'space-between')};
  height: 10%;
  margin: 1rem 0;
`;

export const SRadioGroupDiv = styled.div`
width:40%;
height100%;
`;

export const SImageDiv = styled.div`
  width:100%;
  min-height:20rem;
  margin-bottom:50px;
  margin-top:20px;
`;
export const SRightSideWrapper = styled.div`
  width: 50%;
  height: 100%;
`;

export const SCodeEditorDiv =styled.div`
 height:auto;
`
