import styled, { css } from "styled-components";

// benchmark Excute Div
export const SBenchExcuteDiv = styled.div`
  display: flex;
  align-items: center;
  height: 5rem;
  margin: 3rem 1rem 0rem 1rem;
`;

export const SBenchExcuteSelectDiv = styled.div`
  min-width: 45rem;
`;

export const SBenchExcuteButtonDiv = styled.div`
  margin-left: auto;
`;
