import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useRef, useState } from "react";
import {
  renderInfoSkeleton,
  renderPermissionSkeleton,
  renderOwnershipSkeleton,
  renderAttributesSkeleton,
} from "./InformationRenderer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
  getChannelConf,
  dataToJson,
  detectDataType,
  jsonToYaml,
} from "../../../../../common/CommonUtil";

// code editor
import CodeEditor from "../../../../../components/CodeEditor/Index";

// api
import {
  getSettings,
  updateSettings,
  deleteMeasurement,
} from "../../../../../api/measurement";

import AlertDialog from "../../../../../components/Dialog";
import { tError, tSuccess, tWarnning } from "../../../../../common/CommonToast";

const Information = ({ data, setData }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const { id } = useParams();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // 임시셋팅
    setTimeout(() => {
      setLoading(false);
    }, 750);

    // get settings
    handleSettingsFetch(id);
  }, []);

  const renderInfoDiv = (key, value, icon) => {
    return (
      <div
        className={`driver-info-page-left-grid-container ${
          themeColor === "dark" ? "custom-theme-dark" : ""
        }`}
      >
        <div>{key}</div>
        <div>{value}</div>
        <div className={`driver-info-page-left-grid-icon`}>{icon}</div>
      </div>
    );
  };

  const handleFetch = async () => {
    let channelConf = await getChannelConf(id);

    // 선번장 조회
    if (channelConf?.status == "S") {
      setData((prev) => ({ ...prev, channelConf: channelConf?.data }));
    }
  };

  // settings fetch
  const handleSettingsFetch = async (uuid) => {
    const response = await getSettings(uuid);
    const data = response?.data;

    setData((prev) => ({ ...prev, configData: data }));
  };

  const validationCheck = () => {
    if (detectDataType(dataToJson(data?.configData)) != "JSON Object") {
      tWarnning("Invalid Config format");
      return false;
    }

    return true;
  };

  const saveMeasurementData = async () => {
    let res = await updateSettings(id, dataToJson(data?.configData));

    if (res?.status == 200 || res?.status == 201) {
      tSuccess("Success update");
    }
  };

  const handlePopupResult = () => {
    const isVaild = validationCheck();
    if (isVaild) {
      saveMeasurementData();
    }
  };

  const renderSavePop = () => {
    const runPopContents = {
      title: "Confirm",
      contents: (
        <>
          <div className="create-station-pop-container">Change it?</div>
        </>
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <AlertDialog
        type="custom"
        label="Save"
        id="submit"
        disabled={true ? false : true}
        callback={handlePopupResult}
        contents={runPopContents}
      />
    );
  };

  return (
    <>
      <div className="driver-info-page-container">
        <FieldGroup label={"Channel Conf"} foldable={true}>
          <div
            className={`station-info-page-chnl-text-container ${
              themeColor === "dark" ? "custom-theme-dark" : ""
            }`}
          >
            <div className={`attr-text-item-header`}>Port No</div>
            <div className={`attr-text-item-header`}>Port Name</div>
            <div className={`attr-text-item-header`}>Channel Name</div>
            <div className={`attr-text-item-header`}>Connected</div>
          </div>
          {data?.channelConf?.map((item) => {
            return (
              <div
                className={`station-info-page-chnl-text-container ${
                  themeColor === "dark" ? "custom-theme-dark" : ""
                }`}
              >
                <div className={`attr-text-item-no`}>{item?.port_no}</div>
                <div className={`attr-text-item`}>{item?.port_name}</div>
                <div className={`attr-text-item`}>{item?.channel_name}</div>
                <div className={`attr-text-item`}>{item?.connected_qubits}</div>
              </div>
            );
          })}
        </FieldGroup>

        <FieldGroup label={"Config"} foldable={true}>
          <CodeEditor
            value={jsonToYaml(data?.configData)}
            onChange={(e) => {
              setData((prev) => ({ ...prev, configData: e }));
            }}
          />
        </FieldGroup>

        <div className="instrument-config-tab-btn-container">
          <div>{renderSavePop()}</div>
        </div>
      </div>
    </>
  );
};

export default Information;
