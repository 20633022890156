import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./ListTable.css";

const theme = createTheme({
  typography: {
    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
    fontSize: 14,
  },
});

export default function ListTable({
  cols,
  list,
  selectCallback,
  title,
  maxHeight,
  height,
  disabledClick,
  deselectCallback,
  noPagination,
  defaultValue,
  error,
  errorMessage,
  selectRow
}) {
  const themeColor = process.env?.REACT_APP_THEME;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectedRowIdx, setSelectedRowIdx] = React.useState(null);

  const handleSelectRow = (idx) => {
    // 클릭 비활성화 상태일 경우 클릭 이벤트 무시
    if (disabledClick == true) return;
    setSelectedRowIdx(idx);

    // 중복클릭시 selected row index 초기화
    if (!!deselectCallback && selectedRowIdx === idx) {
      setSelectedRowIdx(null);
      if (!!deselectCallback) {
        deselectCallback();
      }
      return;
    }

    if (selectCallback) {
      selectCallback(list[idx]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    // Default Value 있을 경우, 해당 Row로 init Setting.

    if (defaultValue) {
      // Default Value Index 찾기.

      const defaultValueIndex = list.findIndex((item) => item === defaultValue);

      if (defaultValueIndex >= 0) {
        // Default Row Highlight & Call Parent Callback Function
        handleSelectRow(defaultValueIndex);
      }
    }
  }, [defaultValue]);


  useEffect(()=>{
    setSelectedRowIdx(selectRow)
  },[selectRow])

  return (
    <div id="create_platform">
      {title && (
        <div
          style={{
            fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
            fontSize: "14pt",
            fontWeight: "bold",
            marginLeft: "1rem",
            marginBottom: "1rem",
          }}
          className={themeColor === "dark" ? "dark-theme-tc" : ""}
        >
          {title}
        </div>
      )}
      <ThemeProvider theme={theme}>
        <Paper className={`custom-mui-list-table-paper`}>
          <TableContainer
            sx={{
              height: height ? height : 200,
              maxHeight: maxHeight ? maxHeight : 300,
            }}
            className={`${
              themeColor === "dark" ? "dark-theme-list-table-container" : ""
            } ${
              noPagination
                ? "custom-mui-list-table-mgb-1"
                : "custom-mui-list-table"
            }`}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {noPagination != true && (
                    <TableCell
                      align={"left"}
                      style={{ width: 90, paddingLeft: "2rem" }}
                      sx={{
                        fontFamily:
                          '"Helvetica Neue", Roboto, Arial, sans-serif',
                        fontSize: "14px",
                      }}
                      className={
                        themeColor === "dark"
                          ? "dark-theme-list-table-header"
                          : ""
                      }
                    >
                      No
                    </TableCell>
                  )}
                  {cols?.map((item, idx) => {
                    if (item?.hidden) return;

                    return (
                      <TableCell
                        align={item.align}
                        key={idx}
                        style={{ minWidth: item.minWidth }}
                        sx={{
                          fontFamily:
                            '"Helvetica Neue", Roboto, Arial, sans-serif',
                          fontSize: "14px",
                        }}
                        className={
                          themeColor === "dark"
                            ? "dark-theme-list-table-header"
                            : ""
                        }
                      >
                        {item.label}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody id="testIsung">
                {/* 페이징 처리 안함 */}
                {list?.map((row, index) => {
                  //페이징처리
                  if (noPagination != true) {
                    if (index < page * rowsPerPage) return;
                    if (index >= page * rowsPerPage + rowsPerPage) return;

                    // index 초기화
                    index = index - page * rowsPerPage;
                  }
                  return (
                    <TableRow
                      hover
                      key={"data-row-" + index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      selected={selectedRowIdx === index}
                      onClick={() => {
                        handleSelectRow(index);
                      }}
                      className={
                        themeColor === "dark"
                          ? "dark-theme-list-table-data-row"
                          : ""
                      }
                    >
                      {noPagination != true && (
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ paddingLeft: "2rem" }}
                          key={index}
                          sx={{
                            fontFamily:
                              '"Helvetica Neue", Roboto, Arial, sans-serif',
                            fontSize: "14px",
                          }}
                          className={`${
                            themeColor === "dark"
                              ? "dark-theme-list-table-data"
                              : ""
                          }`}
                        >
                          <a>{page * rowsPerPage + index + 1}</a>
                        </TableCell>
                      )}

                      {Object.keys(row)?.map((column, idx) => {
                        if (cols.find((item) => item.id === column)?.hidden)
                          return;

                        return (
                          <TableCell
                            align={
                              cols.find((item) => item.id === column)?.align
                            }
                            component={"th"}
                            scope={"row"}
                            sx={{
                              fontFamily:
                                '"Helvetica Neue", Roboto, Arial, sans-serif',
                              fontSize: "14px",
                            }}
                            style={{ maxWidth: 400, wordWrap: "break-word" }}
                            key={idx}
                            className={`${
                              themeColor === "dark"
                                ? "dark-theme-list-table-data"
                                : ""
                            }`}
                          >
                            {/* React component 인지 판별 */}
                            {typeof row[column] == "object" ? (
                              <>{row[column]}</>
                            ) : (
                              <>
                                <a>{row[column]}</a>
                              </>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}

                {/* 페이징 처리 */}
                {/* {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                return (
                <TableRow
                  hover
                  key={"data-row-"+index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  selected={selectedRowIdx===index}
                  onClick={()=>{handleSelectRow(index)}}
                  className={themeColor==='dark'?"dark-theme-list-table-data-row":""}
                >
                  <TableCell
                    align="left"
                    component="th"
                    scope="row"
                    style={{paddingLeft: '2rem'}}
                    key={index}
                    sx={{
                      fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                      fontSize: "14px",
                    }}
                    className={`${themeColor==='dark'?"dark-theme-list-table-data":""}`}
                  >
                    <a>{(page*rowsPerPage) + index + 1}</a>
                  </TableCell>
                  {Object.keys(row).map((column,idx)=>{
                    if(cols.find(item=>item.id === column)?.hidden) return

                    return(
                      <TableCell align={cols.find(item=>item.id === column)?.align} component={"th"} scope={"row"} sx={{
                        fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                        fontSize: "14px",
                      }}
                        style={{maxWidth:400, wordWrap:"break-word"}}
                        key={idx}
                        className={`${themeColor==='dark'?"dark-theme-list-table-data":""}`}
                      >
                        <a>{row[column]}</a>
                      </TableCell>
                    )
                  })}
                </TableRow>
              )})} */}
              </TableBody>
            </Table>
          </TableContainer>
          {noPagination != true && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={list?.length ? list?.length : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              className={`custom-mui-table-pagination ${
                themeColor === "dark" ? "dark-theme-pagination" : ""
              }`}
            />
          )}
          {error && (
            <div
              className="custom-mui-list-table-error-msg-container"
              style={{ marginTop: "-4.8rem" }}
            >
              {errorMessage}
            </div>
          )}
        </Paper>
      </ThemeProvider>
    </div>
  );
}
