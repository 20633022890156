import axiosService from "./axiosService";
import { baseUrl, serverUrl } from "../redux/contants/urlApi";

// measurements list
export const listMeasurement = async () => {
  return axiosService.get(baseUrl + "v1/measurements/");
};

// get settings
export const getSettings = async (uuid) => {
  return axiosService.get(baseUrl + `v1/measurements/${uuid}/settings`);
};

// update settings
export const updateSettings = async (uuid, settings) => {
  return axiosService.put(
    baseUrl + `v1/measurements/${uuid}/settings`,
    settings
  );
};

// delete measurement
export const deleteMeasurement = async (uuid) => {
  return axiosService.delete(baseUrl + `v1/measurements/${uuid}`);
};
