import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton"
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useRef, useState } from "react";
import {
  renderInfoSkeleton
  , renderPermissionSkeleton
  , renderOwnershipSkeleton
  , renderAttributesSkeleton
} from "./InformationRenderer";
import IconButton from "../../../../../components/Button/IconButton";
import { FaPlus } from "react-icons/fa6";

import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";

const Information = ({ }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const [data, setData] = useState({
    configData: `# NB cool stuff: this can also optimize integers and other
# hyper-parameters
self.MC.soft_avg(1)
self.mock_parabola.noise(0.5)
self.MC.set_sweep_functions([self.mock_parabola.x, self.mock_parabola.y])
self.MC.set_adaptive_function_parameters(
    {
        "adaptive_function": adaptive.SKOptLearner,
        "goal": lambda l: l.npoints > 14,
        "dimensions": [(-50.0, +50.0), (-20.0, +30.0)],
        "base_estimator": "gp",
        "acq_func": "gp_hedge",
        "acq_optimizer": "lbfgs",
    }
)
self.MC.set_detector_function(self.mock_parabola.parabola_list)
dat = self.MC.run(
    "2D SKOptLearner adaptive sampling test multi", mode="adaptive"
)`
  });
  const [loading, setLoading] = useState(true)
  const configRef = useRef()

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(() => {

    // 임시셋팅
    setTimeout(() => {
      setLoading(false)
    }, 450)
  }, [])


  const renderInfoDiv = (key, value, icon) => {
    return (
      <div className={`info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <div>{key}</div>
        <div>{value}</div>
        <div className={`info-page-left-grid-icon`}>{icon}</div>
      </div>
    )
  }

  return <>
    <div className="info-page-container">
      <div className={`info-page-contents-container`}>
        <div>
          {/* Information 영역 */}
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Information
            </div>

            {/* Information Skeleton */}
            {loading && renderInfoSkeleton()}

            {/* Information Data Setting */}
            {!loading && <>
              {renderInfoDiv("ID", "be1y8yasd98as2asd786sd9nd9u8", null)}
              {renderInfoDiv("Name", "test", <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("Description", "for Dummy Test", <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("State", "ACTIVE", null)}
              {renderInfoDiv("Resonator Type", "M", null)}
              {renderInfoDiv("LCM State", "LCM_INIT", null)}
              {renderInfoDiv("Address", "0.0.0.0", null)}
              {renderInfoDiv("Port", "4901", null)}
              {renderInfoDiv("Started at", "10:39:32 30/12/2023", null)}
              {renderInfoDiv("Deploy ID", "-", null)}
              {renderInfoDiv("Reschedule", "no", null)}
              {renderInfoDiv("Virtual Router", "-", null)}
              <div style={{marginTop:'2rem'}}>
                <div className={`driver-info-page-right-image-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                  <img src="/assets/quantums/quantum-5.jpg" ></img>
                </div>
              </div>
            </>}
            
          </FieldGroup>
        </div>

        {/* Permissions 영역 */}
        <div>
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Permissions
            </div>

            <div className={`info-page-right-grid-container header ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              <div></div>
              <div className={`item`}>Use</div>
              <div className={`item`}>Manage</div>
              <div className={`item`}>Admin</div>
            </div>

            {/* Permissions Skeleton */}
            {loading && renderPermissionSkeleton()}

            {/* Permissions Data Setting */}
            {!loading && <>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Owner</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked={true} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
              </div>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Group</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
              </div>
              <div className={`info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Other</div>
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} />
                <Checkbox className={`item`} style={{ pointerEvents: true ? 'none' : 'all' }} checked />
              </div>
            </>}
          </FieldGroup>

          {/* Ownership 영역 */}
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Ownership
            </div>

            {/* Ownership Skeleton */}
            {loading && renderOwnershipSkeleton()}

            {/* Ownership Data Setting */}
            {!loading && <>
              <div className={`info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Owner</div>
                <div className='info-page-right-grid-onwership-link'>oneadmin</div>
                <div className={`info-page-right-grid-onwership-icon`}><CustomIcon type={"faEdit"} /></div>
              </div>
              <div className={`info-page-right-grid-onwership-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>Group</div>
                <div className='info-page-right-grid-onwership-link'>oneadmin</div>
                <div className={`info-page-right-grid-onwership-icon`}><CustomIcon type={"faEdit"} /></div>
              </div>
            </>}
          </FieldGroup>
        </div>
      </div>


      {/* <FieldGroup foldable={true} label={"Channel Conf"}> */}
      {/* {loading && renderOwnershipSkeleton()} */}
      {/* {!loading&&<>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-header`}>Port No</div>
            <div className={`attr-text-item-header`}>Port Name</div>
            <div className={`attr-text-item-header`}>Channel Name</div>
            <div className={`attr-text-item-header`}>Connected</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>1</div>
            <div className={`attr-text-item`}>o1</div>
            <div className={`attr-text-item`}>ch1out</div>
            <div className={`attr-text-item`}>(2) QSVM.q[0]-readout, QSVM.q[1]-readout</div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>2</div>
            <div className={`attr-text-item`}>i1</div>
            <div className={`attr-text-item`}>ch1in</div>
            <div className={`attr-text-item`}></div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>3</div>
            <div className={`attr-text-item`}>o2</div>
            <div className={`attr-text-item`}>ch2</div>
            <div className={`attr-text-item`}></div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>4</div>
            <div className={`attr-text-item`}>o3</div>
            <div className={`attr-text-item`}>ch3</div>
            <div className={`attr-text-item`}></div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>5</div>
            <div className={`attr-text-item`}>o4</div>
            <div className={`attr-text-item`}>chf1</div>
            <div className={`attr-text-item`}></div>
          </div>
          <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item-no`}>6</div>
            <div className={`attr-text-item`}>o5</div>
            <div className={`attr-text-item`}>chf2</div>
            <div className={`attr-text-item`}></div>
          </div>
        </>} */}

      {/* </FieldGroup> */}

      {/* Attributes 영역 */}
      {/* <FieldGroup> */}
      {/* <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
          Attributes
        </div> */}

      {/* Attributes Skeleton */}
      {/* {loading && renderAttributesSkeleton()} */}

      {/* Attributes Data Setting */}
      {/* {!loading && <>
          <div className={`info-page-attr-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item`}>DESCRIPTION</div>
            <div className={`attr-text-item`}>Temp</div>
            <div className={`info-page-attr-btn-container`}>
            </div>
          </div>
          <div className={`info-page-attr-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <div className={`attr-text-item`}>MEMORY_UNIT_COST</div>
            <div className={`attr-text-item`}>MB</div>
            <div className={`info-page-attr-btn-container`}>
            </div>
          </div>
          <div className={`info-page-attr-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
            <TextField id={"attr_key"} label={"Attribute Name"} handleChange={() => { }} />
            <TextField id={"attr_value"} label={"Attribute Value"} handleChange={() => { }} />
            <div className={`info-page-attr-btn-container`}>
              <CustomButton type="iconPlus" onClick={() => { }} />
            </div>
          </div>
        </>} */}
      {/* </FieldGroup> */}

      {/* <FieldGroup label="Config" foldable={true}>
        <YamlEditor
          text={data?.configData}
          onChange={(e) => {
            setData((prev) => ({ ...prev, configData: e?.text }));
          }}
          ref={configRef}
          theme={oneDark}
        />
      </FieldGroup> */}

    </div>
  </>
}

export default Information;