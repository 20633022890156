import TextField from '@mui/material/TextField';
import * as React from 'react';
import "../../../components/TextField/index.css"

const Index = ({ id, label, handleChange, error, errMsg, type,index,width,value }) => {
    const themeColor = process.env?.REACT_APP_THEME;
    if (!id) throw "This component requires id"


    return (
        <div style={{ marginBottom: '1.5rem' }} className={`${themeColor==='dark'?"textfield-dark-theme":""}`}>
        <TextField 
          sx={{ width: width ||600, marginRight: "5rem" }} 
          type={type ? type : 'text'} 
          onChange={(e) => {
            if (e && e.target) {
              handleChange(index,id,e.target.value);
            }
          }}
          value={value} 
          error={error} 
          id={id} 
          label={label} 
          variant="outlined" 
          helperText={error ? errMsg : ''} 
          className={`${error?'custom-mui-text-field-error':''}`}
          size='small' 
        />
      </div>
    )
}


export default Index