import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCaretDown,
  faList,
  faPlus,
  faRefresh,
  faSync,
  faTags,
  faTrash,
  faUser,
  faLock,
  faThList,
  faRedo,
  faPowerOff,
  faPause,
  faPlay,
  faSave,
  faTrashAlt,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import "./CustomButton.css";
import ButtonLink from "./ButtonLink";
import ButtonAction from "./ButtonAction";
import ButtonDropdown from "./ButtonDropdown";
import Button from "@mui/material/Button";
import IconButton from "./IconButton";

const CustomButton = ({ type, onClick, label, disabled, className, width }) => {
  const renderButton = () => {
    switch (type) {
      case "goback":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <a className="button radius">
              <FontAwesomeIcon
                icon={faArrowLeft}
                style={{ marginRight: "5px" }}
              />
              <FontAwesomeIcon icon={faList} />
            </a>
          </span>
        );
      case "reset":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <span id="users-tabreset_button">
              <button className="button secondary radius reset_button">
                Reset
              </button>
            </span>
          </span>
        );
      case "create":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <button
              className="button success radius submit_button"
              href="submit"
            >
              Create
            </button>
          </span>
        );
      case "custom":
        return (
          <span
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <Button
              variant="contained"
              className={className}
              sx={{ marginTop: "-1.1rem" }}
              onClick={() => {
                onClick();
              }}
            >
              {label ? label : "Temp Btn"}
            </Button>
          </span>
        );
      case "iconPlus":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonLink
              icon={<FontAwesomeIcon icon={faPlus} />}
              cls={`success`}
            />
          </span>
        );
      case "sync":
        return (
          <span
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonAction
              cls={`secondary`}
              icon={<FontAwesomeIcon icon={faSync} />}
              act={onClick}
            />
          </span>
        );
      case "save":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonAction
              cls={`action_button top_button state-dependent button`}
              icon={<FontAwesomeIcon icon={faSave} />}
              act={onClick}
            />
          </span>
        );
      case "run":
        return (
          <span
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonAction
              cls={`action_button top_button state-dependent button`}
              icon={<FontAwesomeIcon icon={faPlay} />}
              act={onClick}
            />
          </span>
        );
      case "dropdownLock":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faLock} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownPause":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faPause} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownPoweroff":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faPowerOff} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownRedo":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faRedo} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownList":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faThList} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownUser":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faUser} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "dropdownTag":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonDropdown
              icon={<FontAwesomeIcon icon={faTags} />}
              cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown`}
            />
          </span>
        );
      case "delete":
        return (
          <span
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonAction
              icon={<FontAwesomeIcon icon={faTrashAlt} />}
              cls={`top_button  button alert`}
              act={onClick}
            />
          </span>
        );
      case "update":
        return (
          <span
            className={
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }
          >
            <ButtonAction
              icon={<FontAwesomeIcon icon={faEdit} />}
              cls={`top_button button success`}
              act={onClick}
            />
          </span>
        );
      case "iconPlusAndLabel":
        return (
          <span
            onClick={() => {
              onClick();
            }}
            className={` ${
              disabled ? "sqs-custom-btn-disabled vm-action-disabled" : ""
            }`}
          >
            <IconButton
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              color="#07bc0c"
              hover="#07bc0c"
              width={width}
            >
              {label ? label : ""}
            </IconButton>
          </span>
        );
    }
  };

  return <>{renderButton()}</>;
};

export default CustomButton;
