import { ActionTypes } from '../contants/action-types';


export const listInstrument = () => {
    return {
         type : ActionTypes.LIST_INSTRUMENT
    }
}

export const listInstrumentSuccess = (payload) => {
    return {
         type : ActionTypes.LIST_INSTRUMENT_SUCCESS,
         payload
    }
}

export const listInstrumentError = (payload) => {
    return {
         type : ActionTypes.LIST_INSTRUMENT_ERROR,
         payload
    }
}


export const listInstrumentDriver = () => {
    console.log('vao action');
    return {
         type : ActionTypes.LIST_INSTRUMENT_DRIVER
    }
}

export const listInstrumentDriverSuccess = (payload) => {
    return {
         type : ActionTypes.LIST_INSTRUMENT_DRIVER_SUCCESS,
         payload
    }
}

export const listInstrumentDriverError = (payload) => {
    return {
         type : ActionTypes.LIST_INSTRUMENT_DRIVER_ERROR,
         payload
    }
}

export const createInstrument = (payload, history, edit) => {
    return {
         type : ActionTypes.CREATE_INSTRUMENT,
         payload: {...payload, history}
    }
}

export const createInstrumentSuccess = (payload) => {
    let list = payload
    return {
         type : ActionTypes.CREATE_INSTRUMENT_SUCCESS,
         payload : list
    }
}

export const createInstrumentError = (payload) => {
    let error = payload
    return {
         type : ActionTypes.CREATE_INSTRUMENT_ERROR,
         payload : error
    }
}

export const detailInstrument = (payload) => {
    let list = payload
    return {
         type : ActionTypes.DETAIL_INSTRUMENT,
         payload : list
    }
}

export const detailInstrumentSuccess = (payload) => {
    let detail = payload
    console.log('data in action', detail);
    return {
         type : ActionTypes.DETAIL_INSTRUMENT_SUCCESS,
         payload : detail
    }
}

export const detailInstrumentError = (payload) => {
    let list = payload
    return {
         type : ActionTypes.DETAIL_INSTRUMENT_ERROR,
         payload : list
    }
}

export const deleteInstrument = (payload) => {
    let list = payload
    return {
         type : ActionTypes.DELETE_INSTRUMENT,
         payload : list
    }
}

export const deleteInstrumentSuccess = (payload) => {
    let success = payload
    return {
         type : ActionTypes.DELETE_INSTRUMENT_SUCCESS,
         payload : success
    }
}

export const deleteInstrumentError = (payload) => {
    let error = payload
    return {
         type : ActionTypes.DELETE_INSTRUMENT_ERROR,
         payload : error
    }
}