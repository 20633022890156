import { useContentContext } from "../../../context/ContentContext";
import SubItem from "./SubItem/Index";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const Index = ({ icon, title, sub, link, isPop }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const {
    currentActiveSideBar,
    setCurrentActiveSideBar,
    setIsShowSideBar,
    isShowSideBar,
    currentActiveSideBarChild,
    setCurrentActiveSideBarChild,
    currentActiveSideBarActive,
    setCurrentActiveSideBarActive,
  } = useContentContext();

  const handleClickMenuSidebar = () => {
    setIsShowSideBar(true);
    if (currentActiveSideBar === title) {
      // sub 메뉴 닫기
      if (sub.length > 0) {
        setCurrentActiveSideBar("");
      }
      return;
    }

    setCurrentActiveSideBar(title);
  };

  return (
    <li
      className={`topTab tab_with_no_content ${
        sub.length > 0 && "is-accordion-submenu-parent"
      }`}
      onClick={handleClickMenuSidebar}
    >
      {link ? (
        isPop ? (
          <div className={`sidebar-menu-1-layout`}>
            <a
              onClick={() => {
                window.open(link);
              }}
              style={{ fontSize: "15px" }}
              className={`sidebar-menu-1-item ${
                themeColor === "dark" ? "dark-theme-menu" : ""
              }`}
            >
              <div
                style={{
                  width: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </div>
              {isShowSideBar && title}
              {isShowSideBar && sub?.length > 0 && (
                <ArrowBackIosIcon
                  className={`${
                    currentActiveSideBar === title
                      ? "sidebar-menu-1-active"
                      : "sidebar-menu-1-inactive"
                  }`}
                />
              )}
            </a>
          </div>
        ) : (
          <div
            className={`sidebar-menu-1-layout ${
              currentActiveSideBarActive === title
                ? "sidebar-menu-1-layout-active"
                : ""
            }`}
          >
            <Link
              to={link}
              style={{ fontSize: "15px" }}
              className={`sidebar-menu-1-item ${
                themeColor === "dark" ? "dark-theme-menu" : ""
              }`}
              onClick={() => {
                setCurrentActiveSideBarActive(title);
                // 1 depth 메뉴 클릭시 2 depth 메뉴 active 초기화
                setCurrentActiveSideBarChild("");
              }}
            >
              <div
                style={{
                  width: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </div>
              {isShowSideBar && title}
              {isShowSideBar && sub?.length > 0 && (
                <ArrowBackIosIcon
                  className={`${
                    currentActiveSideBar === title
                      ? "sidebar-menu-1-active"
                      : "sidebar-menu-1-inactive"
                  }`}
                />
              )}
            </Link>
          </div>
        )
      ) : (
        <div className={`sidebar-menu-1-layout `}>
          <a
            style={{ fontSize: "15px" }}
            className={`sidebar-menu-1-item ${
              themeColor === "dark" ? "dark-theme-menu" : ""
            }`}
          >
            <div
              style={{
                width: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </div>
            {isShowSideBar && title}
            {isShowSideBar && sub?.length > 0 && (
              <ArrowBackIosIcon
                className={`${
                  currentActiveSideBar === title
                    ? "sidebar-menu-1-active"
                    : "sidebar-menu-1-inactive"
                }`}
              />
            )}
          </a>
        </div>
      )}
      {sub.length > 0 && currentActiveSideBar === title && isShowSideBar && (
        <div
          className={`menu vertical nested submenu is-accordion-submenu is-active sidebar-menu-2-layout`}
        >
          {sub.map((element, index) => {
            return (
              <SubItem
                key={index + element.title}
                icon={element?.icon}
                title={element?.title}
                link={element?.link}
                parentTitle={title}
                isPop={element?.isPop}
              />
            );
          })}
        </div>
      )}
    </li>
  );
};

export default Index;
