import "./Main.css";
import Sidebar from "../../layouts/Sidebar/Index";
import * as Styled from "./main.styled";
import { useEffect, useState } from "react";

import { baseUrl, circuitUrl } from "../../redux/contants/urlApi";
import SkeletonPage from "../../components/SkeletonPage";
import axiosService from "../../api/axiosService";

const Main = () => {
  const [loading, setLoading] = useState(true)
  // iframe에서 데이터 전달받기
  useEffect(() => {
    // iframe에서 데이터 전달받기
    window.addEventListener("message", async function (event) {
      //if (process.env.NEXT_PUBLIC_WORKFLOW_URL?.includes(event.origin)) {
      const message = event.data;

      if(message === 'completed'){
        setLoading(false)
      }

      if(message?.hasOwnProperty("saveCircuit")) {
        let circuitData = message?.saveCircuit
        let circuitParam = {
          name: circuitData?.name
          ,description: "Created by Circuit Editor"
          ,qubit_count: circuitData?.qubitCnt
          ,circuit_content:circuitData?.qasmContents
        }

        axiosService.post(baseUrl+"v1/circuits/",circuitParam)

      }
    });
  }, []);

  return (
    <>
      <div className="row">
        <div className="small-12 columns sunstone-content large-10">
          {loading && <SkeletonPage title={"Please Wait..."}/>}
          <iframe
            src={`${circuitUrl}iframe/projects/1/12049467573088/circuit`}
            width="100%"
            height="100%"
            style={{ border: "0px solid white", height: "90vh", visibility: loading?'hidden':'' }}
            name="circuit_iframe"
          >

          </iframe>
        </div>
      </div>
    </>
  );
};

export default Main;
