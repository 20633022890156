import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { ActionTypes } from "../redux/contants/action-types";
import { registerUser, loginUser, listUser } from "../api/auth";

import {
  listUserSuccess,
  listUserError,
  loginUserError,
  registerUserSuccess,
} from "../redux/actions/auth";

function* watchRegisterUser({ payload, type }) {
  const { data, history } = payload;
  let newData = {
    email: data?.email,
    username: data?.username,
    password: data?.password,
  };
  const res = yield call(registerUser, newData);
  if (res?.status === 200) {
    yield put(registerUserSuccess(res?.data));
    history.push("/user/list");
  } else {
    //  yield put(actionCar.getListCarsFailed());
  }
  //  yield put(actionUi.hideLoading());
}

function* watchLoginUser({ payload }) {
  const res = yield call(loginUser, payload?.data);

  // yield put(loginUserError(res));

  if (res?.code === 500) {
    yield put(loginUserError(res));
  }

  // if(res?.code === 401){
  //   yield put(loginUserError(res));
  //   payload?.history.push('/login')
  // }
  // if(res?.code === 422){
  //   yield put(loginUserError(res));
  //   payload?.history.push('/login')
  // }
  if (res.status === 200&& res.data?.access_token && res.data?.refresh_token) {
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    let cookieStr = `access_token=${res?.data?.access_token}; `;
    let cookieStr1 = `refresh_token=${res?.data?.refresh_token}; `;
    cookieStr += `Expires=${expires.toUTCString()};`;
    document.cookie = cookieStr;
    document.cookie = cookieStr1;
    payload?.history.push('/')

    // sessionStorage.setItem("access_token", res?.data?.access_token);
    // sessionStorage.setItem("refresh_token", res?.data?.refresh_token);
    // sessionStorage.setItem("cookie_str", res?.config?.headers?.Cookie);
    // 
    // yield put({
    //   type: ActionTypes.GET_DATA_HEADER_SUCCESS,
    //   payload: res.data.result
    // });
  } else {
    //  yield put(actionCar.getListCarsFailed());
  }
}

function* watchListUser(payload) {
  const res = yield call(listUser);
  if (res?.data?.status === 401) {
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie = 'cookie_str=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    // sessionStorage.removeItem("access_token");
    // sessionStorage.removeItem("cookie_str");
    yield put(listUserError(res));
    payload?.history.push("/login");
  }
  if (res?.status === 200) {
    yield put(listUserSuccess(res?.data));
  }
  // else {
  //   yield put(actionCar.getListCarsFailed());
  // }
}

function* handleRegisterUser() {
  yield takeEvery(ActionTypes.REGISTER_USER, watchRegisterUser);
}

function* handleLoginUser() {
  yield takeEvery(ActionTypes.LOGIN_USER, watchLoginUser);
}

function* handleListUser() {
  yield takeEvery(ActionTypes.LIST_USER, watchListUser);
}

// function* handleCreateUser() {
//   yield takeEvery(ActionTypes.REGISTER_USER, watchCreateUser);

// }

export default function* authSaga() {
  yield all([
    fork(handleRegisterUser),
    fork(handleLoginUser),
    fork(handleListUser),
    // fork(handleCreateUser),
  ]);
}
