
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../TemplateInfo.css'




const Network = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href='' className="accordion_advanced_toggle importation">
                {icon1}
                &nbsp; {icon3}&nbsp;{name}
            </a>
            <div id="advanced_section_1" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="small-12 columns nicsContext0">
                        <div className="provision_network_selector">
                            <fieldset>
                                <div>
                                    <dl className="accordion provision_nic_accordion" data-accordion="l7wkne-accordion" data-allow-all-closed="true" role="tablist" />
                                    <a className="button small provision_add_network_interface">
                                        <FontAwesomeIcon icon={faPlusCircle} /> Network Interface </a>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Network;