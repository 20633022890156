// styled components
import * as Styled from "./connectPort.styled.js";
import { useState, useEffect } from "react";

import ListTable from "../../../../../components/Table/ListTable.jsx";

// ConnectButtonDiv Component
import ConnectButtonDiv from "../../../../../components/Button/ConnectButtonDiv.js";

// port list Component
import PortList from "./components/PortList.js";
// qubit list Component
import QubitList from "./components/QubitList.js";

const ConnectPort = ({ circuitInfo, setCircuitInfo, setPageStep }) => {
  const themeColor = process.env?.REACT_APP_THEME;

  // Qubit Count
  const QUBITCNT = parseInt(circuitInfo?.qubits);

  // Qubit List 활성화 시키기 위한 state
  const [selectedPortYn, setSelectedPortYn] = useState(false);

  // 선택한 port 정보
  const [selectedPort, setSelectedPort] = useState(null);

  // connected qubits count 임시 변수
  const [tempConnectedQubitsCnt, setTempConnectedQubitsCnt] = useState(0);

  return (
    <>
      <Styled.SLayoutDiv>
        <div className="row">
          {/* back button */}
          <div className="sunstone-list large-1 columns">
            <button
              className="button"
              onClick={() => {
                setPageStep(1);
              }}
            >
              Back
            </button>
          </div>
        </div>

        {/* Port 컴포넌트 */}
        <PortList
          setSelectedPortYn={setSelectedPortYn}
          setSelectedPort={setSelectedPort}
          selectedPort={selectedPort}
          tempConnectedQubitsCnt={tempConnectedQubitsCnt}
          setTempConnectedQubitsCnt={setTempConnectedQubitsCnt}
        />

        <>
          <QubitList
            QUBITCNT={QUBITCNT}
            selectedPort={selectedPort}
            setSelectedPort={setSelectedPort}
            selectedPortYn={selectedPortYn}
            tempConnectedQubitsCnt={tempConnectedQubitsCnt}
            setTempConnectedQubitsCnt={setTempConnectedQubitsCnt}
          />

          {/* save button */}
          <div className="row ">
            <div className="sunstone-list large-12 columns">
              <div className="text-right">
                <button className="button">Save</button>
              </div>
            </div>
          </div>
        </>
      </Styled.SLayoutDiv>
    </>
  );
};

// port 클릭시 qubit list 활성화

export default ConnectPort;
