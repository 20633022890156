import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { quantumSystems } from '../../../../constants';

const Information = () => {

  const { id } = useParams();
  const quantum = quantumSystems.filter(quantum => Number(quantum.id) === Number(id));

  return (
    <div id="vm_info_tab" className="tabs-panel is-active" role="tabpanel" aria-hidden="false" aria-labelledby="vm_info_tab-label">
      <div className="row">
        <div className="large-6 columns">
          <table className="dataTable">
            <thead>
              <tr><th colSpan={3}>Information</th></tr>
            </thead>
            <tbody>
              <tr><td className="key_td" colSpan={2}><img src /></td></tr>
              <tr>
                <td className="key_td">ID</td>
                <td className="value_td" colSpan={2}>0</td>
              </tr>
              <tr className="vm_rename">
                <td className="key_td">Name</td>
                <td className="value_td_rename">Template 1-0</td>
                <td>
                  <div id="div_edit_rename">
                    <a id="div_edit_rename_link" className="edit_e" href="#">
                      <FontAwesomeIcon icon={faEdit} />
                    </a>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="key_td">State</td>
                <td className="value_td">PENDING</td>
                <td />
              </tr>
              <tr>
                <td className="key_td">LCM State</td>
                <td className="value_td">LCM_INIT</td>
                <td />
              </tr>
              <tr>
                <td className="key_td">Qubit count</td>
                <td className="value_td">20</td>
                <td />
              </tr>
              <tr>
                <td className="key_td">1Q Fidelity</td>
                <td className="value_td">median 99.91%</td>
                <td />
              </tr>
              
              <tr>
                <td className="key_td">2Q Fidelity</td>
                <td className="value_td">median 98.25%</td>
                <td />
              </tr>
              <tr>
                <td className="key_td">Quantum Volume</td>
                <td className="value_td">16</td>
                <td />
              </tr>
              <tr>
                <td className="key_td">Q-score</td>
                <td className="value_td">
                  8
                </td>
              </tr>
              <tr>
                <td className="key_td">Largest GHZ genuinely entangled state</td>
                <td className="value_td">9</td>
              </tr>
              <tr>
                <td className="key_td">Start time</td>
                <td className="value_td">10:39:32 30/12/2023</td>
                <td />
              </tr>
            </tbody>
          </table>
        </div>
        <div className="large-6 columns" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start", gap: "50px" }}>
          <table className="vm_permissions_table dataTable">
            <thead>
              <tr>
                <th>Device</th>
                <th>Firmware</th>
                <th>Software</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="key_td">{quantum[0].name}</td>
                <td>{quantum[0].firmWare}</td>
                <td>{quantum[0].softWare}</td>
              </tr>
            </tbody>
          </table>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", gap: "40px" }}>
            <img src={quantum[0].quantumImage} alt={quantum[0].name} style={{ width: "100%", height: "auto", display: "block", objectFit: "contain" }} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Information;