import DefaultLayout from "../../../layouts/DefaultLayout";
import TabItem from "../../../components/Tab";
import CustomIcon from "../../../components/Icon";
import Info from "./Path/Info/Information";
// import Benchmark from "./Path/Benchmark/Benchmark";
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

// import Excute Benchmark
import ExcuteBenchmark from "../../Benchmark/excuteBenchmark/Index";

const Index = () => {
  const history = useHistory();
  const [data, setData] = useState({});

  const tabData = [
    {
      tabId: "info",
      tabLabel: "Info",
      icon: <CustomIcon type="faInfoCircle" />,
      contents: <Info></Info>,
    },
    {
      tabId: "benchmark",
      tabLabel: "Benchmark",
      icon: <CustomIcon type="faLaptop" />,
      contents: <ExcuteBenchmark ware={"simulator name 1"} />,
    },
  ];

  useEffect(() => {
    // Controller Data 조회
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    // Controller Data 조회
  };

  return (
    <>
      <DefaultLayout title={"Simulator detail"}>
        <div className="sunstone-list">
          <CustomButton
            type="goback"
            onClick={() => {
              history.push("/simulation/simulator");
            }}
          />
          <CustomButton
            type="sync"
            onClick={() => {
              fetchDataList();
            }}
          />
          <CustomButton type="run" label={"Run"} onClick={() => {}} />

          <TabItem tabData={tabData} />
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
