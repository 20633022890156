import {all,call,fork,put,takeEvery} from "redux-saga/effects";
import { ActionTypes } from '../redux/contants/action-types';
import { 
    listInstrument,
    listInstrumentDriver,
    createInstrument,
    detailInstrument,
    deleteInstrument,
    updateInstrument
   } from '../api/instrument'

import { 
  listInstrumentSuccess,
  listInstrumentDriverSuccess,
  createInstrumentSuccess,
  detailInstrumentSuccess,
  createInstrumentError,
  deleteInstrumentSuccess,
  deleteInstrumentError
 } from '../redux/actions/instrument'


function* watchListInstrument(){
    const res = yield call(listInstrument);
    if(res?.status === 200){
      yield put(listInstrumentSuccess(res?.data))
    }
  }

function* watchListInstrumentDriver(){
  const res = yield call(listInstrumentDriver);
  console.log('res', res);
  if(res?.status === 200){
    yield put(listInstrumentDriverSuccess(res?.data))
  }
}

function* watchHandleCreateInstrument({payload}){
  var id = payload?.payload?.edit?.uuid
  var res = {}
  if(id !== undefined){
    res = yield call(updateInstrument, payload)
  }else{
    res = yield call(createInstrument, payload);
  }
  if(res?.status === 201 || res?.status === 200){
    yield put(createInstrumentSuccess(res?.statusText))
    payload?.history.push('/instrument-controller/list')
  }else {
    yield put(createInstrumentError(id === undefined ? "Create controller faild" : "Update controller faild"))
  }
}

function* watchHandleDetailInstrument({payload}){
  console.log('payload in sagas', payload);
  const res = yield call(detailInstrument, payload)
  if(res?.status === 200){
    yield put(detailInstrumentSuccess(res?.data))
  }
  console.log('detail instrument', res);
}

function* watchDeleteInstrument({payload}){
  console.log('payload in sagas', payload);
  const res = yield call(deleteInstrument, payload)
  if(res?.status === 200){
    yield put(deleteInstrumentSuccess("Delete controller success"))
  }else{
    yield put(deleteInstrumentError("Delete controller faild"))
  }
}


function* handleListInstrument() {
    yield takeEvery(ActionTypes.LIST_INSTRUMENT, watchListInstrument);
  }

function* handleListInstrumentDriver() {
    yield takeEvery(ActionTypes.LIST_INSTRUMENT_DRIVER, watchListInstrumentDriver);
  }
function* handleCreateInstrument(){
  yield takeEvery(ActionTypes.CREATE_INSTRUMENT, watchHandleCreateInstrument)
}

function* handleDetailInstrument(){
  yield takeEvery(ActionTypes.DETAIL_INSTRUMENT, watchHandleDetailInstrument)
}

function* handleDeleteInstrument(){
  yield takeEvery(ActionTypes.DELETE_INSTRUMENT, watchDeleteInstrument)
}

export default function* authSaga() {
    yield all([
       fork(handleListInstrument), 
       fork(handleListInstrumentDriver), 
       fork(handleCreateInstrument), 
       fork(handleDetailInstrument), 
       fork(handleDeleteInstrument), 
   ]);
  }