import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

function ModalRender(func,closeModal) {
  return (
    <Stack spacing={2} direction="row">
      <Button
        variant="contained"
        onClick={() => {
            func();
        }}
      >
        Okay
      </Button>
      <Button
        variant="outlined"
        onClick={() => {
            closeModal(false);
        }}
      >
        Cancel
      </Button>
    </Stack>
  );
}

export default ModalRender;
