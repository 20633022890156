const ConnectButtonDiv = (props) => {
  const connectEvt = (index, type) => {
    // Connect Port

    if (props.connectEvt) {
      props.connectEvt(index, type);
    }
  };

  const disconnectEvt = (index, type) => {
    // Disconnect Port

    if (props.disconnectEvt) {
      props.disconnectEvt(index, type);
    }
  };

  return (
    <>
      <div className="row ">
        <button
          className="button"
          onClick={() => {
            connectEvt(props.nIndex, props.type);
          }}
          disabled={!props.selectedPortYn}
        >
          Connect
        </button>
        <button
          className="button alert"
          onClick={() => {
            disconnectEvt(props.nIndex, props.type);
          }}
          disabled={!props.selectedPortYn}
        >
          Disconnect
        </button>
      </div>
    </>
  );
};

export default ConnectButtonDiv;
