import { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/DefaultLayout"
import SkeletonPage from "../../components/SkeletonPage";
import "./index.css"

const Index = () => {
    const [loading, setLoading] = useState(true)
    const [height, setHeight] = useState("0px");
    useEffect(() => {
        // iframe에서 데이터 전달받기
        window.addEventListener("message", async function (event) {
            //if (process.env.NEXT_PUBLIC_WORKFLOW_URL?.includes(event.origin)) {
            const message = event.data;
            if(message?.hasOwnProperty("completed")){
                setLoading(false)
                setHeight(message?.completed)
            }
        });
    }, []);


    return (
        <DefaultLayout title={"Transpiler2"}>
            <div className="sunstone-list">
                {loading && <SkeletonPage title="Please Wait..."/>}
                <iframe src="https://oreilly.sqkcloud.com" className="iframe-transpiler2" style={{height: height}}></iframe>
            </div>
        </DefaultLayout >
    )
}


export default Index;