// import React from "react";
// import {
//   View,
//   GeometryRepresentation,
//   Reader,
//   VolumeRepresentation,
//   VolumeController,
//   ImageData,
// } from "react-vtk-js";

// export default function ReaderVtk({ current_view }) {
//   return (
//     // <>
//     //   <div style={{ width: "100%", height: "700px" }}>
//     //     <View>
//     //       <GeometryRepresentation>
//     //         <Reader vtkClass="vtkOBJReader" url={current_view} />
//     //       </GeometryRepresentation>
//     //     </View>
//     //   </div>
//     // </>
//     <div style={{ width: "100%", height: "700px" }}>
//       <View>
//         {current_view === "darth_vader" && (
//           <GeometryRepresentation property={{ color: [0.3, 0.3, 0.3] }}>
//             <Reader
//               vtkClass="vtkOBJReader"
//               url={
//                 "https://kitware.github.io/vtk-js-datasets/data/obj-mtl/star-wars-vader-tie-fighter.obj"
//               }
//             />
//           </GeometryRepresentation>
//         )}
//         {current_view === "upper_torso" && (
//           <VolumeRepresentation>
//             <VolumeController />
//             <Reader
//               vtkClass="vtkXMLImageDataReader"
//               url="https://kitware.github.io/vtk-js-datasets/data/vti/LIDCFull.vti"
//             />
//           </VolumeRepresentation>
//         )}
//         {current_view === "earth" && (
//           <GeometryRepresentation
//             property={{
//               opacity: 0.5,
//               color: [0.7, 0, 0],
//             }}
//           >
//             <ImageData>
//               <Reader
//                 vtkClass="vtkPolyDataReader"
//                 // url="https://kitware.github.io/vtk-js-datasets/data/vtp/earth.vtp"
//                 url="https://kitware.github.io/vtk-js-datasets/data/vtp/lidar.vtp"
//               />
//             </ImageData>
//           </GeometryRepresentation>
//         )}
//         {current_view === "header" && (
//           <VolumeRepresentation>
//             <VolumeController />
//             <Reader
//               vtkClass="vtkXMLImageDataReader"
//               url="https://kitware.github.io/vtk-js-datasets/data/vti/head-binary-zlib.vti"
//             />
//           </VolumeRepresentation>
//         )}
//         {/* {current_view === "test" && (
//           <VolumeRepresentation>
//             <ImageData>
//               <Reader
//                 vtkClass="vtkPolyDataReader"
//                 url="https://kitware.github.io/vtk-js-datasets/data/vtp/lidar.vtp"
//               />
//             </ImageData>
//           </VolumeRepresentation>
//         )} */}
//         {current_view === "aneurism" && (
//           <VolumeRepresentation>
//             <VolumeController />
//             <Reader
//               vtkClass="vtkXMLImageDataReader"
//               url="https://kitware.github.io/vtk-js-datasets/data/vti/aneurism.vti"
//             />
//           </VolumeRepresentation>
//         )}
//       </View>
//     </div>
//   );
// }
