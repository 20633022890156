

const Index = () => {
    return   <div className="advancedForms tabs-panel" id="vms-tab-advancedForms" role="tabpanel" aria-hidden="true" aria-labelledby="vms-tab-advancedForms-label">
                <form data-abide="1nljl2-abide" noValidate id="updateConfFormAdvanced" className="custom creation">
                <div className="row">
                    <div className="large-12 columns">
                    <p>Write the Virtual Machine template here</p>
                    </div>
                </div>
                <div className="row">
                    <div className="large-12 columns">
                        <textarea id="template" className="monospace" rows={15} required defaultValue={""} />
                    </div>
                </div>
                </form>
            </div>
}


export default Index;