import React from "react";
import ListHeader from "./ListHeader";
import "./index.css";
import ListMain from "./ListMain";
import ArchitectureCard from "./ArchitectureCard";
import DefaultLayout from "../../../layouts/DefaultLayout";

function index() {
  const themeColor = process.env?.REACT_APP_THEME;
  const whiteModeImg = "/assets/images/quamtum_image.png";
  const blackModeImg = "/assets/quantums/instrument-driver.webp";

  return (
    <>
      <DefaultLayout>
        <ListHeader
          title="Instrument Driver"
          btnTitle="Create"
          link="/instrument-driver/create"
        />
        <ListMain />
        <ArchitectureCard
          imgSrc={themeColor === "dark" ? blackModeImg : whiteModeImg}
        />
      </DefaultLayout>
    </>
  );
}

export default index;
