import Header from '../../../layouts/Header/Index'
import Sidebar from '../../../layouts/Sidebar/Index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faArrowLeft, 
    faCaretDown, 
    faList, 
    faPlus, 
    faSync, 
    faTag, 
    faTags, 
    faTrash, 
    faUser } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux';
import React, {useState, useEffect} from 'react';
import { useLoginFormValidator } from "./hooks/useCreateFormValidator";
import {registerUser} from '../../../redux/actions/auth'
import { useHistory } from 'react-router-dom';






const Index = () => {

    const dispath = useDispatch([]);
    const history = useHistory()
    const [form, setForm] = useState({
        username: "",
        password: "",
        re_password: ""
      });

    const arr_auth = [
        {
            label: 'core',
            value: 'core',
            selected: true
        },
        {
            label: 'public',
            value: 'public',
            selected: false
        },
        {
            label: 'ssh',
            value: 'ssh',
            selected: false
        },
        {
            label: 'x509',
            value: 'x509',
            selected: false
        },
        {
            label: 'ldap',
            value: 'ldap',
            selected: false
        },
        {
            label: 'server_cipher',
            value: 'server_cipher',
            selected: false
        },
        {
            label: 'server_x509',
            value: 'server_x509',
            selected: false
        },
        {
            label: 'custom',
            value: 'custom',
            selected: false
        },

    ]

    const main_group = [
        {
            label: 'Default',
            value: -1,
            selected: true
        },
        {
            label: 'oneadmin',
            value: 0,
            selected: false
        },
        {
            label: 'users',
            value: 1,
            selected: false
        },
    ]

    const secondary_group = [
        {
            label: 'oneadmin',
            value: 0,
            selected: false
        },
        {
            label: 'users',
            value: 1,
            selected: false
        },
    ] 

      const { errors, validateForm, onBlurField, setErrors } = useLoginFormValidator(form);
      const handleChange = (e) => {
          const nextFormState = {
              ...form,
              [e.target.name]: e.target.value,
            };
            let field = e.target.name
            setForm(nextFormState);
            if (errors[field]?.dirty)
              validateForm({
                  form: nextFormState,
                  errors,
                  field,
              });
        };

        const onSubmitForm = e => {
     
            e.preventDefault();
            const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
            if (!isValid) return;
            var obj = {}
            Object.entries(form).forEach(([key, value]) => {
                if(!value.length){
                    obj = {...obj, ...{[key] : {
                        dirty: true,
                        error: true,
                        message: key.charAt(0).toUpperCase() + key.slice(1)+ ' is required'
                    }}}
                }
            });
            setErrors(obj)
            if(form?.username.length && form?.password.length && form?.email.length){
                // setLoading(true)
                dispath(registerUser(form, history))
                // alert(JSON.stringify(form))
            }
            
        };

    return <>
     <Header />
        <div className="row">
            <Sidebar />
            <div className='small-12 columns sunstone-content large-10' style={{fontSize:"14px"}}>
                <div className="sunstone-header"></div>
                <div id='dashboard-tab' className='tab'>
                    <div className="row header-row">
                        <div className="large-12 columns">
                            <h5 className="header-title only-sunstone-list">
                                <span className="header-resource">Create User</span>
                            </h5>
                            <h5 className="header-title only-sunstone-info" hidden="" style={{ display: 'none' }}>
                                <span className="header-resource">
                                
                                <small className="resource-id"></small>
                                <small className="resource-info-header"></small>
                                <small className="resource-info-header-small"></small>
                                </span>
                            </h5>
                            <h5 className="header-title only-sunstone-form" hidden="" style={{ display: 'none' }}>
                                <span className="sunstone-form-title"></span>
                                <small className="sunstone-form-id"></small>
                                <small className="sunstone-form-info-header"></small>
                                <small className="sunstone-form-info-header-small"></small>
                            </h5>
                        </div>
                    </div>
                    <div className="actions_row">
                    <div className="medium-12 columns action_blocks large-12">
                        <span>
                            <span id="users-tabback_button" className=" only-sunstone-info only-sunstone-form" style={{display: 'inline'}}>
                            <a className="button radius" href="/user/list">
                                <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }}/>
                                <FontAwesomeIcon icon={faList}/>
                            </a>
                            </span>
                            <span id="users-tabform_buttons" className="only-sunstone-form" hidden style={{display: 'inline'}}>
                            <span id="users-tabreset_button">
                                <button className="button secondary radius reset_button" href="submit">Reset</button>
                            </span>
                            <span id="users-tabsubmit_button">
                                <button 
                                    onClick={e=>onSubmitForm(e)} 
                                    className="button success radius submit_button" 
                                    href="submit">Create</button>
                            </span>
                            </span>
                            <span id="users-tabcreate_buttons" className=" only-sunstone-list" style={{display: 'none'}}>
                                <button className="create_dialog_button action_button top_button success button" href="User.create_dialog">
                                    <FontAwesomeIcon icon={faPlus}/>
                                </button>
                            </span>
                            <span id="users-tabrefresh_buttons" className=" only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                                <button className="action_button top_button alwaysActive refresh button secondary" href="User.refresh">
                                    <i className="fas fa-sync-alt" />
                                    <FontAwesomeIcon icon={faSync}/>
                                </button>
                            </span>
                        </span>
                        <span>
                            <span id="users-tabmain_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}} />
                            <span id="users-tabvmsplay_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}} />
                            <ul id="users-tablock_buttons" className="lock-sunstone-info only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="eqioe5-dropdown" aria-hidden="true" data-yeti-box="users-tablock_buttons" data-resize="users-tablock_buttons" aria-labelledby="bm649k-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <ul id="users-tabvmspause_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="pf40hu-dropdown" aria-hidden="true" data-yeti-box="users-tabvmspause_buttons" data-resize="users-tabvmspause_buttons" aria-labelledby="m1jooi-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <ul id="users-tabvmsstop_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="v9ludl-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsstop_buttons" data-resize="users-tabvmsstop_buttons" aria-labelledby="b99wao-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <ul id="users-tabvmsrepeat_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="rlx7ez-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsrepeat_buttons" data-resize="users-tabvmsrepeat_buttons" aria-labelledby="r6o0eo-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <ul id="users-tabvmsplanification_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="4p817x-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsplanification_buttons" data-resize="users-tabvmsplanification_buttons" aria-labelledby="o5injb-dd-anchor" data-events="resize" style={{display: 'none'}} />
                        </span>
                        <span>
                            <ul id="users-tabmore_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="zs2dqs-dropdown" aria-hidden="true" data-yeti-box="users-tabmore_buttons" data-resize="users-tabmore_buttons" aria-labelledby="f2oznb-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <button type="button" data-toggle="users-tabuser_buttons" className="only-sunstone-info only-sunstone-list top_button  secondary button dropdown" aria-controls="users-tabuser_buttons" data-is-focus="false" data-yeti-box="users-tabuser_buttons" aria-haspopup="true" aria-expanded="false" disabled="disabled" style={{display: 'none'}}>
                            <i className="fas fa-user" />
                            </button>
                            <ul id="users-tabuser_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="lb164g-dropdown" aria-hidden="true" data-yeti-box="users-tabuser_buttons" data-resize="users-tabuser_buttons" aria-labelledby="t5vypz-dd-anchor" data-events="resize" style={{display: 'none'}}>
                            <li>
                                <a className="confirm_with_select_button top_button" href="User.chgrp" disabled="disabled">Change primary group</a>
                            </li>
                            </ul>
                            <span id="users-tablabels_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                            <button type="button" data-toggle="users-tabLabelsDropdown" className="undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown" aria-controls="users-tabLabelsDropdown" data-is-focus="false" data-yeti-box="users-tabLabelsDropdown" aria-haspopup="true" aria-expanded="false" disabled="disabled" style={{display: 'none'}}>
                                <i className="fas fa-tags" />
                            </button>
                            <div id="users-tabLabelsDropdown" className="only-sunstone-info only-sunstone-list labels-dropdown dropdown-pane large menu vertical" data-dropdown="ix27dw-dropdown" data-close-on-click="true" aria-hidden="true" data-yeti-box="users-tabLabelsDropdown" data-resize="users-tabLabelsDropdown" aria-labelledby="l1lse9-dd-anchor" data-events="resize" style={{display: 'none'}}>
                                <div>
                                <h6>Edit Labels</h6>
                                <div className="labeltree-container">
                                    <ul className="labels-tree" />
                                </div>
                                <div className="input-container">
                                    <input type="text" className="newLabelInput" placeholder="Add Label" />
                                </div>
                                </div>
                            </div>
                            </span>
                            <ul id="users-tabvmsdelete_buttons" className="only-sunstone-info only-sunstone-list dropdown-pane menu vertical" data-dropdown="chnqv4-dropdown" aria-hidden="true" data-yeti-box="users-tabvmsdelete_buttons" data-resize="users-tabvmsdelete_buttons" aria-labelledby="52gtey-dd-anchor" data-events="resize" style={{display: 'none'}} />
                            <span id="users-tabdelete_buttons" className="only-sunstone-info only-sunstone-list" style={{display: 'none'}}>
                            <button className="confirm_button top_button alert button" href="User.delete" disabled="disabled">
                                <i className=" fas fa-trash-alt" />
                            </button>
                            </span>
                            <span className="only-sunstone-form" style={{display: 'inline'}}>
                            <ul id="users-tabFormTabs" className="tabs right wizard_tabs " hidden data-tabs="oiub66-tabs" style={{display: 'none'}}>
                                <li id="wizard_mode" className="tabs-title is-active" role="presentation">
                                <a href="#users-tab-wizardForms" role="tab" aria-controls="users-tab-wizardForms" aria-selected="true" id="users-tab-wizardForms-label">Wizard</a>
                                </li>
                                <li id="advanced_mode" className="tabs-title" role="presentation">
                                <a href="#users-tab-advancedForms" role="tab" aria-controls="users-tab-advancedForms" aria-selected="false" id="users-tab-advancedForms-label">Advanced</a>
                                </li>
                            </ul>
                            </span>
                        </span>
                        </div>
                    </div>

                    <div className="sunstone-list">
                       <div className="contentForm">
                            <div className="tabs-content tabs-contentForm" data-tabs-content="users-tabFormTabs" form-panel-id="createUserForm" style={{display: 'block'}}>
                                <div className="wizardForms tabs-panel is-active" id="users-tab-wizardForms" role="tabpanel" aria-hidden="false" aria-labelledby="users-tab-wizardForms-label">
                                <form data-abide="rtihqo-abide" noValidate id="createUserFormWizard" className="custom creation">
                                    <div className="row">
                                    <div className="medium-6 columns">
                                        <div className="row name_row">
                                        <div className="large-12 columns">
                                            <label htmlFor="createUserForm_email" style={{fontSize:"14px"}}>Email</label>
                                            <input 
                                                required 
                                                type="email" 
                                                name="email" 
                                                id="createUserForm_email" 
                                                value={form.email}
                                                onChange={(e)=>handleChange(e)}
                                                onBlur={onBlurField}
                                            />
                                            {errors?.email?.dirty && errors?.email?.error ? (
                                                <p style={{ 
                                                    fontSize: '11px',
                                                    color: 'red'
                                                }}>{errors.email.message}</p>
                                            ) : null}
                                        </div>
                                        <div className="large-12 columns">
                                            <label htmlFor="username" style={{fontSize:"14px"}}>Username</label>
                                            <input 
                                                required 
                                                type="text" 
                                                name="username" 
                                                id="createUserForm_username" 
                                                value={form.username}
                                                onChange={(e)=>handleChange(e)}
                                                onBlur={onBlurField}
                                            />
                                            {errors?.username?.dirty && errors?.username?.error ? (
                                                <p style={{ 
                                                    fontSize: '11px',
                                                    color: 'red'
                                                }}>{errors.username.message}</p>
                                            ) : null}
                                        </div>
                                        </div>
                                        <div className="row password_row">
                                        <div className="large-12 columns">
                                            <label htmlFor="pass" style={{fontSize:"14px"}}>Password</label>
                                            <input 
                                                required 
                                                type="password" 
                                                name="password" 
                                                id="createUserForm_pass" 
                                                value={form.password}
                                                onChange={(e)=>handleChange(e)}
                                                onBlur={onBlurField}
                                            />
                                            {errors?.password?.dirty && errors?.password?.error ? (
                                                <p style={{ 
                                                    fontSize: '11px',
                                                    color: 'red'
                                                }}>{errors.password.message}</p>
                                            ) : null}
                                        </div>
                                        <div className="large-12 columns">
                                            <label style={{fontSize:"14px"}}>Confirm Password</label>
                                            <input 
                                                type="password" 
                                                name="re_password" 
                                                value={form.re_password}
                                                onChange={(e)=>handleChange(e)}
                                                onBlur={onBlurField}
                                            />
                                            <small className="form-error">Passwords do not match</small>
                                            {errors?.re_password?.dirty && errors?.re_password?.error ? (
                                                <p style={{ 
                                                    fontSize: '11px',
                                                    color: 'red'
                                                }}>{errors.re_password.message}</p>
                                            ) : null}
                                        </div>
                                        </div>
                                        <div className="row auth_driver_row">
                                        <div className="large-12 columns">
                                            <label id="label_auth" htmlFor="driver" style={{fontSize:"14px"}}>Authentication</label>
                                                <select 
                                                    name="driver" 
                                                    id="createUserForm_driver"
                                                    onChange={(e)=>handleChange(e)}
                                                    >
                                                    {arr_auth?.length && 
                                                    arr_auth.map((element, key)=><option 
                                                                                    value={element?.value} 
                                                                                    key={key} 
                                                                                    selected={element?.selected}
                                                                                >{element?.label}</option>)}
                                                </select>
                                            <div style={{display: 'none'}}>
                                                <input 
                                                    type="text" 
                                                    id="createUserForm_custom_auth" 
                                                    name="custom_auth" 
                                                />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="row main_group_row">
                                        <div className="large-12 columns">
                                            <label style={{fontSize:"14px"}}> Main Group 
                                                <div className="">
                                                <select 
                                                    className="resource_list_select"
                                                    onChange={(e)=>handleChange(e)}
                                                    name="main_group"
                                                    >
                                                    {main_group?.length && main_group.map((element, key)=><option 
                                                    value={element?.value}
                                                    key={key}
                                                    >{element?.label}</option>)}
                                                </select>
                                            </div>
                                            </label>
                                        </div>

                                        <div className="large-12 columns">
                                            <label style={{fontSize:"14px"}}> secondary Groups <div className="main_group_div">
                                                <select 
                                                    className="resource_list_select"
                                                    onChange={(e)=>handleChange(e)}
                                                    name="secondary_group"
                                                    >
                                                    {secondary_group?.length && secondary_group.map((element, key)=><option value={element?.value} key={key}>{element?.label}</option>)}
                                                </select>
                                            </div>
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </form>
                                </div>
                                <div className="advancedForms tabs-panel" id="users-tab-advancedForms" role="tabpanel" aria-hidden="true" aria-labelledby="users-tab-advancedForms-label" />
                            </div>
                            </div>

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Index;