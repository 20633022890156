import React, { useRef, useState } from "react";
import { useEffect } from "react";
import "./index.css";
import DefaultLayout from "../../../layouts/DefaultLayout";
import CommonTextField from "../../../components/TextField";
import FieldGroup from "../../../components/FieldGroup";

import ListTable from "../../../components/Table/ListTable";
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import AlertDialog from "../../../components/Dialog";
import { tError, tSuccess, tWarnning } from "../../../common/CommonToast";
import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import { baseUrl } from "../../../redux/contants/urlApi";
import axiosService from "../../../api/axiosService";
import Select from "../../../components/Select";
import SettingsData from "./SettingsData.json";
import CodeEditor from "../../../components/CodeEditor/Index";
import {
  jsonToYaml,
  dataToJson,
  detectDataType,
} from "../../../common/CommonUtil";

const Index = () => {
  const [data, setData] = useState({
    resonarType: [
      { key: "S", label: "S" },
      { key: "M", label: "M" },
    ],
    selectedResonarType: { key: "S", value: "S" },
  });

  const history = useHistory();

  const handleFetch = async () => {
    const instrumentApi = baseUrl + "v1/instruments/";
    const measurementApi = baseUrl + "v1/measurements/";
    try {
      const instrumentResponse = await axiosService.get(instrumentApi);
      if (instrumentResponse.status === 200) {
        const data = instrumentResponse.data;
        const tempData = [];
        data.map((item) => {
          const instrument = {
            id: item.id,
            name: item.name,
            description: item.description,
            address: item.address,
            port: item.port,
            // controllerType: item.instrument_driver.type,
            manufacturer: item.instrument_driver.type,
            uuid: item.uuid,
          };
          // 추후에 type 추가되면 수정해야됨
          tempData.push(instrument);
        });
        setData((prev) => ({ ...prev, instrumentList: tempData }));
      }
      const measuermentResponse = await axiosService.get(measurementApi);
      if (measuermentResponse.status === 200) {
        const data = measuermentResponse.data;
        const tempData = [];
        data.map((item) => {
          if (item.station_id === null) {
            const measurement = {
              name: item.name,
              description: item.description,
              uuid: item.uuid,
            };
            tempData.push(measurement);
          }
        });
        setData((prev) => ({ ...prev, measurementList: tempData }));
      }
      setData((prev) => ({
        ...prev,
        selectedResonarType: { key: "S", label: "S" },
      }));
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleFetch();
    setData((prev) => ({ ...prev, configData: SettingsData }));
  }, []);

  const handleChangeEvt = (v) => {
    setData((prev) => ({ ...prev, [v.key]: v.value }));
  };
  // Select Event
  const selectInstrumentRows = (selectedRowData) => {
    setData((prev) => ({
      ...prev,
      selectedInstrumentRowData: selectedRowData,
    }));
  };

  const selectMeasurementRows = (selectedRowData) => {
    setData((prev) => ({
      ...prev,
      selectedMeasurementRowData: selectedRowData,
    }));
  };

  const selectResonarType = (selectedRowData) => {
    setData((prev) => ({
      ...prev,
      selectedResonarType: selectedRowData,
    }));
  };

  const createListColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "name", label: "Name", minWidth: 70 },
      { id: "description", label: "Description", minWidth: 200 },
      { id: "address", label: "Address", minWidth: 100 },
      { id: "port", label: "Port", minWidth: 100 },
      // {
      //   id: "Type",
      //   label: "controllerType",
      //   minWidth: 170,
      //   align: "left",
      //   format: (value) => value.toLocaleString("en-US"),
      // },
      { id: "manufacturer", label: "Manufacturer", minWidth: 100 },
      { id: "uuid", label: "uuid", hidden: true },
    ];
  };

  const measurementColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "name", label: "Name", minWidth: 70 },
      { id: "description", label: "Description", minWidth: 200 },
      { id: "uuid", label: "uuid", hidden: true },
      { id: "detector_function", label: "detector_function", hidden: true },
    ];
  };

  const putMeasurement = async (stationId) => {
    const measurementData = {
      name: data.selectedMeasurementRowData.name,
      description: data.selectedMeasurementRowData.description,
      config: {
        noise: data.selectedMeasurementRowData.noise,
        n_points: data.selectedMeasurementRowData.n_points,
        sweep_function: data.selectedMeasurementRowData.sweep_function,
        sweep_points: data.selectedMeasurementRowData.sweep_points,
        detector_function: data.selectedMeasurementRowData.detector_function,
      },
      station_id_or_uuid: stationId,
    };
    const measurementUuid = data.selectedMeasurementRowData.uuid;
    // // config값 확인 필요
    let api = baseUrl + "v1/measurements/" + measurementUuid;
    try {
      const response = await axiosService.put(api, measurementData);
      if (response.status === 201) {
        tSuccess("Measurement modification was successful.");
        // setData((prev) => ({ ...prev, measurementList: measurement }));
      } else {
        tError("Failed to modify measurement");
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const isJSON = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const putSettings = async (stationUuid) => {
    let api = baseUrl + "v1/stations/" + stationUuid + "/settings";

    if (detectDataType(dataToJson(data?.configData)) != "JSON Object") {
      tWarnning("Invalid Config format");
    } else {
      try {
        const response = await axiosService.put(
          api,
          dataToJson(data?.configData)
        );
        if (response.status === 201) {
          tSuccess("Created!!");
        } else {
          tError("Faild");
        }
        console.log("response", response);
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  const onSubmitData = async (id, result) => {
    let api = baseUrl + "v1/stations/";

    const body = {
      name: data.name,
      description: data.description,
      resonator_type: data.selectedResonarType.label,
      instrument_id_or_uuid: data?.selectedInstrumentRowData?.uuid,
    };

    if (id === "submit") {
      try {
        const response = await axiosService.post(api, body);
        console.log("response", response);
        if (response.status === 201 && id == "submit" && result) {
          tSuccess("Created!!");
          if (data?.selectedMeasurementRowData) {
            putMeasurement(response.data.id);
          }

          if (data?.configData) {
            putSettings(response.data.uuid);
          }
          setTimeout(() => {
            history.push("/platform/list");
          }, 3000);
        } else {
          if (response.error_message.response.data.detail) {
            tError(response.error_message.response.data.detail[0].msg);
          }
          tError("Faild");
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      return;
    }
  };
  const handlePopupResult = async ({ id, result }) => {
    if (data.description && data.name && data.selectedInstrumentRowData) {
      onSubmitData(id, result);
    } else {
      tError("check required Fields");
      if (!data.name) {
        setData((prev) => ({ ...prev, ["isInvalid_name"]: true }));
      }
      if (!data.description) {
        setData((prev) => ({ ...prev, ["isInvalid_description"]: true }));
      }
      if (!data.selectedInstrumentRowData) {
        setData((prev) => ({ ...prev, ["isInvalid_instrument"]: true }));
      }
    }
  };

  const renderSavePop = () => {
    const runPopContents = {
      title: "Confirm",
      contents: (
        <>
          <div className="create-station-pop-container">
            <div className="crete-station-pop-grid-container">
              <div>Name :</div>
              <div>{data?.name}</div>
              <div>Description :</div>
              <div>{data?.description}</div>
              {data?.selectedInstrumentRowData && (
                <>
                  <div>-</div>
                  <div></div>
                  <div>Instrument :</div>
                  <div></div>
                  <div>name :</div>
                  <div>{data?.selectedInstrumentRowData?.name}</div>
                  <div>address : </div>
                  <div>{data?.selectedInstrumentRowData?.address}</div>
                  <div>port : </div>
                  <div>{data?.selectedInstrumentRowData?.port}</div>
                </>
              )}
              {data?.selectedMeasurementRowData && (
                <>
                  <div>Measurement :</div>
                  <div></div>
                  <div>name :</div>
                  <div>{data?.selectedMeasurementRowData?.name}</div>
                  <div>address : </div>
                  <div>{data?.selectedMeasurementRowData?.address}</div>
                  <div>port : </div>
                </>
              )}
            </div>
            {/* {data?.configData && (
              <div className="stage-create-config-container">
                <pre style={{ fontWeight: "bold" }}>Config:</pre>
                <pre>{data?.configData}</pre>
              </div>
            )} */}
            <div className="create-statoion-pop-confirm-msg">
              Is the information correct?
            </div>
          </div>
        </>
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <AlertDialog
        type="custom"
        label="Save"
        id="submit"
        disabled={true ? false : true}
        callback={handlePopupResult}
        contents={runPopContents}
      />
    );
  };
  return (
    <>
      <DefaultLayout title="Create Station">
        <div className="sunstone-list" style={{ paddingBottom: "3rem" }}>
          {/* Required Container */}
          <FieldGroup label={"Required"} foldable={true}>
            <CommonTextField
              id="name"
              label="Name"
              handleChange={handleChangeEvt}
              error={data?.isInvalid_name}
              errMsg={"Please Input Data"}
            />
            <CommonTextField
              id="description"
              label="Description"
              handleChange={handleChangeEvt}
              error={data?.isInvalid_name}
              errMsg={"Please Input Data"}
            />
            <Select
              id="select"
              data={data.resonarType}
              defaultValue={data.resonarType[0].label}
              handleChange={selectResonarType}
              label="resonarType"
            />
            <FieldGroup
              label={"Instrument List"}
              foldable={true}
              defaultFold={false}
            >
              <ListTable
                // title={"Instruments List"}
                list={data?.instrumentList}
                cols={createListColumns()}
                selectCallback={selectInstrumentRows}
                height={200}
                error={data?.isInvalid_instrument}
                errorMessage="Please Select Instrument"
              />
            </FieldGroup>
          </FieldGroup>

          {/* Optional Container */}
          <FieldGroup label={"Optional"} foldable={true} defaultFold={true}>
            <FieldGroup
              label={"Measurement List"}
              foldable={true}
              defaultFold={false}
            >
              <ListTable
                list={data?.measurementList}
                cols={measurementColumns()}
                selectCallback={selectMeasurementRows}
                height={200}
              />
            </FieldGroup>

            <FieldGroup
              label={"Station Config"}
              foldable={true}
              defaultFold={false}
            >
              <CodeEditor
                value={jsonToYaml(data?.configData)}
                onChange={(e) => {
                  setData((prev) => ({ ...prev, configData: e }));
                }}
              />
            </FieldGroup>
          </FieldGroup>

          {/* Button Container */}
          <div className="create-station-btn-container">
            <div className="end-item">
              <CustomButton
                className="custom-cancel-btn"
                type="custom"
                label={"Cancel"}
                onClick={() => {
                  history.push("/platform/list");
                }}
              />
              {renderSavePop()}
            </div>
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
