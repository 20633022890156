import { ActionTypes } from '../contants/action-types';

export const createPlatformAction = (data, history, edit) => {
    console.log('data in action', data);
    return {
         type : ActionTypes.CREATE_PLATFORM,
         payload : {data, history, edit}
    }
}

export const createPlatformSuccessAction = (data) => ({
    type: ActionTypes.CREATE_PLATFORM_SUCCESS,
    payload: {statusCreatePlatform: data}
});

export const createPlatformErrorAction = (data) => ({

    type: ActionTypes.CREATE_PLATFORM_ERROR,
    payload: data
});

export const listPlatformAction = (data, history)=>{
    return {
         type : ActionTypes.LIST_PLATFORM,
         payload: {data, history}
    }
}

export const listPlatformSuccessAction = (data)=>{
    return {
         type : ActionTypes.LIST_PLATFORM_SUCCESS,
         payload: data
    }
}

export const listPlatformErrorAction = (data)=>{
    return {
         type : ActionTypes.LIST_PLATFORM_ERROR,
         payload: data
    }
}

export const deletePlatformAction = (data)=>{
    return {
         type : ActionTypes.DELETE_PLATFORM,
         payload: data
    }
}

export const deletePlatformSuccessAction = (data)=>{
    return {
         type : ActionTypes.DELETE_PLATFORM_SUCCESS,
         payload: data
    }
}

export const deletePlatformErrorAction = (data)=>{
    return {
         type : ActionTypes.DELETE_PLATFORM_ERROR,
         payload: data
    }
}

export const detailPlatformAction = (data)=>{
    return {
         type : ActionTypes.DETAIL_PLATFORM,
         payload: data
    }
}

export const detailPlatformSuccessAction = (data)=>{
    return {
         type : ActionTypes.DETAIL_PLATFORM_SUCCESS,
         payload: data
    }
}

export const detailPlatformErrorAction = (data)=>{
    return {
         type : ActionTypes.DETAIL_PLATFORM_ERROR,
         payload: data
    }
}

export const uploadFileRuncardAction = (data, id)=>{
    return {
         type : ActionTypes.UPLOAD_FILE_RUNCARD,
         payload: {data, id}
    }
}

export const uploadFileRuncardSuccessAction = (data)=>{
    return {
         type : ActionTypes.UPLOAD_FILE_RUNCARD_SUCCESS,
         payload: data
    }
}

export const uploadFileRuncardErrorAction = (data)=>{
    return {
         type : ActionTypes.UPLOAD_FILE_RUNCARD_ERROR,
         payload: data
    }
}


export const listFileRuncard = (data)=>{
    return {
         type : ActionTypes.LIST_FILE_RUNCARD,
         payload: data
    }
}

export const listFileRuncardSuccess = (data)=>{
    return {
         type : ActionTypes.LIST_FILE_RUNCARD_SUCCESS,
         payload: data
    }
}

export const listFileRuncardError = (data)=>{
    return {
         type : ActionTypes.LIST_FILE_RUNCARD_ERROR,
         payload: data
    }
}

export const activeFileRunCard = (data)=>{
    return {
         type : ActionTypes.ACTIVE_FILE_RUNCARD,
         payload: data
    }
}

export const activeFileRunCardSuccess = (data)=>{
    return {
         type : ActionTypes.ACTIVE_FILE_RUNCARD_SUCCESS,
         payload: data
    }
}

export const activeFileRunCardError = (data)=>{
    return {
         type : ActionTypes.ACTIVE_FILE_RUNCARD_ERROR,
         payload: data
    }
}