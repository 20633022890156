import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCog,
  faFile,
  faFileAlt,
  faGlobe,
  faInfoCircle,
  faLaptop,
  faServer,
  faSitemap,
  faEdit,
  faExpand,
  faClone,
  faFolderOpen,
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineModeEdit } from "react-icons/md";

const CustomIcon = ({ type, size }) => {
  switch (type) {
    case "faSitemap": {
      return (
        <>
          <FontAwesomeIcon icon={faSitemap} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faServer": {
      return (
        <>
          <FontAwesomeIcon icon={faServer} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faLaptop": {
      return (
        <>
          <FontAwesomeIcon icon={faLaptop} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faGlobe": {
      return (
        <>
          <FontAwesomeIcon icon={faGlobe} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faFileAlt": {
      return (
        <>
          <FontAwesomeIcon icon={faFileAlt} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faFile": {
      return (
        <>
          <FontAwesomeIcon icon={faFile} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faCog": {
      return (
        <>
          <FontAwesomeIcon icon={faCog} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faCalendarAlt": {
      return (
        <>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ pointerEvents: "none" }}
          />
        </>
      );
    }
    case "faInfoCircle": {
      return (
        <>
          <FontAwesomeIcon
            icon={faInfoCircle}
            style={{ pointerEvents: "none" }}
          />
        </>
      );
    }
    case "faEdit": {
      return (
        <>
          <FontAwesomeIcon
            icon={faEdit}
            style={{ pointerEvents: "none" }}
            size={size}
          />
        </>
      );
    }
    case "MdOutlineModeEdit": {
      return (
        <>
          <MdOutlineModeEdit />
        </>
      );
    }
    case "faExpand": {
      return (
        <>
          <FontAwesomeIcon icon={faExpand} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faClone": {
      return (
        <>
          <FontAwesomeIcon icon={faClone} style={{ pointerEvents: "none" }} />
        </>
      );
    }
    case "faFolderOpen": {
      return (
        <>
          <FontAwesomeIcon
            icon={faFolderOpen}
            style={{ pointerEvents: "none" }}
          />
        </>
      );
    }
  }

  return;
};

export default CustomIcon;
