import {
  faSyncAlt,
  faPlusSquare,
  faHardDrive,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Header from "../../../layouts/Header/Index";
import Sidebar from "../../../layouts/Sidebar/Index";
import { quantumSystems } from "../../../constants";
import QuantumCard from "./QuantumCard/QuantumCard";

const Index = () => {
  return (
    <>
      <Header />
      <div className="row page_container">
        <Sidebar />
        <div
          style={{
            flex: "1",
            padding: "20px 40px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div id="provision-tab" className="tab">
            <div className="sunstone-list" style={{ padding: 0 }}>
              <div className="provision_vms_list_section section_content">
                <dl className="accordion accordion_list provision_list_vms">
                  <dd className="is-active accordion-item">
                    <h4 style={{ margin: 0 }}>
                      {/* <FontAwesomeIcon icon={faHardDrive} style={{ marginRight: "10px"}} /> */}
                      <img
                        src="/assets/images/instruments.webp"
                        style={{
                          width: "120px",
                          marginLeft: "-2rem",
                          marginRight: "-2rem",
                        }}
                      />
                      <span className="only-active">Driver</span>
                      <span className="right only-active provision_list_vms_filter">
                        <select
                          className="resource_list_select"
                          style={{ cursor: "pointer" }}
                        >
                          <option value="-2">ALL</option>
                          <option elem_id="1" value="1">
                            serveradmin
                          </option>
                          <option elem_id="0" value="0">
                            oneadmin
                          </option>
                        </select>
                      </span>
                      <input
                        type="search"
                        className="right only-active provision_list_vms_search provision-search-input "
                        placeholder="Search VMs"
                      />
                      <span className="right only-active button success provision_create_vm_button">
                        <FontAwesomeIcon icon={faPlusSquare} />
                      </span>
                    </h4>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div
            className="provision_vms_ul large-up-3 medium-up-3 small-up-1"
            style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}
          >
            {quantumSystems.map((item) => (
              <QuantumCard key={item.id} quantum={item} />
            ))}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className="dataTables_length">
              <label style={{ width: "80px" }}>
                <select name="DataTables_Table_1_length">
                  <option value="6">6</option>
                  <option value="12">12</option>
                  <option value="36">36</option>
                  <option value="72">72</option>
                </select>
              </label>
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <ul className="pagination">
                <li className="paginate_button previous unavailable disabled">
                  Previous
                </li>
                <li className="paginate_button current">1</li>
                <li className="paginate_button next unavailable disabled">
                  Next
                </li>
              </ul>
            </div>
          </div>

          {/* Quantum image add */}
          <img
            src="/assets/images/quamtum_image.png"
            style={{
              width: "860px",
              textAlign: "center",
              margin: "auto",
              marginTop: "5",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Index;
