import React from 'react'
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from 'react-router';

function ButtonField({setIsReset,postInstrumentModel,setIsvalidation}) {
  const history = useHistory()
  return (
    <div className="actions_row">
    <div className="medium-12 columns action_blocks large-12">
      <span>
        {/* Custom Btn Components */}
        <CustomButton
          type="goback"
          onClick={() => {
            console.log("goback Btn");
            history.goBack();
          }}
        />
        <CustomButton
          type="sync"
          onClick={() => {
            console.log("reset Btn");
            setIsReset(true);  
            setIsvalidation({
              bander: false,
              Name: false,
              Description: false,
              type: false,
              selectedOption:false,
              instrument:[]
            })
          }}
        />
        <CustomButton
          type="custom" label={"SAVE"}
          onClick={() => {
            console.log("create Btn");
            postInstrumentModel()
          }}
        />
      </span>
    </div>
  </div>
  )
}

export default ButtonField