import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../Button/CustomButton';
import "./index.css";

export function ConfirmDialog({contents, callback, id}){
    const [open, setOpen] = React.useState(true);

    const handleClose = (bool) => {
        callback({ id: id, result: bool })
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={() => { handleClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='dialog-content-container'
            >
                <DialogTitle id="alert-dialog-title">
                    {contents?.title}
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        {contents?.contents}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-btn-container'>
                    <Button className='custom-alert-dialog-btn custom-disagree' onClick={() => { handleClose(false) }}>
                        {contents?.disagreeBtnLabel ? contents?.disagreeBtnLabel : 'Disagree'}
                    </Button>
                    <Button className='custom-alert-dialog-btn' onClick={() => { handleClose(true) }} autoFocus>
                        {contents?.agreeBtnLabel ? contents?.agreeBtnLabel : 'Agree'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default function AlertDialog({ contents, type, disabled, callback, label, id, error }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (bool, shouldInvokeCallback = true) => {
        if (shouldInvokeCallback) {
        callback({ id: id, result: bool })
        }
        setOpen(false);
    };

    return (
        <React.Fragment>
            {error?
                <CustomButton type={type} disabled={disabled} onClick={()=>{callback({id:id, result:"invalid"})}} label={label} />
                :<CustomButton type={type} disabled={disabled} onClick={handleClickOpen} label={label} />
            }
            
            <Dialog
                open={open}
                onClose={() => { handleClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className='dialog-content-container'
            >
                <DialogTitle id="alert-dialog-title">
                    {contents?.title}
                </DialogTitle>
                <DialogContent >
                    <DialogContentText id="alert-dialog-description">
                        {contents?.contents}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='dialog-btn-container'>
                    <Button className='custom-alert-dialog-btn custom-disagree' onClick={() => { handleClose(false,false) }}>
                        {contents?.disagreeBtnLabel ? contents?.disagreeBtnLabel : 'Disagree'}
                    </Button>
                    <Button className='custom-alert-dialog-btn' onClick={() => { handleClose(true) }} autoFocus>
                        {contents?.agreeBtnLabel ? contents?.agreeBtnLabel : 'Agree'}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}