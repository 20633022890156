import Header from "../../layouts/Header/Index";
import Main from "./Main";
import DefaultLayout from "../../layouts/DefaultLayout"

const Index = () => {
  return (
    <>
      <DefaultLayout title={"Dashboard"} >
        <Main />
      </DefaultLayout>
    </>
  );
};

export default Index;
