import FieldGroup from "../../components/FieldGroup";
import DefaultLayout from "../../layouts/DefaultLayout";
import "./Index.css";


const Release = () => {
    return (
        <DefaultLayout title="Release Note">
            <div className="sunstone-list">
                <FieldGroup>
                <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.30 __ v2.0.10</div>
                        <div className="release-contents">
                            <div>instrument-driver상세페이지 info edit, get detail data 세팅 및 modal 공통 컴포넌트세팅</div>
                            <div>benchmark 공통 Excute Benchmark 컴포넌트 작업</div>
                            <div>Instrument detail 채널 선번장 조회</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.29 __ v2.0.9</div>
                        <div className="release-contents">
                            <div>Instrument List 조회 및 생성 작업 진행</div>
                            <div>Instrument Driver API 작업</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.26 __ v2.0.8</div>
                        <div className="release-contents">
                            <div>Create Instrument Driver API 작업</div>
                            <div>Instrument Driver List API 작업</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.25 __ v2.0.7</div>
                        <div className="release-contents">
                            <div>Benchmark state 오류 수정</div>
                            <div>Station Benchmark 등록 팝업 추가</div>
                            <div>Station Create Config 영역 추가</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.24 __ v2.0.6</div>
                        <div className="release-contents">
                            <div>Transpiler 코드 리팩토링 및 로직 구현</div>
                            <div>Station 생성 화면 수정</div>
                            <div>Simulator AWS Jupyter Link 추가</div>
                            <div>Simulator Type 추가</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.24 __ v2.0.5</div>
                        <div className="release-contents">
                            <div>Station Result 화면 수정</div>
                            <div> ● 3d chart 추가</div>
                            <div> ● 영역 분리</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.24 __ v2.0.4</div>
                        <div className="release-contents">
                            <div>Transpiler Bug fix</div>
                            <div>Station 상세내 measurement 영역 추가</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.23 __ v2.0.3</div>
                        <div className="release-contents">
                            <div>메뉴명 변경 (Controller → Instrument Controller)</div>
                            <div>Measurement Controller 메뉴 추가</div>
                            <div>Measurement Controller Driver 메뉴 추가</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.23 __ v2.0.2 </div>
                        <div className="release-contents">
                            <div>Benchmark Page 생성</div>
                            <div>Transpiler Page 생성</div>
                        </div>
                    </div>
                    <div className="release-contents-area">
                        <div className="release-sub-title">2024.04.23 __ v2.0.1</div>
                        <div className="release-contents">
                            <div>Sidebar Menu 변경</div>
                            <div>Release Note 생성</div>
                            <div>Simulator Page 생성</div>
                            <div> ● List, Create, Detail Page</div>
                        </div>
                    </div>
                </FieldGroup>
            </div>
        </DefaultLayout>
    )
}

export default Release;