import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const SpecificDatastore = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                &nbsp; {icon3}&nbsp;{name}
            </a>
            <div id="advanced_section_4" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="small-12 columns dsContext0">
                        <fieldset>
                            <div className="provision_ds_selector0" data-tab-content>
                                <div id="DatastoresTableone2Container">
                                    <div className="row select-resources">
                                        <div className="small-12 medium-6 columns" id="selected_ids_row_DatastoresTableone2">
                                            <label htmlFor="selected_resource_id_DatastoresTableone2" id="select_resource_DatastoresTableone2" style={{ display: 'none' }}>Please select a datastore from the list</label>
                                            <span id="selected_resource_DatastoresTableone2" hidden style={{ display: 'none' }}>You selected the following datastore:</span>
                                            <span id="select_resource_multiple_DatastoresTableone2" hidden style={{ display: 'inline' }}>Please select one or more datastores from the list</span>
                                            <span id="selected_resource_multiple_DatastoresTableone2" hidden style={{ display: 'none' }}>You selected the following datastores:</span>
                                            <span id="selected_resource_name_DatastoresTableone2" className="radius label" style={{ display: 'none' }} />
                                            <input id="selected_resource_id_DatastoresTableone2" type="text" style={{ visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline' }} />
                                        </div>
                                        <div className="small-10 medium-4 columns right">
                                            <input id="DatastoresTableone2_search" className="search" type="search" placeholder="Search" />
                                        </div>
                                        <div className="small-2 columns right">
                                            <a id="refresh_button_DatastoresTableone2" href="#" className="refresh-table right">
                                                <i className="fas fa-lg fa-fw fa-sync-alt" />
                                            </a>
                                        </div>
                                    </div>
                                    <div id="DatastoresTableone2_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                        <table id="DatastoresTableone2" className="hover dataTable no-footer" role="grid" aria-describedby="DatastoresTableone2_info">
                                            <thead>
                                                <tr role="row">
                                                    <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="DatastoresTableone2" aria-sort="descending">ID</th>
                                                    <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Name</th>
                                                    <th aria-label="Owner: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Owner</th>
                                                    <th aria-label="Group: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Group</th>
                                                    <th aria-label="Capacity: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2" style={{ width: 250 }}>Capacity</th>
                                                    <th aria-label="Cluster: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Cluster</th>
                                                    <th aria-label="Type: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Type</th>
                                                    <th aria-label="Status: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="DatastoresTableone2">Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td>0</td>
                                                    <td>system</td>
                                                    <td>oneadmin</td>
                                                    <td>oneadmin</td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>- / -</span>
                                                        <br />
                                                        <progress value={0} max={0} />
                                                    </td>
                                                    <td>0</td>
                                                    <td>SYSTEM</td>
                                                    <td>ON</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="small-6 columns">
                                                <div className="dataTables_length" id="DatastoresTableone2_length">
                                                    <label>
                                                        <select name="DatastoresTableone2_length" aria-controls="DatastoresTableone2" className>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="dataTables_info" id="DatastoresTableone2_info" role="status" aria-live="polite">Showing 1 to 1 of 1 entries (filtered from 3 total entries)</div>
                                            </div>
                                            <div className="small-6 columns">
                                                <div className="dataTables_paginate paging_simple_numbers" id="DatastoresTableone2_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button previous unavailable disabled" aria-controls="DatastoresTableone2" tabIndex={0} id="DatastoresTableone2_previous">Previous</li>
                                                        <li className="paginate_button current" aria-controls="DatastoresTableone2" tabIndex={0}>1</li>
                                                        <li className="paginate_button next unavailable disabled" aria-controls="DatastoresTableone2" tabIndex={0} id="DatastoresTableone2_next">Next</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="large-12 columns">
                                    <label htmlFor="SCHED_DS_REQUIREMENTS"> Expression <span data-tooltip aria-haspopup="true" className="has-tip" data-disable-hover="false" title="Boolean expression that rules out entries from the pool of datastores suitable to run this VM.">
                                        <FontAwesomeIcon icon={faQuestionCircle} />
                                    </span>
                                    </label>
                                    <input type="text" wizard_field="SCHED_DS_REQUIREMENTS" id="SCHED_DS_REQUIREMENTS0" name="requirements" />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </>
}


export default SpecificDatastore;