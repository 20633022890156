import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload({eventHandler}) {
    const onChangeHandler = (e) => {
        if(e.target.files) {
            eventHandler(e.target.files[0].name)
        }
    }

  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<FileUploadIcon sx={{marginLeft:'13px'}}/>}
      sx={{minWidth: '30px',paddingLeft:'1px', paddingRight:'1px'}}
    >
      <VisuallyHiddenInput type="file" onChange={onChangeHandler} />
    </Button>
  );
} 