import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "./modal.css";
import { IoMdClose } from "react-icons/io";

function CustomModal({ title, open, handleClose, renderer,isDisableCloseBtn }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#233044",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const titleStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
  };


  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={titleStyle}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ color: "white", flexGrow: 1 }}
          >
            {title}
          </Typography>
          {!isDisableCloseBtn && (
            <IoMdClose
              onClick={handleClose}
              color="white"
              size={20}
              style={{ cursor: "pointer" }}
            />
          )}
        </div>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {renderer()}
        </Typography>
      </Box>
    </Modal>
  );
}

export default CustomModal;
