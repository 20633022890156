import { useEffect, useRef, useState } from "react";
import "./index.css";
import ListTable from "../../../../../components/Table/ListTable";
import FieldGroup from "../../../../../components/FieldGroup";
import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { sampleInfoData, sampleCircuitData, sampleResultData, sampleMeasurementInfoData, sampleMeasureResultData } from "./sampleData";

export const ResultTab = ({ id, propsData }) => {
    const [data, setData] = useState({})
    const [foldInfoContainer, setFoldInfoContainer] = useState(false)
    const [foldResultContainer, setFoldResultContainer] = useState(false)
    const rsltRef = useRef(null)
    const measurementRsltRef = useRef(null)
    const circuitRef = useRef(null)
    const [runRsltTab, setRunRsltTab] = useState("visualization");
    const [measureRsltTab, setMeasureRsltTab] = useState("visualization");

    useEffect(()=>{
        setData((prev) => ({ ...prev, list: propsData }))
    },[propsData])

    const createListColumns = () => {
        return (
            [
                { id: 'id', label: 'ID', minWidth: 50, hidden: true },
                { id: 'instruments', label: 'Instruments', minWidth: 100 },
                { id: 'circuit', label: 'Circuit', minWidth: 200 },
                { id: 'status', label: 'Status', minWidth: 100 },
                { id: 'createdAt', label: 'Created Time', minWidth: 100 },
                { id: 'finishedAt', label: 'finished Time', minWidth: 100 },
            ]
        )
    }

    useEffect(() => {
        let tempData = [
                        {
                instruments: "SQK Instruments"
                , circuit: "QSVM"
                , status: 'FINISHED'
                , createdAt: '2024.01.13 17:52:14'
                , finishedAt: '2024.01.13 18:02:04'
                , id: 2
            },
            {
                instruments: "Dummy Instruments"
                , circuit: "ces2024-QSVM"
                , status: 'FINISHED'
                , createdAt: '2024.01.13 17:19:37'
                , finishedAt: '2024.01.13 17:32:04'
                , id: 3
            },
        ]

        setData((prev) => ({ ...prev, list: tempData }))
    }, [])


    // Select Event
    const selectRows = (selectedRowData) => {

        setRunRsltTab("visualization")
        setMeasureRsltTab("visualization")

        let infoData = sampleInfoData(selectedRowData?.id)
        let circuitData = sampleCircuitData(selectedRowData?.id)
        let resultData = sampleResultData(selectedRowData?.id)
        let measurementData = sampleMeasurementInfoData(selectedRowData?.id)

        setData((prev) => ({
            ...prev
            , selectedRowData: selectedRowData
            , sampleInfoData: infoData
            , sampleResultData: resultData
            , sampleCircuitData: circuitData
            , sampleMeasurementInfoData : measurementData
            , sampleMeasureResultData: sampleMeasureResultData(selectedRowData?.id)
        }))

        if (circuitRef?.current) {
            circuitRef?.current?.replaceValue({ json: circuitData })
        }

        if (rsltRef?.current) {
            rsltRef?.current?.replaceValue({ json: resultData })
        }
        if (measurementRsltRef?.current) {
            measurementRsltRef?.current?.replaceValue({ json: resultData })
        }
        setFoldResultContainer(false)
    }

    return (
        <div>
            <ListTable title={"Result List"} cols={createListColumns()} selectCallback={selectRows} list={data?.list} height={200} />

            {data?.selectedRowData && <>
                <FieldGroup label={"Instrument Controller Info"} foldable={true} defaultFold={false}>
                    <div className="result-tab-instruments-arch-grid-container">
                        <div className="col-head">NO</div>
                        <div className="col-head">Port</div>
                        <div className="col-head">Channel</div>
                        <div className="col-head">Connected</div>

                        {data?.sampleInfoData?.map((row) => {
                            return (
                                <>
                                    <div>{row[0]}</div>
                                    <div>{row[1]}</div>
                                    <div>{row[2]}</div>
                                    <div>{row[3]}</div>
                                </>
                            )
                        })}
                    </div>
                </FieldGroup>

                <FieldGroup label={"Measurement Controller Info"} foldable={true} defaultFold={false}>
                    <div className="result-tab-instruments-arch-grid-container">
                        <div className="col-head">NO</div>
                        <div className="col-head">Port</div>
                        <div className="col-head">Channel</div>
                        <div className="col-head">Connected</div>

                        {data?.sampleMeasurementInfoData?.map((row) => {
                            return (
                                <>
                                    <div>{row[0]}</div>
                                    <div>{row[1]}</div>
                                    <div>{row[2]}</div>
                                    <div>{row[3]}</div>
                                </>
                            )
                        })}
                    </div>
                </FieldGroup>

                <FieldGroup label={"Circuit Info"} foldable={true} defaultFold={false}>                   
                    <div>
                        <div id="asd" className="large-12" style={{ border: "1px solid #eee", }} >
                            <YamlEditor ref={circuitRef} theme={oneDark} json={data?.sampleCircuitData} />
                        </div>
                    </div>
                    {data?.selectedRowData?.id<3 && <>
                        <div className="circuit-line-data">
                            <pre>          ┌------┐     ┌-------------┐</pre>
                            <pre>q_0: -----┤ √X  ├-----┤ Rz(0.22867) ├</pre>
                            <pre>     ┌---┴------┴---┐└-------------┘</pre>
                            <pre>q_1: ┤ Rx(3.5786)     ├-----┤ H ├-----</pre>
                            <pre>     └---─------─---┘     └---┘     </pre>
                        </div>
                    </>}
                    {data?.selectedRowData?.id==3 && <>
                        <div className="circuit-line-data">
                            <pre>                 ┌---┐                             ┌--------------┐       </pre>
                            <pre>q_0: ------------┤ S ├--------------------■-------┤1             ├-------------</pre>
                            <pre>     ┌----------┴---┴----------┐     ┌-┴-┐    │              │┌─────┐</pre>
                            <pre>q_1: ┤1                          ├-----┤ H  ├----┤  Rxx(4.7463) ├┤ Tdg ├</pre>
                            <pre>     │  (XX-YY)(2.2495,0.88258)  │┌-------------┐│              │└─────┘</pre>
                            <pre>q_2: ┤0                          ├┤ U1(0.43675) ├┤0             ├-------------</pre>
                            <pre>     └---------------------------┘└-------------┘└--------------┘       </pre>
                        </div>
                    </>}
                    
                    
                </FieldGroup>

                <FieldGroup label={"Run Result"} foldable={true} defaultFold={false}>
                    <div className="station-page-result-tab-container">
                        <span className={`${runRsltTab=='visualization'?'active':''}`} onClick={()=>{setRunRsltTab("visualization")}}>Visualization Result</span>
                        <pre> | </pre>
                        <span className={`${runRsltTab!='visualization'?'active':''}`} onClick={()=>{setRunRsltTab("row")}}>Row Data</span>
                    </div>
                    
                    {runRsltTab=='visualization' && data?.sampleResultData != 'Running.' && <img src="assets/images/samples/3dChartSample2.webp"/>}
                    {runRsltTab=='row' && <YamlEditor ref={rsltRef} theme={oneDark} json={data?.sampleResultData} /> }
                </FieldGroup>

                <FieldGroup label={"Measurement Result"} foldable={true} defaultFold={false}>
                    <div className="station-page-result-tab-container">
                        <span className={`${measureRsltTab=='visualization'?'active':''}`} onClick={()=>{setMeasureRsltTab("visualization")}}>Visualization Result</span>
                        <pre> | </pre>
                        <span className={`${measureRsltTab!='visualization'?'active':''}`} onClick={()=>{setMeasureRsltTab("row")}}>Row Data</span>
                    </div>
                    {measureRsltTab=='visualization' && data?.sampleResultData != 'Running.' && <img src="assets/images/samples/3dChartSample1.webp"/>}
                    {measureRsltTab=='row' && <YamlEditor ref={measurementRsltRef} theme={oneDark} json={data?.sampleMeasureResultData} />}
                </FieldGroup>
            </>}
        </div>
    )
}

export default ResultTab;