import React from 'react';
import Header from '../../layouts/Header/Index';
import Sidebar from '../../layouts/Sidebar/Index';






const Index = () => {
    return <>
    <Header />
        <div className="row">
            <Sidebar />
            <div className='small-12 columns sunstone-content large-10'>
                <div className="sunstone-header"></div>
                <div id='dashboard-tab' className='tab'>
                    <div className="row header-row">
                        <div className="large-12 columns">
                            <h5 className="header-title only-sunstone-list">
                                <span className="header-resource">Not found</span>
                            </h5>
                            <h5 className="header-title only-sunstone-info" hidden="" style={{ display: 'none' }}>
                                <span className="header-resource">
                                
                                <small className="resource-id"></small>
                                <small className="resource-info-header"></small>
                                <small className="resource-info-header-small"></small>
                                </span>
                            </h5>
                            <h5 className="header-title only-sunstone-form" hidden="" style={{ display: 'none' }}>
                                <span className="sunstone-form-title"></span>
                                <small className="sunstone-form-id"></small>
                                <small className="sunstone-form-info-header"></small>
                                <small className="sunstone-form-info-header-small"></small>
                            </h5>
                        </div>
                    </div>

                   
                    <div className="actions_row">
                        

                    </div>

                    <div className="sunstone-list">
                        {/* Content */}
                        <h1>Page is not found.</h1>
                        
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Index;