import { Link } from "react-router-dom";
import { useContentContext } from "../../../../context/ContentContext";
import { useHistory } from "react-router";

const Index = ({ icon, title, link, parentTitle, isPop }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const history = useHistory();
  const {
    currentActiveSideBarChild,
    setCurrentActiveSideBar,
    setCurrentActiveSideBarChild,
    setCurrentActiveSideBarActive,
  } = useContentContext();

  return (
    <div
      className={`subTab is-submenu-item is-accordion-submenu-item navigation-active-li sidebar-menu-2-item-layout`}
      onClick={(e) => {
        e.stopPropagation();
        setCurrentActiveSideBar(parentTitle);
        setCurrentActiveSideBarChild(title);
        setCurrentActiveSideBarActive(parentTitle);
      }}
    >
      {link ? (
        isPop ? (
          <div
            onClick={() => {
              window.open(link);
            }}
            className={`sidebar-menu-2-item ${
              currentActiveSideBarChild === title ? "sidebar-menu-2-active" : ""
            }`}
          >
            <a
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
              className={`${themeColor === "dark" ? "dark-theme-menu" : ""}`}
            >
              <div
                style={{
                  width: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </div>
              <span>{title}</span>
            </a>
          </div>
        ) : (
          <div
            onClick={() => {
              history.push(link);
            }}
            className={`sidebar-menu-2-item ${
              currentActiveSideBarChild === title ? "sidebar-menu-2-active" : ""
            }`}
          >
            <a
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
              className={`${themeColor === "dark" ? "dark-theme-menu" : ""}`}
            >
              <div
                style={{
                  width: "15px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {icon}
              </div>
              <span>{title}</span>
            </a>
          </div>
        )
      ) : (
        <div
          className={`sidebar-menu-2-item ${
            currentActiveSideBarChild === title ? "sidebar-menu-2-active" : ""
          }`}
        >
          <a
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "10px",
            }}
            className={`${themeColor === "dark" ? "dark-theme-menu" : ""}`}
          >
            <div
              style={{
                width: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </div>
            <span>{title}</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default Index;
