
import '../Detail.css'
import YamlEditor from '@focus-reactive/react-yaml';

const Template = () => {
    const handleChange = ({ json, text }) => {
    }
    const text = `
        foo: bar
        `;
    const obj = {
        "type": "object",
        "properties": {
            "prop1": {
                "type": "string",
                "default": "test"
            }
        }
    };
    return <>
    
        <div id="vm_template_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_template_tab-label">
            <div className="row">
                <div className="large-12" style={{ 
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignContent: 'center',
                    alignItems: 'center',
                    margin: '20px 0px'
                 }}>
                    <input 
                        type="file" 
                        id="choose_template" 
                        style={{ 
                            display: 'block',
                            width: '200px',
                            margin: '0px'
                         }} 
                    />
                    <button>Save</button>
                </div>
            </div>
            {/* <div className="row">
                <div className="large-12 columns">
                    <h5>User template</h5>
                    <pre className="template-pre monospace">DESCRIPTION = "Temp"{"\n"}MEMORY_UNIT_COST = "MB"{"\n"}SCHED_MESSAGE = "Sun Dec 31 12:10:19 2023 : Cannot dispatch VM to any Host. Possible reasons: Not enough capacity in Host or System DS, or dispatch limit reached"{"\n"}</pre>
                </div>
            </div>
            <div className="row">
                <div className="large-12 columns">
                    <h5>Template</h5>
                    <pre className="template-pre monospace">
                        AUTOMATIC_REQUIREMENTS = "!(PUBLIC_CLOUD = YES)"{"\n"}
                        CONTEXT = [
                            {"\n"}{"  "}DISK_ID = "0",{"\n"}{"  "}NETWORK = "YES",{"\n"}{"  "}SSH_PUBLIC_KEY = "",{"\n"}{"  "}TARGET = "hda" ]{"\n"}CPU = "4"{"\n"}GRAPHICS = [{"\n"}{"  "}LISTEN = "0.0.0.0",{"\n"}{"  "}TYPE = "VNC" ]{"\n"}MEMORY = "8192"{"\n"}OS = [{"\n"}{"  "}BOOT = "" ]{"\n"}TEMPLATE_ID = "0"{"\n"}VCPU = "4"{"\n"}VMID = "0"{"\n"}</pre>
                </div>

                
            </div> */}
            <div className="row">
                <div className="large-12" style={{ 
                            border: '1px solid #eee',
                         }}>
                    <YamlEditor 
                        json={obj} 
                        onChange={handleChange} 
                        
                    />
                </div>
            </div>
        </div>
    </>
}


export default Template;