import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useHistory } from "react-router";
import Select from "../../../components/Select";
import CirclePagination from "./Pagination";
import { baseUrl } from "../../../redux/contants/urlApi";
import axiosService from "../../../api/axiosService";
import { getTimeAgo } from "./util/getTimeAgo";

const selectData = [
  { key: 6, label: 6, select: 6 },
  { key: 12, label: 12, select: 12 },
  { key: 36, label: 36, select: 36 },
  { key: 36, label: 72, select: 72 },
];

const mapper = {
  "SQK": {
    path: "/assets/images/sqk-kokkos-white-code01-instruments2.svg",
    size: "80px",
    text: 'Instruments',
    textLeft: '0px',
    typeName: "SQK Inst"
  },
  "IQM": {
    path: "/assets/images/IQM-logo.svg",
    size: "50px",
    left: '5px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "IQM Inst"
  },
  "IONQ": {
    path: "/assets/images/ionq-logo-light.svg",
    size: "70px",
    left: '2px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "IONQ Inst"
  },
  "Bluefors": {
    path: "/assets/images/bluefors-logo-white.png.webp",
    size: "90px",
    left: '2px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "Bluefors Inst"
  },
  "Zurich": {
    path: "/assets/quantums/Zurich_Instruments_logo.png",
    size: "130px",
  },
  "QUANTUM": {
    path: "/assets/quantums/2-QM-logo-black.svg",
    size: "160px",
  },
  KeySight: {
    path: "/assets/quantums/KEYS_white.webp",
    size: "130px",
  },
  QBLOX: {
    path: "/assets/quantums/3-QBlox-logo.svg",
    size: "150px",
  },
  default: {
    path: "/assets/quantums/4-qick-logo2.svg",
    size: "40px",
  },
};

function ListMain() {
  const [selectedOption, setSelectedOption] = useState(selectData[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [driver, setDriver] = useState([]);
  const pageCount = Math.ceil(driver.length / selectedOption?.select);

  const startIndex = (currentPage - 1) * Number(selectedOption?.select);
  const endIndex = Number(startIndex) + Number(selectedOption?.select);
  const currentDriver = driver.slice(
    startIndex,
    endIndex,
    selectedOption?.select
  );
  const router = useHistory();

  useEffect(() => {
    getInstrumentModel();
  }, []);
  console.log("driver",driver)
  const getInstrumentModel = async () => {
    let api = baseUrl + "v1/instrument-drivers/";

    try {
      const response = await axiosService.get(api);
     
      if (response.status === 200) {
        const data = response.data;
        const tempDriver = [];
        console.log("data",data)
        data.map((element) => {
          const driver = {
            createTime: getTimeAgo(element.created_at),
            name:element.name,
            type: element.type,
            user: element.created_user.username,
            uuid: element.uuid,
          };

          tempDriver.push(driver);
        });
        setDriver(tempDriver);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleChangeEvt = (v) => {
    setSelectedOption((prev) => ({ ...prev, [v.key]: v.value }));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleRedirectUrl = (id) => {
    router.push(`/instrument-driver/${id}`);
  };
  return (
    <>
      <div className="list-card-layout">
        {currentDriver.map((item) => {
          return (
            <Card
              handleRedirectUrl={handleRedirectUrl}
              driver={item}
              mapper={mapper}
            />
          );
        })}
      </div>
      <div className="list-card-selectoption-layout">
        <div className="list-card-selectoption-selectbox">
          <Select
            id="select"
            defaultValue="6"
            data={selectData}
            handleChange={handleChangeEvt}
            label="count"
            error={false}
            errMsg={"Please Input Data"}
          />
        </div>

        <CirclePagination
          count={pageCount}
          color="primary"
          currentPage={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default ListMain;
