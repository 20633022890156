import { baseUrl } from "../../../../redux/contants/urlApi";
import axiosService from "../../../../api/axiosService";
import { tSuccess, tError } from "../../../../common/CommonToast";

export const getDetailData = async (id, setState, setSelectedOption) => {
  let api = baseUrl + "v1/instrument-drivers/" + id + "/details";
  try {
    const response = await axiosService.get(api);
    if (response.status === 200) {
      const data = response.data;
      const driverDetail = {
        type: data.type,
        name: data.name,
        uuid: data.uuid,
        description: data.description,
      };

      setState((prev) => ({
        ...prev,
        data: driverDetail,
        uuid: data.uuid,
        title: [
          {
            label: "Name",
            value: data.name,
          },
          {
            label: "Description",
            value: data.description,
          },
        ],
      }));
      setSelectedOption({ select: data.type });
    }
  } catch (error) {
    console.log("Error in fetching data:", error);
  }
};

export const fetchChannelData = async (
  id,
  setModelElement,
  setInitialChannelData
) => {
  let api = baseUrl + "v1/instrument-drivers/" + id + "/channels";

  try {
    const response = await axiosService.get(api);
    if (response.status === 200) {
      const data = response.data;
      setModelElement((prev) => ({
        ...prev,
        instrument: [...data],
      }));
      const tempData = [];

      data.map((item) => {
        const tempobj = {
          port_name: "",
          channel_name: "",
          uuid: "",
        };
        tempobj.port_name = item.port_name;
        tempobj.channel_name = item.channel_name;
        tempobj.uuid = item.uuid;
        tempData.push(tempobj);
      });

      setInitialChannelData(tempData);
    }
  } catch (err) {
    console.log("error", err);
  }
};

// 해당모델을 사용한 instrument가 있는지 확인하는 함수
export const instrumentCheck = async (setModelElement, id) => {
  let instrumentApi = baseUrl + "v1/instruments";
  try {
    const response = await axiosService.get(instrumentApi);
    const instrumentData = response.data;
    const matchedInstrumentUuid = [];
    instrumentData.map((item) => {
      if (item.instrument_driver.uuid === id) {
        matchedInstrumentUuid.push(item.uuid);
      }
    });

    let apis = [];
    for (const uuid of matchedInstrumentUuid) {
      let newApi = baseUrl + "v1/instruments/" + uuid + "/channel-relations";
      apis.push(newApi);
    }

    for (const api of apis) {
      const checkRelationDriver = await axiosService.get(api);
      const checkRelationData = checkRelationDriver.data;
      const disableDelete = checkRelationData.every((item) => {
        return item.status !=="DISCONNECTED";
      });
      setModelElement((prev) => ({
        ...prev,
        isDisableDelete: disableDelete,
        relationData: checkRelationDriver?.data,
      }));
    }
  } catch (err) {
    console.log("error", err);
  }
};

export const deleteInstrumentModel = async (id, setIsModalOpen, history) => {
  // 삭제 api 함수 세팅 자리
  try {
    const api = baseUrl + "v1/instrument-drivers/" + id;
    const response = await axiosService.delete(api);
    if (response.status === 201) {
      tSuccess("Your instrument driver was successfully deleted.");
      setIsModalOpen(false);
      history.goBack();
    }
  } catch (err) {
    console.log("err", err);
  }
};

export const editName = async (data, id, setIsEdit) => {
  let api = baseUrl + "v1/instrument-drivers/" + id;
  const body = {
    name: data.name,
    description: data.description,
    type: data.type,
  };

  try {
    const response = await axiosService.put(api, body);
    if (response.status === 201) {
      tSuccess("Edit was successful.");
      setIsEdit(false);
    }
  } catch (err) {
    console.log("Error in fetching data:", err);
  }
};
