import styled, { css } from "styled-components";

export const SLayoutDiv = styled.div`
  margin-top: 1rem;
`;

export const STitleDiv = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
