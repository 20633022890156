import React, { useEffect, useRef, useState } from 'react'
import DefaultLayout from '../../../layouts/DefaultLayout';
import ListTable from '../../../components/Table/ListTable';

import FieldGroup from '../../../components/FieldGroup'
import TextField from '../../../components/TextField'
import CustomButton from '../../../components/Button/CustomButton'
import "./index.css"
import { useHistory } from 'react-router';
import axiosService from '../../../api/axiosService';
import { baseUrl } from '../../../redux/contants/urlApi';
import { tError, tSuccess, tWarnning } from '../../../common/CommonToast'
import { dataToJson, detectDataType, jsonToYaml } from '../../../common/CommonUtil';

import CodeEditor from '../../../components/CodeEditor/Index';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';




const Index = () => {
  const [savePop, setSavePop] = useState({ open: false })
  const [data, setData] = useState({
    configData: {
      "instruments": {
        "qdac": {
          "_comment": "Each instrument is specified by its name. This name is what is looked up by the 'load_instrument' method of 'Station'. Simulated instruments can also be specified here, just put the path to the simulation .yaml file as the value of the 'init'->'visalib' field (see below for an example of the 'init' section as well as an example of specifying a simulated instrument).",
          "type": "qcodes.instrument_drivers.QDev.QDevQDac",
          "address": "ASRL4::INSTR",
          "enable_forced_reconnect": true,
          "init": {
            "terminator": null,
            "update_currents": false
          },
          "parameters": {
            "ch01.v": {
              "_comment": "Each parameter is specified by its name from the instrument driver class. Note that 'dot: notation can be used to specify parameters in (sub)channels and submodules.",
              "alias": "cutter_gate",
              "label": "Cutter Gate Voltage",
              "unit": "mV",
              "scale": 0.001,
              "post_delay": 0,
              "inter_delay": 0.01,
              "step": 0.0001,
              "limits": [-0.1, 0.1],
              "initial_value": 0.01,
              "monitor": true
            },
            "ch02.v": {
              "_comment": "As in all YAML files a one-line notation can also be used, here is an example.",
              "scale": 0.01,
              "limits": [0.0, 1500.0],
              "label": "my label"
            },
            "ch04.v": {
              "alias": "Q1lplg1",
              "monitor": true
            }
          },
          "add_parameters": {
            "Bx": {
              "_comment": "For example, here we define a parameter that represents magnetic field control. Setting and getting this parameter will actually set/get a specific DAC channel. So this new magnetic field parameter is playing a role of a convenient proxy - it is much more convenient to perform a measurement where 'Bx' is changed in tesla as opposed to where some channel of some DAC is changed in volts and one has to clutter the measurement code with the mess of conversion factors and more. Every new parameter definition starts with a name of the new parameter.",
              "source": "ch02.v",
              "label": "Magnetic Field X-Component",
              "unit": "T",
              "scale": 65.243,
              "inter_delay": 0.001,
              "post_delay": 0.05,
              "step": 0.001,
              "limits": [0.0, 3.0],
              "initial_value": 0.0
            }
          }
        },
        "dmm1": {
          "type": "qcodes.instrument_drivers.agilent.Agilent34401A",
          "enable_forced_reconnect": true,
          "address": "GPIB::1::65535::INSTR",
          "init": {
            "visalib": "Agilent_34400A.yaml@sim"
          },
          "parameters": {
            "volt": {
              "monitor": true
            }
          }
        },
        "mock_dac": {
          "type": "qcodes.instrument_drivers.mock_instruments.DummyInstrument",
          "enable_forced_reconnect": true,
          "init": {
            "gates": ["ch1", "ch2"]
          },
          "add_parameters": {
            "Bx": {
              "source": "ch1",
              "label": "Bx",
              "unit": "T",
              "scale": 28,
              "limits": [-1, 1],
              "monitor": true
            }
          }
        },
        "mock_dac2": {
          "type": "qcodes.instrument_drivers.mock_instruments.DummyInstrument",
          "enable_forced_reconnect": true
        }
      }
    }

  })
  const history = useHistory()
  const configRef = useRef()

  const renderListColumns = () => {
    return (
      [
        { id: 'id', label: 'ID', minWidth: 100, hidden: true },
        { id: 'name', label: 'Model Name', minWidth: 70 },
        { id: 'description', label: 'Description', minWidth: 100 },
        {
          id: 'instrumentsType',
          label: 'Insruments Type',
          minWidth: 170,
          align: 'left',
          format: (value) => value.toLocaleString('en-US'),
        }
      ]
    )
  }

  const fetchModelList = async () => {
    // Instruments Model List 조회
    try {
      let res = await axiosService.get(baseUrl + "v1/instrument-drivers/");
      if (res?.status == 200) {
        let tempData = res?.data;
        let instrumentDrivers = tempData?.map(item => ({
          id: item?.id,
          name: item?.name,
          description: item?.description,
          instrumentsType: item?.type
        }))

        setData((prev) => ({ ...prev, list: instrumentDrivers }))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleChangeEvt = (v) => {
    console.log("handleChangeEvt v ", v)
    if (data?.['isInvalid_' + v.key]) {
      setData((prev) => ({ ...prev, ['isInvalid_' + v.key]: false }))
    }
    setData((prev) => ({ ...prev, [v.key]: v.value }))
  }

  useEffect(() => {
    fetchModelList()
  }, [])

  const handleSelectRowEvent = (selectedRowData) => {
    handleChangeEvt({ key: "type", value: selectedRowData })
    handleChangeEvt({ key: "selectedRowData", value: selectedRowData })
  }

  const validateCheck = () => {
    const param = {
      name: data?.name
      , description: data?.description
      , type: data?.selectedRowData?.id
      , address: data?.address
      , port: data?.port
    }

    setData((prev) => ({ ...prev, isInvalid_type: param?.type ? false : true }))
    setData((prev) => ({ ...prev, isInvalid_name: param?.name ? false : true }))
    setData((prev) => ({ ...prev, isInvalid_description: param?.description ? false : true }))
    setData((prev) => ({ ...prev, isInvalid_address: param?.address ? false : true }))
    setData((prev) => ({ ...prev, isInvalid_port: param?.port ? false : true }))
    if (!param?.type
      || !param?.name
      || !param?.description
      || !param?.address
      || !param?.port
    ) {
      tWarnning("Please check input fields.")
      return
    } else if (detectDataType(dataToJson(data?.configData)) != 'JSON Object') {
      tWarnning("Invalid Config format")
      return
    }else {
      setSavePop({ open: true })
    }
  }

  const handleSubmit = async () => {
    const param = {
      name: data?.name
      , description: data?.description
      , type: data?.selectedRowData?.id
      , address: data?.address
      , port: data?.port
    }
    
    let createInstrumentData = {
      name: param?.name
      , description: param?.description
      , address: param?.address
      , port: param?.port
      , driver_id_or_uuid: param?.type
    }

    try {
      let res = await axiosService.post(baseUrl + "v1/instruments/", createInstrumentData)
      if (res?.status == 201) {
        let instrument_uuid = res?.data?.uuid
        let confRes = await axiosService.put(baseUrl + 'v1/instruments/' + instrument_uuid + '/settings', dataToJson(data?.configData))

        tSuccess("Create Success.")
        history.push("/instrument-controller/list")
      }
    } catch (e) {
      console.log(e)
    }

    // const result = await axiosService.post(baseUrl+"v1/instruments", param)
  }

  const SavePopDialog = (props) => {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle style={{fontWeight:'bold'}}>Confirm</DialogTitle>
        <div className="instrument-save-pop-dialog-content">
          <div>
            <div>{`Name:`}</div><div>{data?.name}</div>
            <div>{`Description:`}</div><div>{data?.description}</div>
            <div>{`Address:`}</div><div>{data?.address}</div>
            <div>{`Port:`}</div><div>{data?.port}</div>
          </div>

          <div className="instrument-save-pop-dialog-btn-container">
            <div>Is the information correct?</div>
            <div className="instrument-save-pop-dialog-btn-container btn">
              <CustomButton type="custom" className="custom-cancel-btn" label={"Cancel"} onClick={()=>{setSavePop({open:false})}}/>
              <CustomButton type="custom" label={"Save"} onClick={()=>{handleSubmit()}}/>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <DefaultLayout title={<div>Create Instrument Controller</div>}>
      <div className="sunstone-list">
        <CustomButton type="goback" onClick={() => { history.push("/instrument-controller/list") }} />
        <ListTable
          cols={renderListColumns()}
          selectCallback={handleSelectRowEvent}
          list={data?.list}
          title={"Instrument Controller Driver"}
          error={data?.isInvalid_type}
          errorMessage={'Please select row'}
        />

        <FieldGroup label="Information">
          <TextField id="name" label="Name" handleChange={handleChangeEvt} error={data?.isInvalid_name} errMsg={'Please Input Data'} />
          <TextField id="description" label="Description" handleChange={handleChangeEvt} error={data?.isInvalid_description} errMsg={'Please Input Data'} />
          <TextField id="address" label="Address" handleChange={handleChangeEvt} error={data?.isInvalid_address} errMsg={'Please Input Data'} />
          <TextField id="port" label="Port" handleChange={handleChangeEvt} error={data?.isInvalid_port} errMsg={'Please Input Data'} />
          {/* <Select id="qubitsType" label="Type" data={selectData} handleChange={handleChangeEvt} error={data?.isInvalid_qubitsType} errMsg={'Please Select Data'} /> */}
        </FieldGroup>

        <FieldGroup label="Config" foldable={true}>
          <CodeEditor
            value={jsonToYaml(data?.configData)}
            onChange={((e) => { setData((prev) => ({ ...prev, configData: e })) })}
          />
        </FieldGroup>



        <div className='row-btn-container'>
          <div className='end-item'>
            <CustomButton type="custom" label="SAVE" onClick={() => { validateCheck() }} /> {/** Text Ediable */}
          </div>
        </div>


        <SavePopDialog open={savePop?.open} onClose={() => { setSavePop({ open: false }) }} />
      </div>
    </DefaultLayout>
  )
}

export default Index;