const getFileExtension = (text) => {
  switch (true) {
    case /function|const|let/.test(text):
      return "js";

    case /Program|DECLARE|MEASURE|GATE|HADAMARD|CNOT|CZ/.test(text):
      return "py";

    case /OPENQASM|qreg|creg|cx|measure|reset/.test(text):
      return "qasm";

    default:
      return "txt";
  }
};

const downloadTextAsFile = (text) => {
  const fileExtension = getFileExtension(text);
  const blob = new Blob([text], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `myfile.${fileExtension}`;
  a.click();
  URL.revokeObjectURL(url);
};
export { downloadTextAsFile };
