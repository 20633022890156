

const ButtonDropdown = ({icon, cls}) => {
    return <span 
                id="users-tablabels_buttons" 
                className="only-sunstone-info only-sunstone-list" 
                style={{display: 'inline'}}>
                <button 
                    type="button" 
                    // data-toggle="users-tabLabelsDropdown" 
                    className={cls} 
                    // aria-controls="users-tabLabelsDropdown" 
                    // data-is-focus="false" 
                    // data-yeti-box="users-tabLabelsDropdown" 
                    // aria-haspopup="true" 
                    // aria-expanded="false" 
                    // disabled="disabled" 
                    style={{display: 'inline-block'}}>
                    {icon}
                </button>
                <div id="users-tabLabelsDropdown" className="only-sunstone-info only-sunstone-list labels-dropdown dropdown-pane large menu vertical" data-dropdown="ix27dw-dropdown" data-close-on-click="true" aria-hidden="true" data-yeti-box="users-tabLabelsDropdown" data-resize="users-tabLabelsDropdown" aria-labelledby="l1lse9-dd-anchor" data-events="resize" style={{display: 'block'}}>
                    <div>
                        <h6>Edit Labels</h6>
                        <div className="labeltree-container">
                        <ul className="labels-tree" />
                        </div>
                        <div className="input-container">
                            <input type="text" className="newLabelInput" placeholder="Add Label" />
                        </div>
                    </div>
                </div>
            </span>
}

export default ButtonDropdown;