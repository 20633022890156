import { ActionTypes } from './../contants/action-types';

const initialState = {
     auth : {}
}

export const authReducer = (state = initialState, {type, payload})=>{
     switch (type) {
          case ActionTypes.REGISTER_USER:
               return {...state, payload};
          case ActionTypes.REGISTER_USER_SUCCESS:
               const auth = payload;
               return {...state, auth }
          case ActionTypes.LOGIN_USER_ERROR:
               const loginErr = payload;
               return {...state, loginErr }

          case ActionTypes.LIST_USER:
               const list = payload;
               return {...state, list }
          case ActionTypes.GET_LIST_SUCCESS:
                    const listUserSuccess = payload;
                    return {...state, listUserSuccess }
          case ActionTypes.GET_LIST_ERROR:
               const listUserError = payload;
               return {...state, listUserError }
          default:
               return state;
     }
}