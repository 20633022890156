import React, { useEffect, useState } from "react";
import * as Styled from "../benchmark.styled";

import FieldGroup from "../../../../components/FieldGroup";

// Radio import
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";

// useBenchmark hook
import useBenchmark from "../../hooks/useBenchmark";

// dialog import
import SaveDialog from "../../dialog/SaveExcuteDialog";

// import Code Dialog
import CodeDialog from "../../dialog/CodeDialog";

import CodeEditor from "../../../../components/CodeEditor/Index";
import { jsonToYaml, dataToJson } from "../../../../common/CommonUtil";

import Typography from "@mui/material/Typography";
import { common } from "@mui/material/colors";

const tempCode = `OPENQASM 2.0;
  include "qelib1.inc";
  
  qreg q[5];
  creg m0[1];  
  
  cx q[0],q[2];
  
  h q[0];
  z q[1];
  
  z q[2];
  
  rz(pi*1.0) q[1];
  rx(pi*0.25) q[3];
  ry(pi*3.1415926536) q[4];
  
  rz(pi*3.1415926536) q[3];
  ry(pi*1.0) q[4];
  
  ch q[2],q[1];
  cx q[2],q[3];
  
  measure q[2] -> m477[0];`;

const Index = (props) => {
  const {
    mqtBenchmarkList,
    selectMqtBenchmarkData,
    setSelectMqtBenchmarkData,
    getMqtBenchmarkList,

    qasmPop,
    setQasmPop,

    getBenchmarkDetail,
    selectMqtBenchmarkPreviewData,
  } = useBenchmark();

  // Selected radio compiler data
  const [selectedRadioCompilerData, setSelectedRadioCompilerData] = useState(1);

  // Save dialog state
  const [savePop, setSavePop] = useState({ open: false });

  // radio compiler data
  const radioCompilerData = [
    {
      id: 1,
      name: "Qiskit",
    },
    {
      id: 2,
      name: "TKET",
    },
  ];

  // MQT data
  useEffect(() => {
    // fetch data
    getMqtBenchmarkList(props?.tabId);
  }, []);

  // 선택된 벤치마크 control
  useEffect(() => {
    if (selectMqtBenchmarkData != null && selectMqtBenchmarkData != "") {
      const data = {
        benchmark_uuid: selectMqtBenchmarkData,
        compiler: radioCompilerData[selectedRadioCompilerData - 1]?.name,
        // non-scalable 일때는 2로 고정 // 임시
        num_qubits: 2,
      };

      // 벤치마크 상세 정보 preview data 가져오기
      getBenchmarkDetail(data);
    }
  }, [selectMqtBenchmarkData, selectedRadioCompilerData]);

  return (
    <>
      <Styled.SLayout>
        <Styled.STitleDiv>Benchmarks</Styled.STitleDiv>
        <FieldGroup>
          <Styled.SContentsLayout>
            <Styled.STitleDescriptionDiv>
              The number of qubits for the following benchmarks is fixed.
            </Styled.STitleDescriptionDiv>

            {/* List */}
            <Styled.SListDiv>
              <Styled.SRadioGroupDiv>
                <RadioGroup
                  name="nonScalable"
                  data={mqtBenchmarkList}
                  selectedData={selectMqtBenchmarkData}
                  setSelectedData={setSelectMqtBenchmarkData}
                  labelSpace={"calc(50% - 10px)"}
                />
              </Styled.SRadioGroupDiv>

              <hr
                style={{
                  border: "none",
                  borderRight: "1px solid #858d98",
                  height: "auto",
                  width: "1px",
                }}
              />

              <Styled.SRightSideWrapper>
                <Styled.SControlsDiv>
                  <Styled.SControlItemDiv>
                    <RadioGroup
                      name="compiler"
                      title="Compiler"
                      data={radioCompilerData}
                      selectedData={selectedRadioCompilerData}
                      setSelectedData={setSelectedRadioCompilerData}
                      labelSpace="calc(50% - 10px)"
                      defaultValue={1}
                    />
                  </Styled.SControlItemDiv>
                </Styled.SControlsDiv>

                {selectMqtBenchmarkData && (
                  <>
                    {selectMqtBenchmarkPreviewData?.benchmark_preview_image && (
                      <Styled.SImageDiv>
                        <img
                          src={`data:image/png;base64,${selectMqtBenchmarkPreviewData?.benchmark_preview_image}`}
                          width={"100%"}
                        />
                        {/* 이미지 받아오는 공간 */}
                      </Styled.SImageDiv>
                    )}
                    {selectMqtBenchmarkPreviewData?.benchmark_sample && (
                      <Styled.SCodeEditorDiv>
                        <Typography
                          sx={{
                            color: common["white"],
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            marginBottom: "15px",
                            paddingLeft: "10px",
                          }}
                        >
                          QASM Code
                        </Typography>
                        <CodeEditor
                          value={jsonToYaml(
                            selectMqtBenchmarkPreviewData?.benchmark_sample
                          )}
                        />
                      </Styled.SCodeEditorDiv>
                    )}
                  </>
                )}
              </Styled.SRightSideWrapper>
            </Styled.SListDiv>

            {/* 영역 구분 선 */}
            {/* <hr style={{ border: "1px solid #858d98" }} /> */}

            {/* Controls */}
            {/* <Styled.SControlsDiv>
              <Styled.SControlItemDiv>
                <RadioGroup
                  name="compiler"
                  title="Compiler"
                  data={radioCompilerData}
                  selectedData={selectedRadioCompilerData}
                  setSelectedData={setSelectedRadioCompilerData}
                  labelSpace="calc(50% - 10px)"
                  defaultValue={1}
                />
              </Styled.SControlItemDiv>
            </Styled.SControlsDiv> */}

            {/* 영역 구분 선 */}
            <hr style={{ border: "1px solid #858d98" }} />
            <Styled.SButtonDiv isOneBtn={true}>
              {/* <button
                className="button secondary"
                disabled={selectMqtBenchmarkData == null}
                onClick={() => {
                  setQasmPop({ open: true });
                }}
              >
                Quantum Code
              </button> */}
              <button
                className="button primary"
                disabled={selectMqtBenchmarkData == null}
                onClick={() => {
                  setSavePop({ open: true });
                }}
              >
                Save
              </button>
            </Styled.SButtonDiv>
          </Styled.SContentsLayout>
        </FieldGroup>
      </Styled.SLayout>

      <SaveDialog
        title="Benchmark Save"
        type="save"
        open={savePop?.open}
        onClose={() => {
          setSavePop((prev) => ({ ...prev, open: !savePop?.open }));
        }}
        data={{
          benchmark:
            mqtBenchmarkList.length > 0 &&
            mqtBenchmarkList.find((item) => item.id === selectMqtBenchmarkData),

          compiler: radioCompilerData[selectedRadioCompilerData - 1]?.name,
        }}
      />

      {/* <CodeDialog
        open={qasmPop?.open}
        onClose={() => {
          setQasmPop((prev) => ({ ...prev, open: !qasmPop?.open }));
        }}
      /> */}
    </>
  );
};

export default Index;
