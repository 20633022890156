import React, { useState } from "react";
import "./index.css";
import FieldGroup from "../../../../../components/FieldGroup";
import CodeEditor from "../../../../../components/CodeEditor/Index";
import {
  jsonToYaml,
  dataToJson,
  detectDataType,
} from "../../../../../common/CommonUtil";
import CustomButton from "../../../../../components/Button/CustomButton";
import SaveModal from "../../../../../components/Modal/Modal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import axiosService from "../../../../../api/axiosService";
import { baseUrl } from "../../../../../redux/contants/urlApi";
import { tSuccess, tError, tWarnning } from "../../../../../common/CommonToast";

function index({ data, setData, id }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const editConfig = async () => {
    let api = baseUrl + "v1/stations/" + id + "/settings";

    if (detectDataType(dataToJson(data)) != "JSON Object") {
      tWarnning("Invalid Config format");
    } else {
      try {
        const response = await axiosService.put(api, dataToJson(data));
        if (response.status === 201) {
          tSuccess("Success!!");
          setIsModalOpen(false);
        } else {
          tError("Faild");
          setIsModalOpen(false);
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  const renderInfoDiv = () => {
    return (
      <Stack spacing={2} direction="row">
        <Button
          variant="contained"
          onClick={() => {
            editConfig();
          }}
        >
          Okay
        </Button>
        <Button
          variant="outlined"
          onClick={() => {
            setIsModalOpen(false);
          }}
        >
          Cancel
        </Button>
      </Stack>
    );
  };
  return (
    <>
      <FieldGroup label={"Config"} foldable={true}>
        <CodeEditor
          value={jsonToYaml(data)}
          onChange={(e) => {
            setData((prev) => ({ ...prev, setting: e }));
          }}
        />
      </FieldGroup>
      <div className="conf-btn-layout">
        <CustomButton
          type="custom"
          label={"SAVE"}
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
      </div>
      {SaveModal && (
        <SaveModal
          title="Are you sure you want to edit it?"
          open={isModalOpen}
          isDisableCloseBtn={true}
          handleClose={() => {
            setIsModalOpen(false);
          }}
          renderer={() => renderInfoDiv()}
        />
      )}
    </>
  );
}

export default index;
