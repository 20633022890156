import { faExchangeAlt, faFolder, faPowerOff, faTag } from '@fortawesome/free-solid-svg-icons';
import './UpdateConfFormTabs.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const Index = ({handleClickUpdateFormTab}) => {

    return <dl id="updateConfFormTabs" className="tabs sunstone-info-tabs" data-tabs="260dtj-tabs">
            <dd className="tabs-title hypervisor only_kvm is-active tabs-conf-update" role="presentation">
                <a  onClick={e => handleClickUpdateFormTab(e)} href="javascript:void(0)" role="tab" aria-controls="osTabone1" aria-selected="true" id="osTabone1-label">
                    <FontAwesomeIcon icon={faPowerOff} />
                    <br />OS &amp; CPU </a>
            </dd>
            <dd  className="tabs-title tabs-conf-update" role="presentation">
                <a onClick={e => handleClickUpdateFormTab(e)} href="javascript:void(0)" role="tab" aria-controls="ioTabone5" aria-selected="false" id="ioTabone5-label">
                    <FontAwesomeIcon icon={faExchangeAlt} />
                    <br />Input/Output </a>
            </dd>
            <dd className="tabs-title hypervisor only_kvm only_vcenter tabs-conf-update" role="presentation">
                <a onClick={e => handleClickUpdateFormTab(e)} href="javascript:void(0)" role="tab" aria-controls="contextTabone7" aria-selected="false" id="contextTabone7-label">
                    <FontAwesomeIcon icon={faFolder} />
                    <br />Context </a>
            </dd>
            <dd className="tabs-title tabs-conf-update" role="presentation">
                <a onClick={e => handleClickUpdateFormTab(e)} href="javascript:void(0)" role="tab" aria-controls="otherTabone10" aria-selected="false" id="otherTabone10-label">
                    <FontAwesomeIcon icon={faTag} />
                    <br />Tags </a>
            </dd>
        </dl>
}


export default Index;