import React from "react";
import { useHistory } from "react-router";
import CustomButton from "../../../components/Button/CustomButton";

function ListHeader({ title, btnTitle, link }) {
  const history = useHistory();

  const handleRedirectClick = () => {
    history.push(link);
  };
  return (
    <div className="list-header-layout">
      <div className="list-header-left">
        <span className="list-header-title">{title}</span>
        <CustomButton
          type="iconPlus"
          onClick={() => {
            handleRedirectClick();
          }}
        />
      </div>

      {/* <IconButton
        marginRight="20px"
        onClick={handleRedirectClick}
        startIcon={<FaPlus size={13} />}
      >
        {btnTitle}
      </IconButton> */}
    </div>
  );
}

export default ListHeader;
