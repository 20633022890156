import Information from './Path/Information';
import Capacity from './Path/Capacity';
import Storage from './Path/Stogage';
import Network from './Path/Network';
import Snapshots from './Path/Snapshots';
import Placement from './Path/Placement';
import Actions from './Path/Actions';
import Conf from './Path/Conf';
import Template from './Path/Template';
import Log from './Path/Log';



const TabContent = () => {
  return <>
    <div className="tabs-content" data-tabs-content="vms-tab-panelsTabs">
      <Information />
      <Capacity />
      <Storage />
      <Network />
      <Snapshots />
      <Placement />
      <Actions />
      <Conf />
      <Template />
      <Log />
    </div>
  </>
}


export default TabContent;