import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import { useEffect, useState } from "react";
import {
  renderInfoSkeleton
} from "./InformationRenderer";
import { sim1JupyterUrl } from "../../../../../redux/contants/urlApi";

const Information = ({ }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(() => {

    // 임시셋팅
    setTimeout(() => {
      setLoading(false)
    }, 450)
  }, [])


  const renderInfoDiv = (key, value, icon) => {
    return (
      <div className={`info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <div>{key}</div>
        <div>{value}</div>
        <div className={`info-page-left-grid-icon`}>{icon}</div>
      </div>
    )
  }

  return <>
    <div className="info-page-container">
      <div className={`info-page-contents-container`}>
        <div>
          {/* Information 영역 */}
          <FieldGroup>
            <div className={`info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Information
            </div>

            {/* Information Skeleton */}
            {loading && renderInfoSkeleton()}

            {/* Information Data Setting */}
            {!loading && <>
              {renderInfoDiv("ID", "153", null)}
              {renderInfoDiv("Name", "SQK_Sim 1", <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("Description", "for GHK",null)}
              {renderInfoDiv("State", "ACTIVE", null)}
              {renderInfoDiv("Created at", "10:39:32 15/04/2024", null)}
              {renderInfoDiv("OS Version", "Ubuntu 22.04", null)}
              {renderInfoDiv("Python Version", "3.9.10", null)}
              {renderInfoDiv("IP Address", "192.168.0.247", null)}
              {renderInfoDiv("Port", "22", null)}
              {renderInfoDiv("key file", "SQK.pem", <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("Web Terminal", <a onClick={()=>{window.open(sim1JupyterUrl)}} >Jupyter Link</a> , null)}
            </>}
          </FieldGroup>
        </div>
      </div>
    </div>
  </>
}

export default Information;