import React, { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./Create.css";
import Select from "../../../components/Select";
import { FaTrash } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa";
import IconButton from "../../../components/Button/IconButton";
import Input from "./Input";
import CustomButton from "../../../components/Button/CustomButton";


function InputField({ setIsReset, isReset,isvalidation,setIsvalidation,modelElement,setModelElement,selectedOption,setSelectedOption }) {


  useEffect(() => {
    if (isReset) {
      setModelElement((prevState) => ({
        ...prevState,
        title: prevState.title.map((item) => ({ ...item, value: "" })),
        instrument: [],
      }));
      setSelectedOption("");
      setIsReset(false);
    }
  }, [isReset]);



  const handleInstrumentChange = (index, field, value) => {

    if(value.length>0){
      setIsvalidation((prev)=>(
        {
          ...prev,
          instrument: prev.instrument.map((item, idx) => 
          idx === index ? { ...item, [field]: false } : item
        )
        }
      ))
    }else{
      setIsvalidation((prev)=>(
        {
          ...prev,
          instrument: prev.instrument.map((item, idx) => 
          idx === index ? { ...item, [field]: true } : item
        )
        }
      ))
    }
    setModelElement((prevState) => {
      const newInstruments = [...prevState.instrument];
      newInstruments[index] = {
        ...newInstruments[index],
        [field]: value,
        portNum: index + 1,
      };
      return { ...prevState, instrument: newInstruments };
    });
  };

  const handleInputChange = (index, id, value) => {
    if(value.length >0){
      setIsvalidation((prev)=>(
        {
          ...prev,
          [id]:false
        }
      ))
    }else {
      setIsvalidation((prev)=>(
        {
          ...prev,
          [id]:true
        }
      ))
    }

    setModelElement((prevState) => {
      const updatedTitle = prevState.title.map((item, idx) => {
        if (idx === index && item.label === id) {
          return { ...item, value };
        }
        return item;
      });

      return {
        ...prevState,
        title: updatedTitle,
      };
    });
  };


  const handleAddInstrument = () => {
    setModelElement((prevState) => ({
      ...prevState,
      instrument: [...prevState.instrument, { portName: "", ChannelName: "" }],
    }));
    setIsvalidation((prev) => {
      const updatedInstrumentValidation = prev.instrument
        ? [...prev.instrument, { portName: false, ChannelName: false }]
        : [{ portName: false, ChannelName: false }];

      return {
        ...prev,
        instrument: updatedInstrumentValidation,
      };
    });
  };

  const handleDeleteInstrument = (index) => {
    setModelElement((prevState) => ({
      ...prevState,
      instrument: prevState.instrument.filter((_, i) => i !== index),
    }));
  };


  const instrumentRenderer = () => {
    return (
      <div className="intrument-area">
        {modelElement.instrument.map((_, index) => {
          return (
            <div className="istrument-item">
              <span className="instrument-number" >{index + 1}</span>
              <div className="input-div">
                <Input
                  id="portName"
                  label="Port name"
                  index={index}
                  error={isvalidation?.instrument[index]?.portName}
                  handleChange={handleInstrumentChange}
                />
              </div>

              <Input
                id="ChannelName"
                label="Channel name"
                index={index}
                error={isvalidation?.instrument[index]?.ChannelName}
                handleChange={handleInstrumentChange}
              />
              <div className="close-btn">
                <CustomButton   onClick={() => {
                    handleDeleteInstrument(index);
                  }} type="delete" />
                {/* <IconButton
                  onClick={() => {
                    handleDeleteInstrument(index);
                  }}
                  startIcon={<FaTrash size={13} />}
                >
                  Delete
                </IconButton> */}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const handleSelectChange = (v) => {
    setIsvalidation((prevState) => ({
      ...prevState,
      selectedOption:false
    }));
    setSelectedOption((prev) => ({ ...prev, [v.key]: v.value }));
  };

  return (
    <div className="input-field-layout">
      <div className="input-field-inner-layout">
        {modelElement.title.map((item, index) => {
          return (
            <div className="input-div">
              <Input
                width="100%"
                id={item.label}
                label={item.label}
                index={index}
                value={item.value}
                error={isvalidation[item.label]}
                handleChange={handleInputChange}
              />
            </div>
          );
        })}
        <div className="select-layout">
          <Select
            id="select"
            data={modelElement.type}
            defaultValue={selectedOption}
            handleChange={handleSelectChange}
            label="Type"
            error={isvalidation.selectedOption}
          />
        </div>

        {instrumentRenderer()}
        <div className="plus-icon">
        <CustomButton width="120"   onClick={handleAddInstrument} type="iconPlusAndLabel" label="Add Port name And Channel name" />
          {/* <IconButton
            width={300}
            onClick={handleAddInstrument}
            startIcon={<FaPlus size={13} />}
          >
            c
          </IconButton> */}
        </div>
      </div>
    </div>
  );
}

export default InputField;
