


const Index = () => {
    return <div id="otherTabone10" className="wizard_tab tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="otherTabone10-label">
            <div className="row">
                <div className="large-12 columns">
                <fieldset className="hypervisor only_kvm">
                    <legend>RAW data</legend>
                    <div>
                    <div className="medium-4 columns">
                        <label> Type <select className="raw_type" disabled>
                            <option value="kvm">kvm</option>
                        </select>
                        </label>
                    </div>
                    <div className="medium-8 columns">
                        <label> Data <span data-tooltip="gy22ny-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="tiy1yu-tooltip" data-yeti-box="tiy1yu-tooltip" data-toggle="tiy1yu-tooltip" data-resize="tiy1yu-tooltip">
                            <i className="fas fa-question-circle" />
                        </span>
                        <textarea rows={2} type="text" className="raw_data" defaultValue={""} />
                        </label>
                    </div>
                    </div>
                </fieldset>
                </div>
            </div>
            </div>
}


export default Index;