


const ButtonAction = ({id, cls, act, icon}) => {
    return <span 
                id={id} 
                className="only-sunstone-info only-sunstone-list" 
                style={{display: 'inline'}}>
                <button 
                    className={`action_button top_button alwaysActive refresh button ${cls}`} 
                    onClick={()=>act()}
                    >
                    {icon}
                </button>
            </span>
}

export default ButtonAction;