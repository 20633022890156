import AceEditor from 'react-ace';
import yaml from 'js-yaml';
// Ace Editor의 YAML 모드를 가져옵니다.
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-twilight';
import { useEffect, useState } from 'react';
import "./index.css"

export const CodeEditor = (props) => {
    
    return (
        <AceEditor
            mode="yaml"
            theme="twilight"
            name="yaml_editor"
            editorProps={{ $blockScrolling: true }}
            fontSize={16}
            width="100%"
            height="500px"
            setOptions={{
                showLineNumbers: true,
                tabSize: 2,
            }}
            value={props?.value}
            style={{ backgroundColor: '#282C34' }}
            {...props}
        />
    )
}

export default CodeEditor;