export const usernameValidator = username => {
    if (!username) {
      return "Username is required";
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(username)) {
      // return "Incorrect email format";
    }
    return "";
  };
  
  export const passwordValidator = password => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 12) {
      // return "Password must have a minimum 12 characters";
    }
    return "";
  };

  export const re_passwordValidator = (password, re_password) => {
    if(re_password !== password){
      return "Password is not same";
    }
    if (!re_password) {
      return "Password is required";
    } else if (re_password.length < 12) {
      // return "Password must have a minimum 12 characters";
    }
    return "";
  };

  export const emailValidator = email => {
    if (!email) {
      return "Email is required";
    } else if (email.length < 12) {
      // return "Password must have a minimum 12 characters";
    }
    return "";
  };
