import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




const Associate = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                <i className="fas fa-fw fa-chevron-up" />&nbsp; {icon3}&nbsp;{name}</a>
            <div id="advanced_section_6" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="small-12 columns vmgroupContext0">
                        <fieldset>
                            <div>
                                <a className="button small provision_add_vmgroup">
                                    <FontAwesomeIcon icon={faPlusCircle} /> VM Group
                                </a>
                            </div>
                            <div className="provision_vmgroup_selector">
                                <div className="row" id="vmgroup_section_tables" style={{ display: 'none' }}>
                                    <div className="medium-12 columns">
                                        <a className="button secondary small clear_vmgroup_select" style={{ float: 'right' }}>
                                            <i className="fas fa-lg fa-times-circle" /> Clear </a>
                                    </div>
                                    <div className="medium-8 columns">
                                        <div id="vmgroups_tableone5Container">
                                            <div className="row select-resources">
                                                <div className="small-12 medium-6 columns" id="selected_ids_row_vmgroups_tableone5">
                                                    <label htmlFor="selected_resource_id_vmgroups_tableone5" id="select_resource_vmgroups_tableone5">Please select a vm group from the list</label>
                                                    <span id="selected_resource_vmgroups_tableone5" hidden style={{ display: 'none' }}>You selected the following vm group:</span>
                                                    <span id="select_resource_multiple_vmgroups_tableone5" hidden style={{ display: 'none' }}>Please select one or more vm groups from the list</span>
                                                    <span id="selected_resource_multiple_vmgroups_tableone5" hidden style={{ display: 'none' }}>You selected the following vm groups:</span>
                                                    <span id="selected_resource_name_vmgroups_tableone5" className="radius label" style={{ display: 'none' }} />
                                                    <input id="selected_resource_id_vmgroups_tableone5" type="text" style={{ visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline' }} />
                                                </div>
                                                <div className="small-10 medium-4 columns right">
                                                    <input id="vmgroups_tableone5_search" className="search" type="search" placeholder="Search" />
                                                </div>
                                                <div className="small-2 columns right">
                                                    <a id="refresh_button_vmgroups_tableone5" href="#" className="refresh-table right">
                                                        <i className="fas fa-lg fa-fw fa-sync-alt" />
                                                    </a>
                                                </div>
                                            </div>
                                            <div id="vmgroups_tableone5_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                                <table id="vmgroups_tableone5" className="hover dataTable no-footer" role="grid" aria-describedby="vmgroups_tableone5_info">
                                                    <thead>
                                                        <tr role="row">
                                                            <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="vmgroups_tableone5" aria-sort="descending">ID</th>
                                                            <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="vmgroups_tableone5">Name</th>
                                                            <th aria-label="Owner: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="vmgroups_tableone5">Owner</th>
                                                            <th aria-label="Group: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="vmgroups_tableone5">Group</th>
                                                            <th aria-label="VMs: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="vmgroups_tableone5">VMs</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="odd">
                                                            <td valign="top" colSpan={5} className="dataTables_empty">
                                                                <span className="text-center" style={{ fontSize: 18, color: '#999' }}>
                                                                    <br />
                                                                    <span className="fa-stack fa-3x" style={{ color: '#dfdfdf' }}>
                                                                        <i className="fas fa-cloud fa-stack-2x" />
                                                                        <i className="fas fa-info-circle fa-stack-1x fa-inverse" />
                                                                    </span>
                                                                    <br />
                                                                    <span style={{ color: '#999' }}>There is no data available</span>
                                                                </span>
                                                                <br />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className="row">
                                                    <div className="small-6 columns">
                                                        <div className="dataTables_length" id="vmgroups_tableone5_length">
                                                            <label>
                                                                <select name="vmgroups_tableone5_length" aria-controls="vmgroups_tableone5" className>
                                                                    <option value={10}>10</option>
                                                                    <option value={25}>25</option>
                                                                    <option value={50}>50</option>
                                                                    <option value={100}>100</option>
                                                                </select>
                                                            </label>
                                                        </div>
                                                        <div className="dataTables_info" id="vmgroups_tableone5_info" role="status" aria-live="polite">Showing 0 to 0 of 0 entries</div>
                                                    </div>
                                                    <div className="small-6 columns">
                                                        <div className="dataTables_paginate paging_simple_numbers" id="vmgroups_tableone5_paginate">
                                                            <ul className="pagination">
                                                                <li className="paginate_button previous unavailable disabled" aria-controls="vmgroups_tableone5" tabIndex={0} id="vmgroups_tableone5_previous">Previous</li>
                                                                <li className="paginate_button next unavailable disabled" aria-controls="vmgroups_tableone5" tabIndex={0} id="vmgroups_tableone5_next">Next</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="medium-4 columns" id="role_section" style={{ display: 'none' }}>
                                        <div>
                                            <h6 className="title_roles">Roles</h6>
                                            <select name="roles" className="role_table_section" size={5} style={{ height: 'auto' }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Associate;