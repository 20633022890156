import "./Main.css";
import Sidebar from "../../layouts/Sidebar/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import Card from "./Card/Index";
import Lgcard from "./Lgcard/Index";

const Main = () => {
  const arrCard = [
    {
      title: "Stations",
      titleImg: "/assets/images/quantum-station-black-removebg.webp",
      titleImgWidth: 40,
      titleImgHeight: 40,
      titleImgMarginLeft: 0,
      arrAct: [
        {
          icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />,
          cls: "list-blue-color",
          act: null,
          link: "/platform/list",
        },
        {
          icon: <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />,
          cls: "create-gray-color",
          act: null,
          link: "/",
        },
      ],
      arrCnt: [
        {
          total: 3056,
          stt: "TOTAL",
          cls: "",
        },
        {
          total: 2,
          stt: "OPEN",
          cls: "",
        },
        {
          total: 4,
          stt: "CLOSED",
          cls: "",
        },
      ],
    },
    {
      title: "Dignostics",
      titleImg: "/assets/images/diagnostics-removebg-preview.webp",
      titleImgWidth: 80,
      titleImgHeight: 40,
      titleImgMarginLeft: -20,
      arrAct: [
        {
          icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />,
          cls: "list-blue-color",
          act: null,
          link: "/",
        },
        {
          icon: <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />,
          cls: "create-gray-color",
          act: null,
          link: "/",
        },
      ],
      arrCnt: [
        {
          total: (
            <>
              <div style={{ fontSize: "13px" }}>median 99.91 %</div>
              <div style={{ fontSize: "11px", color: "gray" }}>
                (best 99.944 ± 0.003 %)
              </div>
            </>
          ),
          stt: "1Q fidelity",
          cls: "",
        },
        {
          total: (
            <>
              <div style={{ fontSize: "13px" }}>median 98.25 %</div>
              <div style={{ fontSize: "11px", color: "gray" }}>
                (best 99.1 ± 0.3 %)
              </div>
            </>
          ),
          stt: "2Q fidelity",
          cls: "",
        },
      ],
    },
    {
      title: "Instruements",
      titleImg: "/assets/images/instruments.webp",
      titleImgWidth: 90,
      titleImgHeight: 55,
      titleImgMarginLeft: -30,
      arrAct: [
        {
          icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />,
          cls: "list-blue-color",
          act: null,
          link: "/instrument-driver/list",
        },
        {
          icon: <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />,
          cls: "create-gray-color",
          act: null,
          link: "/",
        },
      ],
      arrCnt: [
        {
          total: 10,
          stt: "PROVIDERS",
          cls: "",
        },
        {
          total: 50,
          stt: "DRIVERS",
          cls: "",
        },
      ],
    },
    {
      title: "Simulators",
      titleImg: "/assets/images/simulator-removebg-preview.webp",
      titleImgWidth: 33,
      titleImgHeight: 40,
      titleImgMarginLeft: 0,
      titleImgMarginBottom: 20,
      arrAct: [
        {
          icon: <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />,
          cls: "list-blue-color",
          act: null,
          link: "/",
        },
        {
          icon: <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />,
          cls: "create-gray-color",
          act: null,
          link: "/",
        },
      ],
      arrCnt: [
        {
          total: 10,
          stt: "BACKENDS",
          cls: "",
        },
        {
          total: 150,
          stt: "CIRCUITS",
          cls: "",
        },
      ],
    },
  ];
  return (
    <>
      <div className="row">
        <div className="small-12 columns sunstone-content large-10">
          <div className="sunstone-header"></div>
          <div id="dashboard-tab" className="tab">
            <div className="row header-row">
              <div className="large-12 columns">
                <h5
                  className="header-title only-sunstone-info"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <span className="header-resource">
                    <small className="resource-id"></small>
                    <small className="resource-info-header"></small>
                    <small className="resource-info-header-small"></small>
                  </span>
                </h5>
                <h5
                  className="header-title only-sunstone-form"
                  hidden=""
                  style={{ display: "none" }}
                >
                  <span className="sunstone-form-title"></span>
                  <small className="sunstone-form-id"></small>
                  <small className="sunstone-form-info-header"></small>
                  <small className="sunstone-form-info-header-small"></small>
                </h5>
              </div>
            </div>

            <div className="sunstone-list">
              <div>
                <div id="two_per_row" className="dashboard-grid">
                  {arrCard.length &&
                    arrCard.map((element, index) => {
                      return (
                        <Card
                          key={element?.title + index}
                          title={element?.title}
                          arrAct={element?.arrAct}
                          arrCnt={element?.arrCnt}
                          titleImg={element?.titleImg}
                          titleImgWidth={element?.titleImgWidth}
                          titleImgHeight={element?.titleImgHeight}
                          titleImgMarginLeft={element?.titleImgMarginLeft}
                          titleImgMarginBottom={element?.titleImgMarginBottom}
                        />
                      );
                    })}
                </div>
                <div id="one_per_row">
                  <Lgcard />
                </div>
                <div id="three_per_row" />
                <div id="one_footer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
