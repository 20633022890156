import React, {useEffect, useState} from 'react'
import DefaultLayout from '../../../layouts/DefaultLayout';
import FieldGroup from '../../../components/FieldGroup'
import TextField from '../../../components/TextField'
import Select from '../../../components/Select'
import CustomButton from '../../../components/Button/CustomButton'
import "./index.css"
import { useHistory } from 'react-router';
import {tError, tSuccess, tWarnning} from '../../../common/CommonToast'
import { chkIpFormat, isNullorEmpty } from '../../../common/CommonUtil';
import InputFileUpload from '../../../components/Button/UploadButton';


const Index = () => {
    const [data,setData] = useState({})
    const history = useHistory()

    const handleChangeEvt = (v) => {
        console.log("handleChangeEvt v ",v)
        if(data?.['isInvalid_'+v.key]){
            setData((prev) => ({ ...prev, ['isInvalid_'+v.key]: false }))
        }
        setData((prev) => ({ ...prev, [v.key]: v.value }))
    }

    useEffect(()=>{
        console.log("OK")
    },[])


    const selectData = [
        { key: 'RHEL', label: 'RHEL' }
        ,{ key: 'Ubuntu', label: 'Ubuntu' }
        ,{ key: 'CentOS', label: 'CentOS' }
        ,{ key: 'RockyOS', label: 'RockyOS' }
    ]

    const handleSubmit = async () => {
        const param = {
            name: data?.name
            ,description: data?.description
            ,address: data?.address
            ,port: data?.port
            ,osType: data?.osType
            ,osVersion: data?.osVersion
            ,keyFileName: data?.keyFileName
        }

        if(!param?.name) setData((prev)=>({...prev, isInvalid_name: true}))
        if(!param?.description) setData((prev)=>({...prev, isInvalid_description: true}))
        if(!param?.address) setData((prev)=>({...prev, isInvalid_address: true}))
        if(!param?.port) setData((prev)=>({...prev, isInvalid_port: true}))
        if(!param?.osType) setData((prev)=>({...prev, isInvalid_osType: true}))
        if(!param?.osVersion) setData((prev)=>({...prev, isInvalid_osVersion: true}))
        if(!param?.keyFileName) setData((prev)=>({...prev, isInvalid_keyFileName: true}))


        if(!param?.name
            ||!param?.description
            ||!param?.address
            ||!param?.port
            ||!param?.osVersion
            ||!param?.osType
            ||!param?.keyFileName){

                console.log("param : ",param)

            tError("Invalid data")
            return
        }

        tSuccess("Success");
        history.push("/simulation/simulator")

        // const result = await axiosService.post(baseUrl+"v1/instruments", param)
    }

    const uploadHandler = (value) => {
        setData((prev)=>({...prev, fileName: value}))
    }

    return (
        <DefaultLayout title={<div>Create Simulator</div>}>
            <div className="sunstone-list">
                <CustomButton type="goback" onClick={() => { history.push("/simulation/simulator") }} />

                <FieldGroup label="Information">
                    <TextField id="name" label="Name" handleChange={handleChangeEvt} error={data?.isInvalid_name} errMsg={'Please Input Data'} />
                    <TextField id="description" label="Description" handleChange={handleChangeEvt} error={data?.isInvalid_description} errMsg={'Please Input Data'} />
                    <TextField id="address" label="Address" handleChange={handleChangeEvt} error={data?.isInvalid_address} errMsg={'Please Input Data'} />
                    <TextField id="port" label="Port" handleChange={handleChangeEvt} error={data?.isInvalid_port} errMsg={'Please Input Data'} />
                    <Select id="osType" label="Type" data={selectData} handleChange={handleChangeEvt} error={data?.isInvalid_osType} errMsg={'Please Select Data'} />
                    <TextField id="osVersion" label="OS Version" handleChange={handleChangeEvt} error={data?.isInvalid_osVersion} errMsg={'Please Input Data'} />
                    <div style={{display:'flex', height:'41px', gap: '10px'}}>
                        <TextField id="fileName" isDisabled={true} value={data?.fileName} label="Key File" handleChange={handleChangeEvt} error={data?.isInvalid_osVersion} errMsg={'Please Input Data'} />
                        <InputFileUpload eventHandler={uploadHandler}/>
                    </div>
                </FieldGroup>

                <div className='row-btn-container'>
                    <div className='end-item'>
                        <CustomButton type="custom" label="CONNECT" onClick={() => { handleSubmit() }} /> {/** Text Ediable */}
                        <CustomButton type="custom" label="SAVE" onClick={() => { handleSubmit() }} /> {/** Text Ediable */}
                    </div>
                </div>
            </div>
        </DefaultLayout>
    )
}

export default Index;