

const Index = ({handleClickContextTabone7}) => {
    return <ul id="context_tabs" className="tabs vertical" data-tabs="58ip1f-tabs">
                <li className="tabs-title is-active tabs-title-contextTabone7" role="presentation">
                    <a onClick={e => handleClickContextTabone7(e)} href="javascript:void(0)" role="tab" aria-controls="netsshTabone9" aria-selected="true" id="netsshTabone9-label">Configuration</a>
                </li>
                <li className="tabs-title hypervisor only_kvm tabs-title-contextTabone7" role="presentation">
                    <a onClick={e => handleClickContextTabone7(e)} href="javascript:void(0)" role="tab" aria-controls="filesTabone9" aria-selected="false" id="filesTabone9-label">Files</a>
                </li>
                <li className="tabs-title tabs-title-contextTabone7" role="presentation">
                    <a onClick={e => handleClickContextTabone7(e)} href="javascript:void(0)" role="tab" aria-controls="customTabone9" aria-selected="false" id="customTabone9-label">Custom vars</a>
                </li>
            </ul>
}

export default Index;