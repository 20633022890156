import React, { useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "./Create.css";
import InputField from "./InputField";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TableArea from "./TableArea";
import DefaultLayout from "../../../layouts/DefaultLayout";
import ButtonField from "./ButtonField";
import axiosService from "../../../api/axiosService";
import { toast } from "react-toastify";
import { baseUrl } from "../../../redux/contants/urlApi";

const columns = [
  { id: "id", label: "ID", minWidth: 100, hidden: true },
  { id: "name", label: "BanderName", minWidth: 70 },
  { id: "description", label: "Description", minWidth: 100 },
  {
    id: "status",
    label: "Status",
    minWidth: 170,
    align: "left",
    format: (value) => value.toLocaleString("en-US"),
  },
];

const list = [
  {
    id: 1,
    name: "Zurich Measurement",
    description: "Zurich driver main class.",
    status: "Active",
  },
  {
    id: 2,
    name: "Quantum Measurement",
    description:
      "Instrument object for controlling Quantum Machines (QM) OPX controllers.",
    status: "Active",
  },
  {
    id: 3,
    name: "Qblox",
    description:
      "A class to extend the functionality of qblox_measurements Cluster.",
    status: "Active",
  },
  {
    id: 4,
    name: "Xilinx RFSoCs",
    description: "Measurement object for controlling RFSoC FPGAs.",
    status: "Active",
  },
  {
    id: 5,
    name: "Dummy Measurement",
    description:
      "Dummy Measurement that returns random voltage values. Useful for testing code without requiring access to hardware.",
    status: "Active",
  },
];

const Index = () => {
  const [selectedModel, setSelectedModel] = useState({});
  const [isReset, setIsReset] = useState(false);
  const [isvalidation, setIsvalidation] = useState({
    bander: false,
    Name: false,
    Description: false,
    type: false,
    selectedOption: false,
    instrument: [],
  });
  const [modelElement, setModelElement] = useState({
    title: [
      {
        label: "Name",
        value: "",
      },
      {
        label: "Description",
        value: "",
      },
    ],
    type: [
      { key: "Dummy Measurement", label: "Dummy Measurement" },
    ],
    instrument: [],
  });
  const setRowEvt = (rowData) => {
    setSelectedModel(rowData);

    console.log(rowData);
  };
  const [modelId, setModelId] = useState(null);
  const [selectedOption, setSelectedOption] = useState({});



  const validationCheck = () => {
    const { title, instrument } = modelElement;
    if (title[0].value.length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        Name: true,
      }));
    }
    if (title[1].value.length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        Description: true,
      }));
    }
    if (Object.keys(selectedOption).length === 0) {
      setIsvalidation((prev) => ({
        ...prev,
        selectedOption: true,
      }));
    }
    if (instrument.length > 0) {
      const validationResults = instrument.map((item) => {
        const portNameValid = item.portName.length > 0;
        const channelNameValid = item.ChannelName.length > 0 ;
        return { portName: !portNameValid, ChannelName: !channelNameValid };
      });

      setIsvalidation((prev) => ({
        ...prev,
        instrument: validationResults,
      }));
    }
  };

  const postInstrumentModel = async () => {
    let api = baseUrl + "/v1/instruments";
    const body = {
      name: modelElement.title[0].value,
      description: modelElement.title[1].value,
      type: selectedOption,
      bander: selectedModel.name,
    };
    try {
      const { name, description, type, bander } = body;

      if (
        name.length > 0 &&
        description.length > 0 &&
        type.length > 0 &&
        bander.length > 0
      ) {
        const response = await axiosService.post(api, body);
        // response로 받은 id값
        console.log("response", response);
        const data = await response.data;
        const status = response.status;
        if (modelElement.instrument.length > 0 && status === 200) {
          const response = await postPortName(data);
          const portStatus = await response.status;
          if (portStatus === 200) {
            // 등록 성공 alert
          }
        } else {
          //포트이름, 채널 이름 없을때 등록
        }
      } else {
        validationCheck();
        toast.error("You must enter all values");
      }
    } catch (err) {
      validationCheck();
      console.log("err", err);
    }
  };

  const postPortName = async (id) => {
    let api = baseUrl + "";
    body = {
      id: id,
      instrument: modelElement.instrument,
    };
    // key값은 추후에 api나오면 변경예정

    const allFieldsValid = modelElement.instrument.every(
      (item) => item.portName.length > 0 && item.ChannelName.length > 0
    );
    try {
      if (allFieldsValid) {
        const response = await axiosService.post(api, body);
        const status = response.status;
        if (status === 200) {
          // 성공 alert
        } else {
          // 실패 alert
        }
      } else {
        validationCheck();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  return (
    <DefaultLayout title={"Create Measurement Driver"}>
      <ToastContainer />
      <ButtonField
        setIsReset={setIsReset}
        postInstrumentModel={postInstrumentModel}
        setIsvalidation={setIsvalidation}
      />
      <TableArea
        columns={columns}
        list={list}
        isReset={isReset}
        setRowEvt={setRowEvt}
        setIsReset={setIsReset}
      />
      <InputField
        modelElement={modelElement}
        setModelElement={setModelElement}
        setSelectedOption={setSelectedOption}
        setIsReset={setIsReset}
        isReset={isReset}
        isvalidation={isvalidation}
        setIsvalidation={setIsvalidation}
        selectedOption={selectedOption}
      />
    </DefaultLayout>
  );
};

export default Index;
