import "./Login.css";
import "./jquery.min.js";
import "./bootstrap.min.js";
import "./popper.js";
import React, { useState, useEffect, useRef } from "react";
import { useLoginFormValidator } from "./hooks/useLoginFormValidator";
import { loginUser } from "../../redux/actions/auth.js";
import { useDispatch, useSelector } from "react-redux";
import useToken from "../../helpers/useToken.js";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { baseUrl } from "../../redux/contants/urlApi.js";
import axiosService from "../../api/axiosService.js";

const Index = () => {
  const history = useHistory();
  const { token } = useToken();
  const [loading, setLoading] = useState(false);
  const loginErr = useSelector((state) => state);
  const selectLoginError = useSelector((state) => state?.authReducer?.loginErr);
  const radRoot = useRef();
  const radUsr = useRef();
  const radDivRoot = useRef();
  const radDivUsr = useRef();

  // state 선언
  const [state, setState] = useState({
    selType: "root",
    resolveMsg: "",
    pwdAlertMsg: "",
    gettingUserInfo: false,
    isNextClick: false,
  });

  // true로 잠시
  if (token) {
    window.location.href = "/";
  }

  const dispath = useDispatch([]);
  const [form, setForm] = useState({
    username: "",
    password: "",
  });
  const [isUserMailChecked,setIsUserMailChecked] = useState(false);
  const themeColor = process.env?.REACT_APP_THEME;
  const { errors, validateForm, onBlurField, setErrors } =
    useLoginFormValidator(form);

  useEffect(() => {
    const { username, password } = form;

    if (username.length > 0) {
      setState((prevState) => ({
        ...prevState,
        resolveMsg: "",
      }));
    }

    if (password.length > 0) {
      setState((prevState) => ({
        ...prevState,
        pwdAlertMsg: "",
      }));
    }
  }, [form]);

  useEffect(() => {
    const { username } = form;
    if (selectLoginError?.code == 500) {
      if (username === "admin@sqkcloud.com") {
        setState((prevState) => ({
          ...prevState,
          pwdAlertMsg: "Check your Enter your account PASSWORD.",
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          pwdAlertMsg: "Check your Enter your account PASSWORD.",
        }));
      }

      toast.error("Check yourt account", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [selectLoginError]);

  const handleChange = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    let field = e.target.name;

    setForm(nextFormState);

    if (errors[field].dirty)
      validateForm({
        form: nextFormState,
        errors,
        field,
      });
  };


  const nextStepValidation = async(e) =>{
    const { username } = form;

    let param = {
      email: username,
    };
    
    try{
      let api = baseUrl + 'v1/users/email/exist?email='+param.email
      const response =  await axiosService.post(api,null);
      setIsUserMailChecked(response.data);

      if(response.data){
        setState((prevState) => ({
          ...prevState,
          isNextClick: true,
        }));
        setState((prevState) => ({
          ...prevState,
          resolveMsg: "",
          selType: e.target.value,
          gettingUserInfo: true,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          resolveMsg: "Check your Enter your account ID.",
        }));
      }
    }catch(err){
       setState((prevState) => ({
        ...prevState,
        resolveMsg: "Check your Enter your account ID.",
      }));
    }

  }



  const onSubmitForm = (e) => {
    e.preventDefault();
    const { password } = form;
    const { isValid } = validateForm({ form, errors, forceTouchErrors: true });
    // if (!isValid) return;

    if (!(password.length > 0)) {
      setState((prevState) => ({
        ...prevState,
        pwdAlertMsg: "Check your Enter your account PASSWORD.",
      }));
    }

    setState((prevState) => ({
      ...prevState,
      pwdAlertMsg: "Check your Enter your account PASSWORD.",
    }));
    if (form?.username.length && form?.password.length) {
      setLoading(true);
      dispath(loginUser(form, history));

      setState((prevState) => ({
        ...prevState,
        resolveMsg: "",
        pwdAlertMsg: "",
      }));
    }
  };
  const { gettingUserInfo } = state;
  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  useEffect(() => {
    
    if (gettingUserInfo) {
      passwordInputRef.current && passwordInputRef.current.focus();
    } else {
      usernameInputRef.current && usernameInputRef.current.focus();
    }
  }, [gettingUserInfo]);

  const userIdRenderer = () => {
    const hasPwdError = state.pwdAlertMsg.length > 0;
    const { gettingUserInfo } = state;
    const hasIdError = state.resolveMsg.length > 0 && !gettingUserInfo;

    const getErrorStyle = (hasError) => ({
      border: hasError ? "1px solid red" : undefined,
    });

    const getErrorMessageStyle = (hasError) => ({
      color: "red",
      position: "relative",
      top: hasError ? "-10px" : undefined,
    });

    return (
      <>
        <input
         ref={usernameInputRef}
          type="text"
          style={getErrorStyle(hasIdError)}
          className="form-control rounded-left input-style"
          placeholder="username"
          name="username"
          value={form.username}
          onChange={(e) => handleChange(e)}
          onBlur={onBlurField}
          disabled={gettingUserInfo}
          required
        />
        {hasIdError ? (
          <span style={getErrorMessageStyle(hasIdError)}>
            {state.resolveMsg}
          </span>
        ) : null}

        {gettingUserInfo && (
          <>
            <h3 style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>Password</h3>
            <input
              ref={passwordInputRef}
              type="password"
              style={getErrorStyle(hasPwdError)}
              className="form-control rounded-left input-style"
              placeholder="Password"
              name="password"
              value={form.password}
              onChange={(e) => handleChange(e)}
              onBlur={onBlurField}
              required
            />
            {hasPwdError && (
              <span style={getErrorMessageStyle(hasPwdError)}>
                {state.pwdAlertMsg}
              </span>
            )}
          </>
        )}
      </>
    );
  };

  const BtnRenderer = (text, type = "button", onClick) => {
    
    return (
      <button type={type} className="btn-square" onClick={onClick}>
        {text}
      </button>
    );
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (state.gettingUserInfo) {
      onSubmitForm(e);
    } else {
      nextStepValidation(e);
    }
  };
  // set selType
  const setSelType = (e) => {
    setState((prevState) => ({
      ...prevState,
      selType: e.target.value,
    }));
  };
  // 로그인 타입 선택
  const selType = (target) => {
    target.current.click();
  };

  const darkmodeLogo ="/assets/images/sqk-kokkos-code01-white.svg"
  const whitemodeLogo ="/assets/images/sqk-kokkos-code01.svg"
  return (
    <>
      <ToastContainer />
      <section className={`login-layout  ${themeColor==='dark'?"dark-theme-bgc-contents dark-theme-bl1":""}`} >
        <form className="login-wrapper" onSubmit={handleSubmit}>
          <div className="login-left">
            <div className="login-logo">
              <img src={themeColor==='dark'?darkmodeLogo:whitemodeLogo} />
            </div>
            <div className="login-title" >
              <h1 style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>Sign in</h1>
            </div>
            {!state.gettingUserInfo && (
              <div className="select-box">
                <div
                  className="select-box-item"
                  onClick={() => {
                    selType(radRoot);
                  }}
                  onChange={setSelType}
                  ref={radDivRoot}
                >
                  <div className="select-box-title">
                    <input
                      type="radio"
                      id="rad_root"
                      name="usrType"
                      defaultChecked={true}
                      value="root"
                      ref={radRoot}
                      onChange={setSelType}
                    />
                    <h4 style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>Root user</h4>
                  </div>
                  <div className="desc" style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"lightgray"}}>
                    Account owner that performs tasks requiring unrestricted
                    access.
                  </div>
                </div>
                <div
                  className="select-box-item"
                  ref={radDivUsr}
                  onClick={() => {
                    selType(radUsr);
                  }}
                >
                  <div className="select-box-title">
                    <input
                      type="radio"
                      id="rad_usr"
                      name="usrType"
                      value="usr"
                      ref={radUsr}
                      onChange={setSelType}
                    />
                    <h4 style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>IAM user</h4>
                  </div>
                  <div className="desc" style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"lightgray"}}>
                    User within an account that performs daily tasks.
                  </div>
                </div>
              </div>
            )}

            <div
              className="login-textarealayout"
              style={{
                height: state.gettingUserInfo ? "29rem" : "auto",
              }}
            >
              <h3 style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>User ID</h3>
              {userIdRenderer()}
              {BtnRenderer("Next", "submit")}
              {!state.gettingUserInfo && (
                <div className="login-desc">
                  {/* <p style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"#555"}}>
                    By continuing, you agree to the
                    <a href="#"> SQS Customer Agreement</a>or other agreement
                    for AWS services, and the<a href="#"> Privacy Notice.</a>
                    This site uses essential cookies. See our
                    <a href="#"> Cookie Notice</a> fro more information.
                  </p> */}
                </div>
              )}
            </div>
            <div className="login-btn-layout">
              <div className="login-wanna-sqs-text">
                <div className="bfr"></div>
                <div style={{color:themeColor==='dark'?"rgba(255, 255, 255, 0.95)":"black"}} >New to SQS?</div>
                <div className="aft"></div>
              </div>
              {BtnRenderer("Create a new SQS account")}
            </div>
          </div>
          <div className="login-right">
            <img src="/assets/images/signin/main.png" />
          </div>
        </form>
      </section>
    </>
  );
};

export default Index;
