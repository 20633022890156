import React, { useEffect, useState } from "react";
import "../../Platform/Detail/Path/Benchmark/Benchmark.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

import CustomButton from "../../../components/Button/CustomButton";
import CommonTextField from "../../../components/TextField";
import Select from "../../../components/Select";

import { tSuccess } from "../../../common/CommonToast";

// benchmark API
import { createMyBenchmark, executeMyBenchmark } from "../../../api/benchmarks";

const SaveExcuteDialog = (props) => {
  const { title, type, onClose, open, data } = props;

  // 저장 가능 required data state
  const [isSaveable, setIsSaveable] = useState(false);
  // 실행 가능 required data state
  const [isExcutable, setIsExcutable] = useState(false);

  const handleBenchPop = (result) => {
    if (result) {
      if (type == "save") {
        // save
        // 입력 파라미터 값 저장
        const benchmarkParam = {
          title: data.name,
          description: data.benchmark.description,
          benchmark_type: data.benchmark.benchmark_type,
          benchmark_algorithm: data.benchmark.benchmark_algorithm,
          benchmark_name: data.benchmark.name,
          num_qubits: data?.qubit ? data.qubit : 0,
          compiler: data.compiler,
        };

        createMyBenchmark(benchmarkParam).then((res) => {
          if (res) {
            tSuccess("Benckmark Save.");
          }

          onClose(true);
        });
      }
      if (type == "excute") {
        // excute
        // 입력 파라미터 값 저장
        const benchmarkParam = {
          benchmark_uuid: data.uuid,
          simulator: data.instrument,
          device_type: data.device_type,
          method_type: data.method_type,
        };

        executeMyBenchmark(benchmarkParam).then((res) => {
          if (res) {
            tSuccess("Benckmark Excute.");
          }

          onClose(true);
        });
      }
    }
    //setBenchPop({ open: false });
  };

  const handleClose = () => {
    onClose(false);
    setIsSaveable(false);
  };

  useEffect(() => {
    if (type == "save") {
      setIsSaveable(false);
    }
  }, [open]);

  return (
    <Dialog
      maxWidth="1024px"
      onClose={handleClose}
      open={open}
      className="benchmark-pop-container"
    >
      <DialogTitle className="benchmark-pop-title">{title}</DialogTitle>
      <div className="benchmark-pop-contents">
        <CommonTextField
          id="benchmark"
          label="Benchmark"
          handleChange={() => {}}
          value={
            data?.benchmark?.name == undefined
              ? data?.benchmark
              : data?.benchmark?.name
          }
          isDisabled={true}
        />

        {data?.qubit > 0 && (
          <CommonTextField
            id="qubits"
            label="Number of Qubits"
            handleChange={() => {}}
            type={"number"}
            value={data?.qubit}
            isDisabled={true}
          />
        )}

        <CommonTextField
          id="compiler"
          label="Compiler"
          handleChange={() => {}}
          value={data?.compiler}
          isDisabled={true}
        />

        <CommonTextField
          id="name"
          label="Benchmark Name"
          handleChange={(e) => {
            // name 입력시 저장 가능
            if (e.value) {
              setIsSaveable(true);
            } else {
              setIsSaveable(false);
            }

            // data 저장
            data.name = e.value;
          }}
          isDisabled={type == "save" ? false : true}
          value={type == "save" ? "" : data.name}
          placeholder={"Enter Benchmark Name"}
          error={type == "save" && !isSaveable}
        />

        {data?.instrument && (
          <>
            <CommonTextField
              id="instrument"
              label="Instrument"
              handleChange={() => {}}
              value={data?.instrument}
              isDisabled={true}
            />
          </>
        )}

        {type == "excute" && (
          <>
            <Select
              id="device_type"
              label="Device type"
              handleChange={(e) => {
                // data 저장
                data.device_type = e.value;

                // 실행가능 유효성 검사
                if (data.device_type && data.method_type) {
                  setIsExcutable(true);
                } else {
                  setIsExcutable(false);
                }
              }}
              value={data.device_type}
              data={[
                { key: "CPU", label: "CPU" },
                { key: "GPU", label: "GPU" },
              ]}
              placeholder={"Select Device type"}
              error={data?.device_type == undefined || data?.device_type == ""}
            />

            <Select
              id="method_type"
              label="Method type"
              handleChange={(e) => {
                // data 저장
                data.method_type = e.value;

                // 실행가능 유효성 검사
                if (data.device_type && data.method_type) {
                  setIsExcutable(true);
                } else {
                  setIsExcutable(false);
                }
              }}
              value={data.method_type}
              data={[
                { key: "automatic", label: "automatic" },
                { key: "statevector", label: "statevector" },
                { key: "density_matrix", label: "density_matrix" },
                { key: "stabilizer", label: "stabilizer" },
                { key: "matrix_product_state", label: "matrix_product_state" },
                { key: "extended_stabilizer", label: "extended_stabilizer" },
                { key: "unitary", label: "unitary" },
                { key: "superop", label: "superop" },
                { key: "tensor_network", label: "tensor_network" },
              ]}
              placeholder={"Select Method type"}
              error={data?.method_type == undefined || data?.method_type == ""}
            />
          </>
        )}

        <div className="station-benchmark-pop-btn-container">
          <div>
            <CustomButton
              type={"custom"}
              label={"Cancel"}
              className="custom-btn-cancel"
              onClick={() => {
                handleClose();
              }}
            />
            <CustomButton
              type={"custom"}
              label={type == "save" ? "Save" : "Excute"}
              disabled={
                type == "save" && !isSaveable
                  ? true
                  : type == "excute" && !isExcutable
                  ? true
                  : false
              }
              onClick={() => {
                handleBenchPop(true);
              }}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SaveExcuteDialog;
