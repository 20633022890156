import "./Information.css";
import FieldGroup from "../../../../../components/FieldGroup";
import CustomButton from "../../../../../components/Button/CustomButton"
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { dataToJson, detectDataType, getChannelConf, jsonToYaml } from "../../../../../common/CommonUtil";
import axiosService from "../../../../../api/axiosService";
import { baseUrl } from "../../../../../redux/contants/urlApi";
import CodeEditor from "../../../../../components/CodeEditor/Index";
import { tError, tSuccess, tWarnning } from "../../../../../common/CommonToast";
import AlertDialog from "../../../../../components/Dialog";

const Information = ({ }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const configRef = useRef();
  const { id } = useParams();
  const [data, setData] = useState({
    configData: null
  });

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(() => {
    // 임시셋팅
    handleFetch()
  }, [])

  const validationCheck = () => {
    if (detectDataType(dataToJson(data?.configData)) != 'JSON Object') {
      tWarnning("Invalid Config format")
      return false
    }

    return true
  }

  const handleSubmit = async () => {
    let res = await axiosService.put(baseUrl+`v1/instruments/${id}/settings`, dataToJson(data?.configData))

    if(res?.status == 200 || res?.status == 201){
      tSuccess("Success update")
      handleFetch()
    }
  }

  const handlePopupResult = () => {
    const isVaild = validationCheck();
    if (isVaild) {
      handleSubmit();
    }
  };
  const renderSavePop = () => {
    const runPopContents = {
      title: "Confirm",
      contents: (
        <>
          <div className="create-station-pop-container">
              Change it?
          </div>
        </>
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <AlertDialog
        type="custom"
        label="Save"
        id="submit"
        disabled={true ? false : true}
        callback={handlePopupResult}
        contents={runPopContents}
      />
    );
  };

  const handleFetch = async () => {
    let channelConf = await getChannelConf(id);
    

    // 선번장 조회
    if (channelConf?.status == 'S') {
      setData((prev) => ({ ...prev, channelConf: channelConf?.data }))
    }

    let confRes = await axiosService.get(baseUrl+'v1/instruments/'+id+'/settings')
    if (confRes?.status == 200){
      let confData = confRes?.data;
      setData((prev)=>({...prev, configData:confData}))
      
      if(configRef?.current){
        configRef.current.replaceValue({json: confData})
      }
    }
  }


  return <>
    <div className="driver-info-page-container">
      <FieldGroup label={"Channel Conf"} foldable={true}>
        <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
          <div className={`attr-text-item-header`}>Port No</div>
          <div className={`attr-text-item-header`}>Port Name</div>
          <div className={`attr-text-item-header`}>Channel Name</div>
          <div className={`attr-text-item-header`}>Connected</div>
        </div>
        {data?.channelConf?.map((item) => {
          return (
            <div className={`station-info-page-chnl-text-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              <div className={`attr-text-item-no`}>{item?.port_no}</div>
              <div className={`attr-text-item`}>{item?.port_name}</div>
              <div className={`attr-text-item`}>{item?.channel_name}</div>
              <div className={`attr-text-item`}>{item?.connected_qubits}</div>
            </div>
          )
        })}
      </FieldGroup>

      <FieldGroup label={"Config"} foldable={true}>
        <CodeEditor value={jsonToYaml(data?.configData)} onChange={(e)=>{setData((prev)=>({...prev, configData:e }))}} />
      </FieldGroup>

      <div className="instrument-config-tab-btn-container">
        <div>
          {renderSavePop()}
        </div>
      </div>
    </div>
  </>
}

export default Information;