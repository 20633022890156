import axios from "axios";

const getCookieValue = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
      }
  }
  return null;
};
class axiosService {
  constructor() {
    const instance = axios.create({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE",
        "Access-Control-Max-Age": "3600",
        "Access-Control-Allow-Headers": "x-requested-with, authorization",
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
      processData: false,
      mimeType: "multipart/form-data",
      contentType: "application/json",
    });

    instance.interceptors.response.use(this.handleSuccess, this.handleError);

    instance.interceptors.request.use(
      (config) => {
        config.headers.Authorization = `Bearer ${getCookieValue('access_token')}`;
        return config;
      },
      (error) => {
        // 요청 인터셉트 중 발생한 에러 처리
        return Promise.reject(error);
      }
    );

    this.instance = instance;
  }

  handleSuccess(res) {
    return res;
  }

  handleError(err) {
    if (!err.hasOwnProperty("code")) {
      if (err.response && err.response.status === 403) {
        document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return (window.location.href = "/login");
      } else {
        return {
          code: 500,
          error_message: err,
        };
      }
    } else {
      return err;
    }
  }

  get(url) {
    try {
      return this.instance.get(url);
    } catch (error) {
      return {};
    }
  }

  post(url, body) {
    return this.instance.post(url, body);
  }

  put(url, body) {
    return this.instance.put(url, body);
  }

  delete(url) {
    return this.instance.delete(url);
  }
}

export default new axiosService();