import React from "react";
import CustomTab from "../../../../components/Tab";

function Tab({ tabData }) {
  return (
    <div className="custom-tab-layout">
      <CustomTab tabData={tabData} />
    </div>
  );
}

export default Tab;
