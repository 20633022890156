import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton"
import Checkbox from '@mui/material/Checkbox';
import { useEffect, useState } from "react";
import {
  renderInfoSkeleton
  , renderPermissionSkeleton
  , renderOwnershipSkeleton
  , renderAttributesSkeleton
} from "./InformationRenderer";


const Information = ({ data }) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const [contentsData, setContentsData] = useState({});
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    console.log("data : ", data)
  }, [data])

  useEffect(() => {
    // 임시셋팅
    setTimeout(() => {
      setLoading(false)
    }, 250)
  }, [])

  const renderInfoDiv = (key, value, icon) => {
    return (
      <div className={`driver-info-page-left-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
        <div>{key}</div>
        <div>{value}</div>
        <div className={`driver-info-page-left-grid-icon`}>{icon}</div>
      </div>
    )
  }

  return <>
    <div className="driver-info-page-container">
      <div className={`driver-info-page-contents-container`}>
        <div>
          {/* Information 영역 */}
          <FieldGroup>
            <div className={`driver-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              Information
            </div>

            {/* Information Skeleton */}
            {loading && renderInfoSkeleton()}

            {/* 임시 곧 삭제 대상 */}
            {!loading && <>
              {renderInfoDiv("ID", "0", null)}
              {renderInfoDiv("Name", data?.id, <CustomIcon type={"faEdit"} />)}
              {renderInfoDiv("State", "ACTIVE", null)}
              {renderInfoDiv("LCM State", "LCM_INIT", null)}
              {renderInfoDiv("Qubit count", "20", null)}
              {renderInfoDiv("1Q Fidelity", "median 99.91%", null)}
              {renderInfoDiv("2Q Fidelity", "median 98.25%", null)}
              {renderInfoDiv("Quantum Volume ", "16", null)}
              {renderInfoDiv("Q-score", "8", null)}
              {renderInfoDiv("Largest GHZ genuinely entangled state", "9", null)}
              {renderInfoDiv("Started at", "10:39:32 30/12/2023", null)}
            </>}
          </FieldGroup>
        </div>

        {/* Device Info 영역 */}
        <div>
          <FieldGroup>
            <div className={`driver-info-page-title-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              <br />
            </div>

            <div className={`measurement-driver-info-page-right-grid-container header ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
              <div className={``}>Device</div>
              <div className={``}>Firmware</div>
              <div className={``}>Software</div>
            </div>

            {/* Device Info Skeleton */}
            {loading && renderPermissionSkeleton()}

            {/* Device Info Setting */}
            {!loading  && <>
              <div className={`measurement-driver-info-page-right-grid-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div>{data?.id}</div>
                <div>Latest (Feb 2024)</div>
                <div>{data?.id+" "+data?.version}</div>
              </div>
            </>}
          </FieldGroup>

          {/* Image 영역 */}
          <FieldGroup>

            {/* Image Skeleton */}
            {loading && renderOwnershipSkeleton()}

            {/* Image Setting */}
            {!loading  && <>
              <div className={`driver-info-page-right-image-container ${themeColor === 'dark' ? 'custom-theme-dark' : ''}`}>
                <div style={{backgroundColor:'', borderRadius:'8px', width: '60%', display:'flex', justifyContent:'center',padding:'1rem'}}>
                  <img src={data?.imageUrl} style={{
                    width: data?.imageSize ? data?.imageSize : '30rem', height: data?.imageHeight ? data?.imageHeight : '', maxWidth: data?.maxWidth
                      ? data?.maxWidth
                      : "",
                  }} ></img>
                </div>
              </div>
            </>}
          </FieldGroup>
        </div>
      </div>
    </div>
  </>
}

export default Information;