import React, { useEffect } from "react";

import PersonIcon from "@material-ui/icons/Person";
import LanguageIcon from "@material-ui/icons/Language";
import SettingsIcon from "@material-ui/icons/Settings";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import VisibilityIcon from "@material-ui/icons/Visibility";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CheckIcon from "@material-ui/icons/Check";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import "./header.css";
import { useContentContext } from "../../context/ContentContext";

// router
import { Link } from "react-router-dom";

const Index = () => {
  const themeColor = process.env?.REACT_APP_THEME;
  const { isShowSideBar, setIsShowSideBar } = useContentContext();
  const [isUserselector, setIsUserselector] = React.useState(false);

  if(themeColor==='dark'){
    document.getElementsByTagName("body")[0].classList.add("dark-theme-body")
  }

  useEffect(() => {
    renderUserselector();
  });
  const userselector = (e) => {
    e.preventDefault();
    setIsUserselector(!isUserselector);
  };

  const handleLogout = () => {
    // sessionStorage.removeItem("access_token");
    document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.href = "/login";
  };

  const renderUserselector = () => {
    // if(isUserselector){
    return (
      <ul className={`menu submenu is-dropdown-submenu first-sub vertical`}>
        <li className={`is-submenu-item is-dropdown-submenu-item`}>
          <a
            href=""
            className={`configuration`}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <SettingsIcon
              style={{
                marginRight: "10px",
              }}
            />
            <span>Settings</span>
          </a>
        </li>
        <li className={`is-submenu-item is-dropdown-submenu-item`}>
          <a
            onClick={() => handleLogout()}
            href=""
            className={`configuration`}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <PowerSettingsNewIcon
              style={{
                marginRight: "10px",
              }}
            />
            <span>Sign Out</span>
          </a>
        </li>
        <li className={`is-submenu-item is-dropdown-submenu-item`}>
          <a
            href=""
            className={`configuration`}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <VisibilityIcon
              style={{
                marginRight: "10px",
              }}
            />
            <span>Views (mixed)</span>
          </a>
          <ul
            className={`menu submenu is-dropdown-submenu vertical`}
            style={{
              position: "absolute",
              top: "60%",
            }}
          >
            <li className={`is-submenu-item is-dropdown-submenu-item`}>
              <a
                href=""
                className={`quickconf_view`}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FiberManualRecordIcon
                  style={{
                    marginRight: "10px",
                    fontSize: "10px",
                  }}
                />
                <span
                  style={{
                    textDecoration: "none",
                  }}
                >
                  admin
                </span>
              </a>
            </li>
            <li className={`is-submenu-item is-dropdown-submenu-item`}>
              <a
                href=""
                className={`quickconf_view`}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FiberManualRecordIcon
                  style={{
                    marginRight: "10px",
                    fontSize: "10px",
                  }}
                />
                <span
                  style={{
                    textDecoration: "none",
                  }}
                >
                  groupadmin
                </span>
              </a>
            </li>
            <li className={`is-submenu-item is-dropdown-submenu-item`}>
              <a
                href=""
                className={`quickconf_view`}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FiberManualRecordIcon
                  style={{
                    marginRight: "10px",
                    fontSize: "10px",
                  }}
                />
                <span
                  style={{
                    textDecoration: "none",
                  }}
                >
                  user
                </span>
              </a>
            </li>
            <li className={`is-submenu-item is-dropdown-submenu-item`}>
              <a
                href=""
                className={`quickconf_view`}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <FiberManualRecordIcon
                  style={{
                    marginRight: "10px",
                    fontSize: "10px",
                  }}
                />
                <span
                  style={{
                    textDecoration: "none",
                  }}
                >
                  cloud
                </span>
              </a>
            </li>
          </ul>
        </li>
        <li className={`is-submenu-item is-dropdown-submenu-item`}>
          <a
            href=""
            className={`configuration`}
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <SupervisorAccountIcon
              style={{
                marginRight: "10px",
              }}
            />
            <span>Groups</span>
          </a>
        </li>
      </ul>
    );
  };
  return (
    <div id="top-row" className={`row ${themeColor==='dark'?"dark-theme-header-bgc dark-theme-bb1":""}`}>
      <div className="small-12 columns">
        <div className="row sunstone-menu-body sunstone-menu-header media-object">
          <Link
            to="/"
            className="logo-header menu small-8 medium-4 large-4 columns"
          >
            <img
              className="opennebula-img"
              src={themeColor==="dark"?"/assets/images/sqk-kokkos-code01-white.svg":"/assets/images/sqk-kokkos-code01.svg"}
            />
          </Link>
          <div className={`small-4 medium-8 large-8 columns`}>
            <div className={`user-zone-info right`}>
              <ul className={`dropdown menu`}>
                <li className={`is-dropdown-submenu-parent opens-left`}>
                  <a
                    href=""
                    className={`thin-font ${themeColor==='dark'?'dark-theme-tc':''}`}
                    id="userselector"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <PersonIcon
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    <span>sqkcloud</span>
                  </a>
                  {renderUserselector()}
                </li>
                <li className={`is-dropdown-submenu-parent opens-left`}>
                  <a
                    href=""
                    className={`thin-font ${themeColor==='dark'?'dark-theme-tc':''}`}
                    id="zonelector"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <LanguageIcon
                      style={{
                        marginRight: "10px",
                      }}
                    />
                    <span>CES2024</span>
                  </a>
                  <ul
                    className={`menu submenu is-dropdown-submenu first-sub vertical`}
                  >
                    <li className={`is-submenu-item is-dropdown-submenu-item`}>
                      <a
                        href=""
                        className={`configuration`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <CheckIcon
                          style={{
                            marginRight: "10px",
                            fontSize: "10px",
                          }}
                        />
                        <span>Project 1</span>
                      </a>
                    </li>
                    <li className={`is-submenu-item is-dropdown-submenu-item`}>
                      <a
                        href=""
                        className={`configuration`}
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <RadioButtonUncheckedIcon
                          style={{
                            marginRight: "10px",
                            fontSize: "10px",
                          }}
                        />
                        <span>Project 2</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
