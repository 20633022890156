import React, { useEffect, useState } from "react";
import * as Styled from "../benchmark.styled";

// import Excute Benchmark
import ExcuteBenchmark from "../../excuteBenchmark/Index";

const Index = () => {
  return (
    <>
      <Styled.SLayout>
        <Styled.STitleDiv>My Benchmarks</Styled.STitleDiv>
        <ExcuteBenchmark ware={"benchmark"} />
      </Styled.SLayout>
    </>
  );
};

export default Index;
