import axiosService from './axiosService';
import { baseUrl, serverUrl } from '../redux/contants/urlApi';


// list 

export const listInstrument = async () => {
    return axiosService.get(baseUrl + 'v1/instruments/');
}

// list driver

export const listInstrumentDriver = async () => {
    return axiosService.get(baseUrl + 'v1/instruments/drivers/');
}

// create instrument

export const createInstrument = async (data) => {
    let api = baseUrl+'v1/instruments/'
    return axiosService.post(api, data);
}

// detail instrument

export const detailInstrument = async (data) => {
    console.log('data in api', data);
    let api = baseUrl+'v1/instruments/'+ data?.id
    return axiosService.get(api);
}

// delete instrument

export const deleteInstrument = async (payload) => {
    console.log('Payload in api', payload);
    let instrument_uuid = payload?.instrument_uuid
    let url = baseUrl + `v1/instruments/${instrument_uuid}`
    return axiosService.delete(url)
}

// update
export const updateInstrument = (data) => {
    console.log('data in api', data);
    let api = baseUrl+'v1/instruments/' + data?.payload?.edit?.uuid
    return axiosService.put(api, data?.payload?.data);
}

