import UpdateConfFormTabs from './UpdateConfFormTabs'
import UpdateContentFormContent from './UpdateConfFormContent'


const Index = () => {
    const handleClickUpdateFormTab = (e) => {
        let id = e.target.getAttribute('aria-controls')
        let element = document.getElementsByClassName("tabs-conf-update");
        let tabs = document.getElementsByClassName('wizard_tab')
        for (var i = 0; i < element.length; i++) {
            element[i].classList.remove("is-active")
            tabs[i].classList.remove("is-active")
            if(id === element[i].childNodes[0].getAttribute('aria-controls')){
                element[i].classList.add("is-active")
                tabs[i].classList.add("is-active")
            }
        }
    }
    return <div className="wizardForms tabs-panel is-active" id="vms-tab-wizardForms" role="tabpanel" aria-hidden="false" aria-labelledby="vms-tab-wizardForms-label">
            <form data-abide="i3f7xp-abide" noValidate id="updateConfFormWizard" className="custom creation">
            <div>
                <UpdateConfFormTabs handleClickUpdateFormTab={handleClickUpdateFormTab}/>
                <UpdateContentFormContent />
            </div>
            </form>
        </div>
}

export default Index;