import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { common, blue } from "@mui/material/colors";

import Typography from "@mui/material/Typography";

function valueLabelFormat(value) {
  const units = ["KB", "MB", "GB", "TB"];

  let unitIndex = 0;
  let scaledValue = value;

  while (scaledValue >= 1024 && unitIndex < units.length - 1) {
    unitIndex += 1;
    scaledValue /= 1024;
  }

  return `${scaledValue} ${units[unitIndex]}`;
}

function calculateValue(value) {
  return 2 ** value;
}

// debounce
function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;

      func.apply(context, args);
    }, wait);
  };
}

export default function DiscreteSliderMarks({
  name,
  title,
  sliderInfo,
  sliderData,
  setSliderData,
}) {
  function valuetext(value) {
    return `${value} ${sliderInfo.unit}`;
  }

  const handleChange = (event, newValue) => {
    setSliderData(newValue);
  };

  return (
    <>
      <Box sx={{ width: 350 }}>
        {title && (
          <Typography
            sx={{
              color: common["white"],
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        )}
        <Slider
          name={name}
          aria-label="Custom marks"
          defaultValue={sliderData ? sliderData : 5}
          valueLabelFormat={valuetext}
          getAriaValueText={valuetext}
          valueLabelDisplay="on"
          // marks 정보가 있을 경우
          marks={sliderInfo?.marks}
          step={sliderInfo.step ? sliderInfo.step : 10}
          min={sliderInfo?.min}
          max={sliderInfo?.max}
          onChange={debounce(handleChange, 100)}
          sx={{
            color: blue[600],
            ".MuiSlider-markLabel": {
              color: common["white"],
            },
            ".MuiSlider-markLabelActive": {
              color: blue[600],
            },
          }}
        />
      </Box>
      {sliderInfo.unit && (
        <>
          <Box sx={{ width: 350 }}>
            <Typography
              sx={{
                color: common["white"],
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              {sliderData} {sliderInfo.unit}
            </Typography>
          </Box>
        </>
      )}
    </>
  );
}
