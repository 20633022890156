import Header from "../../layouts/Header/Index";
import Main from "./Main";
import useToken from "../../helpers/useToken";
import DefaultLayout from "../../layouts/DefaultLayout";

const Index = () => {
  // 임시 로그인 통과 처리
  // const { token } = useToken();
  // if (token) {
  //   window.location.href = "/login";
  // } else {
  return (
    <>
      {/* <Header /> */}
      <DefaultLayout title={"Benchmark"}>
        <div className="sunstone-list">
          <Main />
        </div>
      </DefaultLayout>
    </>
  );
  // }
};

export default Index;
