



const Index = () => {
    return <div id="ioTabone5" className="wizard_tab tabs-panel " role="tabpanel" aria-hidden="true" aria-labelledby="ioTabone5-label">
                <div className="row">
                    <div className="medium-6 columns graphics">
                    <fieldset>
                        <legend>Graphics</legend>
                        <div className>
                        <div className="large-12 columns text-center">
                            <input type="radio" wizard_field="TYPE" name="graphics_type" id="radioNoneTypeone6" defaultValue />
                            <label htmlFor="radioNoneTypeone6">None</label>
                            <input type="radio" wizard_field="TYPE" name="graphics_type" id="radioVncTypeone6" defaultValue="VNC" defaultChecked />
                            <label htmlFor="radioVncTypeone6">VNC</label>
                            <input type="radio" wizard_field="TYPE" name="graphics_type" id="radioSdlTypeone6" defaultValue="SDL" className="hypervisor only_kvm" />
                            <label className="hypervisor only_kvm" htmlFor="radioSdlTypeone6">SDL</label>
                            <input type="radio" wizard_field="TYPE" name="graphics_type" id="radioSpiceTypeone6" defaultValue="SPICE" className="hypervisor only_kvm" />
                            <label className="hypervisor only_kvm" htmlFor="radioSpiceTypeone6">SPICE</label>
                        </div>
                        </div>
                        <br />
                        <div className>
                        <div className="large-12 columns">
                            <label> Listen on IP <input className="graphics-setting" type="text" wizard_field="LISTEN" placeholder="0.0.0.0" />
                            </label>
                        </div>
                        </div>
                        <div className>
                        <div className="medium-6 columns">
                            <label> Keymap <input className="graphics-setting" type="text" wizard_field="KEYMAP" placeholder="en-us" />
                            </label>
                        </div>
                        </div>
                        <div className=" hypervisor only_kvm">
                        <div className="large-12 columns">
                            <label> Password <input className="graphics-setting" type="text" wizard_field="PASSWD" />
                            </label>
                        </div>
                        </div>
                    </fieldset>
                    </div>
                    <div className="medium-6 columns inputs hypervisor only_kvm">
                    <fieldset>
                        <legend>Inputs</legend>
                        <div className>
                        <div className="medium-5 columns">
                            <label> Type <select id="INPUT_TYPE" name="input_type">
                                <option value />
                                <option value="mouse">Mouse</option>
                                <option value="tablet">Tablet</option>
                            </select>
                            </label>
                        </div>
                        <div className="medium-4 columns">
                            <label> Bus <select id="INPUT_BUS" name="input_bus">
                                <option value />
                                <option value="usb">USB</option>
                                <option value="ps2">PS2</option>
                            </select>
                            </label>
                        </div>
                        <div className="medium-3 columns">
                            <label>&nbsp;</label>
                            <a href="#" className="button small radius secondary" id="add_input">Add</a>
                        </div>
                        </div>
                        <div className>
                        <div className="large-12 columns">
                            <table id="input_table" className="dataTable policies_table">
                            <tbody id="tbodyinput" />
                            </table>
                        </div>
                        </div>
                    </fieldset>
                    </div>
                    <div className="medium-12 columns hypervisor only_kvm" />
                </div>
                </div>
}


export default Index;