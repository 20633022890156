import React, { useLayoutEffect, useState } from "react";
import CustomButton from "../../components/Button/CustomButton";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import "./index.css";
import TextField from "../../components/TextField";
import CustomIcon from "../../components/Icon";

const mapper = {
  "d-wave": "https://www.dwavesys.com/media/j31olsrx/d-wave.svg",
  ibm: "/assets/images/ibm.png",
  ionq: "/assets/images/ionq.png",
  google: "/assets/images/구글.png",
  azure: "/assets/images/azure.png",
  aws: "/assets/images/aws.png",
};
const imageSize = {
  ionq: "130px",
  ibm: "130px",
  google: "140px",
  azure: "160px",
  "d-wave": "150px",
  aws: "80px",
};
const deafultImg = "/assets/quantums/4-qick-logo2.svg";

function Header({ changeInstrumentModel, setIsReset, deleteInstrumentModel }) {
  const { id } = useParams();
  const history = useHistory();
  const [imageSrc, setImageSrc] = useState(deafultImg);
  const [imageStyle, setImageStyle] = useState({});
  const [data, setData] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);

  useLayoutEffect(() => {
    const newImageSrc = mapper[id] ? mapper[id] : deafultImg;
    setImageSrc(newImageSrc);

    // 이미지가 로드되면 실행될 콜백 함수
    function onImageLoad() {
      setImageStyle({ width: imageSize[id] || "auto" }); // 이미지 크기 조정
    }

    // 이미지 로딩 상태 감지를 위해 이미지 객체 생성
    const img = new Image();
    img.onload = onImageLoad;
    img.src = newImageSrc;

    // 클린업 함수: 이미지 로드 리스너 제거
    return () => {
      img.onload = null;
    };
  }, [id]);

  // 헤더 이미지 깜빡거리는 현상으로 useLayoutEffect 사용 useEffect와 다르게 동기적으로 동작해서 렌더링전에 작동함

  const handleChangeEvt = (v) => {
    if (data?.["isInvalid_" + v.key]) {
      setData((prev) => ({ ...prev, ["isInvalid_" + v.key]: false }));
    }
    setData((prev) => ({ ...prev, [v.key]: v.value }));
  };

  function generateApiKey(length = 32) {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) =>
      ("0" + (byte & 0xff).toString(16)).slice(-2)
    ).join("");
  }

  const toggleTextField = () => {
    setIsDisabled(!isDisabled);
  };

  return (
    <div className="list-detail-header-layout">
      <div className="list-detail-title">
        <img
          style={imageStyle}
          src={imageSrc}
          alt="Quantum Provider"
        />
        {!mapper[id] && (
          <span className="image-title" style={{ color: "#EEEEEE" }}>
            {id}
          </span>
        )}
      </div>
      <div className="custom-btn-layout">
        <div className="btn-inner-layout">
          <CustomButton
            type="goback"
            onClick={() => {
              console.log("goback");
              history.push("/instrument-driver/list");
            }}
          />
          <CustomButton
            type="sync"
            onClick={() => {
              console.log("reset Btn");
              setIsReset(true);
            }}
          />
          <CustomButton
            type="custom"
            label={"SAVE"}
            onClick={() => {
              console.log("save Btn");
              changeInstrumentModel();
            }}
          />
          <CustomButton
            disabled="disabled"
            type="delete"
            onClick={() => {
              console.log("create Btn");
              deleteInstrumentModel();
            }}
          />
        </div>

        <div className="apikey-layout">
          <TextField
            id="apikey"
            label="apikey"
            handleChange={handleChangeEvt}
            error={data?.isInvalid_name}
            errMsg={"Please Input Data"}
            defaultValue={generateApiKey()}
            width={800}
            isDisabled={isDisabled}
          />
          <div
            className="station-info-page-title-container-update-btn"
            onClick={toggleTextField}
          >
            <CustomIcon type={"faEdit"} size="lg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
