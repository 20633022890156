export const sampleInfoData = (id) => {
    if(id==1){
        return [
            ["1","o1","ch1out","q[0].readout, q[1].readout"]
           ,["2","i1","ch1in","q[0].feedback,   q[1].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1","q[0].flux"]
           ,["6","o5","chf2","q[1].flux"]
        ]
    } else if(id==2){
        return [
            ["1","o1","ch1out","q[1].readout"]
           ,["2","i1","ch1in","q[0].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1",""]
           ,["6","o5","chf2",""]
        ]
    } else if(id==3){
        return [
            ["1","o1","ch1out","q[0].readout"]
           ,["2","i1","ch1in","q[0].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3",""]
           ,["5","o4","chf1",""]
           ,["6","o5","chf2","q[1].flux"]
        ]
    } else {
        return [
            ["1","o1","ch1out","q[0].readout, q[1].readout"]
           ,["2","i1","ch1in","q[0].feedback,   q[1].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1","q[0].flux"]
           ,["6","o5","chf2","q[1].flux"]
        ]
    }
}

export const sampleMeasurementInfoData = (id) => {
    if(id==1){
        return [
            ["1","o1","ch1out","q[0].readout"]
           ,["2","i1","ch1in","q[0].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3",""]
           ,["5","o4","chf1","q[0].flux"]
           ,["6","o5","chf2",""]
        ]
    } else if(id==2){
        return [
            ["1","o1","ch1out","q[1].readout"]
           ,["2","i1","ch1in","q[0].feedback"]
           ,["3","o2","ch2",""]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1","q[1].flux"]
           ,["6","o5","chf2",""]
        ]
    } else if(id==3){
        return [
            ["1","o1","ch1out","q[0].readout"]
           ,["2","i1","ch1in","q[0].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3",""]
           ,["5","o4","chf1",""]
           ,["6","o5","chf2","q[1].flux"]
        ]
    } else {
        return [
            ["1","o1","ch1out","q[1].readout"]
           ,["2","i1","ch1in","q[1].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1","q[0].flux"]
           ,["6","o5","chf2","q[1].flux"]
        ]
    }
}

export const sampleCircuitData = (id) => {
    if(id==1 || id==2){
        return {
            id: 1
            , name: 'QSVM'
            , qubitCnt: 2
            , qasmContens: `OPENQASM 2.0;
            include "qelib1.inc";
            qreg q[2];
            sx q[0];
            rx(3.578577310128818) q[1];
            rz(0.22866686078460366) q[0];
            h q[1];
            `
        }
    } else if(id==3){
      return {
        id: 1
        , name: 'ces2024-QSVM'
        , qubitCnt: 3
        , qasmContens: `OPENQASM 2.0;
    include "qelib1.inc";
    gate xx_minus_yy(param0,param1) q0,q1 { rz(-1.0*param1) q1; 
    rz(-pi/2) q0; sx q0; rz(pi/2) q0; s q1; cx q0,q1; ry(param0/2) q0; ry(-0.5*param0) q1; cx q0,q1; sdg q1; rz(-pi/2) q0; sxdg q0; rz(pi/2) q0; rz(param1) q1; }
    qreg q[3];
    s q[0];
    xx_minus_yy(2.249463366189866,0.882581011880542) q[2],q[1];
    u1(0.43674931390312954) q[2];
    ch q[0],q[1];
    rxx(4.746264656226055) q[2],q[0];
    tdg q[1];
        `
    }  
    } else {
        return [
            ["1","o1","ch1out","q[0].readout, q[1].readout"]
           ,["2","i1","ch1in","q[0].feedback,   q[1].feedback"]
           ,["3","o2","ch2","q[0].drive"]
           ,["4","o3","ch3","q[1].drive"]
           ,["5","o4","chf1","q[0].flux"]
           ,["6","o5","chf2","q[1].flux"]
        ]
    }
}

export const sampleResultData = (id) => {
    if(id==1){
        return 'Running.'
    }else if(id==2){
        return `0:"0",
        1:"1",
        2:"10",
        3:"11",
        4:"100",
        5:"101",
        6:"110",
        7:"111",
        8:"1000",
        9:"1001",
        10:"1010",
        11:"1011",
        12:"1100",
        13:"1101",
        14:"1110",
        15:"1111",
        16:"10000",
        17:"10001",
        18:"10010",
        19:"10011",
        20:"10100",
        21:"10101",
        22:"10110",
        23:"10111",
        24:"11000",
        25:"11001",
        26:"11010",
        27:"11011",
        28:"11100",
        29:"11101",
        30:"11110",
        31:"11111"
            `
    } else {
        return `0:"0",
        1:"1",
        2:"10",
        3:"11",
        4:"100",
        5:"101",
        6:"110",
        7:"111",
        8:"1000",
        9:"1001",
        10:"1010",
        11:"1011",
        12:"1100",
            `
    }

}


export const sampleMeasureResultData = (id) => {
    if(id==1){
        return 'Running.'
    }else if(id==2){
        return `Depth 1:
        1 2 3
        4 5 6
        7 8 9
        
        Depth 2:
        10 11 12
        13 14 15
        16 17 18
        
        Depth 3:
        19 20 21
        22 23 24
        25 26 27
        
        Depth 4:
        28 29 30
        31 32 33
        34 35 36
        
        Depth 5:
        37 38 39
        40 41 42
        43 44 45
        
        Depth 6:
        46 47 48
        49 50 51
        52 53 54
        
        Depth 7:
        55 56 57
        58 59 60
        61 62 63
        
        Depth 8:
        64 65 66
        67 68 69
        70 71 72
        
        Depth 9:
        73 74 75
        76 77 78
        79 80 81
            `
    } else {
        return `Depth 1:
        1 2 3
        4 5 6
        7 8 9
        
        Depth 2:
        10 11 12
        13 14 15
        16 17 18
        
        Depth 3:
        19 20 21
        22 23 24
        25 26 27
        
        Depth 4:
        28 29 30
        31 32 33
        34 35 36
        
        Depth 5:
        37 38 39
        40 41 42
        43 44 45
        
        Depth 6:
        46 47 48
        49 50 51
        52 53 54
            `
    }

}