import { useEffect, useState } from 'react'
import "./index.css";




const TabItem = ({ tabData }) => {
    const themeColor = process.env?.REACT_APP_THEME;
    const [selectedTab, setSelectedTab] = useState(null)

    const handleClickTab = (tabId) => {
        console.log("tabId :",tabId)
        setSelectedTab(tabId)
    }

    useEffect(()=>{
        if(!tabData)tabData=[]
        setSelectedTab(tabData[0]?.tabId) 
    },[])


    return <>
        <ul id="vms-tab-panelsTabs" className={`tabs sunstone-info-tabs text-center ${themeColor==='dark'?'custom-tab-ul-dark':'custom-tab-ul'}`} data-tabs="4s1dd6-tabs">
            {tabData.map((item, idx) => {
                return (
                    // <li className="tabs-title is-active" role="presentation" key={idx}>
                    <li className={selectedTab===item.tabId?"tabs-title is-active":"tabs-title"} role="presentation" key={idx} onClick={(e) => handleClickTab(item.tabId)}>
                        <a role="tab" aria-controls="vm_info_tab" aria-selected="false" id="vm_info_tab-label">
                            {item?.icon}
                            <br /> {item?.tabLabel} </a>
                    </li>
                )
            })}
        </ul>
        {selectedTab&&tabData.find(item=>item.tabId === selectedTab)?.contents}
    </>
}

export default TabItem;