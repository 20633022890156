import React, { useEffect, useState } from "react";
import * as Styled from "./excuteBenchmark.styled";

import ListTable from "../../../components/Table/ListTable";

import CustomButton from "../../../components/Button/CustomButton";

import Select from "../../../components/Select";

// useBenchmark hook
import useBenchmark from "../hooks/useBenchmark";

// import Code Dialog
import CodeDialog from "../dialog/CodeDialog";

import FieldGroup from "../../../components/FieldGroup";

// Excute dialog import
import ExcuteDialog from "../dialog/SaveExcuteDialog";

// Render Radar chart
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import "./index.css";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const Index = ({ ware }) => {
  const {
    benchmarkList,
    selectedBenchmark,
    setSelectedBenchmark,
    getInstrumentList,
    getBenchmarkList,
    qasmPop,
    setQasmPop,
    benchmarkResults,
    getBenchmarkResults,
    selectedBenchmarkResult,
    setSelectedBenchmarkResult,

    viewCode,

    getBenchmarkResultsDetail,
    selectedBenchmarkResultDetail,
  } = useBenchmark();

  // Device
  // ware = "benchmark" 는 벤치마크 페이지에서 사용
  // Instruments state
  const [instruments, setInstruments] = useState([]);
  // selected Instrument state
  const [selectedInstrument, setSelectedInstrument] = useState(null);

  // Excute dialog state
  const [excutePop, setExcutePop] = useState({ open: false });

  // Benchmark List 테이블 헤더 컬럼
  const createBenchmarkListColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "tempCircuit", label: "TempCircuit", hidden: true },
      { id: "benchmark", label: "Benchmark", minWidth: 150 },
      { id: "name", label: "Benchmark Name", minWidth: 200 },
      { id: "qubits", label: "Qubits", minWidth: 70 },
      { id: "compiler", label: "Compiler", minWidth: 100 },
      { id: "createdAt", label: "Created Time", minWidth: 100 },
      { id: "code", label: "Code", minWidth: 100 },
      { id: "instrument", hidden: true },
    ];
  };

  // Benchmark Results 테이블 헤더 컬럼
  const createBenchmarkResultListColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "name", label: "Result Name", minWidth: 70 },
      { id: "qubits", label: "Qubits", minWidth: 100 },

      { id: "simulator", label: "Simulator", minWidth: 150 },
      { id: "status", label: "Status", minWidth: 150 },

      { id: "createdAt", label: "Created Time", minWidth: 200 },
      { id: "finishedAt", label: "Finished Time", minWidth: 200 },
      { id: "features_chart", hidden: true },
      { id: "results_chart", hidden: true },
    ];
  };

  // Change Instrument
  const handleInstrument = (v) => {
    setSelectedInstrument(v.value);
  };

  // instruments 를 key와 label로 재구성 func
  function getInstrumentsSelectData(inputData) {
    let data = [];

    inputData.map((item) => {
      data.push({ key: item.name, label: item.name });
    });
    return data;
  }

  // Benchmark select
  const selectBenchmarkRows = (selectedRowData) => {
    setSelectedBenchmark(selectedRowData);

    if (ware == "benchmark") {
      getBenchmarkResults(
        selectedRowData.instrument,
        selectedRowData.id,
        selectedRowData.tempCircuit
      );
    } else {
      getBenchmarkResults(
        ware,
        selectedRowData.id,
        selectedRowData.tempCircuit
      );
    }

    deselectBenchmarkResultRows();
  };

  // Benchmark deselect
  const deselectBenchmarkRows = () => {
    setSelectedBenchmark(null);

    deselectBenchmarkResultRows();
  };

  // Benchmark Result select
  const selectBenchmarkResultRows = (selectedRowData) => {
    setSelectedBenchmarkResult(selectedRowData);
  };

  // Benchmark Result deselect
  const deselectBenchmarkResultRows = () => {
    setSelectedBenchmarkResult(null);
  };

  useEffect(() => {
    // my 벤치마크 리스트 호출
    getBenchmarkList();

    if (ware == "benchmark") {
      setInstruments(getInstrumentList());
    } else {
      setSelectedInstrument(ware);
    }
  }, []);

  // result detail
  useEffect(() => {
    if (selectedBenchmarkResult) {
      getBenchmarkResultsDetail(selectedBenchmarkResult.id);
    }
  }, [selectedBenchmarkResult]);

  const renderRadarChart = (chartDatas) => {
    console.log("chartDatas :", chartDatas);

    const chartData = {
      labels: chartDatas?.spoke_labels,
      datasets: [
        {
          label: chartDatas?.labels[0],
          data: chartDatas?.features[0],
          fill: true,
          backgroundColor: "rgba(54, 162, 235, 0.1)",
          borderColor: "rgba(54, 162, 235, 1)",
          pointBackgroundColor: "rgba(54, 162, 235, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(54, 162, 235, 1)",
        },
        {
          label: chartDatas?.labels[1],
          data: chartDatas?.features[1],
          fill: true,
          backgroundColor: "rgba(255, 206, 86, 0.1)",
          borderColor: "rgba(255, 206, 86, 1)",
          pointBackgroundColor: "rgba(255, 206, 86, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255, 206, 86, 1)",
        },
        {
          label: chartDatas?.labels[2],
          data: chartDatas?.features[2],
          fill: true,
          backgroundColor: "rgba(75, 192, 192, 0.1)",
          borderColor: "rgba(75, 192, 192, 1)",
          pointBackgroundColor: "rgba(75, 192, 192, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(75, 192, 192, 1)",
        },
        {
          label: chartDatas?.labels[3],
          data: chartDatas?.features[3],
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.1)",
          borderColor: "rgba(255, 99, 132, 1)",
          pointBackgroundColor: "rgba(255, 99, 132, 1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255, 99, 132, 1)",
        },
      ],
    };

    const options = {
      elements: {
        line: {
          borderWidth: 3,
        },
      },
      scales: {
        r: {
          beginAtZero: true,
          pointLabels: {
            font: {
              size: 17,
              weight: "bold",
            },
            color: "#EEEEEE",
          },
          ticks: {
            stepSize: 0.2,
          },
          grid: {
            circular: true,
            color: "#EEEEEE", // 이 부분에서 축의 선색을 설정합니다.
          },
          angleLines: {
            color: "#EEEEEE", // 각도 선의 색상 설정
          },
        },
      },
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
      },
    };

    return (
      <div style={{ width: "600px", position: "relative" }}>
        <div className="radar-chart-legend-container">
          <div>
            <div
              className="radar-chart-legend-line-guide"
              style={{ border: "1px solid rgba(54, 162, 235, 1)" }}
            />
            {chartDatas?.labels[0]}
          </div>
          <div>
            <div
              className="radar-chart-legend-line-guide"
              style={{ border: "1px solid rgba(255, 206, 86, 1)" }}
            />
            {chartDatas?.labels[1]}
          </div>
          <div>
            <div
              className="radar-chart-legend-line-guide"
              style={{ border: "1px solid rgba(75, 192, 192, 1)" }}
            />
            {chartDatas?.labels[2]}
          </div>
          <div>
            <div
              className="radar-chart-legend-line-guide"
              style={{ border: "1px solid rgba(255, 99, 132, 1)" }}
            />
            {chartDatas?.labels[3]}
          </div>
        </div>
        <Radar data={chartData} options={options} width={600} height={600} />
      </div>
    );
  };

  return (
    <>
      <Styled.SBenchExcuteDiv>
        {ware == "benchmark" && (
          <>
            <Styled.SBenchExcuteSelectDiv>
              <Select
                id="instruments"
                label="Simulators"
                data={getInstrumentsSelectData(instruments)}
                handleChange={handleInstrument}
              />
            </Styled.SBenchExcuteSelectDiv>
          </>
        )}

        <Styled.SBenchExcuteButtonDiv>
          <CustomButton
            label="Excute Benchmark"
            onClick={() => {
              setExcutePop((prev) => ({ ...prev, open: !excutePop?.open }));
            }}
            type={"custom"}
            disabled={selectedInstrument && selectedBenchmark ? false : true}
          />
        </Styled.SBenchExcuteButtonDiv>
      </Styled.SBenchExcuteDiv>

      <ListTable
        title={"Benchmark List"}
        list={benchmarkList}
        cols={createBenchmarkListColumns()}
        selectCallback={selectBenchmarkRows}
        deselectCallback={deselectBenchmarkRows}
        height={200}
      />

      {selectedBenchmark && (
        <ListTable
          title={"Benchmark Results"}
          list={benchmarkResults}
          cols={createBenchmarkResultListColumns()}
          selectCallback={selectBenchmarkResultRows}
          deselectCallback={deselectBenchmarkResultRows}
          height={200}
        />
      )}

      {selectedBenchmarkResult && (
        <>
          <FieldGroup
            label={"Result Detail"}
            foldable={true}
            defaultFold={false}
          >
            {selectedBenchmarkResultDetail?.status != "Done" ? (
              <>
                <div
                  style={{
                    fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                    fontSize: "12pt",
                    fontWeight: "bold",
                    marginLeft: "1rem",
                    marginBottom: "1rem",
                    color: "lightgray",
                  }}
                >
                  {selectedBenchmarkResultDetail?.status}

                  <div
                    style={{
                      fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                      fontSize: "14pt",
                      fontWeight: "bold",
                      marginTop: "3.5rem",
                      marginBottom: "1rem",
                      color: "white",
                    }}
                  >
                    {selectedBenchmarkResultDetail?.error_message}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div>
                  <div
                    style={{
                      fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                      fontSize: "14pt",
                      fontWeight: "bold",
                      marginBottom: "1rem",
                      color: "white",
                    }}
                  >
                    Benchmark Performance
                  </div>
                  <img
                    // src={"/assets/images/samples/chartSample2.webp"}
                    src={`data:image/png;base64,${selectedBenchmarkResultDetail?.results_chart}`}
                    width={500}
                  />

                  {/* {renderRadarChart(selectedBenchmarkResultDetail?.results)} */}

                  <div
                    style={{
                      fontFamily: '"Helvetica Neue", Roboto, Arial, sans-serif',
                      fontSize: "14pt",
                      fontWeight: "bold",
                      marginTop: "3.5rem",
                      marginBottom: "1rem",
                      color: "white",
                    }}
                  >
                    Measure the correlation between device performance and
                    application features
                  </div>
                  <img
                    // src="/assets/images/samples/chartSample1.webp"
                    src={`data:image/png;base64,${selectedBenchmarkResultDetail?.features_chart}`}
                    width={500}
                  />
                </div>
              </>
            )}
          </FieldGroup>
        </>
      )}

      <ExcuteDialog
        title="Excute Benchmark"
        type="excute"
        open={excutePop?.open}
        onClose={async (rslt) => {
          if (rslt) {
            // let tempData = {
            //   benchmark: selectedBenchmark?.benchmark,
            //   qubit: selectedBenchmark?.qubits,
            //   compiler: selectedBenchmark?.compiler,
            //   name: selectedBenchmark?.name,
            //   instrument: selectedInstrument,
            // };

            // // getBenchmarkResults
            // let res = await axiosService.post(
            //   baseUrl +
            //     "v1/benchmarks/cq_sqale_simulator/features?circuit_name=" +
            //     selectedBenchmark?.tempCircuit,
            //   { title: "test", description: "test" }
            // );
            getBenchmarkResults(
              ware,
              selectedBenchmark.id,
              selectedBenchmark.tempCircuit
            );
          }
          setExcutePop((prev) => ({ ...prev, open: !excutePop?.open }));
        }}
        data={{
          benchmark: selectedBenchmark?.benchmark,
          qubit: selectedBenchmark?.qubits,
          compiler: selectedBenchmark?.compiler,
          name: selectedBenchmark?.name,
          instrument: selectedInstrument,
          uuid: selectedBenchmark?.id,
        }}
      />

      <CodeDialog
        open={qasmPop?.open}
        onClose={() => {
          setQasmPop((prev) => ({ ...prev, open: !qasmPop?.open }));
        }}
        data={viewCode}
      />
    </>
  );
};

export default Index;
