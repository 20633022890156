import { useParams } from "react-router";
import CircuitSelect from "./CircuitSelect";
import ConnectPort from "./ConnectPort";

import { useState, useEffect } from "react";
import axiosService from "../../../../../api/axiosService";
import { baseUrl } from "../../../../../redux/contants/urlApi";

const Index = () => {
  // page step state
  const [pageStep, setPageStep] = useState(null);

  // circuit info State
  const [circuitInfo, setCircuitInfo] = useState(null);

  const [updateFlag, setUpdateFlag] = useState(false);
  const {id} = useParams();

  // page reset
  useEffect(() => {
    handleFetch();
    setCircuitInfo(null);
  }, []);


  const handleFetch = async () => {
    try {
      let instrumentRes = await axiosService.get(baseUrl+"v1/instruments/"+id+'/details');
      console.log("instrumentRes :",instrumentRes)

      if(instrumentRes?.status == 200) {
        if(instrumentRes?.data?.circuit_id){
          let circuitListRes = await axiosService.get(baseUrl+"v1/circuits/")
          if(circuitListRes?.status==200){
            let circuitList = circuitListRes?.data
            let instrumnetCircuitInfo = circuitList?.find(item=>item?.id == instrumentRes?.data?.circuit_id)
            let convertedCircuitInfo = {
              id : instrumnetCircuitInfo?.id
              ,name: instrumnetCircuitInfo?.name
              ,description: instrumnetCircuitInfo?.description
              ,qubits: instrumnetCircuitInfo?.qubit_count
            }

            setCircuitInfo(convertedCircuitInfo)
          }
          setPageStep(2)
        } else {
          setPageStep(1)
        }
      }else {
        setPageStep(1)
      }

    } catch (e) {
      console.log(e);
    }
  }

  return (
    <>
      <div
      >
        {/* Step 1 */}
        {pageStep === 1 && (
          <CircuitSelect
            circuitInfo={circuitInfo}
            setCircuitInfo={setCircuitInfo}
            setPageStep={setPageStep}
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
          />
        )}
        {/* Step 2 */}
        {pageStep === 2 && (
          <ConnectPort
            circuitInfo={circuitInfo}
            setCircuitInfo={setCircuitInfo}
            setPageStep={setPageStep}
            updateFlag={updateFlag}
            setUpdateFlag={setUpdateFlag}
          />
        )}
      </div>
    </>
  );
};

export default Index;
