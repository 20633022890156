import styled, { css } from "styled-components";

export const SLayoutDiv = styled.section`
  margin-top: 1rem;
`;

export const SQubitContainerDiv = styled.div`
  background-color: #233044;
  border-radius: 5px;
`

export const SQubitDivTitle = styled.div`
  font-family: '"Helvetica Neue", Roboto, Arial, sans-serif';
  font-size: 14pt;
  font-weight: bold;
  margin-left: 1rem;
  margin-bottom: 1rem;
  color: white;
`;

export const SQubitRowTitleDiv = styled.div`
  display: grid;
  font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 1.077rem !important;
  padding-top: 1rem !important;
  font-weight: bold;
  padding-left: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  color:white;
  grid-template-columns: 0.53fr 0.65fr 1fr 1fr;
  border-bottom: 1px solid rgba(81,81,81);
`;

export const SQubitRowDiv = styled.div`
  display: grid;
  grid-template-columns: 0.53fr 0.65fr 1fr 1fr;
  font-family: "Helvetica Neue", Roboto, Arial, sans-serif;
  font-size: 1.077rem !important;
  /* font-weight: bold; */
  padding-left: 1rem;
  color:white;
  border-bottom: 1px solid rgba(81,81,81);
  align-items: center;
  background-color: ${props => props.active!=''?'#2A4267':'transparent'};
`;

export const SQubitButtonDiv = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
`;

export const SQubitButton = styled.button`
  margin-top:0.5rem;
  margin-bottom:0.5rem;
`
