import CircuitSelect from "./CircuitSelect";
import ConnectPort from "./ConnectPort";

import { useState, useEffect } from "react";

const Index = () => {
  // page step state
  const [pageStep, setPageStep] = useState(1);

  // circuit info State
  const [circuitInfo, setCircuitInfo] = useState(null);

  // page reset
  useEffect(() => {
    setPageStep(1);
    setCircuitInfo(null);
  }, []);

  return (
    <>
      <div
      >
        {/* Step 1 */}
        {pageStep === 1 && (
          <CircuitSelect
            circuitInfo={circuitInfo}
            setCircuitInfo={setCircuitInfo}
            setPageStep={setPageStep}
          />
        )}
        {/* Step 2 */}
        {pageStep === 2 && (
          <ConnectPort
            circuitInfo={circuitInfo}
            setCircuitInfo={setCircuitInfo}
            setPageStep={setPageStep}
          />
        )}
      </div>
    </>
  );
};

export default Index;
