import "./Information.css";
import CustomIcon from "../../../../../components/Icon";
import FieldGroup from "../../../../../components/FieldGroup";
import TextField from "../../../../../components/TextField";
import CustomButton from "../../../../../components/Button/CustomButton";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import {
  renderInfoSkeleton,
  renderPermissionSkeleton,
  renderOwnershipSkeleton,
  renderAttributesSkeleton,
} from "./InformationRenderer";
import getImagePath from "../../util/getImagePath";
import EditModal from "../../../../../components/Modal/Modal";

const Information = ({
  mapper,
  onChange,
  editName,
  isEdit,
  setIsEdit,
  modelElement,
  id
}) => {

  const themeColor = process.env?.REACT_APP_THEME;
  const [loading, setLoading] = useState(true);
  const driverImage = modelElement?.data&& getImagePath(modelElement?.data, mapper);


  useEffect(() => {
    if (modelElement) {
      setLoading(false);
    }
  }, [modelElement]);

  const renderInfoDiv = (key, value, icon, edit) => {
    const modalRenderer = () => {
      return (
        <>
          <TextField
            id={key}
            label={key}
            handleChange={onChange}
            value={value}
          />
          <div className="list-detail-btn-div">
            <CustomButton
              type="custom"
              label={"SAVE"}
              onClick={() => editName(modelElement?.data,id,setIsEdit)}
            />
          </div>
        </>
      );
    };
    return (
      <div
        className={`driver-info-page-left-grid-container ${
          themeColor === "dark" ? "custom-theme-dark" : ""
        }`}
      >
        <div>{key}</div>
        <div>{value}</div>
        <div
          className={`driver-info-page-left-grid-icon`}
          onClick={() => {
            setIsEdit(true);
          }}
        >
          {icon}
        </div>
        {edit && (
          <EditModal
            title="Edit Name"
            open={isEdit}
            handleClose={() => {
              setIsEdit(false);
            }}
            renderer={modalRenderer}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div className="driver-info-page-container">
        <div className={`driver-info-page-contents-container`}>
          <div>
            {/* Information 영역 */}
            <FieldGroup>
              <div
                className={`driver-info-page-title-container ${
                  themeColor === "dark" ? "custom-theme-dark" : ""
                }`}
              >
                Information
              </div>

              {/* Information Skeleton */}
              {loading && renderInfoSkeleton()}

              {/* 임시 곧 삭제 대상 */}
              {!loading && (
                <>
                  {renderInfoDiv("ID", "0", null, false)}
                  {renderInfoDiv(
                    "name",
                    modelElement?.data?.name,
                    <CustomIcon type={"faEdit"} />,
                    true
                  )}
                  {renderInfoDiv("LCM State", "LCM_INIT", null, false)}
                  {renderInfoDiv("Qubit count", "20", null, false)}
                  {renderInfoDiv("1Q Fidelity", "median 99.91%", null, false)}
                  {renderInfoDiv("2Q Fidelity", "median 98.25%", null, false)}
                  {renderInfoDiv("Quantum Volume ", "16", null, false)}
                  {renderInfoDiv("Q-score", "8", null, false)}
                  {renderInfoDiv(
                    "Largest GHZ genuinely entangled state",
                    "9",
                    null,
                    false
                  )}
                  {renderInfoDiv("Started at", "10:39:32 30/12/2023", null)}
                </>
              )}
            </FieldGroup>
          </div>

          {/* Device Info 영역 */}
          <div>
            <FieldGroup>
              <div
                className={`driver-info-page-title-container ${
                  themeColor === "dark" ? "custom-theme-dark" : ""
                }`}
              >
                <br />
              </div>

              <div
                className={`driver-info-page-right-grid-container header ${
                  themeColor === "dark" ? "custom-theme-dark" : ""
                }`}
              >
                <div className={``}>Device</div>
                <div className={``}>Firmware</div>
                <div className={``}>Software</div>
              </div>

              {/* Device Info Skeleton */}
              {loading && renderPermissionSkeleton()}

              {/* Device Info Setting */}
              {!loading && (
                <>
                  <div
                    className={`driver-info-page-right-grid-container ${
                      themeColor === "dark" ? "custom-theme-dark" : ""
                    }`}
                  >
                    <div>{modelElement?.data?.type}</div>
                    <div>Latest (Feb 2024)</div>
                    <div>{modelElement?.data?.type}</div>
                  </div>
                </>
              )}
            </FieldGroup>

            {/* Image 영역 */}
            <FieldGroup>
              {/* Image Skeleton */}
              {loading && renderOwnershipSkeleton()}

              {/* Image Setting */}
              {!loading && (
                <>
                  <div
                    className={`driver-info-page-right-image-container ${
                      themeColor === "dark" ? "custom-theme-dark" : ""
                    }`}
                  >
                    <div
                      style={{
                        backgroundColor: "",
                        borderRadius: "8px",
                        width: "60%",
                        display: "flex",
                        justifyContent: "center",
                        padding: "1rem",
                      }}
                    >
                      <img
                        src={driverImage?.imageUrl}
                        style={{
                          width: driverImage?.imageSize
                            ? driverImage?.imageSize
                            : "30rem",
                          height: driverImage?.imageHeight
                            ? driverImage?.imageHeight
                            : "",
                          maxWidth: driverImage?.maxWidth
                            ? driverImage?.maxWidth
                            : "",
                        }}
                      ></img>
                    </div>
                  </div>
                </>
              )}
            </FieldGroup>
          </div>
        </div>
        {modelElement.instrument.length > 0 && (
          <div style={{ width: "100%" }}>
            <FieldGroup foldable={true} label={"Channel Conf"}>
              {loading && renderOwnershipSkeleton()}
              {!loading && (
                <>
                  <div
                    className={`station-info-page-chnl-text-container ${
                      themeColor === "dark" ? "custom-theme-dark" : ""
                    }`}
                  >
                    <div className={`attr-text-item-header`}>Port No</div>
                    <div className={`attr-text-item-header`}>Port Name</div>
                    <div className={`attr-text-item-header`}>Channel Name</div>
                    <div className={`attr-text-item-header`}>Connected</div>
                  </div>
                  {modelElement?.instrument.map((item)=>{
                    return(
                      <div
                      className={`station-info-page-chnl-text-container ${
                        themeColor === "dark" ? "custom-theme-dark" : ""
                      }`}
                    >
                      <div className={`attr-text-item-no`}>{item?.port_no}</div>
                      <div className={`attr-text-item`}>{item?.port_name}</div>
                      <div className={`attr-text-item`}>{item?.channel_name}</div>
                      <div className={`attr-text-item`}>
                        {item?.connected}
                      </div>
                      {/* 추후 relation check하고 로직 추가예정 */}
                    </div>
                    )
                  })}
                </>
              )}
            </FieldGroup>
          </div>
        )}
      </div>
    </>
  );
};

export default Information;
