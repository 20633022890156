import React, { useRef } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import YamlEditor from "@focus-reactive/react-yaml";
import { oneDark } from "@codemirror/theme-one-dark";

const QASMDialog = (props) => {
  const { onClose, open, data } = props;
  const configRef = useRef();

  const handleClose = () => {
    onClose();
  };

  const tmpCodeData = `OPENQASM 2.0;
  include "qelib1.inc";
  
  qreg q[5];
  creg m0[1];  
  
  cx q[0],q[2];
  
  h q[0];
  z q[1];
  
  z q[2];
  
  rz(pi*1.0) q[1];
  rx(pi*0.25) q[3];
  ry(pi*3.1415926536) q[4];
  
  rz(pi*3.1415926536) q[3];
  ry(pi*1.0) q[4];
  
  ch q[2],q[1];
  cx q[2],q[3];
  
  measure q[2] -> m477[0];`;

  return (
    <Dialog onClose={handleClose} open={open} className="qasm-pop-container">
      <DialogTitle className="qasm-pop-title-2">QASM Code</DialogTitle>
      <div className="qasm-pop-contents-2">
        <YamlEditor
          text={data != "" ? data : tmpCodeData}
          ref={configRef}
          theme={oneDark}
        />
      </div>
    </Dialog>
  );
};

export default QASMDialog;
