import ListTable from '../../../components/Table/ListTable.jsx'


function TableArea({columns, list, setRowEvt,defaultTableValue}) {


  return (
    <div  >
      {/* Table */}
      <div className="table-layout">
      <ListTable cols={columns} list={list} selectCallback={setRowEvt} defaultValue={defaultTableValue}  />
      </div>
  

    </div>
  )
}

export default TableArea
