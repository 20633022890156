import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import rootSaga from "./sagas";
import authSaga from "./sagas/auth";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";

import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware } from "redux";
import { ContentProvider } from "./context/ContentContext";
import { ToastContainer } from "react-toastify";

const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga, authSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContentProvider>
        <ToastContainer/>
        <App />
      </ContentProvider>
    </Provider>
  </React.StrictMode >,
  document.getElementById("root")
);
