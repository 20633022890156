import CircularProgress from "@material-ui/core/CircularProgress";
import '../Detail.css'




const Storage = () => {
    return <>
    <div id="vm_storage_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_storage_tab-label">
    <form id="tab_storage_form" vmid={0}>
      <div className="row">
        <div className="large-12 columns">
          <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-foundation no-footer"><table className="disks_table no-hover info_table dataTable no-footer" id="DataTables_Table_0" role="grid" aria-describedby="DataTables_Table_0_info">
              <thead>
                <tr role="row"><th aria-label className="open-control sorting_disabled" rowSpan={1} colSpan={1} /><th aria-label="ID: activate to sort column descending" className="sorting_asc" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1} aria-sort="ascending">ID</th><th aria-label="Target: activate to sort column ascending" className="sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}>Target</th><th aria-label="Image / Size-Format" className="sorting_disabled" rowSpan={1} colSpan={1}>Image / Size-Format</th><th aria-label="Size: activate to sort column ascending" className="sorting" tabIndex={0} aria-controls="DataTables_Table_0" rowSpan={1} colSpan={1}>Size</th><th aria-label="Persistent" className="sorting_disabled" rowSpan={1} colSpan={1}>Persistent</th><th aria-label="Actions
        
          
            Attach disk
          
        
      " className="sorting_disabled" rowSpan={1} colSpan={1}>Actions
                    <span className="right">
                      <button id="attach_disk" className="button small success right radius">
                        Attach disk
                      </button>
                    </span>
                  </th></tr>
              </thead>
              <tbody>
                <tr role="row" className="odd" disk_id={0}><td className />
                <td className="sorting_1">0</td><td>hda</td><td>Context</td><td>-/-</td><td>NO</td><td /></tr></tbody>
            </table><div className="row"><div className="small-6 columns"><div className="dataTables_length" id="DataTables_Table_0_length"><label><select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className><option value={10}>10</option><option value={25}>25</option><option value={50}>50</option><option value={100}>100</option></select></label></div><div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Showing 1 to 1 of 1 entries</div></div><div className="small-6 columns"><div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate"><ul className="pagination"><li className="paginate_button previous unavailable disabled" aria-controls="DataTables_Table_0" tabIndex={0} id="DataTables_Table_0_previous">Previous</li><li className="paginate_button current" aria-controls="DataTables_Table_0" tabIndex={0}>1</li><li className="paginate_button next unavailable disabled" aria-controls="DataTables_Table_0" tabIndex={0} id="DataTables_Table_0_next">Next</li></ul></div></div></div></div>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <table>
            <tbody>
              <tr>
                <td className="key_td">Cost / MByte</td>
                <td className="value_td">0</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </form>
    <div className="row">              <div className="medium-6 columns">                <div className="row">                  <span>Disk read bytes              </span></div>                <div className="row">                  <div className="large-12 columns centered graph" id="vm_st_drb_graph" style={{height: 100}}>                    <span id="provision_dashboard_total" style={{fontSize: 80}}>                      
        <CircularProgress className="loading-w40"/>
    </span>                </div>              </div>                <div className="row graph_legend">                  <div className="large-12 columns centered" id="vm_net_rx_legend">                </div>              </div>            </div>              <div className="medium-6 columns">                <div className="row">                  <span>Disk write bytes              </span></div>                <div className="row">                  <div className="large-12 columns centered graph" id="vm_st_dwb_graph" style={{height: 100}}>                    <span id="provision_dashboard_total" style={{fontSize: 80}}>                      
        <CircularProgress className="loading-w40"/>                 
    </span>                </div>              </div>                <div className="row graph_legend">                  <div className="large-12 columns centered" id="vm_net_tx_legend">                </div>              </div>            </div>              <div className="medium-6 columns">                <div className="row">                  <span>Disk read IOPS              </span></div>                <div className="row">                  <div className="large-12 columns centered graph" id="vm_st_drio_graph" style={{height: 100}}>                    <span id="provision_dashboard_total" style={{fontSize: 80}}>                      
        <CircularProgress className="loading-w40"/>
    </span>                </div>              </div>                <div className="row graph_legend">                  <div className="large-12 columns centered" id="vm_net_rx_speed_legend">                </div>              </div>            </div>              <div className="medium-6 columns">                <div className="row">                  <span>Disk write IOPS              </span></div>                <div className="row">                  <div className="large-12 columns centered graph" id="vm_st_dwio_graph" style={{height: 100}}>                    <span id="provision_dashboard_total" style={{fontSize: 80}}>                      
        <CircularProgress className="loading-w40"/>
    </span>                </div>              </div>                <div className="row graph_legend">                  <div className="large-12 columns centered" id="vm_net_tx_speed_legend">                </div>              </div>            </div>        </div>        
  </div>
    </>
}


export default Storage;