import { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import CheckableTable from "../../../components/Table/SelectTable";
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";

// api
import { listMeasurement, deleteMeasurement } from "../../../api/measurement";

import { tError, tSuccess, tWarnning } from "../../../common/CommonToast";

const Index = () => {
  const [data, setData] = useState({});
  const history = useHistory();

  useEffect(() => {
    // 1. List 목록 받아오기.
    fetchDataList();
  }, []);

  const fetchDataList = async () => {
    try {
      const response = await listMeasurement();
      const data = response.data;
      const tempData = [];
      data.map((item, index) => {
        const measurement = {
          id: 0,
          name: "",
          description: "",
          link: "",
          uuid: "",
          connected: "ok",
        };
        measurement.id = index + 1;
        measurement.name = item.name;
        measurement.description = item.description;
        measurement.link = `${item.uuid}`;
        measurement.uuid = item.uuid;
        measurement.connected =
          item.station_id !== null ? "connected" : "disconnected";
        tempData.push(measurement);
      });
      setData((prev) => ({ ...prev, list: tempData }));
    } catch (err) {
      console.log("err", err);
    }
    // setData((prev) => ({ ...prev, list: tempData }));
  };

  const createListColumns = () => {
    return [
      { id: "id", label: "ID", minWidth: 100, hidden: true },
      { id: "name", label: "Name", minWidth: 70 },
      { id: "description", label: "Description", minWidth: 200 },
      { id: "uuid", label: "uuid", minWidth: 100, hidden: true },
      { id: "connected", label: "Connected", minWidth: 100 },
    ];
  };

  // Select Event
  const selectRows = (selectedRowData) => {
    setData((prev) => ({ ...prev, selectedRowData: selectedRowData }));
  };

  // Delete Event
  const deleteData = async () => {
    const deleteTargetList = data?.selectedRowData;

    // 삭제할 대상이 없을 경우
    if (deleteTargetList?.length == 0) {
      tWarnning("Please select the item you want to delete.");
      return;
    }

    // list 삭제
    let resultDelete = false;

    // for...of 반복문 사용
    for (const item of deleteTargetList) {
      try {
        const res = await deleteMeasurement(item.uuid);
        if (res?.data?.status_code == 200 || res?.data?.status_code == 201) {
          resultDelete = true;
        }
      } catch (error) {
        console.error("Error deleting item: ", item, error);
      }
    }

    if (resultDelete) {
      tSuccess("Success delete");

      // list 재조회
      fetchDataList();
    } else {
      tError("Failed");
    }
  };

  return (
    <>
      <DefaultLayout title="Measurement Controller">
        <div className="sunstone-list">
          <CustomButton
            type="iconPlus"
            onClick={() => {
              history.push("/measurement-controller/create");
            }}
          />
          <CustomButton
            type="sync"
            onClick={() => {
              fetchDataList();
            }}
          />
          <CustomButton
            type="delete"
            onClick={() => {
              deleteData();
            }}
          />
          <CheckableTable
            cols={createListColumns()}
            callback={selectRows}
            list={data?.list}
            height={200}
            type={"link"}
          />
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
