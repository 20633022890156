import React, { useEffect, useRef, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import ListTable from "../../../components/Table/ListTable";

import FieldGroup from "../../../components/FieldGroup";
import TextField from "../../../components/TextField";
import Select from "../../../components/Select";
import CustomButton from "../../../components/Button/CustomButton";
import "./index.css";
import { useHistory } from "react-router";
import axiosService from "../../../api/axiosService";
import { baseUrl } from "../../../redux/contants/urlApi";
import { tError, tSuccess, tWarnning } from "../../../common/CommonToast";
import {
  chkIpFormat,
  isNullorEmpty,
  dataToJson,
  detectDataType,
  jsonToYaml,
} from "../../../common/CommonUtil";
import AlertDialog from "../../../components/Dialog";

// code editor
import CodeEditor from "../../../components/CodeEditor/Index";

const Index = () => {
  const [data, setData] = useState({
    configData:`Measurement:
    # Each measurement is specified by its name.
    # This name is what is looked up by the 'load_measurement'
    # method of 'Station'.
    # Simulated measurements can also be specified here, just put
    # the path to the simulation .yaml file as the value of the
    # "init"->"visalib" field (see below for an example of the
    # "init" section as well as an example of specifying
    # a simulated measurement).
    qdac:
      # Full import path to the python class of the measurement
      # driver
      type: qcodes.measurement_drivers.QDev.QDevQDac
      # Visa address of the measurement.
      # Note that this field can also be specified in the
      # "init" section (see below) but the address specified
      # here will overrule the address from the "init" section.
      # Essentially, specifying address here allows avoiding
      # the "init" section completely when address is the only
      # necessary argument that the measurement driver needs.
      # For obvious reasons, this field is required for VISA
      # measurements.
      address: ASRL4::INSTR
      # If an measurement with this name is already instantiated,
      # and this field is true, then the existing measurement
      # instance will be closed before instantiating this new one.
      # If this field is false, or left out, closing will not
      # happen.
      enable_forced_reconnect: true
      #
      # The "init" section specifies constant arguments that are
      # to be passed to the __init__ function of the measurement.
      # Note that it is the measurement's driver class that defines
      # the allowed arguments, for example, here "update_currents"
      # is an argument that is specific to "QDac" driver.
      init:
        terminator: \n
        update_currents: false
      #
      # Setting up properties of parameters that already exist on
      # the measurement.
      parameters:
        # Each parameter is specified by its name from the
        # measurement driver class.
        # Note that "dot: notation can be used to specify
        # parameters in (sub)channels and submodules.
        ch01.v:
          # If an alias is specified, the parameter becomes  
          # accessible under another name, so that you can write
          # 'qdac.cutter_gate(0.2)' instead of 'qdac.ch01.v(0.2)'.
          # Note that the parameter instance does not get copied,
          # so that '(qdac.ch01.v is qdac.cutter_gate) == True'.
          alias: cutter_gate
          # Set new label.
          label: Cutter Gate Voltage
          # Set new unit.
          unit: mV
          # Set new scale.
          scale: 0.001
          # Set new post_delay.
          post_delay: 0
          # Set new inter_delay.
          inter_delay: 0.01
          # Set new step.
          step: 1e-4
          # If this field is given, and contains an array of two
          # numbers like here, then the parameter
          # gets a new 'Numbers' validator with these values as
          # lower and upper limits, respectively (in this case, it
          # is 'Numbers(-0.1, 0.1)').
          limits: [-0.1, 0.1]
          # Set the parameter to this given initial value upon
          # measurement initialization.
          # Note that if the current value on the physical
          # measurement is different, the parameter will be ramped
          # with the delays and step specified in this file.
          initial_value: 0.01
          # In case this values equals to true, upon loading this
          # measurement from this configuration this parameter will
          # be appended to the list of parameters that are
          # displayed in QCoDeS 'Monitor'.
          monitor: true
        # As in all YAML files a one-line notation can also be
        # used, here is an example.
        ch02.v: {scale: 0.01, limits: [0.0, 1.5e+3] , label: my label}
        ch04.v: {alias: Q1lplg1, monitor: true}
      #
      # This section allows to add new parameters to the
      # measurement instance which are based on existing parameters
      # of the measurement. This functionality is based on the use
      # of the 'DelegateParameter' class.
      add_parameters:
        # For example, here we define a parameter that represents
        # magnetic field control. Setting and getting this
        # parameter will actually set/get a specific DAC channel.
        # So this new magnetic field parameter is playing a role
        # of a convenient proxy - it is much more convenient to
        # perform a measurement where "Bx" is changed in tesla as
        # opposed to where some channel of some DAC is changed in
        # volts and one has to clutter the measurement code with
        # the mess of conversion factors and more.
        # Every new parameter definition starts with a name of
        # the new parameter.
        Bx:
          # This field specifies the parameter which "getter" and
          # "setter" will be used when calling 'get'/'set' on this
          # new parameter.
          # Required field.
          source: ch02.v
          # Set the label. Otherwise, the one of the source parameter
          # will be used.
          label: Magnetic Field X-Component
          # Set the unit. Otherwise, the one of the source parameter
          # will be used.
          unit: T
          # Other fields have the same purpose and behavior as for
          # the entries in the 'add_parameter' section.
          scale: 65.243
          inter_delay: 0.001
          post_delay: 0.05
          step: 0.001
          limits: [0.0, 3.0]
          initial_value: 0.0
          # For the sake of example, we decided not to monitor this
          # parameter in QCoDeS 'Monitor'.
          #monitor: true
    #
    # More example measurements, just for the sake of example.
    # Note that configuring simulated measurements also works,
    # see the use of 'visalib' argument field below
    dmm1:
      type: qcodes.measurement_drivers.agilent.Agilent34401A
      enable_forced_reconnect: true
      address: GPIB::1::65535::INSTR
      init:
        visalib: 'Agilent_34400A.yaml@sim'
      parameters:
        volt: {monitor: true}
    mock_dac:
      type: qcodes.measurement_drivers.mock_measurements.Dummymeasurement
      enable_forced_reconnect: true
      init:
        # To pass an list of items use {}.
        gates: {"ch1", "ch2"}
      add_parameters:
        Bx: {source: ch1, label: Bx, unit: T,
             scale: 28, limits: [-1, 1], monitor: true}
    mock_dac2:
      type: qcodes.measurement_drivers.mock_measurements.Dummymeasurement
      enable_forced_reconnect: true`,
  });
  const history = useHistory();

  const handleChangeEvt = (v) => {
    if (data?.["isInvalid_" + v.key]) {
      setData((prev) => ({ ...prev, ["isInvalid_" + v.key]: false }));
    }
    setData((prev) => ({ ...prev, [v.key]: v.value }));
  };

  const handleSubmit = async () => {
    const api = baseUrl + "v1/measurements/";
    const body = {
      name: data.name,
      description: data.description,
      settings: dataToJson(data.configData),
    };
    try {
      const response = await axiosService.post(api, body);
      if (response.status === 201) {
        tSuccess("Created!!");
        history.push("/measurement-controller/list");
      } else {
        tError("Faild");
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const validationCheck = () => {
    const param = {
      name: data?.name,
      description: data?.description,
    };

    if (!param?.name) setData((prev) => ({ ...prev, isInvalid_name: true }));
    if (!param?.description)
      setData((prev) => ({ ...prev, isInvalid_description: true }));

    // config data validation check
    if (detectDataType(dataToJson(data?.configData)) != "JSON Object") {
      tWarnning("Invalid Config format");
      return false;
    }
    return !param?.name || !param?.description ? false : true;
  };

  const handlePopupResult = () => {
    const isVaild = validationCheck();
    if (isVaild) {
      handleSubmit();
    } else {
      tError("Faild");
    }
  };

  const renderSavePop = () => {
    const runPopContents = {
      title: "Confirm",
      contents: (
        <>
          <div className="create-station-pop-container">
            <div className="crete-station-pop-grid-container">
              <div>Name :</div>
              <div>{data?.name}</div>
              <div>Description :</div>
              <div>{data?.description}</div>
            </div>
            <div className="create-statoion-pop-confirm-msg">
              Is the information correct?
            </div>
          </div>
        </>
      ),
      agreeBtnLabel: "OK",
      disagreeBtnLabel: "Cancel",
    };

    return (
      <AlertDialog
        type="custom"
        label="Save"
        id="submit"
        disabled={true ? false : true}
        callback={handlePopupResult}
        contents={runPopContents}
      />
    );
  };
  return (
    <DefaultLayout title={<div>Create Measurement Controller</div>}>
      <div className="sunstone-list">
        <CustomButton
          type="goback"
          onClick={() => {
            history.push("/measurement-controller/list");
          }}
        />

        <FieldGroup label="Information">
          <TextField
            id="name"
            label="Name"
            handleChange={handleChangeEvt}
            error={data?.isInvalid_name}
            errMsg={"Please Input Data"}
          />
          <TextField
            id="description"
            label="Description"
            handleChange={handleChangeEvt}
            error={data?.isInvalid_description}
            errMsg={"Please Input Data"}
          />
        </FieldGroup>

        <FieldGroup label="Config" foldable={true}>
          <CodeEditor
            value={jsonToYaml(data?.configData)}
            onChange={(e) => {
              setData((prev) => ({ ...prev, configData: e }));
            }}
          />
        </FieldGroup>

        <div className="row-btn-container">
          <div className="end-item">{renderSavePop()}</div>
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Index;
