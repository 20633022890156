import FieldGroup from "../FieldGroup";
import "./index.css"
import { Skeleton } from "@mui/material";

const SkeletonPage = ({title}) => {

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    return(
        <>
            <div className='skeleton-template-page-container'>
                <div className='skeleton-template-page-title'>
                    {title}
                </div>
                <div className='skeleton-template-page-grid-container'>
                    <div className="skeleton-template-page-left-container">
                        <FieldGroup>
                            <div className="skeleton-template-page-sub-title">
                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '100px', height: '3rem' }} />
                            </div>
                            <div className="skeleton-template-page-left-grid-container">
                                {[...Array(21)].map((_, index) => (
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: getRandomInt(10, 95) + '%', height: '2.5rem' }} />
                                ))}
                            </div>
                        </FieldGroup>
                    </div>


                    <div className="skeleton-template-page-right-container">
                        <FieldGroup>
                            <div className="skeleton-template-page-sub-title">
                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '100px', height: '3rem' }} />
                            </div>
                            <div className="skeleton-template-page-right-grid-container">
                                {[...Array(6)].map((_, index) => (
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: getRandomInt(10, 95) + '%', height: '2.5rem' }} />
                                ))}
                            </div>
                        </FieldGroup>

                        <FieldGroup>
                            <div className="skeleton-template-page-sub-title">
                                <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '100px', height: '3rem' }} />
                            </div>
                            <div className="skeleton-template-page-right-grid-container">
                                {[...Array(6)].map((_, index) => (
                                    <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: getRandomInt(10, 95) + '%', height: '2.5rem' }} />
                                ))}
                            </div>
                        </FieldGroup>
                    </div>
                </div>
                <FieldGroup>
                    <div className="skeleton-template-page-sub-title">
                        <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: '100px', height: '3rem' }} />
                    </div>
                    <div className="skeleton-template-page-right-grid-container">
                        {[...Array(8)].map((_, index) => (
                            <Skeleton animation="wave" variant="text" sx={{ fontSize: '1rem', width: getRandomInt(10, 95) + '%', height: '2.5rem' }} />
                        ))}
                    </div>
                </FieldGroup>
            </div>
        </>
    )
}

export default SkeletonPage;