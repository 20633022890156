import axiosService from "../api/axiosService"
import { baseUrl } from "../redux/contants/urlApi"
import yaml from 'js-yaml'

export const isNullorEmpty = (param) => {
    const type = typeof(param)
    switch(type){
        case "object": 
            if(param instanceof Array){
                if(param.length>0){
                    return false
                } else {
                    return true
                }
            } else {
                if(param == null || Object.keys(param).length < 1){
                    return true
                }else {
                    return false
                }
            }
        case "undefined": 
            return true
        case "string": 
            if(param==""||param.trim() == ""){
                return true
            } else {
                return false
            }
        case "number":
            console.log("This is number :",param); 
            return false
    }

    console.log("type : ",type)

    return 
}


export const chkIpFormat = (param) => {
    const regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return regex.test(param);
}

export const convertDateFormat = (param) => {
    let dateObject = new Date(param);
    
    // 날짜와 시간 추출
    let year = dateObject.getFullYear();
    let month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
    let day = ('0' + dateObject.getDate()).slice(-2);
    let hours = ('0' + dateObject.getHours()).slice(-2);
    let minutes = ('0' + dateObject.getMinutes()).slice(-2);
    let seconds = ('0' + dateObject.getSeconds()).slice(-2);

    // YYYY-MM-DD HH:MM:SS 형식으로 조합
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}



export const getChannelConf = async (instrument_id) => {
    try {

        // Instrument 상세정보 조회
        let instrumentRes = await axiosService.get(baseUrl+"v1/instruments/"+instrument_id+'/details');
        if( instrumentRes?.status == 200 ) {
            let instrumentData = instrumentRes?.data
            let instrumentDriver = instrumentData?.instrument_driver
            let circuit_id = instrumentData?.circuit_id

            if(instrumentDriver?.uuid) {
                
                // Instrument Driver의 채널 선번장 조회
                let instrumentDriverChannelConfRes = await axiosService.get(baseUrl + "v1/instrument-drivers/"+instrumentDriver?.uuid+"/channels")
                
                if ( instrumentDriverChannelConfRes?.status == 200 ){
                    let instrumentDriverChannelConfData = instrumentDriverChannelConfRes?.data
                    instrumentDriverChannelConfData = instrumentDriverChannelConfData?.sort((a,b)=>(parseInt(a.port_no)>parseInt(b.port_no)? 1: -1 ));

                    if(circuit_id) {
                        // 기존 연결성 정보 조회
                        let instrumentRelationRes = await axiosService.get(baseUrl+"v1/instruments/"+instrument_id+"/channel-relations")
                        let circuit_name = instrumentData?.circuit?.name
                        
                        if( instrumentRelationRes?.status == 200 ) {
                            let instrumentRelationData = instrumentRelationRes?.data

                            // 기존 연결성 선번장에 매핑.
                            instrumentDriverChannelConfData?.map((base)=>{
                                if(!base.hasOwnProperty("connected_qubits")){
                                    base.connected_qubits = ""
                                }
                                let readout = instrumentRelationData?.find(relation=>relation?.conn_qubit_readout == base?.channel_name);
                                let feedback = instrumentRelationData?.find(relation=>relation?.conn_qubit_feedback == base?.channel_name);
                                let drive = instrumentRelationData?.find(relation=>relation?.conn_qubit_drive == base?.channel_name);
                                let flux = instrumentRelationData?.find(relation=>relation?.conn_qubit_flux == base?.channel_name);

                                if(readout){
                                    base.connected_qubits += base?.connected_qubits ? ', q['+readout.conn_qubit_no+'].readout': '['+circuit_name+'] ---- q['+readout.conn_qubit_no+'].readout'
                                }
                                if(feedback){
                                    base.connected_qubits += base?.connected_qubits ? ', q['+feedback.conn_qubit_no+'].feedback': '['+circuit_name+'] ---- q['+feedback.conn_qubit_no+'].feedback'
                                }
                                if(drive){
                                    base.connected_qubits += base?.connected_qubits ? ', q['+drive.conn_qubit_no+'].drive': '['+circuit_name+'] ---- q['+drive.conn_qubit_no+'].drive'
                                }
                                if(flux){
                                    base.connected_qubits += base?.connected_qubits ? ', q['+flux.conn_qubit_no+'].flux': '['+circuit_name+'] ---- q['+flux.conn_qubit_no+'].flux'
                                }
                            })

                            return {status:'S', data:instrumentDriverChannelConfData}
                        }
                    } else {
                        return {status:'S', data:instrumentDriverChannelConfData}
                    }
                }
            }

        } else {
            return {status: "E", data: null}    
        }

    } catch (e) {
        console.log(e)
        return {status: "E", data: null}
    }
}


export const cookieParser = () => {
    const cookieString = document.cookie;
    const cookies = {};
    const cookiePairs = cookieString.split(';');  // 세미콜론으로 쿠키 쌍을 분리

    cookiePairs.forEach(pair => {
        const [key, value] = pair.split('=');  // 등호로 쿠키의 키와 값을 분리
        cookies[key.trim()] = decodeURIComponent(value);  // 쿠키의 값을 디코딩하여 저장
    });

    return cookies;
}


export const detectDataType = (input) => {
    // Helper function to check if a string is valid JSON
    const isJSONString = (str) => {
      try {
        JSON.parse(str);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    // Helper function to check if a string is valid YAML
    const isYAMLString = (str) => {
      try {
        yaml.load(str);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    // Check if input is a JSON object
    if (typeof input === 'object' && !Array.isArray(input) && input !== null) {
      return 'JSON Object';
    }
  
    // Check if input is a JSON string
    if (typeof input === 'string' && isJSONString(input)) {
      return 'JSON String';
    }
  
    // Check if input is a YAML object
    if (typeof input === 'object' && !Array.isArray(input) && input !== null) {
      return 'YAML Object';
    }
  
    // Check if input is a YAML string
    if (typeof input === 'string' && isYAMLString(input)) {
      return 'YAML String';
    }
  
    // Check if input is a string but not valid JSON or YAML
    if (typeof input === 'string') {
      return 'Other String';
    }
  
    // If input doesn't match any of the above types
    return 'Other Object';
  }


export const jsonToYaml = (str) => {
    let type = detectDataType(str)
    
    switch(type){
        case "JSON Object": return yaml.dump(str);
        case "JSON String": return yaml.dump(JSON.parse(str));
        case "YAML Object": return yaml.dump(str)
        case "YAML String": return str
        case "Other String": return str
        case "Other Object": return "ERROR"
    }
}

export const dataToJson = (data) => {
    let type = detectDataType(data)
    
    if(type == "JSON Object") {
        return data
    } else if (type == "JSON String") {
        return JSON.parse(data)
    } else if (type == "YAML Object") {
        return yaml.load(data)
    } else if (type == "YAML String") {
        return yaml.load(data)
    } else {
        return "Invalid data format";
    }

}
  