import * as Styled from "../connectPort.styled.js";
import { useState, useEffect } from "react";

import ListTable from "../../../../../../components/Table/ListTable.jsx";

// ConnectButtonDiv Component
import ConnectButtonDiv from "../../../../../../components/Button/ConnectButtonDiv.js";

const QubitList = (props) => {
  // Qubit Table
  const qubitColumns = [
    { id: "id", label: "ID", minWidth: 10, hidden: true },
    { id: "qubitNo", label: "Qubit No", minWidth: 10, hidden: false },
    { id: "type", label: "Type", minWidth: 20 },
    { id: "asignedChannel", label: "Asigned Channel", minWidth: 20 },
    {
      id: "action",
      label: "Actions",
      minWidth: 20,
    },
  ];

  // Qubit Set Template create
  const [qubitListState, setQubitListState] = useState(null);

  // 버튼을 통한 qubit list 변경을 위한 임시 변수
  let tempTables = [];

  // Qubit Set Template create
  const createQubitSetTemplate = (prevQubitList) => {
    // 테이블 생성을 위한 초기 qubit list
    const tempQubitList = [];

    for (let i = 0; i < props.QUBITCNT; i++) {
      tempQubitList.push([
        {
          id: 1,
          qubitNo: "q" + i,
          type: "readout",
          asignedChannel:
            prevQubitList !== undefined && prevQubitList !== null
              ? prevQubitList[i]?.filter((item) => {
                  return (
                    item.type === "readout" &&
                    item.asignedChannel !== "" &&
                    item.asignedChannel !== undefined
                  );
                })[0]?.asignedChannel
              : "",
          action: (
            <ConnectButtonDiv
              nIndex={i}
              type={"readout"}
              connectEvt={connectEvt}
              disconnectEvt={disconnectEvt}
              selectedPortYn={props.selectedPortYn}
            />
          ),
        },
        {
          id: 2,
          qubitNo: "q" + i,
          type: "feedback",
          asignedChannel:
            prevQubitList !== undefined && prevQubitList !== null
              ? prevQubitList[i]?.filter((item) => {
                  return item.type === "feedback" && item.asignedChannel !== "";
                })[0]?.asignedChannel
              : "",
          action: (
            <ConnectButtonDiv
              nIndex={i}
              type={"feedback"}
              connectEvt={connectEvt}
              disconnectEvt={disconnectEvt}
              selectedPortYn={props.selectedPortYn}
            />
          ),
        },
        {
          id: 3,
          qubitNo: "q" + i,
          type: "drive",
          asignedChannel:
            prevQubitList !== undefined && prevQubitList !== null
              ? prevQubitList[i]?.filter((item) => {
                  return item.type === "drive" && item.asignedChannel !== "";
                })[0]?.asignedChannel
              : "",
          action: (
            <ConnectButtonDiv
              nIndex={i}
              type={"drive"}
              connectEvt={connectEvt}
              disconnectEvt={disconnectEvt}
              selectedPortYn={props.selectedPortYn}
            />
          ),
        },
        {
          id: 4,
          qubitNo: "q" + i,
          type: "flux",
          asignedChannel:
            prevQubitList !== undefined && prevQubitList !== null
              ? prevQubitList[i]?.filter((item) => {
                  return item.type === "flux" && item.asignedChannel !== "";
                })[0]?.asignedChannel
              : "",
          action: (
            <ConnectButtonDiv
              nIndex={i}
              type={"flux"}
              connectEvt={connectEvt}
              disconnectEvt={disconnectEvt}
              selectedPortYn={props.selectedPortYn}
            />
          ),
        },
      ]);
    }

    setQubitListState(tempQubitList);
    tempTables = tempQubitList;
  };

  // qubbit list connect/disconnect function
  const handleConnectEvt = (index, type, actions) => {
    // 선택한 port 정보 qubit list에 적용

    // 선택한 port 가 없으면 return
    if (props.selectedPort === null) {
      return;
    }

    // Qubit list가 없으면 return
    if (tempTables.length === 0) {
      return;
    }

    // 중복 이벤트 방지
    let returnYn = false;
    tempTables?.forEach((qubitTable, idx) => {
      qubitTable.forEach((qubit) => {
        if (idx === index) {
          if (qubit.type === type) {
            if (actions === "connect" && qubit.asignedChannel !== "") {
              returnYn = true;
              return;
            }
            if (actions === "disconnect" && qubit.asignedChannel === "") {
              returnYn = true;
              return;
            }
          }
        }
      });
    });

    if (returnYn) {
      return;
    }

    const tempQubitTables = [];

    tempTables?.forEach((qubitTable, idx) => {
      const tempQubitTable = [];

      if (idx === index) {
        qubitTable.forEach((qubit, i) => {
          tempQubitTable.push(qubit);

          if (qubit.type === type) {
            tempQubitTable[i].asignedChannel =
              actions === "connect" ? props.selectedPort?.channelName : "";
          }
        });
      } else {
        tempQubitTable.push(...qubitTable);
      }
      tempQubitTables.push(tempQubitTable);
    });

    // 선택한 port 정보의 connected qubits count 증가/감소
    const tempPort = props.selectedPort;
    tempPort.qubitsCnt =
      actions === "connect" ? tempPort.qubitsCnt + 1 : tempPort.qubitsCnt - 1;
    props.setTempConnectedQubitsCnt(tempPort.qubitsCnt);
    props.setSelectedPort(tempPort);

    // Qubit List create
    setQubitListState(tempQubitTables);
    tempTables = tempQubitTables;
  };

  const connectEvt = (index, type) => {
    // Connect Port
    handleConnectEvt(index, type, "connect");
  };

  const disconnectEvt = (index, type) => {
    // Disconnect Port
    handleConnectEvt(index, type, "disconnect");
  };

  useEffect(() => {
    // Qubit List 생성
    createQubitSetTemplate();
  }, [props.selectedPort]);

  return (
    <>
      {qubitListState?.map((item, index) => {
        return (
          <div className="row">
            <div className="sunstone-list large-12">
              <ListTable
                cols={qubitColumns}
                list={item}
                title={`Qubit ${index} List`}
                noPagination={true}
                height={300}
                maxHeight={300}
                disabledClick={true}
                key={index}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default QubitList;
