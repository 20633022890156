import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import styled from "styled-components";

import { common, blue } from "@mui/material/colors";

import Typography from "@mui/material/Typography";

const RadioGroupComponent = ({
  name,
  title,
  data,
  selectedData,
  setSelectedData,
  labelSpace,
  defaultValue,
}) => {
  const themeColor = process.env?.REACT_APP_THEME;

  const handleChange = (event) => {
    setSelectedData(event.target.value);
  };

  return (
    <>
      <FormControl>
        {title && (
          <Typography
            sx={{
              color: common["white"],
              fontSize: "1.5rem",
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        )}
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={name}
          defaultValue={defaultValue ? defaultValue : null}
          onChange={handleChange}
          sx={{
            flexDirection: "row",
            gap: "10px",
          }}
        >
          {data.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={
                <Radio
                  sx={{
                    color: common["white"],
                    "&.Mui-checked": {
                      color: blue[600],
                    },
                  }}
                />
              }
              label={item.name}
              sx={{
                color: common["white"],
                padding: "5px",
                width: labelSpace ? labelSpace : "auto",
                textAlign: "left",
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default RadioGroupComponent;
