import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import "./index.css"


const BootstrapInput = styled(InputBase)(({ theme }) => ({
    // '.MuiInputLabel-outlined': {
    //     backgroundColor: 'white',
    //     paddingLeft: '0.3rem',
    //     paddingRight: '0.3rem',
    //     marginLeft: '-0.3rem',
    //     fontSize: '20pt',
    // },
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 13,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: 'var(--TextField-brandBorderFocusedColor)',
        boxShadow: '0 0 0 0.1rem rgba(0,0,0,.25)',
      },
    }
  }));


export default function BasicSelect({id, label, data, error, handleChange, defaultValue, ref}) {
    const themeColor = process.env?.REACT_APP_THEME;
    if (!id) throw "This component requires id"
    return (
        <FormControl fullWidth error size='small' className="custom-mui-selectbox-form">
            <InputLabel id="demo-simple-select-label" className={`custom-mui-label ${themeColor==='dark'?'custom-mui-label-dark-theme':''} ${error?"custom-mui-select-label-error":""}`}>{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                label={label}
                defaultValue={defaultValue?defaultValue:''}
                onChange={($event)=>{handleChange({key:id, value:$event.target.value})}}
                className={`${error?"custom-mui-select-error":""}`}
                ref={ref}
                input={<BootstrapInput id={id} className={`custom-mui-select-input ${themeColor==='dark'?"custom-mui-selectbox-dark":""}`}/>}
            >
                <MenuItem key="default" value={"null"} disabled>선택</MenuItem>
                {data && data.map((item,idx)=>{
                    return(
                        <MenuItem key={idx} value={item.key}>{item.label}</MenuItem>
                    )
                })}
            </Select>
            {error&&<span className='custum-mui-helper-message'>Please Input Data</span>}
        </FormControl>
    );
}