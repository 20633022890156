import React from 'react';
import Header from '../../../layouts/Header/Index'
import Sidebar from '../../../layouts/Sidebar/Index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faList,
  faLock,
  faPause,
  faPlay,
  faPowerOff,
  faRedo,
  faSave,
  faSync,
  faTags,
  faThList,
  faTrashAlt,
  faUser
} from '@fortawesome/free-solid-svg-icons'
import ButtonAction from '../../../components/Button/ButtonAction'
import '../List/Index.css'
import ButtonDropdown from '../../../components/Button/ButtonDropdown'
import TabItem from './TabItem'
import TabContent from './TabContent';
import { Link, useParams } from 'react-router-dom';
import { quantumSystems } from '../../../constants';

const Index = () => {
  const { id } = useParams();
  const quantum = quantumSystems.filter(quantum => Number(quantum.id) === Number(id));

  const handleClickTab = async (e) => {
    if (!e.target.childNodes.length) {
      e.preventDefault();
    } else {
      let id = e.target.getAttribute('aria-controls')
      let element = document.getElementsByClassName("tabs-panel");
      let tabs = document.getElementsByClassName('tabs-title')
      for (var i = 0; i < element.length; i++) {
        element[i].classList.remove("is-active")
        tabs[i].classList.remove("is-active")
        if (id === element[i].id) {
          element[i].classList.add("is-active")
          tabs[i].classList.add("is-active")
        }
      }
    }
  }

  return <>
    <Header />
    <div className="row" style={{ display: "flex" }}>
      <Sidebar />
      <div className='small-12 columns sunstone-content large-10' style={{ flex: "1" }}>
        <div className="sunstone-header"></div>
        <div id='dashboard-tab' className='tab'>
          <div className="row header-row">
            <div className="large-12 columns">
              <h5 className="header-title only-sunstone-list" style={{display:'flex',alignItems:'center'}}>
                <img src={quantum[0]?.image} width={quantum[0]?.width} style={{marginTop:'1rem'}}/>
                {quantum[0]?.name==='QICK'?<><span className="header-resource" style={{marginLeft: '0.4rem', marginTop:'0.9rem', fontSize:'19pt', fontWeight: 'normal'}}>{quantum[0].name}</span></>:<></>}
                {quantum[0]?.name==='Dummy Instrument'?<><span className="header-resource" style={{marginLeft: '0.4rem', marginTop:'0.9rem', fontSize:'19pt', fontWeight: 'normal'}}>{quantum[0].name}</span></>:<></>}
              </h5>
              <h5 className="header-title only-sunstone-info" hidden="" style={{ display: 'none' }}>
                <span className="header-resource">

                  <small className="resource-id"></small>
                  <small className="resource-info-header"></small>
                  <small className="resource-info-header-small"></small>
                </span>
              </h5>
              <h5 className="header-title only-sunstone-form" hidden="" style={{ display: 'none' }}>
                <span className="sunstone-form-title"></span>
                <small className="sunstone-form-id"></small>
                <small className="sunstone-form-info-header"></small>
                <small className="sunstone-form-info-header-small"></small>
              </h5>
            </div>
          </div>
          <div className="actions_row" style={{ display: "flex", flexDirection: "column", gap: "30px" }}>
            <div className="medium-12 columns action_blocks large-9">
              <span className='mgr-10'>
                <span id="users-tabback_button" className=" only-sunstone-info only-sunstone-form" style={{ display: 'none' }}>
                  <button className="button radius" href="back">
                    <i className="fas fa-arrow-left" />
                    <i className="fas fa-list" />
                  </button>
                </span>
                <span id="users-tabform_buttons" className="only-sunstone-form" hidden style={{ display: 'none' }}>
                  <span id="users-tabreset_button">
                    <button className="button secondary radius reset_button" href="submit">Reset</button>
                  </span>
                  <span id="users-tabsubmit_button">
                    <button className="button success radius submit_button" href="submit">Create</button>
                  </span>
                </span>

                <span id="users-tabback_button" className=" only-sunstone-info only-sunstone-form" style={{ display: 'inline' }}>
                  <Link className="button radius" to="/instrument/list">
                    <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: '5px' }} />
                    <FontAwesomeIcon icon={faList} />
                  </Link>
                </span>

                <ButtonAction
                  id={`users-tabrefresh_buttons`}
                  cls={`secondary`}
                  act={() => window.location.reload}
                  icon={<FontAwesomeIcon icon={faSync} />}
                />

              </span>

              <span className='mgr-10'>
                <ButtonAction
                  id={`btn_save`}
                  cls={`action_button top_button state-dependent button vm-action-disabled`}
                  act={() => alert('clicked btn save')}
                  icon={<FontAwesomeIcon icon={faSave} />}
                />

                <ButtonAction
                  id={`btn_save`}
                  cls={`action_button top_button state-dependent button vm-action-disabled`}
                  act={() => alert('clicked btn save')}
                  icon={<FontAwesomeIcon icon={faPlay} />}
                />

                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faLock} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />

                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faPause} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />

                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faPowerOff} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />

                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faRedo} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />

                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faThList} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />
              </span>
              <span>
                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faUser} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />
                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faTags} />}
                  cls={`undefined_button top_button only-sunstone-info only-sunstone-list top_button secondary button dropdown vm-action-disabled`}
                />
                <ButtonDropdown
                  icon={<FontAwesomeIcon icon={faTrashAlt} />}
                  cls={`only-sunstone-info only-sunstone-list top_button  button alert dropdown vm-action-disabled`}
                />
              </span>
            </div>
          </div>

          <div className="sunstone-list" style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <TabItem handleClickTab={handleClickTab} />
            <TabContent />
          </div>
        </div>
      </div>
    </div>
  </>
}


export default Index;