import { useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout"
import CheckableTable from "../../../components/Table/SelectTable"
import CustomButton from "../../../components/Button/CustomButton"
import { useHistory } from "react-router";
import { baseUrl } from "../../../redux/contants/urlApi";
import axiosService from "../../../api/axiosService";

const Index = () => {
    const [data, setData] = useState({});
    const history = useHistory();

    useEffect(() => {
        // 1. List 목록 받아오기.
        fetchDataList();

    }, [])

    const fetchDataList = async () => {
        try {
            let res = await axiosService.get(baseUrl+"v1/instruments/");
            if(res?.status == 200) {
                let tempData = res?.data
                let instrumentsList = tempData.map(item=>({
                    name: item.name,
                    description: item.description,
                    address: item.address,
                    port:item.port,
                    instrumentType: item?.instrument_driver?.type,
                    link: "/instrument-controller/"+item?.uuid

                }))
                setData((prev) => ({ ...prev, list: instrumentsList }))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const createListColumns = () => {
        return (
            [
                { id: 'id', label: 'ID', minWidth: 100, hidden: true },
                { id: 'name', label: 'Name', minWidth: 70 },
                { id: 'description', label: 'Description', minWidth: 200 },
                { id: 'address', label: 'Address', minWidth: 100 },
                { id: 'port', label: 'Port', minWidth: 100 },
                {
                    id: 'Type',
                    label: 'Insruments Type',
                    minWidth: 170,
                    align: 'left',
                    format: (value) => value.toLocaleString('en-US'),
                },
                // { id: 'manufacturer', label: 'Manufacturer', minWidth: 100 },
            ]
        )
    }

    // Select Event
    const selectRows = (selectedRowData) => {
        setData((prev)=>({...prev, selectedRowData: selectedRowData}))
    }

    // Delete Event
    const deleteData = () => {
        const deleteTargetList = data?.selectedRowData
        console.log("deleteTargetList : ",deleteTargetList)
    }

    return <>
        <DefaultLayout title="Instrument Controller">
            <div className="sunstone-list">
                <CustomButton type="iconPlus" onClick={() => { history.push("/instrument-controller/create") }} />
                <CustomButton type="sync" onClick={() => { fetchDataList() }} />
                <CustomButton type="delete" onClick={() => { deleteData() }} />
                <CheckableTable cols={createListColumns()} callback={selectRows} list={data?.list} height={200} type={"link"} />
            </div>
        </DefaultLayout>
    </>
}

export default Index;