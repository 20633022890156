import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCalendarAlt,
    faCog,
    faFile,
    faFileAlt,
    faGlobe,
    faInfoCircle,
    faLaptop,
    faServer,
    faSitemap
} from '@fortawesome/free-solid-svg-icons'




const TabItem = ({ handleClickTab }) => {
    return <>
        <ul id="vms-tab-panelsTabs" className="tabs sunstone-info-tabs text-center" data-tabs="4s1dd6-tabs">
            <li className="tabs-title is-active" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_info_tab" aria-selected="true" id="vm_info_tab-label">
                    <FontAwesomeIcon icon={faInfoCircle} style={{ pointerEvents: 'none' }} />
                    <br /> Info </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_capacity_tab" aria-selected="false" id="vm_capacity_tab-label">
                    <FontAwesomeIcon icon={faLaptop} style={{ pointerEvents: 'none' }} />
                    <br /> Capacity </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_storage_tab" aria-selected="false" id="vm_storage_tab-label">
                    <FontAwesomeIcon icon={faServer} style={{ pointerEvents: 'none' }} />
                    <br /> Storage </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_network_tab" aria-selected="false" id="vm_network_tab-label">

                    <FontAwesomeIcon icon={faGlobe} style={{ pointerEvents: 'none' }} />
                    <br /> Network </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_snapshot_tab" aria-selected="false" id="vm_snapshot_tab-label">
                    <FontAwesomeIcon icon={faLaptop} style={{ pointerEvents: 'none' }} />
                    <br /> Snapshots </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_placement_tab" aria-selected="false" id="vm_placement_tab-label">
                    <FontAwesomeIcon icon={faSitemap} style={{ pointerEvents: 'none' }} />

                    <br /> Placement </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_actions_tab" aria-selected="false" id="vm_actions_tab-label">
                    <FontAwesomeIcon icon={faCalendarAlt} style={{ pointerEvents: 'none' }} />
                    <br /> Actions </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_conf_tab" aria-selected="false" id="vm_conf_tab-label">
                    <FontAwesomeIcon icon={faCog} style={{ pointerEvents: 'none' }} />
                    <br /> Conf </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_template_tab" aria-selected="false" id="vm_template_tab-label">

                    <FontAwesomeIcon icon={faFile} style={{ pointerEvents: 'none' }} />
                    <br /> Template </a>
            </li>
            <li className="tabs-title" role="presentation">
                <a onClick={(e) => handleClickTab(e)} role="tab" aria-controls="vm_log_tab" aria-selected="false" id="vm_log_tab-label">

                    <FontAwesomeIcon icon={faFileAlt} style={{ pointerEvents: 'none' }} />
                    <br /> Log </a>
            </li>
        </ul>

    </>
}

export default TabItem;