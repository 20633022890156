import React, { useState } from "react";
import Header from "../../../layouts/Header/Index";
import Sidebar from "../../../layouts/Sidebar/Index";
import { listUser } from "../../../redux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Statistical from "../../../components/Statistical/Index";
import ListTable from "../../../components/Table/ListTable.jsx";
import TableAreaHeader from "../../Platform/List/TableAreaHeader";
import DefaultLayout from "../../../layouts/DefaultLayout";

const columns = [
  { id: "id", label: "ID", minWidth: 100, hidden: true },
  { id: "name", label: "Name", minWidth: 70 },
  { id: "description", label: "Block", minWidth: 100 },
  {
    id: "instrumentsType",
    label: "Time Created",
    minWidth: 170,
    align: "left",
  },
];

const list = [
  {
    id: 1,
    name: "admin",
    description: "False",
    instrumentsType: "2024-01-30 14:51:08",
  },
];

const Index = () => {
  const dispath = useDispatch([]);
  const createState = useSelector((state) => state?.authReducer?.auth);
  const themeColor = process.env?.REACT_APP_THEME;
  const [data, setData] = useState({});
  const [checked, setChecked] = React.useState(false);

  useEffect(() => {
    dispath(listUser());
    if (createState?.data?.message?.trim().length) {
      toast.success(createState?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [createState]);
  const setRowEvt = (rowData) => {
    console.log(rowData);
  };
  return (
   
      <DefaultLayout title={"Users"}>
        <TableAreaHeader checked={checked} setData={setData} />
        <div style={{padding:"0 2rem"}}>
        <ListTable
          cols={columns}
          list={list}
          selectCallback={setRowEvt}
          title={"Nutrition"}
        />
        </div>

        <Statistical total={1} active={0} off={0} pendding={1} failded={0} />
      </DefaultLayout>
  );
};

export default Index;
