import React from 'react'
import "./ArchitectureCard.css"

function ArchitectureCard({imgSrc}) {
  const themeColor = process.env.REACT_APP_THEME
  return (
    <div className={`list-architecturecard-layout ${themeColor==='dark'?'architecturecard-image-div':''}`}><img src={imgSrc}/></div>
  )
}

export default ArchitectureCard