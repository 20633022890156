import React, { useState } from "react";
import CustomButton from "../../../components/Button/CustomButton";
import TextField from "../../../components/TextField";
import { useHistory } from 'react-router';

function TableAreaHeader({ checked, setData }) {
  const history = useHistory()

  // Change Event
  const handleChangeEvt = (v) => {
    setData((prev) => ({ ...prev, [v.key]: v.value }));
  };

  return (
    <div className="actions_row" >
      <div style={{ display: "flex" }}>

        <div className="medium-12 columns action_blocks large-12">
          <span>
            {/* Custom Btn Components */}
            <CustomButton
              type="iconPlus"
              onClick={() => {
                history.push("/platform/create")
                console.log("create Btn");
              }}
            />
            <CustomButton
              type="sync"
              onClick={() => {
                console.log("reset Btn");
              }}
            />
          </span>

        </div>

        <div className="textfield-layout">
          <TextField
            id="Search"
            label="Search..."
            handleChange={handleChangeEvt}
            error={false}
            errMsg={"Please Input Data"}
          />
        </div>
      </div>
    </div>
  );
}

export default TableAreaHeader;
