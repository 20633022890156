import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinearProgress from '@mui/material/LinearProgress';
import "./index.css";

const Index = () => {
  const themeColor = process.env?.REACT_APP_THEME;
  const arrHst = [
    {
      title: "Allocated Qubits",
      percent: 80,
      usedVln: "80",
      totalVln: "100",
    },
    {
      title: "1Q fidelity",
      percent: 99,
      usedVln: "99.91%",
      totalVln: "100",
    },
    {
      title: "Real Qubits",
      percent: 50,
      usedVln: "40",
      totalVln: "80",
    },
    {
      title: "2Q fidelity",
      percent: 98,
      usedVln: "98.25 %",
      totalVln: "100",
    },
  ];

  return (
    <>
      <div className={`dashboard-lgcard-container ${themeColor === 'dark' ? 'dashboard-lgcard-bg-dark' : ''}`}>
        <div className="dashboard-lgcard-flex">
          <div className={`dashboard-lgcard-title ${themeColor === 'dark' ? 'dashboard-lgcard-f-dark' : ''}`}>
            Hosts
          </div>

          <div className="dashboard-lgcard-btn-container">
            <button className="button radius tiny show_hosts_tab list-blue-color">
              <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />
            </button>
            <button className="button radius tiny show_create_host create-gray-color">
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />
            </button>
          </div>
        </div>

        <div className={`${themeColor==='dark'?'dashboard-lgcard-graph-container-dark':'dashboard-lgcard-graph-container'}`}>
          {arrHst.length &&
            arrHst.map((ele, idx) => {
              return (
                <div className="small-12 medium-6 lgcard-mg0" key={idx}>
                  <div id="dashboard_host_allocated_cpu">
                    <div className="">
                      <div className="large-12">
                        <span>{ele?.title}</span>
                      </div>
                    </div>
                    <div className="">
                      <div className="large-12">
                        {/* <meter
                          id="dashboard_host_allocated_cpu_meter"
                          min={0}
                          low={33}
                          high={66}
                          optimum={0}
                          max={100}
                          value={ele?.percent}
                          className={`${themeColor==='dark'?'dashboard-lgcard-meter-dark':''}`}
                        /> */}
                        <LinearProgress variant="determinate" value={ele?.percent} sx={{height:'6px', borderRadius:'8px'}} />
                      </div>
                    </div>
                    <div className="">
                      <div className="large-12">
                        <span
                          id="dashboard_host_allocated_cpu_str"
                          className="right"
                        >
                          {ele?.usedVln ? ele?.usedVln : "- "} /{" "}
                          {ele?.totalVln ? ele?.totalVln : " -"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {/* <div className="small-12 large-12 columns">
        <div>
          <fieldset className="large-font dashboard-fieldset">
            <div className="row small-12 medium-12 columns">
              <div className="small-6 medium-8 columns dashboard-div-titles">
                <h5>Hosts</h5>
              </div>
              <div className="small-6 medium-4 columns dashboard-div-buttons">
                <button className="button radius tiny show_hosts_tab list-blue-color">
                  <FontAwesomeIcon icon={faList} style={{ fontSize: "12px" }} />
                </button>
                <button className="button radius tiny show_create_host create-gray-color">
                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="row" style={{ marginLeft: "0.2rem" }} />

              
            </div>
          </fieldset>
        </div>
      </div> */}
    </>
  );
};

export default Index;
