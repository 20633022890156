



const ButtonLink = ({url, icon, cls, id}) => {
    return <span 
                id={id} 
                className=" only-sunstone-list" 
                style={{display: 'inline'}}>
                <a className={`create_dialog_button action_button top_button ${cls} button`} 
                    href={url}>
                    {icon}
                </a>
            </span>
}

export default ButtonLink;