import { useState } from "react";

import {
  usernameValidator,
  emailValidator,
  passwordValidator,
  re_passwordValidator
} from "./validators.js";

const touchErrors = errors => {
  return Object.entries(errors).reduce((acc, [field, fieldError]) => {
    acc[field] = {
      ...fieldError,
      dirty: true,
    };
    return acc;
  }, {});
};

export const useLoginFormValidator = form => {
  const [errors, setErrors] = useState({
    email: {
      dirty: false,
      error: false,
      message: "",
    },
    username: {
      dirty: false,
      error: false,
      message: "",
    },
    password: {
      dirty: false,
      error: false,
      message: "",
    },
    re_password: {
      dirty: false,
      error: false,
      message: "",
    }
  });

  const validateForm = ({ form, field, errors, forceTouchErrors = false }) => {
    let isValid = true;
    // Create a deep copy of the errors
    const nextErrors = JSON.parse(JSON.stringify(errors));

    // Force validate all the fields
    // if (forceTouchErrors) {
    //   nextErrors = touchErrors(errors);
    // }

    const { email, username, password, re_password } = form;

    if (nextErrors.username.dirty && (field ? field === "username" : true)) {
      const emailMessage = emailValidator(email, form);
      nextErrors.email.error = !!emailMessage;
      nextErrors.email.message = emailMessage;
      if (!!emailMessage) isValid = false;
    }

    if (nextErrors.username.dirty && (field ? field === "username" : true)) {
      const usernameMessage = usernameValidator(username, form);
      nextErrors.username.error = !!usernameMessage;
      nextErrors.username.message = usernameMessage;
      if (!!usernameMessage) isValid = false;
    }

    if (nextErrors.password.dirty && (field ? field === "password" : true)) {
      const passwordMessage = passwordValidator(password, form);
      nextErrors.password.error = !!passwordMessage;
      nextErrors.password.message = passwordMessage;
      if (!!passwordMessage) isValid = false;
    }

    if (nextErrors.re_password.dirty && (field ? field === "re_password" : true)) {
      const passwordMessage = re_passwordValidator(password,re_password, form);
      nextErrors.re_password.error = !!passwordMessage;
      nextErrors.re_password.message = passwordMessage;
      if (!!passwordMessage) isValid = false;
    }


    setErrors(nextErrors);

    return {
      isValid,
      errors: nextErrors,
    };
  };

  const onBlurField = e => {
    const field = e.target.name;
    const fieldError = errors[field];
    if (fieldError.dirty) return;

    const updatedErrors = {
      ...errors,
      [field]: {
        ...errors[field],
        dirty: true,
      },
    };

    validateForm({ form, field, errors: updatedErrors });
  };

  return {
    validateForm,
    onBlurField,
    errors,
    setErrors
  };
};