import React from "react";
import Select from "../../components/Select";
import IconButton from "../../components/Button/IconButton";
import { FaUpload } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa6";

function EditorHeader({
  importLanguage,
  exportLanguage,
  handleChangeEvt,
  handleButtonClick,
  handleFileChange,
  fileInputRef,
  downLoadFile
}) {
  return (
    <div className="editor-info-header">
      <div className="header-left-item">
        <h3>From: </h3>
        <div className="select-item-layout">
          <Select
            id="import"
            defaultValue="QASM"
            data={importLanguage}
            handleChange={handleChangeEvt}
            label="Language"
            error={false}
          />
        </div>
        <IconButton
          startIcon={<FaUpload />}
          color="#233044"
          width={110}
          onClick={handleButtonClick}
        >
          Upload
        </IconButton>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </div>
      <div className="header-right-item">
        <h3>To: </h3>
        <div className="select-item-layout">
          <Select
            id="export"
            defaultValue="QASM"
            data={exportLanguage}
            handleChange={handleChangeEvt}
            label="Language"
            error={false}
          />
        </div>
        <IconButton startIcon={<FaDownload />} color="#233044" width={120} onClick={downLoadFile}>
          DownLoad
        </IconButton>
      </div>
    </div>
  );
}

export default EditorHeader;
