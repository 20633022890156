import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faCalendarAlt,
    faChevronCircleDown,
    faChevronCircleUp,
    faChevronDown,
    faChevronUp,
    faCodeBranch,
    faFolder,
    faFolderOpen,
    faHdd,
    faLaptop,
    faQuestionCircle,
    faUser
} from '@fortawesome/free-solid-svg-icons'
import './TemplateInfo.css'
import { Link } from "react-router-dom";
import Network from './Path/Network';
import UserGroup from './Path/UserGroup';
import SpecificHost from './Path/SpecificHost';
import SpecificDatastore from './Path/SpecificDatastore';
import ScheduleAction from './Path/ScheduleAction';
import Associate from './Path/Associate'
import CenterDeployment from './Path/CenterDeployment'


const TemplateInfo = ({cls, selectTemp}) => {
    const [inPer, setInPer] = React.useState(0)
    const handleSetInPer = () => {
        if(inPer === 0){
            setInPer(1)
        }else{
            setInPer(0)
        }
    }

    const toggleContent = (e) => {
        let dis = e.target.parentNode.childNodes[1].style.display;
        let vl = dis == "none" ? "block" : "none";
        e.target.parentNode.childNodes[1].style.display = vl;
        let transform = e.target.parentNode.childNodes[0].childNodes[0].style.transform
        let transform_value = transform === "rotate(180deg)" ? "rotate(0deg)" : "rotate(180deg)"
        e.target.parentNode.childNodes[0].childNodes[0].style.transform = transform_value
    }
    return <>
        <div className={`row persistentContainer ${cls}`} style={{display: 'block'}}>
            <div className="large-12 large-centered columns">
                <div className="switch left" onChange={()=>handleSetInPer(1)}>
                    <input className="switch-input instantiate_pers" id="createVMForminstantiate_pers" type="checkbox" value={inPer} />
                    <label className="switch-paddle" htmlFor="createVMForminstantiate_pers"></label>
                </div>
                <label className="left">
                    &nbsp;&nbsp;
                    Instantiate as persistent
                    <span 
                        data-tooltip="6mrmpt-tooltip" 
                        aria-haspopup="true" 
                        className="has-tip" 
                        data-disable-hover="false" 
                        title aria-describedby="90wrd4-tooltip" 
                        data-yeti-box="90wrd4-tooltip" 
                        data-toggle="90wrd4-tooltip" 
                        data-resize="90wrd4-tooltip">
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                </label>
            </div>
        </div>

        {/* Row 2 */}

        <div className="row nameContainer" style={{display: 'block'}}>
            <div className="medium-4 columns">
                <label htmlFor="vm_name"> VM name <span className>
                    <span data-tooltip="jfuq9m-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="zqr2zv-tooltip" data-yeti-box="zqr2zv-tooltip" data-toggle="zqr2zv-tooltip" data-resize="zqr2zv-tooltip">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                </span>
                </label>
                <input type="text" name="vm_name" id="vm_name" />
            </div>
            <div className="medium-4 columns">
                <label htmlFor="vm_n_times"> Number of instances </label>
                {inPer === 1 ? 
                    <input type="number" name="vm_n_times_disabled" id="vm_n_times_disabled" defaultValue={1} disabled /> :
                    <input type="number" name="vm_n_times" id="vm_n_times" defaultValue={1} min={1} />
                }
                
                
            </div>
            <div className="medium-4 columns">
                <label>
                <input type="checkbox" name="hold" id="hold" /> Start on hold <span className>
                    <span data-tooltip="bghdu7-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="cwt2nr-tooltip" data-yeti-box="cwt2nr-tooltip" data-toggle="cwt2nr-tooltip" data-resize="cwt2nr-tooltip">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                </span>
                </label>
            </div>
            </div>
        {/* Row 3 */}

        <div className="row">
            <div className="large-12 large-centered columns list_of_templates">
                <br />
                <div className="template-row" element-id={0}>
                <div className="row">
                    <div className="medium-2 small-12 columns">
                    <h6> {selectTemp} <br />
                        <span className="total_cost_div" hidden>
                        <small className="cost_value">0.00</small>
                        <small>COST / HOUR</small>
                        </span>
                    </h6>
                    <span>
                        <img src />
                    </span>
                    </div>
                    <div className="medium-5 small-12 columns capacityContext0" style={{paddingTop: 15, paddingLeft: 35}}>
                    <fieldset>
                        <legend>
                        <FontAwesomeIcon icon={faLaptop} /> Capacity <span className="capacity_cost_div" hidden>
                            <span className="cost_value">0.00</span>
                            <small>COST / HOUR</small>
                        </span>
                        </legend>
                        <div>
                        <div className="row">
                            <div className="memory_input_wrapper large-8 small-12 columns large-12 medium-12 large-6 medium-8">
                            <label style={{padding: 0, textAlign: 'left'}} className="small-5 columns" htmlFor="MEMORY"> Memory <span data-tooltip="w72s4g-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="5lomcy-tooltip" data-yeti-box="5lomcy-tooltip" data-toggle="5lomcy-tooltip" data-resize="5lomcy-tooltip">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                </span>
                            </label>
                            <label style={{padding: 0, textAlign: 'right', fontSize: '95%'}} className="small-7 columns" id="quotas-mem" hidden />
                            <div className="memory_input">
                                <div className="input-group mb_input_wrapper">
                                <div className="mb_input input-group-field" style={{display: 'none'}}>
                                    <input type="number" step={1} min={1} defaultValue={8192} wizard_field="MEMORY" />
                                </div>
                                <div className="gb_input input-group-field">
                                    <input type="number" step="0.1" min={1} defaultValue />
                                </div>
                                <div className="input-group-button">
                                    <select className="mb_input_unit">
                                    <option value="MB">MB</option>
                                    <option value="GB" selected>GB</option>
                                    </select>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="cpu_input_wrapper small-12 columns medium-6">
                            <label style={{padding: 0, textAlign: 'left'}} className="small-5 columns" htmlFor="CPU"> CPU <span data-tooltip="pp9e4p-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="2m0mlb-tooltip" data-yeti-box="2m0mlb-tooltip" data-toggle="2m0mlb-tooltip" data-resize="2m0mlb-tooltip">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                </span>
                            </label>
                            <label style={{padding: 0, textAlign: 'right'}} className="small-7 columns" id="quotas-cpu" hidden />
                            <div className="cpu_input">
                                <input type="number" step="0.01" min="0.01" defaultValue={4} wizard_field="CPU" />
                            </div>
                            </div>
                            <div className="vcpu_input_wrapper small-12 columns end medium-6">
                            <label className htmlFor="VCPU"> VCPU <span data-tooltip="r0yazq-tooltip" aria-haspopup="true" className="has-tip" data-disable-hover="false" title aria-describedby="jd6giu-tooltip" data-yeti-box="jd6giu-tooltip" data-toggle="jd6giu-tooltip" data-resize="jd6giu-tooltip">
                                <FontAwesomeIcon icon={faQuestionCircle} />
                                </span>
                            </label>
                            <div className="vcpu_input">
                                <input type="number" step={1} min={1} defaultValue={4} wizard_field="VCPU" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </fieldset>
                    </div>
                    <div className="medium-5 small-12 columns disksContext0" style={{paddingTop: 15}} />
                </div>
                <div className="row">
                    <div className="medium-5 small-12 columns template_user_inputs0" />
                </div>

                

                 <Network 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>} 
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>} 
                    icon3={<FontAwesomeIcon icon={faCodeBranch} style={{ pointerEvents: 'none' }}/>} 
                    name={`Network`} 
                    toggleContent={toggleContent}
                />

                <UserGroup 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faUser} style={{ pointerEvents: 'none' }}/>}
                    name={`Instantiate as different User / Group`}
                    toggleContent={toggleContent}
                /> 
                

                <SpecificHost 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faHdd} style={{ pointerEvents: 'none' }}/>}
                    name={`Deploy VM in a specific Host`}
                    toggleContent={toggleContent}
                />

                <SpecificDatastore 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faHdd} style={{ pointerEvents: 'none' }}/>}
                    name={`Deploy VM in a specific Datastore`}
                    toggleContent={toggleContent}
                />

                <ScheduleAction 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faHdd} style={{ pointerEvents: 'none' }}/>}
                    name={`Schedule Actions for VM`}
                    toggleContent={toggleContent}
                />


                <Associate 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faHdd} style={{ pointerEvents: 'none' }}/>}
                    name={`Associate VM to a VM Group`}
                    toggleContent={toggleContent}
                />

                


                <CenterDeployment 
                    icon1={<FontAwesomeIcon icon={faChevronDown} style={{ pointerEvents: 'none' }}/>}
                    icon2={<FontAwesomeIcon icon={faChevronUp} style={{ pointerEvents: 'none' }}/>}
                    icon3={<FontAwesomeIcon icon={faHdd} style={{ pointerEvents: 'none' }}/>}
                    name={`vCenter Deployment`}
                    toggleContent={toggleContent}
                />

                </div>
                <br />
            </div>
            </div>


    </>
}

export default TemplateInfo;