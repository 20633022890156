import { useState } from 'react';

export default function useToken() {
  // const getToken = () => {
  //   const tokenString = sessionStorage.getItem('access_token');
  //   // const userToken = JSON.parse(tokenString);
  //   return tokenString
  const getToken = () => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith('access_token=')) {
        return cookie.substring('access_token='.length, cookie.length);
      }
    }
    return null;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    // sessionStorage.setItem('access_token', userToken);
    // setToken(token);
    const expires = new Date();
    expires.setDate(expires.getDate() + 7);
    document.cookie = `access_token=${userToken}; Expires=${expires.toUTCString()};`;
    setToken(userToken);
  };

  return {
    setToken: saveToken,
    token
  }
}