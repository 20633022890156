import { ActionTypes } from './../contants/action-types';

const initialState = {
     instrument : {}
}


export const instrumentReducer = (state = initialState, {type, payload})=>{
    switch (type) {
         case ActionTypes.LIST_INSTRUMENT:
                return {...state, payload};
        case ActionTypes.LIST_INSTRUMENT_SUCCESS:
                let listInstrument = payload
                return {...state, listInstrument};
        case ActionTypes.LIST_INSTRUMENT_ERROR:
                let listInstrumentError = payload
                return {...state, listInstrumentError};
        case ActionTypes.LIST_INSTRUMENT_DRIVER:
                return {...state, payload};
        case ActionTypes.LIST_INSTRUMENT_DRIVER_SUCCESS:
                let listInstrumentDriverSuccess = payload
                return {...state, listInstrumentDriverSuccess};
        case ActionTypes.LIST_INSTRUMENT_DRIVER_ERROR:
                let listInstrumentDriverError = payload
                return {...state, listInstrumentDriverError};
        case ActionTypes.CREATE_INSTRUMENT_SUCCESS:
                let statusCreateController = payload
                return {...state, statusCreateController};
        case ActionTypes.CREATE_INSTRUMENT_ERROR:
                let messErr = payload
                return {...state, messErr};
        case ActionTypes.DETAIL_INSTRUMENT_SUCCESS:
                let detail = payload
                return {...state, detail};
        case ActionTypes.DELETE_INSTRUMENT_SUCCESS:
                let deleteSuccess = payload
                return {...state, deleteSuccess};
        case ActionTypes.DELETE_INSTRUMENT_ERROR:
                let deleteError = payload
                return {...state, deleteError};
        case ActionTypes.DETAIL_INSTRUMENT:
                let itemInstrument = payload
                return {...state, itemInstrument};
        default:
                return state;
      }
 }