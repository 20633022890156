import DetailHeader from "./Header";
import DefaultLayout from "../../../../layouts/DefaultLayout";
import Tab from "./Tab";
import Edit from "./Edit";
import CustomIcon from "../../../../components/Icon";
import Info from "./Conf/Information";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import axiosService from "../../../../api/axiosService";
import { baseUrl } from "../../../../redux/contants/urlApi";
import { tSuccess } from "../../../../common/CommonToast";
import { tError } from "../../../../common/CommonToast";
import SaveModal from "../../.././../components/Modal/Modal";
import DeleteModal from "../../../../components/Modal/Modal";
import ModalRenderer from "./ModalRender";
import { useHistory } from "react-router-dom";
import { getDetailData, fetchChannelData,instrumentCheck,deleteInstrumentModel,editName } from "../api";

const initModelElement = {
  title: [
    {
      label: "Name",
      value: "",
    },
    {
      label: "Description",
      value: "",
    },
  ],
  isDisableDelete: false,
  uuid: "",
  type: [
    { key: "Dummy", label: "Dummy Instrument" },
    { key: "Zurich", label: "Zurich Instrument" },
    { key: "QMOPX", label: "QMOPX Instrument" },
    { key: "Qblox", label: "Qblox Instrument" },
    { key: "RFSoC", label: "RFSoC" },
    { key: "ERA", label: "ERA Instrument" },
    { key: "SGS100A", label: "SGS100A Instrument" },
    { key: "SPI", label: "SPI Instrument" },
  ],
  instrument: [],
};
const mapper = {
  "SQK": {
    path: "/assets/images/sqk-kokkos-white-code01-instruments2.svg",
    size: "80px",
    text: 'Instruments',
    textLeft: '0px',
    typeName: "SQK Inst"
  },
  "IQM": {
    path: "/assets/images/IQM-logo.svg",
    size: "50px",
    left: '5px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "IQM Inst"
  },
  "IONQ": {
    path: "/assets/images/ionq-logo-light.svg",
    size: "70px",
    left: '2px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "IONQ Inst"
  },
  "Bluefors": {
    path: "/assets/images/bluefors-logo-white.png.webp",
    size: "90px",
    left: '2px',
    text: 'Instruments',
    textLeft: '8px',
    typeName: "Bluefors Inst"
  },
  "Zurich": {
    path: "/assets/quantums/Zurich_Instruments_logo.png",
    size: "130px",
  },

  "QUANTUM": {
    path: "/assets/quantums/2-QM-logo-black.svg",
    size: "160px",
  },

  KeySight: {
    path: "/assets/quantums/KEYS_white.webp",
    size: "130px",
  },
  QBLOX: {
    path: "/assets/quantums/3-QBlox-logo.svg",
    size: "150px",
  },
  default: {
    path: "/assets/quantums/4-qick-logo2.svg",
    size: "40px",
  },
};

const detailMapper = {
  "SQK Instruments": {
    imageUrl: "/assets/quantums/sqk-instruments.webp",
    imageSize: "",
  },
  "Zurich Instruments": {
    imageUrl: "/assets/quantums/1.zurichi-instrument.webp",
    imageSize: "50rem",
  },
  "QUANTUM MACHINES": {
    imageUrl: "/assets/quantums/3.QM-instrument.webp",
    imageSize: "50rem",
  },
  "Bluefors": {
    imageUrl: "/assets/quantums/bluefors-mc.png",
    imageSize: "50rem",
  },
  "IQM": {
    imageUrl: "/assets/quantums/IQM-instrument-2.png",
    imageSize: "200%",
    imageHeight: "30rem",
    maxWidth: "200%"
  },
  "IONQ": {
    imageUrl: "/assets/quantums/ionqbox.webp",
    imageSize: "40rem",
  },
  QBLOX: {
    imageUrl: "/assets/quantums/2.qblox-instrument.webp",
    imageSize: "40rem",
  },
  KeySight: {
    imageUrl: "/assets/quantums/4.keysight-instrument.webp",
    imageSize: "50rem",
  },
  default: {
    imageUrl: "/assets/quantums/quantum-1.jpg",
    imageSize: "",
  },
};
function index() {
  const { id } = useParams();
  const [isReset, setIsReset] = useState(false);
  const [modelElement, setModelElement] = useState(initModelElement);
  const [isvalidation, setIsvalidation] = useState({
    bander: false,
    Name: false,
    Description: false,
    type: false,
    selectedOption: false,
    instrument: [],
  });
  const [selectedOption, setSelectedOption] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [currentUuid, setCurrentUuid] = useState(null);
  const [initialChannelData, setInitialChannelData] = useState([]);
  const [isSaveModalopen, setSaveModalOpen] = useState(false);
  const [isChannelDeleteModalOpen, setIsChannelDeleteModalOpen] =
    useState(false);
  const [isDriverDeleteModalOpen, setIsDriverDeleteModalOpen] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (id || isReset) {
      getDetailData(id, setModelElement, setSelectedOption);
      fetchChannelData(id,setModelElement,setInitialChannelData)
      instrumentCheck(setModelElement,id);
    }
  }, [id, isReset]);

  const deleteChannel = async () => {
    // {driver_uuid}/channels/{channel_uuid}
    const checkData = initialChannelData.some(
      (item) => item.uuid === currentUuid
    );
    if (checkData) {
      let api =
        baseUrl + "v1/instrument-drivers/" + id + "/channels/" + currentUuid;
      try {
        const response = await axiosService.delete(api);
        if (response.status === 201) {
          fetchChannelData(id,setModelElement,setInitialChannelData);
          tSuccess("Delete Channel was successful.");
        }
        setIsChannelDeleteModalOpen(false);
      } catch (err) {
        console.log("error", err);
      }
    } else {
      const updatedInstruments = modelElement.instrument.filter(
        (item) => item.uuid !== currentUuid
      );
      setModelElement((prevState) => ({
        ...prevState,
        instrument: updatedInstruments,
      }));
      setIsChannelDeleteModalOpen(false);
    }
  };
  const handleNameChangeEvt = (v) => {
    setModelElement((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [v.key]: v.value,
      },
    }));
  };

  const tabData = [
    {
      tabId: "info",
      tabLabel: "Info",
      icon: <CustomIcon type="faInfoCircle" />,
      contents: (
        <>
          <Info
            mapper={detailMapper}
            editName={editName}
            onChange={handleNameChangeEvt}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            modelElement={modelElement}
            id={id}
          />
        </>
      ),
    },
    {
      tabId: "Edit",
      tabLabel: "Edit",
      icon: <CustomIcon type="MdOutlineModeEdit" />,
      contents: (
        <Edit
          isReset={isReset}
          setIsReset={setIsReset}
          isvalidation={isvalidation}
          setIsvalidation={setIsvalidation}
          modelElement={modelElement}
          setModelElement={setModelElement}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setCurrentUuid={setCurrentUuid}
          deleteChannel={deleteChannel}
          isModalOpen={isChannelDeleteModalOpen}
          setIsModalOpen={setIsChannelDeleteModalOpen}
        />
      ),
    },
  ];



  const changeInstrumentModel = async () => {
    // 수정 api 함수 자리
    const check = validationCheck();
    let allRequestsSuccessful = true;
    if (!check) {
      const driverApi = baseUrl + "v1/instrument-drivers/" + id;

      // 새로 추가된 채널 post하는 api
      const addChanelApi =
        baseUrl + "v1/instrument-drivers/" + id + "/channels";

      // 수정된 채널 목록의 인덱스만 선별해서 api 주소 만드는 로직
      const editChannel = [];
      for (const instrument of modelElement.instrument) {
        const tempChannel = initialChannelData.find(
          (temp) => temp.uuid === instrument.uuid
        );
        if (
          tempChannel &&
          (instrument.port_name !== tempChannel.port_name ||
            instrument.channel_name !== tempChannel.channel_name)
        ) {
          const api = `${baseUrl}v1/instrument-drivers/${id}/channels/${instrument.uuid}`;
          editChannel.push({
            url: api,
            data: {
              port_name: instrument.port_name,
              channel_name: instrument.channel_name,
              port_no: instrument.port_no,
            },
          });
        }
      }

      // 새로 추가된 채널의 인덱스들만 찾아서 따로 배열로 빼두는 로직
      const addedChannel = [];
      if (modelElement.instrument.length > initialChannelData.length) {
        const startIndex = initialChannelData.length;
        const newInstruments = modelElement.instrument.slice(startIndex);
        addedChannel.push(...newInstruments);
      }
      try {
        // // 드라이버 수정 로직
        if (
          modelElement.data.type !== selectedOption.select ||
          modelElement.data.description !== modelElement.title[1].value ||
          modelElement.data.name !== modelElement.title[0].value
        ) {
          const driverBody = {
            name: modelElement.title[0].value,
            description: modelElement.title[1].value,
            type: selectedOption.select,
          };
          const response = await axiosService.put(driverApi, driverBody);
          if (response.status !== 201) {
            allRequestsSuccessful = false;
            if (response?.error_message?.response?.data?.message) {
              tError(response?.error_message?.response?.data?.message);
              setSaveModalOpen(false);
            }
          }
        }

        // // 기존의 채널 목록에서 수정된 채널들만 수정하는 로직
        if (editChannel.length > 0) {
          for (const channel of editChannel) {
            const response = await axiosService.put(channel.url, channel.data);
            if (response.status !== 201) {
              allRequestsSuccessful = false;
              if (response?.error_message?.response?.data?.message) {
                tError(response?.error_message?.response?.data?.message);
                setSaveModalOpen(false);
              }
            }
          }
        }

        // 새로 추가된 채널들만 새로 추가 하는 로직
        if (addedChannel?.length > 0) {
          const response = await axiosService.post(addChanelApi, addedChannel);
          if (response.status !== 201) {
            allRequestsSuccessful = false;
            if (response?.error_message?.response?.data?.message) {
              tError(response?.error_message?.response?.data?.message);
              setSaveModalOpen(false);
            }
          }
        }

        // 세가지 스텝 모두 완료되면 toast ui 나오게
        if (allRequestsSuccessful) {
          tSuccess("edit was successful.");
          setSaveModalOpen(false);
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      tError("Please enter the required elements")
      setSaveModalOpen(false);
    }
  };

  const validationCheck = () => {
    const { title, instrument } = modelElement;
    let isInvalid = false;

    if (title[0].value.length === 0) {
      setIsvalidation((prev) => ({ ...prev, Name: true }));
      isInvalid = true;
    }
    if (title[1].value.length === 0) {
      setIsvalidation((prev) => ({ ...prev, Description: true }));
      isInvalid = true;
    }
    if (Object.keys(selectedOption).length === 0) {
      setIsvalidation((prev) => ({ ...prev, selectedOption: true }));
      isInvalid = true;
    }
    if (instrument.length > 0) {
      const validationResults = instrument.map((item) => ({
        port_name: item.port_name.length === 0,
        channel_name: item.channel_name.length === 0,
      }));

      setIsvalidation((prev) => ({ ...prev, instrument: validationResults }));
      if (validationResults.some((v) => v.port_name || v.channel_name)) {
        isInvalid = true;
      }
    }

    return isInvalid;
  };

  return (
    <>
      <DefaultLayout>
        <DetailHeader
          setIsReset={setIsReset}
          mapper={mapper}
          setSaveModalOpen={setSaveModalOpen}
          modelElement={modelElement}
          setIsDriverDeleteModalOpen={setIsDriverDeleteModalOpen}
        />
        <Tab tabData={tabData} />
        {isSaveModalopen && (
          <SaveModal
            title="Are you sure you want to Edit it?"
            open={isSaveModalopen}
            isDisableCloseBtn={true}
            handleClose={() => {
              setSaveModalOpen(false);
            }}
            renderer={() =>
              ModalRenderer(() => changeInstrumentModel(), setSaveModalOpen)
            }
          />
        )}
        {isDriverDeleteModalOpen && (
          <DeleteModal
            title="Are you sure you want to Delete it?"
            open={isDriverDeleteModalOpen}
            isDisableCloseBtn={true}
            handleClose={() => {
              setIsDriverDeleteModalOpen(false);
            }}
            renderer={() =>
              ModalRenderer(
                () => deleteInstrumentModel(id, setIsDriverDeleteModalOpen,history),
                setIsDriverDeleteModalOpen
              )
            }
          />
        )}
      </DefaultLayout>
    </>
  );
}

export default index;
