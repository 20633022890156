

const Conf = () => {
    return <>
    <div id="vm_conf_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_conf_tab-label">
    <div className="row">
      <div className="small-12 columns">
        <span className="right">
          <button id="vm_updateconf" className="button success right radius small">
            Update Configuration
          </button>
        </span>
      </div>
    </div>
    <div className="row">
      <div className="medium-6 columns">
        <table className="dataTable">
          <thead>
            <tr>
              <th colSpan={2}>OS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key_td">BOOT</td>
              <td className="value_td" />
            </tr>
          </tbody>
        </table>
      </div>
      <div className="medium-6 columns">
        <table className="dataTable">
          <thead>
            <tr>
              <th colSpan={2}>GRAPHICS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key_td">LISTEN</td>
              <td className="value_td">0.0.0.0</td>
            </tr>
            <tr>
              <td className="key_td">TYPE</td>
              <td className="value_td">VNC</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="medium-6 columns">
        <table className="dataTable">
          <thead>
            <tr>
              <th colSpan={2}>CONTEXT</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="key_td">DISK_ID</td>
              <td className="value_td">0</td>
            </tr>
            <tr>
              <td className="key_td">NETWORK</td>
              <td className="value_td">YES</td>
            </tr>
            <tr>
              <td className="key_td">SSH_PUBLIC_KEY</td>
              <td className="value_td" />
            </tr>
            <tr>
              <td className="key_td">TARGET</td>
              <td className="value_td">hda</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
    </>
}

export default Conf;