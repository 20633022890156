import Tabs from './Tabs/Index'
import Content from './Content/Index'

const Index = () => {
    const handleClickContextTabone7 = (e) => {
        let id = e.target.getAttribute('aria-controls')
        let element = document.getElementsByClassName("tabs-title-contextTabone7");
        let tabs = document.getElementsByClassName('wizard_internal_tab_ContextTabone7')
        for (var i = 0; i < element.length; i++) {
            element[i].classList.remove("is-active")
            tabs[i].classList.remove("is-active")
            if(id === element[i].childNodes[0].getAttribute('aria-controls')){
                element[i].classList.add("is-active")
                tabs[i].classList.add("is-active")
            }
            
        }
    }
    return <div id="contextTabone7" className="wizard_tab tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="contextTabone7-label">
                <div className="row hypervisor only_vcenter" hidden>
                    <div className="large-12 columns text-center">
                    <label>Contextualization type</label>
                    <input type="radio" name="context_type" id="context_type_vcenter" defaultValue="context_type_vcenter" />
                    <label htmlFor="context_type_vcenter">vCenter</label>
                    <input type="radio" name="context_type" id="context_type_opennebula" defaultValue="context_type_opennebula" />
                    <label htmlFor="context_type_opennebula">OpenNebula</label>
                    </div>
                    <hr />
                </div>
                <div className="row hypervisor only_vcenter context_type context_type_vcenter" hidden style={{display: 'none'}}>
                    <div className="columns medium-6">
                    <label> vCenter customizations <select className="vcenter_customizations">
                        <option value>None</option>
                        <option value custom="true">Set manually</option>
                        </select>
                    </label>
                    </div>
                    <div className="columns medium-6">
                    <label>&nbsp;</label>
                    <input className="vcenter_customizations_value" type="text" style={{display: 'none'}} />
                    </div>
                </div>
                <div className="context_type context_type_opennebula row collapse" style={{display: 'block'}}>
                    <div className="medium-2 columns">
                        <Tabs handleClickContextTabone7={handleClickContextTabone7} />
                    </div>
                    <div className="medium-10 columns">
                    <div className="tabs-content vertical" data-tabs-content="context_tabs">
                        <Content />
                    </div>
                    </div>
                </div>
            </div>
}

export default Index;