import axiosService from './axiosService';
import { baseUrl, serverUrl } from '../redux/contants/urlApi';
import axios from 'axios';
import $, { error } from 'jquery'




// register
export const registerUser = (data) => {
    let url = serverUrl + 'api/data'
    let token = sessionStorage.getItem("access_token")
    let cookie = sessionStorage.getItem("cookie_str")
    let api = baseUrl+'v1/users'
    return axiosService.post(baseUrl+'v1/users', { data, token, cookie, api});
}

// login
export const loginUser = async (data) => {
    const url = `${baseUrl}v1/users/login`;
    data = {
        email: data?.username,
        password: data?.password
    }
    const result = await axiosService.post(url, data)

    console.log("RESULT: ",result)

    return result
}

// users 

export const listUser = async () => {
    let url = serverUrl + 'api/data?token=' + sessionStorage.getItem("access_token")
    + "&cookie=" + sessionStorage.getItem("cookie_str")
    + "&link=" + baseUrl + 'v1/users/'
    return axiosService.get(baseUrl + 'v1/users/');
}
