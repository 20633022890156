import DefaultLayout from "../../../layouts/DefaultLayout";
import TabItem from "../../../components/Tab";
import CustomIcon from "../../../components/Icon";
import Info from "./Path/Info/Information"
import Conf from "./Path/Conf/Information"
import CustomButton from "../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";

// connect circuit
import ConnectCircuit from "./Path/connectCircuit/Index";

const Index = () => {
  const history = useHistory()
  const [data, setData] = useState({})

  const tabData = [
    { tabId: 'info', tabLabel: 'Info', icon: <CustomIcon type="faInfoCircle" />, contents: <Info></Info> }
    , { tabId: 'conf', tabLabel: 'config', icon: <CustomIcon type="faLaptop" />, contents: <Conf></Conf> }
    , { tabId: 'connectCircuit', tabLabel: 'Connect Circuit', icon: <CustomIcon type="faSitemap" />, contents: <ConnectCircuit></ConnectCircuit> }
  ]

  useEffect(()=>{
    // Controller Data 조회
    fetchDataList()
  },[])

  const fetchDataList = async () => {
    // Controller Data 조회
  }
  

  return (
    <>
      <DefaultLayout title={"Instrument Detail"}>
        <div className="sunstone-list">
          <CustomButton type="goback" onClick={() => { history.goBack() }} />
          <CustomButton type="sync" onClick={() => { fetchDataList() }} />
          {/* <CustomButton type="custom" label={"SAVE"} onClick={() => { console.log("Oh~~~") }} /> */}

          <TabItem tabData={tabData} />
        </div>
      </DefaultLayout>
    </>
  );
};

export default Index;
