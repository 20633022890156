export const emailValidator = username => {
    if (!username) {
      return "Username is required";
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(username)) {
      // return "Incorrect email format";
    }
    return "";
  };
  
  export const passwordValidator = password => {
    if (!password) {
      return "Password is required";
    } else if (password.length < 12) {
      // return "Password must have a minimum 12 characters";
    }
    return "";
  };
