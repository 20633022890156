import {all,call,fork,put,takeEvery} from "redux-saga/effects";
import { ActionTypes } from '../redux/contants/action-types';
import {baseUrl} from '../redux/contants/urlApi'
import { 
  createPlatform, 
  listPlatform, 
  detailPlatform,
  deletePlatform,
  listRuncard,
  activeFileRuncard,
  updatePlatform
 } from '../api/platform'
import {
    createPlatformSuccessAction,
    listPlatformSuccessAction,
    detailPlatformSuccessAction,
    deletePlatformSuccessAction,
    deletePlatformErrorAction,
    listFileRuncardSuccess,
    uploadFileRuncardSuccessAction,
    uploadFileRuncardErrorAction,
    activeFileRunCardSuccess,
    activeFileRunCardError,
    createPlatformErrorAction
 } from '../redux/actions/platform'
 import $ from 'jquery'

 function* watchCreatePlatform(payload){
  console.log('payload in sagas', payload);
  var id = payload?.payload?.edit?.uuid
  var res = {}
  if(id !== undefined){
    res = yield call(updatePlatform, payload)
  }else{
    res = yield call(createPlatform, payload);
  }
  
  if(res?.status === 201 || res?.status === 200){
    yield put(createPlatformSuccessAction(res?.statusText))
    payload?.payload?.history.push('/platform/list')
  }else{
    yield put(createPlatformErrorAction(id === undefined ? "Create Platform faild" : "Update platform faild"))
  }
 }

 function* watchListPlatform(payload)
 {
      const res = yield call(listPlatform);
      if (res?.status === 200) {
        yield put(listPlatformSuccessAction(res?.data));
      }
  }

  function* watchDetailPlatform(payload)
 {
      const res = yield call(detailPlatform, payload);
      if (res?.status === 200) {
        yield put(detailPlatformSuccessAction(res?.data));
      }
  }

  function* watchUploadFileRuncard(payload){
    console.log('vao sagas');
    let uploadState = false
    var settings = {
    "url": baseUrl + payload?.payload?.id + "/runcard",
    "method": "PUT",
    "timeout": 0,
    "headers": {
        "Authorization": "Bearer "+sessionStorage.getItem("access_token")
    },
    "processData": false,
    "mimeType": "multipart/form-data",
    "contentType": false,
    "data": payload?.payload?.data
    };

    $.ajax(settings).done(function (response) {
      console.log('response upload file', response);
      uploadState = true
    }).fail(function(error){
      console.log('error upload file', error);
    });
    if(uploadState){
      yield put(uploadFileRuncardSuccessAction("Upload file success"))
    }else{
      yield put(uploadFileRuncardErrorAction("Upload file faild"))
    }
  }

  function* watchActiveFileRunCard({payload}){
    console.log('payload', payload);
    const res = yield call(activeFileRuncard, payload)
    console.log('res', res);
    if(res?.status === 200){
      yield put(activeFileRunCardSuccess(res?.statusText))
    }else{
      yield put(activeFileRunCardError(res?.error_message))
    }
  }

  function* watchDeletePlatform({payload}){
    console.log('payload in sagas', payload);
    const res = yield call(deletePlatform, payload)
    if(res?.status === 200){
      yield put(deletePlatformSuccessAction(res))
    }else{
      yield put(deletePlatformErrorAction(res))
    }
  }

function* watchListRuncard(payload){
  const res = yield call(listRuncard, payload);
  if(res?.status === 200){
    yield put(listFileRuncardSuccess(res?.data))
  }
}

function* handleCreatePlatform() {
  yield takeEvery(ActionTypes.CREATE_PLATFORM, watchCreatePlatform);
}

function* handleListPlatform() {
    yield takeEvery(ActionTypes.LIST_PLATFORM, watchListPlatform);
}

function* handleDetailPlatform() {
  yield takeEvery(ActionTypes.DETAIL_PLATFORM, watchDetailPlatform);
}

function* handleUploadFileRuncard() {
  yield takeEvery(ActionTypes.UPLOAD_FILE_RUNCARD, watchUploadFileRuncard);
}

function* handleListRuncard() {
  yield takeEvery(ActionTypes.LIST_FILE_RUNCARD, watchListRuncard);
}

function* handleActiveFileRuncard(){
  yield takeEvery(ActionTypes.ACTIVE_FILE_RUNCARD, watchActiveFileRunCard)
}

function* handleDeletePlatform(){
  yield takeEvery(ActionTypes.DELETE_PLATFORM, watchDeletePlatform)
}

export default function* authSaga() {
    yield all([
       fork(handleListPlatform), 
       fork(handleDetailPlatform), 
       fork(handleUploadFileRuncard), 
       fork(handleListRuncard),
       fork(handleCreatePlatform),
       fork(handleActiveFileRuncard),
       fork(handleDeletePlatform),
   ]);
  }