import React from 'react';
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

function CirclePagination({ count, currentPage, onChange }) {
  const themeColor = process.env?.REACT_APP_THEME;

  // 공통 스타일을 정의
  const commonStyle = {
    color: themeColor === 'dark' ? "rgba(255, 255, 255, 0.95)" : "black",
    "&:hover": {
      backgroundColor: "#2e9cb9",
      color: "white"
    }
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={count}
        color="primary" 
        page={currentPage}
        onChange={onChange}
        sx={{
          "& .MuiPaginationItem-page": commonStyle,
          "& .Mui-selected": {
            backgroundColor: "#2e9cb9",
            color: "white"
          },
          "& .MuiPaginationItem-icon": commonStyle
        }}
      />
    </Stack>
  );
}

export default CirclePagination;