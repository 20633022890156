


const CenterDeployment = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                <i className="fas fa-fw fa-chevron-up" />&nbsp; {icon3}&nbsp;{name}</a>
            <div id="advanced_section_7" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="medium-6 small-12 columns vcenterVMFolderContext0" />
                </div>
            </div>
        </div>
    </>
}


export default CenterDeployment;