import SkeletonPage from "../../components/SkeletonPage";
import DefaultLayout from "../../layouts/DefaultLayout";

const Index = () => {
    const themeColor = process.env?.REACT_APP_THEME;
    return <>
        <DefaultLayout title={"Settings"}>
            <SkeletonPage />
        </DefaultLayout>
    </>
}


export default Index;