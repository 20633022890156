import { ActionTypes } from '../contants/action-types';


export const registerUser = (data, history) => {
     return {
          type : ActionTypes.REGISTER_USER,
          payload : {data, history}
     }
}


export const registerUserSuccess = (data) => ({
     type: ActionTypes.REGISTER_USER_SUCCESS,
     payload: {
          data
     }
});

export const loginUser = (data, history)=>{
     return {
          type : ActionTypes.LOGIN_USER,
          payload: {data, history}
     }
}

export const loginUserSuccess = (data)=>{
     return {
          type : ActionTypes.LOGIN_USER_SUCCESS,
          payload: data
     }
}

export const loginUserError = (data)=>{
     return {
          type : ActionTypes.LOGIN_USER_ERROR,
          payload: data
     }
}


export const listUser = (data, history)=>{
     return {
          type : ActionTypes.LIST_USER,
          payload: {data, history}
     }
}

export const listUserSuccess = (data)=>{
    return {
         type : ActionTypes.GET_LIST_SUCCESS,
         payload: data
    }
}

export const listUserError = (data)=>{
     return {
          type : ActionTypes.GET_LIST_ERROR,
          payload: data
     }
 }



