import OsTabone1 from './Child/OsTabone1/Index'
import OsTabone5 from './Child/ioTabone5/Index'
import ContextTabone7 from './Child/ContextTabone7/Index'
import OtherTabone10 from './Child/otherTabone10/Index'

const Index = () => {
    return <div className="tabs-content" data-tabs-content="updateConfFormTabs">
            <OsTabone1 />
            <OsTabone5 />
            <ContextTabone7 />
            <OtherTabone10 />
        </div>
}


export default Index;