import axiosService from './axiosService';
import { baseUrl, serverUrl } from '../redux/contants/urlApi';



// create
export const createPlatform = (data) => {
    console.log('data in api', data);
    let url = serverUrl + 'api/data'
    let token = sessionStorage.getItem("access_token")
    let cookie = sessionStorage.getItem("cookie_str")
    let api = baseUrl+'v1/platforms/'
    return axiosService.post(api, data?.payload?.data);
}

// update
export const updatePlatform = (data) => {
    console.log('data in api', data);
    let api = baseUrl+'v1/platforms/' + data?.payload?.edit?.uuid
    return axiosService.put(api, data?.payload?.data);
}



// list 

export const listPlatform = async () => {
    return axiosService.get(baseUrl + 'v1/platforms/');
}

// detail

export const detailPlatform = async (data) => {
    return axiosService.get(baseUrl + 'v1/platforms/'+data?.payload?.id);
}

// delete platform

export const deletePlatform = async (payload) => {
    console.log('Payload in api', payload);
    let platform_uuid = payload?.platform_uuid
    let url = baseUrl + `v1/platforms/${platform_uuid}`
    return axiosService.delete(url)
}

// upload file runcard

export const uploadFileRunCard = async (input) => {
    let url = serverUrl + 'api/put'
    let token = sessionStorage.getItem("access_token")
    let cookie = sessionStorage.getItem("cookie_str")
    let api = baseUrl+'v1/platforms/'+input?.payload?.id+'/runcard'
    console.log('data in api', api);
    let data = input?.payload?.data
    console.log('cookie', cookie);
    return axiosService.post(url, { data, token, cookie, api});
}

// list runcard

export const listRuncard = async (payload) => {
    return axiosService.get(baseUrl + 'v1/platforms/'+payload?.payload+'/runcard/');
}

// Active file runcard

export const activeFileRuncard = async (payload) => {
    console.log('payload in api', payload);
    let platform_uuid = payload?.platform_uuid
    let runcard_uuid = payload?.runcard_uuid
    return axiosService.get(baseUrl + `/v1/platforms/${platform_uuid}/runcard/${runcard_uuid}/activate`);
}