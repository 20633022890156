import React, { useRef, useEffect, useState } from "react";
import DefaultLayout from "../../../layouts/DefaultLayout";
import ReaderVtk from "./vtk/VtkViewer";

import Select from "../../../components/Select";

import Sample from "./vtk/Sample";

const Index = () => {
  const [viewFile, setViewFile] = useState("darth_vader");

  const changeView = (newView) => {
    console.log(newView.value);

    switch (newView.value) {
      case "upper_torso":
        setViewFile("upper_torso");
        break;
      case "header":
        setViewFile("header");
        break;
      case "aneurism":
        setViewFile("aneurism");
        break;
      case "test":
        setViewFile("test");
        break;
      default:
        setViewFile("darth_vader");
        break;
    }
  };

  // select data
  const qcfdSelectData = [
    {
      key: "darth_vader",
      label:
        "darth_vader(Object Viewer Semelhante a um objeto importado do 'blender Extensão: .obj')",
    },
    {
      key: "upper_torso",
      label: "upper_torso (Volume Viewer Extensão:'.vti')",
    },
    { key: "header", label: "Cabeça (Volume Viewer Extensão:'.vti')" },
    {
      key: "aneurism",
      label:
        "Abnormal swelling or bulge in the wall of a blood vessel, such as an artery",
    },
    {
      key: "test",
      label: "test",
    },
  ];

  const render = (ss) => {
    try {
      if (ss != "test") {
        //return <ReaderVtk current_view={ss} />;
      } else {
        //return <Sample />;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* <Header /> */}
      <DefaultLayout title={"QCFD"}>
        <div className="sunstone-list">
          <Select
            id="qcfd"
            label="QCFD"
            data={qcfdSelectData}
            defaultValue={"darth_vader"}
            handleChange={changeView}
          />
          {render(viewFile)}
        </div>
      </DefaultLayout>
    </>
  );
  // }
};

export default Index;
