import React from 'react'
import Button from "@mui/material/Button";

function IconButton({children,marginRight,onClick,startIcon,width,color,hover}) {
  return (
    <Button
      sx={{
      width: width || 90,
      marginRight: marginRight,
      height:40,
      color:  "rgba(255, 255, 255, 0.95)",
      backgroundColor:color?color:"",
      border:"1px solid rgba(255, 255, 255, 0.95)" ,
      whiteSpace: "nowrap",
      '&:hover': {
        backgroundColor: hover?hover:'rgba(255, 255, 255, 0.15)', 
        borderColor: hover?"":'rgba(255, 255, 255, 0.75)' 
      }      
    }}
    type="button"
    onClick={onClick}
    variant="outlined"
    startIcon={startIcon}
  >
    {children}
  </Button>
  )
}

export default IconButton