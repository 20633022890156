import React, { useEffect, useState } from "react";
import Card from "./Card";
import { useHistory } from "react-router";
import Select from "../../../components/Select"
import CirclePagination from "./Pagination";
import { baseUrl } from "../../../redux/contants/urlApi";
import axiosService from "../../../api/axiosService";


const selectData = [
  {key:6, label:6,select:6 }
  ,{key:12, label:12,select:12}
  ,{key:36, label:36,select:36},
  {key:36, label:72,select:72}
]



const mapper = {
  SQK: {
    path: "/assets/images/sqk-kokkos-white-code01-instruments2.svg",
    size: "80px",
    text: 'Measurements',
    textLeft: '0px',
    typeName: "SQK Inst"
  },
  IQM: {
    path: "/assets/images/IQM-logo.svg",
    size: "50px",
    left: '5px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "IQM Inst"
  },
  IONQ: {
    path: "/assets/images/ionq-logo-light.svg",
    size: "70px",
    left: '2px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "IONQ Inst"
  },
  Bluefors: {
    path: "/assets/images/bluefors-logo-white.png.webp",
    size: "90px",
    left: '2px',
    text: 'Measurements',
    textLeft: '8px',
    typeName: "Bluefors Inst"
  },
  Zurich: {
    path: "/assets/quantums/Zurich_Instruments_logo.png",
    size: "130px",
  },

  QUANTUM: {
    path: "/assets/quantums/2-QM-logo-black.svg",
    size: "160px",
  },

  KeySight: {
    path: "/assets/quantums/KEYS_white.webp",
    size: "130px",
  },
  QBLOX: {
    path: "/assets/quantums/3-QBlox-logo.svg",
    size: "150px",
  },
  default: {
    path: "/assets/quantums/4-qick-logo2.svg",
    size: "40px",
  },
};

const imageSize = {
  "SQK Measurement":"150px",
  "Zurich Measurement": "130px",
  "QUANTUM MACHINES": "160px",
  "KeySight":"130px",
  QBLOX: "150px",
};
const venture = [
  "SQK",
  "IONQ",
  "IQM",
  "Bluefors",
];
const deafultImg = "/assets/quantums/4-qick-logo2.svg";

const instrumentType = {
  SQK:"SQK Measurement Driver",
  "IONQ":"IONQ Measurement Driver",
  "IQM":"IQM Measurement Driver",
  "Bluefors":"Bluefors Measurement Driver",
};
const createTime = {
  "SQK Measurement": "17 days",
  "Zurich Measurement": "24m",
  "QUANTUM MACHINES": "1h",
  QBLOX: "35m",
};

function ListMain() {
  const [selectedOption, setSelectedOption] = useState(selectData[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [driver,setDriver] =useState([])
  const router =useHistory();

  useEffect(()=>{
    getInstrumentModel()
   },[])
  
   const getInstrumentModel = async() =>{
      let api = baseUrl +'/v1/instruments'
      try{
        const response =await axiosService.get(api)
        const data = response.data;
        if(response.status ===200){
          // api나오면 코드 변경예정
          const tempDriver = []
          data.map(element => {
            const driver = {
              createTime:data.createdTime,
              type:data.type,
              user:data.user
            }
            tempDriver.push(driver)
          });
          setDriver(tempaVenture)
        }
  
      }catch(err){
        console.log("err",err)
      }
   }

  const pageCount = Math.ceil(venture.length / selectedOption.select);

  const startIndex = (currentPage - 1) * selectedOption.select;
  const endIndex = Number(startIndex) + Number(selectedOption.select);
  const currentVentureItems = venture.slice(startIndex, endIndex, selectedOption.select);


  const handleChangeEvt = (v) => {
    setSelectedOption((prev)=>({...prev, [v.key]: v.value}))
}

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

 const handleRedirectUrl =(id)=>{
    router.push(`/measurement-driver/${id}`)
 }


  return (
    <>
      <div className="list-card-layout">
        {currentVentureItems.map((item) => {
          console.log("item:",item)
          return (     
            <Card
              imageMapper={mapper}
              deafultImg={deafultImg}
              venture={item}
              imageSize={imageSize}
              instrumentType={instrumentType}
              user="admin"
              createTime={createTime}
              handleRedirectUrl={handleRedirectUrl}
            />
          );
        })}
      </div>
      <div className="list-card-selectoption-layout">
        <div className="list-card-selectoption-selectbox">
          <Select id="select" defaultValue="6" data={selectData} handleChange={handleChangeEvt} label="count"  error={false} errMsg={'Please Input Data'}/>
        </div>

          <CirclePagination
            count={pageCount}
            color="primary" 
            currentPage={currentPage}
            onChange={handlePageChange}
          />

      </div>
    </>
  );
}

export default ListMain;