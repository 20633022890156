import Header from "../../../layouts/Header/Index";
import Sidebar from "../../../layouts/Sidebar/Index";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Index.css";
import Statistical from "../../../components/Statistical/Index";
import {
  listPlatformAction,
  deletePlatformAction,
} from "../../../redux/actions/platform";
import { useHistory } from "react-router";
import TableArea from "./TableArea";
import DefaultLayout from "../../../layouts/DefaultLayout";

const Index = () => {
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [listActive, setListActive] = React.useState(0);
  const [listOff, setListOff] = React.useState(0);
  const [listPending, setListPending] = React.useState(0);
  const [listFaild, setListFaild] = React.useState(0);
  const [checked, setChecked] = React.useState(false);
  const [listPlatform, setListPlatform] = React.useState(0);

  const handleSelectedRow = (selected) => {
    setSelectedRow(selected);
  };

 
  return (
      <DefaultLayout  title={"Stations"}>
        <TableArea checked={checked} />  
        <Statistical
          total={listPlatform?.length}
          active={listActive}
          off={listOff}
          pendding={listPending}
          failded={listFaild}
        />
      </DefaultLayout>
  );
};

export default Index;
