



const Snapshots = () =>{
    return <>
    <div id="vm_snapshot_tab" className="tabs-panel " role="tabpanel" aria-hidden="true" aria-labelledby="vm_snapshot_tab-label">
    <div className="row">      
    <div className="large-12 columns">         
        <table className="info_table dataTable">           
            <thead>             
                <tr>                
                    <th>ID</th>                
                    <th>Name</th>                
                    <th>Timestamp</th>                
                    <th>Actions</th>                
                    <th>           
                        <button id="take_snapshot" className="button small success right radius" disabled="disabled">Take snapshot</button>
                    </th>            
                </tr>         
            </thead>           
            <tbody>          
                <tr id="no_snapshots_tr">            
                    <td colSpan={6}>No snapshots to show</td>        
                </tr>          
            </tbody>        
        </table>      
    </div>      
    </div>      
  </div>

    </>
}


export default Snapshots;