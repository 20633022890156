import {
  faLaptop,
  faGlobe,
  faUser,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const QuantumCard = ({ quantum }) => {
  return (
    <div className="column" style={{ padding: 0, maxWidth: "300px" }}>
      <Link to={`/instrument/${quantum.id}`}>
        <ul className="8 provision-pricing-table menu vertical">
          <li className="provision-title">
            <div
              style={{
                display: "inline-flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "baseline",
              }}
            >
              <div
                className="provision_info_vm_button"
                style={{ flexGrow: "1", color: "#555" }}
              >
                <span className="deploying-color right">
                  <FontAwesomeIcon icon={faSquare} />
                </span>
                <img src={quantum?.image} width={quantum?.width} height={quantum?.height} style={{marginBottom: '1rem'}}/>
                {quantum?.name === 'QICK'?<span style={{marginTop:'-0.1rem', position:'absolute', fontSize: '15pt'}}>{quantum.name}</span>:<></>}
                {quantum?.name === 'Dummy Instrument'?<span style={{marginTop:'0.1rem', position:'absolute', fontSize: '12pt'}}>{quantum.name}</span>:<></>}
                {/* {quantum.name} */}
              </div>
              <div className="charter"></div>
            </div>
          </li>
          <li
            className="provision-bullet-item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <FontAwesomeIcon icon={faLaptop} />
            {/* x0.02 - 100GB - */}
            {quantum.softWare}
          </li>
          <li
            className="provision-bullet-item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            <FontAwesomeIcon icon={faGlobe} />
            <div>--</div>
          </li>
          <li
            className="provision-bullet-item-last"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
              }}
            >
              <FontAwesomeIcon icon={faUser} />
              {quantum.user}
            </div>
            <span className="right">{quantum.time}</span>
          </li>
        </ul>
      </Link>
    </div>
  );
};

export default QuantumCard;
