import CircularProgress from "@material-ui/core/CircularProgress";




const Capacity = () => {
    return <>
    <div id="vm_capacity_tab" className="tabs-panel" role="tabpanel" aria-hidden="true" aria-labelledby="vm_capacity_tab-label">
    <div className="row">
      <div className="large-9 columns">
        <table className="info_table dataTable">
          <thead>
            <tr>
              <th>CPU</th>
              <th>VCPU</th>
              <th>Memory</th>
              <th>Cost / CPU</th>
              <th>Cost / MByte</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td id="cpu_info">4</td>
              <td id="vcpu_info">4</td>
              <td id="memory_info">8GB</td>
              <td id="cpu_cost_info">0</td>
              <td id="memory_cost_info">0</td>
              <td>
                <button id="resize_capacity" className="button small success right radius">Resize</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="row">
      <div className="medium-6 columns">
        <div className="row">
          <div className="large-12 columns">
            <span>Real CPU</span>
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns">
            <div className="large-12 columns centered graph vm_cpu_graph" style={{height: 100}}>
              <span id="provision_dashboard_total" style={{fontSize: 80}}>
                <CircularProgress className='loading-w40'/>
              </span>
            </div>
          </div>
        </div>
        <div className="row graph_legend">
          <div className="large-10 columns centered vm_cpu_legend" />
        </div>
      </div>
      <div className="medium-6 columns">
        <div className="row">
          <div className="large-12 columns">
            <span>Real memory</span>
          </div>
        </div>
        <div className="row">
          <div className="large-12 columns centered graph vm_memory_graph" style={{height: 100}}>
            <span id="provision_dashboard_total" style={{fontSize: 80}}>
              <CircularProgress className='loading-w40' />
            </span>
          </div>
        </div>
        <div className="row graph_legend">
          <div className="large-10 columns centered vm_memory_legend" />
        </div>
      </div>
    </div>
  </div>
    </>
}

export default Capacity;