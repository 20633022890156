import "./Card.css";

// router
import { Link } from "react-router-dom";

const Index = ({
  title,
  arrAct,
  arrCnt,
  titleImg,
  titleImgWidth,
  titleImgHeight,
  titleImgMarginLeft,
  titleImgMarginBottom,
}) => {
  const themeColor = process.env?.REACT_APP_THEME;


  return (
    <>
      <div className="small-12 medium-6 columns dashboard-mg0">
        <div>
          <fieldset className={`large-font dashboard-fieldset ${themeColor === 'dark' ? 'dashboard-card-container-dark' : 'dashboard-card-container'}`}>
            <div className={`dashboard-card-flex`}>
              <div className={`dashboard-card-title ${themeColor==='dark'?'dashboard-txt-w':''}`}>{title}</div>
              
              <div className="dashboard-card-header-btn-container">
                {arrAct.length ? (
                  <>
                    {arrAct.map((element, index) => {
                      return (
                        <Link
                          to={element.link}
                          className={
                            `button radius tiny show_vms_tab ` + element?.cls
                          }
                          onClick={element?.act}
                          key={index + title + element?.total}
                        >
                          {element?.icon}
                        </Link>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="dashboard-card-flex dashboard-card-mgt-1">
              {arrCnt.length ? (
                <>
                  {arrCnt.map((ele, idx) => {
                    return (
                      <div
                        className={`card small-12 medium-6 ${themeColor==='dark'?'dashboard-card-contents-container-dark':''}`}
                        key={idx + title + ele?.total}
                      >
                        {ele?.total.length > 10 ? (
                          <>
                            <h3 className={`total_vms ` + ele?.cls}>
                              {ele?.total}{" "}
                            </h3>
                          </>
                        ) : (
                          <>
                            <h3
                              className={`total_vms number-title ` + ele?.cls}
                            >
                              {ele?.total}{" "}
                            </h3>
                          </>
                        )}

                        <span className="small-12 medium-12 columns label-card">
                          {ele?.stt}
                        </span>
                      </div>
                    );
                  })}
                </>
              ) : (
                ""
              )}
            </div>
          </fieldset>
        </div>
      </div>
    </>
  );
};

export default Index;
