import './Statistical.css'

const Index = ({total, active, off, pendding, failded}) => {
    const themeColor = process.env?.REACT_APP_THEME;
    return <>
        <div className="row header-info" >
            <div className="large-12 columns text-center">
                <h5 className="statistical-box" style={{color:themeColor==='dark'?'#fefefe':"#555"}}>
                    <span className="total_vms">{total} </span>
                    <small style={{color:themeColor==='dark'?'#fefefe':"#555"}}>TOTAL</small>
                    <span className="active_vms">{active} </span>
                    <small style={{color:themeColor==='dark'?'#fefefe':"#555"}}>ACTIVE</small>
                    <span className="off_vms">{off} </span>
                    <small style={{color:themeColor==='dark'?'#fefefe':"#555"}}>OFF</small>
                    <span className="pending_vms">{pendding} </span>
                    <small style={{color:themeColor==='dark'?'#fefefe':"#555"}}>PENDING</small>
                    <span className="failed_vms">{failded} </span>
                    <small style={{color:themeColor==='dark'?'#fefefe':"#555"}}>FAILED</small>
                </h5>
            </div>
        </div>
    </>
}

export default Index;