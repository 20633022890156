import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import "../../Create/Create.css";
import Select from "../../../../components/Select";
import Input from "../../Create/Input";
import CustomButton from "../../../../components/Button/CustomButton";
import CheckModal from "../../../../components/Modal/Modal";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ModalRenderer from "./ModalRender";
import { v4 as uuidv4 } from "uuid";

function InputField({
  isvalidation,
  setIsvalidation,
  modelElement,
  setModelElement,
  selectedOption,
  setSelectedOption,
  setCurrentUuid,
  deleteChannel,
  isModalOpen,
  setIsModalOpen,
}) {
  const handleInputChange = (index, id, value) => {
    if (id === "Name" || id === "Description") {
      setModelElement((prevState) => {
        const updatedTitle = prevState.title.map((item, idx) => {
          if (idx === index && item.label === id) {
            return { ...item, value };
          }
          return item;
        });

        return {
          ...prevState,
          title: updatedTitle,
        };
      });
    } else {
      setModelElement((prevState) => {
        const newInstruments = [...prevState.instrument];
        newInstruments[index] = {
          ...newInstruments[index],
          [id]: value,
        };
        return { ...prevState, instrument: newInstruments };
      });
    }
  };

  const handleAddInstrument = () => {
    setModelElement((prevState) => ({
      ...prevState,
      instrument: [
        ...prevState.instrument,
        {
          port_name: "",
          port_no:
            prevState.instrument.length === 0
              ? 1
              : Number(
                  prevState.instrument[prevState.instrument.length - 1]?.port_no
                ) + 1 || 1,
          channel_name: "",
          uuid: uuidv4(),
        },
      ],
    }));
    setIsvalidation((prev) => {
      const updatedInstrumentValidation = prev.instrument
        ? [...prev.instrument, { port_name: false, channel_name: false }]
        : [{ port_name: false, port_name: false }];

      return {
        ...prev,
        instrument: updatedInstrumentValidation,
      };
    });
  };

  const openModalWithUuid = (uuid) => {
    setCurrentUuid(uuid);
    setIsModalOpen(true);
  };
  const instrumentRenderer = () => {
    return (
      <div className="intrument-area">
        {modelElement.instrument.map((item, index) => {
          return (
            <div className="istrument-item">
              <span className="instrument-number">{index + 1}</span>
              <div className="input-div">
                <Input
                  id="port_name"
                  label="Port name"
                  value={item.port_name}
                  index={index}
                  error={isvalidation?.instrument[index]?.port_name}
                  handleChange={handleInputChange}
                />
              </div>

              <Input
                id="channel_name"
                label="Channel name"
                index={index}
                value={item.channel_name}
                error={isvalidation?.instrument[index]?.channel_name}
                handleChange={handleInputChange}
              />
              <div className="close-btn">
                <CustomButton
                  disabled={modelElement.isDisableDelete}
                  onClick={() => {
                    openModalWithUuid(item.uuid);
                  }}
                  type="delete"
                />
              </div>
            </div>
          );
        })}
        {isModalOpen && (
          <CheckModal
            title="Are you sure you want to delete it?"
            open={isModalOpen}
            isDisableCloseBtn={true}
            handleClose={() => {
              setIsModalOpen(false);
            }}
            renderer={() =>
              ModalRenderer(() => deleteChannel(), setIsModalOpen)
            }
          />
        )}
      </div>
    );
  };

  const handleSelectChange = (v) => {
    setIsvalidation((prevState) => ({
      ...prevState,
      selectedOption: false,
    }));
    setSelectedOption((prev) => ({ ...prev, [v.key]: v.value }));
  };

  return (
    <div>
      <div className="input-field-inner-layout">
        {modelElement.title.map((item, index) => {
          return (
            <div className="input-div">
              <Input
                width="100%"
                id={item.label}
                label={item.label}
                index={index}
                value={item.value}
                error={isvalidation[item.label]}
                handleChange={handleInputChange}
              />
            </div>
          );
        })}
        <div className="select-layout">
          <Select
            id="select"
            data={modelElement.type}
            defaultValue={selectedOption.select}
            handleChange={handleSelectChange}
            label="Type"
            error={isvalidation.selectedOption}
          />
        </div>

        {instrumentRenderer()}
        <div className="plus-icon">
          <CustomButton
            width="120"
            onClick={handleAddInstrument}
            type="iconPlusAndLabel"
            label="Add Port name And Channel name"
          />
        </div>
      </div>
    </div>
  );
}

export default InputField;
