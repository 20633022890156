import React, { useLayoutEffect, useState } from "react";
import CustomButton from "../../../../components/Button/CustomButton";
import { useHistory } from "react-router";
import getImagePath from "../util/getImagePath";

function Header({
  setIsReset,
  mapper,
  setSaveModalOpen,
  modelElement,
  setIsDriverDeleteModalOpen,
}) {
  const history = useHistory();
  const [ventureImage, setVentureImage] = useState(null);

  useLayoutEffect(() => {
    if (modelElement) {
      if (modelElement?.data) {
        const imageDetails =
          modelElement && getImagePath(modelElement?.data, mapper);
        setVentureImage(imageDetails);
      }
    }
  }, [modelElement, mapper]);


  return (
    <div className="list-detail-header-layout">
      <div className="list-detail-title">
        {ventureImage && (
          <>
            <img
              style={{ width: ventureImage?.size }}
              src={ventureImage?.path}
              alt="Instrument"
            />
            {ventureImage?.text && (
              <span style={{marginLeft: ventureImage?.textLeft?ventureImage?.textLeft:'8px', fontSize: '15px', fontWeight: 'bold'}}>
                {ventureImage?.text}
              </span>
            )}
            <span>
              {ventureImage.path.includes("qick-logo2") &&
                modelElement.data?.type}
            </span>
          </>
        )}
      </div>
      <div className="custom-btn-layout">
        <CustomButton
          type="goback"
          onClick={() => {
            history.push("/instrument-driver/list");
          }}
        />
        <CustomButton
          type="sync"
          onClick={() => {
            setIsReset(true);
          }}
        />
        <CustomButton
          disabled={modelElement.isDisableDelete}
          type="custom"
          label={"SAVE"}
          onClick={() => {
            setSaveModalOpen(true);
          }}
        />
        <CustomButton
          disabled={modelElement.isDisableDelete}
          type="delete"
          onClick={() => {
            setIsDriverDeleteModalOpen(true);
          }}
        />
      </div>
    </div>
  );
}

export default Header;
