

const UserGroup = ({ icon1, icon2, icon3, name, toggleContent }) => {
    return <>
        <div className="accordion_advanced">
            <a onClick={(e) => toggleContent(e)} href="" className="accordion_advanced_toggle importation">
                {icon1}
                &nbsp; {icon3}&nbsp;{name} </a>
            <div id="advanced_section_2" className="content" style={{ display: 'none' }}>
                <div className="row">
                    <div className="small-12 columns usersContext0">
                        <fieldset>
                            <div className="provision_uid_selector0" data-tab-content>
                                <div id="UsersTableone3Container">
                                    <div className="row select-resources">
                                        <div className="small-12 medium-6 columns" id="selected_ids_row_UsersTableone3">
                                            <label htmlFor="selected_resource_id_UsersTableone3" id="select_resource_UsersTableone3">Please select a User from the list</label>
                                            <span id="selected_resource_UsersTableone3" hidden style={{ display: 'none' }}>You selected the following User:</span>
                                            <span id="select_resource_multiple_UsersTableone3" hidden style={{ display: 'none' }}>Please select one or more users from the list</span>
                                            <span id="selected_resource_multiple_UsersTableone3" hidden style={{ display: 'none' }}>You selected the following users:</span>
                                            <span id="selected_resource_name_UsersTableone3" className="radius label" style={{ display: 'none' }} />
                                            <input id="selected_resource_id_UsersTableone3" type="text" style={{ visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline' }} />
                                        </div>
                                        <div className="small-10 medium-4 columns right">
                                            <input id="UsersTableone3_search" className="search" type="search" placeholder="Search" />
                                        </div>
                                        <div className="small-2 columns right">
                                            <a id="refresh_button_UsersTableone3" href="#" className="refresh-table right">
                                                <i className="fas fa-lg fa-fw fa-sync-alt" />
                                            </a>
                                        </div>
                                    </div>
                                    <div id="UsersTableone3_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                        <table id="UsersTableone3" className="hover dataTable no-footer" role="grid" aria-describedby="UsersTableone3_info">
                                            <thead>
                                                <tr role="row">
                                                    <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="UsersTableone3" aria-sort="descending">ID</th>
                                                    <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="UsersTableone3">Name</th>
                                                    <th aria-label="Group: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="UsersTableone3">Group</th>
                                                    <th aria-label="Auth driver: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="UsersTableone3">Auth driver</th>
                                                    <th aria-label="VMs" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>VMs</th>
                                                    <th aria-label="Memory" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>Memory</th>
                                                    <th aria-label="CPU" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>CPU</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td>1</td>
                                                    <td>serveradmin</td>
                                                    <td>oneadmin</td>
                                                    <td>server_cipher</td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0 / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0KB / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0 / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td>0</td>
                                                    <td>oneadmin</td>
                                                    <td>oneadmin</td>
                                                    <td>core</td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="small-6 columns">
                                                <div className="dataTables_length" id="UsersTableone3_length">
                                                    <label>
                                                        <select name="UsersTableone3_length" aria-controls="UsersTableone3" className>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="dataTables_info" id="UsersTableone3_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div>
                                            </div>
                                            <div className="small-6 columns">
                                                <div className="dataTables_paginate paging_simple_numbers" id="UsersTableone3_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button previous unavailable disabled" aria-controls="UsersTableone3" tabIndex={0} id="UsersTableone3_previous">Previous</li>
                                                        <li className="paginate_button current" aria-controls="UsersTableone3" tabIndex={0}>1</li>
                                                        <li className="paginate_button next unavailable disabled" aria-controls="UsersTableone3" tabIndex={0} id="UsersTableone3_next">Next</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 columns groupContext0">
                        <fieldset>
                            <div className="provision_gid_selector0" data-tab-content>
                                <div id="GroupTableone4Container">
                                    <div className="row select-resources">
                                        <div className="small-12 medium-6 columns" id="selected_ids_row_GroupTableone4">
                                            <label htmlFor="selected_resource_id_GroupTableone4" id="select_resource_GroupTableone4">Please select a Group from the list</label>
                                            <span id="selected_resource_GroupTableone4" hidden style={{ display: 'none' }}>You selected the following Group:</span>
                                            <span id="select_resource_multiple_GroupTableone4" hidden style={{ display: 'none' }}>Please select one or more groups from the list</span>
                                            <span id="selected_resource_multiple_GroupTableone4" hidden style={{ display: 'none' }}>You selected the following groups:</span>
                                            <span id="selected_resource_name_GroupTableone4" className="radius label" style={{ display: 'none' }} />
                                            <input id="selected_resource_id_GroupTableone4" type="text" style={{ visibility: 'hidden', width: '0px !important', height: '0px !important', padding: '0px !important', margin: '0px !important', display: 'inline' }} />
                                        </div>
                                        <div className="small-10 medium-4 columns right">
                                            <input id="GroupTableone4_search" className="search" type="search" placeholder="Search" />
                                        </div>
                                        <div className="small-2 columns right">
                                            <a id="refresh_button_GroupTableone4" href="#" className="refresh-table right">
                                                <i className="fas fa-lg fa-fw fa-sync-alt" />
                                            </a>
                                        </div>
                                    </div>
                                    <div id="GroupTableone4_wrapper" className="dataTables_wrapper dt-foundation no-footer">
                                        <table id="GroupTableone4" className="hover dataTable no-footer" role="grid" aria-describedby="GroupTableone4_info">
                                            <thead>
                                                <tr role="row">
                                                    <th aria-label="ID: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting_desc" tabIndex={0} aria-controls="GroupTableone4" aria-sort="descending">ID</th>
                                                    <th aria-label="Name: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="GroupTableone4">Name</th>
                                                    <th aria-label="Users: activate to sort column ascending" rowSpan={1} colSpan={1} className="sorting" tabIndex={0} aria-controls="GroupTableone4">Users</th>
                                                    <th aria-label="VMs" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>VMs</th>
                                                    <th aria-label="Memory" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>Memory</th>
                                                    <th aria-label="CPU" rowSpan={1} colSpan={1} className="sorting_disabled" style={{ width: 150 }}>CPU</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr role="row" className="odd">
                                                    <td>1</td>
                                                    <td>users</td>
                                                    <td>0</td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0 / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0KB / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>0 / -</span>
                                                        <br />
                                                        <progress value={0} max={-2} />
                                                    </td>
                                                </tr>
                                                <tr role="row" className="even">
                                                    <td>0</td>
                                                    <td>oneadmin</td>
                                                    <td>2</td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                    <td>
                                                        <span className="progress-text right" style={{ fontSize: 12 }}>-</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="small-6 columns">
                                                <div className="dataTables_length" id="GroupTableone4_length">
                                                    <label>
                                                        <select name="GroupTableone4_length" aria-controls="GroupTableone4" className>
                                                            <option value={10}>10</option>
                                                            <option value={25}>25</option>
                                                            <option value={50}>50</option>
                                                            <option value={100}>100</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="dataTables_info" id="GroupTableone4_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div>
                                            </div>
                                            <div className="small-6 columns">
                                                <div className="dataTables_paginate paging_simple_numbers" id="GroupTableone4_paginate">
                                                    <ul className="pagination">
                                                        <li className="paginate_button previous unavailable disabled" aria-controls="GroupTableone4" tabIndex={0} id="GroupTableone4_previous">Previous</li>
                                                        <li className="paginate_button current" aria-controls="GroupTableone4" tabIndex={0}>1</li>
                                                        <li className="paginate_button next unavailable disabled" aria-controls="GroupTableone4" tabIndex={0} id="GroupTableone4_next">Next</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default UserGroup;