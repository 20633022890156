import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import * as React from "react";
import "./index.css";

const CommonTextField = ({
  id,
  label,
  handleChange,
  error,
  errMsg,
  type,
  defaultValue,
  ref,
  width,
  isDisabled,
  value,
  placeholder,
}) => {
  const themeColor = process.env?.REACT_APP_THEME;
  const [data, setData] = React.useState("");

  if (!id) throw "This component requires id";

  React.useEffect(() => {
    if (value != undefined) {
      setData(value);
    }
  }, [value]);

  return (
    <div
      style={{ marginBottom: "1.5rem", width: width ? width : "100%" }}
      className={`${themeColor === "dark" ? "textfield-dark-theme" : ""}`}
    >
      <TextField
        type={type ? type : "text"}
        onChange={(e) => {
          setData(e.target.value);
        }}
        onKeyUp={($event) => {
          handleChange({ key: id, value: $event.target?.value });
        }}
        sx={{
          width: width ? width : "100%",
        }}
        error={error}
        id={id}
        label={label}
        variant="outlined"
        defaultValue={defaultValue ? defaultValue : ""}
        helperText={error ? errMsg : ""}
        className={`${error ? "custom-mui-text-field-error" : ""}`}
        size="small"
        ref={ref}
        InputProps={{
          readOnly: isDisabled ? isDisabled : false,
        }}
        value={data}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CommonTextField;
