import { all } from 'redux-saga/effects';

// sagas
import auth from './auth';
import platform from './platform';
import instrument from './instrument';


export default function* rootSaga(getState) {
    yield all([
        auth(),
        platform(),
        instrument()
    ]);
}
